import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import Button from "../../components/Button";
import Dialog from "../../components/Dialog";
import FormInput from "../../components/formComponents/FormInput";

enum CTA {
  CREATE = "Create new dashboard",
  DUPLICATE = "Duplicate dashboard",
}
interface Props {
  isOpen: boolean;
  isCreateDialogueOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  existingDashboardNames: string[];
  onSubmit: (dashBoardName: string, createNew?: boolean) => void;
  handleClose: () => void;
}

const CreateAndDuplicateNewDashBoard = ({
  isOpen,
  isCreateDialogueOpen,
  setIsOpen,
  existingDashboardNames,
  onSubmit,
  handleClose,
}: Props) => {
  const [cta, setCta] = useState<CTA>(CTA.CREATE);
  const [lowerCasedExistingDashboardNames, setLowerCasedExistingDashboardNames] = useState<string[]>([]);

  useEffect(() => {
    if (isOpen) {
      setCta(isCreateDialogueOpen ? CTA.CREATE : CTA.DUPLICATE);
    }
  }, [isOpen]);

  useEffect(() => {
    setLowerCasedExistingDashboardNames(existingDashboardNames.map((name) => name.toLowerCase()));
  }, [existingDashboardNames]);

  return (
    <Dialog
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
      title={cta}
      dialogContentStyle={{
        padding: "0px",
      }}
      fullWidth={false}
    >
      <div className="p-6 flex flex-col gap-2">
        <Formik
          initialValues={{
            dashboardName: "",
          }}
          validationSchema={Yup.object({
            dashboardName: Yup.string()
              .required("Required")
              .test("unique-dashboard-name", "Dashboard name already exists", (value) => {
                return !lowerCasedExistingDashboardNames.includes(value.toLowerCase().trim());
              })
              .max(50, "Name should be less than 50 characters"),
          })}
          onSubmit={(values) => {
            onSubmit(values.dashboardName.trim(), isCreateDialogueOpen);
            handleClose();
          }}
        >
          {(formik) => {
            useEffect(() => {
              formik.validateForm();
            }, []);

            return (
              <Form className="pt-4 flex flex-col justify-between min-h-[200px] w-[400px] h-[200px]">
                <FormInput
                  label="New dashboard name"
                  name="dashboardName"
                  type="text"
                  className="min-w-[250px]"
                  errorLabelClassName="w-[270px]"
                />
                <div className="flex justify-end mt-8">
                  <Button label={cta} type="submit" disabled={!formik.isValid} />
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Dialog>
  );
};

export default CreateAndDuplicateNewDashBoard;
