import { IconProps } from "./utils/utils";

const LockedIcon = (props: IconProps) => {
  const { height = 20, width = 20, className } = props;
  return (
    <svg height={height} width={width} className={className} fill="currentColor" viewBox="0 0 20 20" {...props}>
      <g>
        <path d="M9.99996 6.10352e-05C6.9123 6.10352e-05 4.40027 2.51209 4.40027 5.59979V8.3417C4.40027 8.52842 4.55179 8.67983 4.73851 8.67983H6.61574C6.80246 8.67983 6.95386 8.52842 6.95386 8.3417V5.59979C6.95386 3.92018 8.32031 2.55369 9.99992 2.55369C11.6795 2.55369 13.046 3.92018 13.046 5.59979V8.3417C13.046 8.52842 13.1975 8.67983 13.3843 8.67983H15.2615C15.4482 8.67983 15.5996 8.52842 15.5996 8.3417V5.59979C15.5997 2.51209 13.0877 6.10352e-05 9.99996 6.10352e-05ZM14.9234 8.00358H13.7223V5.59979C13.7223 3.54729 12.0525 1.87744 10 1.87744C7.94757 1.87744 6.27773 3.54729 6.27773 5.59979V8.00358H5.07664V5.59979C5.07664 2.88498 7.28523 0.67635 9.99999 0.67635C12.7148 0.67635 14.9234 2.88498 14.9234 5.59979V8.00358Z" />
        <path d="M16.0403 8.00357H3.95986C3.15888 8.00357 2.5072 8.65525 2.5072 9.45623V18.5474C2.5072 19.3484 3.15888 20.0001 3.95986 20.0001H16.0403C16.8413 20.0001 17.4929 19.3484 17.4929 18.5474V9.45623C17.4929 8.65525 16.8413 8.00357 16.0403 8.00357ZM16.8167 18.5474C16.8167 18.9755 16.4684 19.3238 16.0403 19.3238H3.95986C3.53173 19.3238 3.18345 18.9755 3.18345 18.5474V9.45623C3.18345 9.0281 3.53173 8.67982 3.95986 8.67982H16.0403C16.4684 8.67982 16.8167 9.0281 16.8167 9.45623V18.5474Z" />
        <path d="M11.137 14.4446C11.5321 14.1118 11.762 13.6252 11.762 13.1022C11.762 12.1306 10.9715 11.3402 9.9999 11.3402C9.02826 11.3402 8.23779 12.1306 8.23779 13.1022C8.23779 13.6252 8.46768 14.1118 8.86279 14.4447L8.48268 16.2557C8.46178 16.3555 8.48689 16.4593 8.55111 16.5384C8.61537 16.6174 8.71178 16.6634 8.81361 16.6634H11.1862C11.2881 16.6634 11.3845 16.6174 11.4487 16.5384C11.5128 16.4592 11.5379 16.3555 11.5171 16.2558L11.137 14.4446ZM10.5801 14.0165C10.4613 14.0921 10.4017 14.2334 10.4307 14.3712L10.7697 15.9871H9.2301L9.56924 14.3713C9.59814 14.2335 9.53869 14.0922 9.41986 14.0166C9.10314 13.815 8.91408 13.4732 8.91408 13.1023C8.91408 12.5036 9.40115 12.0165 9.9999 12.0165C10.5986 12.0165 11.0857 12.5036 11.0857 13.1023C11.0858 13.4733 10.8967 13.815 10.5801 14.0165Z" />
      </g>
    </svg>
  );
};

export default LockedIcon;
