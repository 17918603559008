import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { GetVersion, GetVersionResponse } from "../../../../api/fetcher";
import ChartTooltipElement from "../../../../components/ChartTooltipElement";
import { useMainContext } from "../../../../MainContext";
import { getDisplayWorkloadName, workloadTypes } from "../../../../utils/namesUtils";
import { getWorkloadType } from "../../../../utils/typesUtils";
import ExploreEntityTooltip from "../../../../utils/exploreEntityTooltip";

const VERSION_THRESHOLD = "1.5.2";

const getIsVersionXGreaterThanVersionY = (versionX: string | undefined, versionY: string | undefined): boolean => {
  if (!versionX || !versionY) {
    return false;
  }
  const versionXArray = versionX.split(".");
  const versionYArray = versionY.split(".");
  for (let i = 0; i < versionXArray.length; i++) {
    if (parseInt(versionXArray[i]) >= parseInt(versionYArray[i])) {
      return true;
    }
  }
  return false;
};

const { queryKey, queryFn } = GetVersion();

interface Props {
  color: string;
  value: React.ReactNode;
  label: React.ReactNode;
  secondaryValue?: React.ReactNode;
  rawLabel: string;
  isDashed?: boolean;
  openInNewTab?: boolean;
  disableLink?: boolean;
  textToCopyOnClick?: string;
  isMulticluster?: boolean;
}

const LinkableWorkloadChartTooltipElement = ({
  color,
  value,
  label,
  rawLabel,
  secondaryValue,
  isDashed,
  openInNewTab = true,
  disableLink,
  textToCopyOnClick,
  isMulticluster,
}: Props) => {
  const { currentCluster } = useMainContext();
  const [link, setLink] = useState<string>("");
  const [tooltip, setTooltip] = useState<React.ReactNode>(undefined);

  const { data } = useQuery<GetVersionResponse, Error>({
    queryKey: [queryKey, currentCluster],
    queryFn: queryFn,
    enabled: !disableLink,
  });

  useEffect(() => {
    const isVersionXGreaterOrEqualThanVersionY = getIsVersionXGreaterThanVersionY(
      data?.currentVersion,
      VERSION_THRESHOLD
    );

    let namespace;
    let workloadName;

    const splitLabel = rawLabel.split("/");
    const splitLen = splitLabel.length;

    if (isVersionXGreaterOrEqualThanVersionY) {
      namespace = splitLabel[splitLen - 2] ?? "";
      workloadName = splitLabel[splitLen - 1] ?? "";
    } else {
      namespace = splitLabel[splitLen - 1] ?? "";
      workloadName = splitLabel[splitLen - 2] ?? "";
    }

    let type = splitLabel[splitLen - 3] ?? "";
    type = getWorkloadType(workloadName, type) ?? type;
    type = workloadTypes?.[type as keyof typeof workloadTypes] ?? type;
    type = type ?? "";
    workloadName = getDisplayWorkloadName(workloadName);
    let currentClusterURLParam = currentCluster ? `currentClusterURLParam=${currentCluster}` : "";
    const searchTermUrlParam = `searchTerms=${namespace}/${workloadName}`;
    const typeUrlParam = `types=${type}`;
    let tooltipCluster = undefined;
    if (isMulticluster) {
      tooltipCluster = splitLabel.slice(0, Math.max(0, splitLen - 3)).join("/") ?? "";
      currentClusterURLParam = `currentClusterURLParam=${tooltipCluster}`;
    }

    setLink(`/?${currentClusterURLParam}&${searchTermUrlParam}&${typeUrlParam}&openFirstRowWorkloadOverview=1`);
    setTooltip(
      <ExploreEntityTooltip namespace={namespace} workloadName={workloadName} type={type} cluster={tooltipCluster} />
    );
  }, [data]);

  if (disableLink) {
    return (
      <ChartTooltipElement
        color={color}
        value={value}
        secondaryValue={secondaryValue}
        label={label}
        isDashed={isDashed}
        enableCopyTextOnClick={textToCopyOnClick}
        hasLimitedWidth
        tooltip={tooltip}
      />
    );
  }

  label = (
    <NavLink to={link} target={openInNewTab ? "_blank" : undefined} className="underline hover:text-main-linkBlue ">
      {label}
    </NavLink>
  );
  return (
    <ChartTooltipElement
      color={color}
      value={value}
      secondaryValue={secondaryValue}
      label={label}
      isDashed={isDashed}
      enableCopyTextOnClick={textToCopyOnClick}
      hasLimitedWidth
      tooltip={tooltip}
    />
  );
};

export default LinkableWorkloadChartTooltipElement;
