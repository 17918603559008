import { Typography } from "@mui/material";
import clsx from "clsx";
import React from "react";
import CustomIcon from "../Icons/CustomIcon";

const CUSTOM_ICON_SIZE = 14;
interface Props {
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  wrapperClassName?: string;
  iconClassName?: string;
}

const BuiltInTag = ({ prefix, suffix, wrapperClassName, iconClassName }: Props) => (
  <Typography
    variant="caption"
    className={clsx(
      wrapperClassName,
      "bg-mainMenuSelected rounded-full px-2 text-black flex items-center justify-center gap-1"
    )}
  >
    {prefix}
    <CustomIcon width={CUSTOM_ICON_SIZE} height={CUSTOM_ICON_SIZE} className={iconClassName} />
    Built in
    {suffix}
  </Typography>
);

export default BuiltInTag;
