import FormSwitch from "../../../components/formComponents/FormSwitch";
import FormTitleAndDescription from "../../../components/formComponents/FormTitleAndDescription";
import { DISPLAY_NAME } from "../../../utils/namesUtils";
import { shouldDisableDefaultPolicyInputs } from "../utils";
import { IsSnapshotServer } from "../../../utils/FeaturesHelper";

interface Props {
  isCustomizedPolicy?: boolean;
}

const AutoHealingSwitch = ({ isCustomizedPolicy }: Props) => {
  return (
    <div className="flex flex-col gap-4">
      <FormTitleAndDescription
        title={DISPLAY_NAME.AutoHealingReaction}
        description="ScaleOps will automatically heal and recover pods suffering from CPU stress, out-of-memory issues, and pods with liveness probe errors due to lack of resources."
      />
      <FormSwitch
        name="autoHealingEnabled"
        disabled={!isCustomizedPolicy && shouldDisableDefaultPolicyInputs}
        disableChange={!IsSnapshotServer() && !isCustomizedPolicy}
      />
    </div>
  );
};
export default AutoHealingSwitch;
