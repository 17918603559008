import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { components } from "../../../../api/schema";
import { getClusterNameRow } from "../../../../components/WorkloadStatusByNamespaceSection/utils";
import WorkloadName from "../Aggregation/WorkloadName";
import { getCostRow, getDataRow } from "../NetworkWorkloads/utils";
import { ColumnNames, Field } from "../utils";

const COST_COlUMN_MIN_WIDTH = 175;

export type WORKLOAD_ROW = components["schemas"]["NetworkCostWorkloadNetworkReport"] & {
  displayWorkloadName?: string;
};

export const getColumns = (selectedColumns: (string | undefined)[]): GridColDef[] => [
  {
    field: Field.id,
    headerName: ColumnNames[Field.id],
    hide: !selectedColumns.includes(Field.id),
    flex: 3,
    minWidth: 350,
    type: "string",
    align: "left",
    disableColumnMenu: true,
    sortable: true,
    renderCell: (
      params: GridRenderCellParams<
        components["schemas"]["UtilsWorkloadCostReport"],
        components["schemas"]["UtilsWorkloadCostReport"]
      >
    ) => {
      return <WorkloadName row={params.row} />;
    },
  },
  {
    field: Field.clusterName,
    headerName: ColumnNames[Field.clusterName],
    hide: !selectedColumns.includes(Field.clusterName),
    flex: 1,
    minWidth: 200,
    type: "string",
    align: "left",
    disableColumnMenu: true,
    sortable: true,
    renderCell: (
      params: GridRenderCellParams<
        components["schemas"]["NetworkCostWorkloadNetworkReport"],
        components["schemas"]["NetworkCostWorkloadNetworkReport"]
      >
    ) =>
      getClusterNameRow({
        clusterName: params.row.clusterName,
      }),
  },
  {
    field: Field.replicas,
    headerName: ColumnNames[Field.replicas],
    hide: !selectedColumns.includes(Field.replicas),
    flex: 1,
    minWidth: 150,
    type: "number",
    align: "center",
    headerAlign: "center",
    disableColumnMenu: true,
    sortable: true,
    renderCell: (
      params: GridRenderCellParams<
        components["schemas"]["NetworkCostWorkloadNetworkReport"],
        components["schemas"]["NetworkCostWorkloadNetworkReport"]
      >
    ) => Math.round(params.row?.replicas ?? 0),
  },
  {
    field: Field.totalCost,
    headerName: ColumnNames[Field.totalCost],
    hide: !selectedColumns.includes(Field.totalCost),
    flex: 1,
    minWidth: COST_COlUMN_MIN_WIDTH,
    type: "number",
    align: "center",
    headerAlign: "center",
    disableColumnMenu: true,
    sortable: true,
    renderCell: (
      params: GridRenderCellParams<
        components["schemas"]["NetworkCostWorkloadNetworkReport"],
        components["schemas"]["NetworkCostWorkloadNetworkReport"]
      >
    ) =>
      getCostRow({
        cost: params.row.totalCost,
      }),
  },
  {
    field: Field.crossAZCost,
    headerName: ColumnNames[Field.crossAZCost],
    hide: !selectedColumns.includes(Field.crossAZCost),
    flex: 1,
    minWidth: COST_COlUMN_MIN_WIDTH,
    type: "number",
    align: "center",
    headerAlign: "center",
    disableColumnMenu: true,
    sortable: true,
    renderCell: (
      params: GridRenderCellParams<
        components["schemas"]["NetworkCostWorkloadNetworkReport"],
        components["schemas"]["NetworkCostWorkloadNetworkReport"]
      >
    ) =>
      getCostRow({
        cost: params.row.crossAZCost,
      }),
  },
  // {
  //   field: Field.intraAZCost,
  //   headerName: ColumnNames[Field.intraAZCost],
  //   hide: !selectedColumns.includes(Field.intraAZCost),
  //   flex: 1,
  //   minWidth: COST_COlUMN_MIN_WIDTH,
  //   type: "number",
  //   align: "center",
  //   disableColumnMenu: true,
  //   sortable: true,
  //   renderCell: (
  //     params: GridRenderCellParams<
  //       components["schemas"]["NetworkCostWorkloadNetworkReport"],
  //       components["schemas"]["NetworkCostWorkloadNetworkReport"]
  //     >
  //   ) =>
  //     getCostRow({
  //       cost: params.row.intraAZCost,
  //     }),
  // },
  {
    field: Field.totalDataTransfer,
    headerName: ColumnNames[Field.totalDataTransfer],
    hide: !selectedColumns.includes(Field.totalDataTransfer),
    flex: 1,
    minWidth: COST_COlUMN_MIN_WIDTH,
    type: "number",
    align: "center",
    headerAlign: "center",
    disableColumnMenu: true,
    sortable: true,
    renderCell: (
      params: GridRenderCellParams<
        components["schemas"]["NetworkCostWorkloadNetworkReport"],
        components["schemas"]["NetworkCostWorkloadNetworkReport"]
      >
    ) =>
      getDataRow({
        value: params.row.totalDataTransfer,
      }),
  },
  {
    field: Field.crossAZDataTransfer,
    headerName: ColumnNames[Field.crossAZDataTransfer],
    hide: !selectedColumns.includes(Field.crossAZDataTransfer),
    flex: 1,
    minWidth: COST_COlUMN_MIN_WIDTH,
    type: "number",
    align: "center",
    headerAlign: "center",
    disableColumnMenu: true,
    sortable: true,
    renderCell: (
      params: GridRenderCellParams<
        components["schemas"]["NetworkCostWorkloadNetworkReport"],
        components["schemas"]["NetworkCostWorkloadNetworkReport"]
      >
    ) =>
      getDataRow({
        value: params.row.crossAZDataTransfer,
      }),
  },
  {
    field: Field.intraAZDataTransfer,
    headerName: ColumnNames[Field.intraAZDataTransfer],
    hide: !selectedColumns.includes(Field.intraAZDataTransfer),
    flex: 1,
    minWidth: COST_COlUMN_MIN_WIDTH,
    type: "number",
    align: "center",
    headerAlign: "center",
    disableColumnMenu: true,
    sortable: true,
    renderCell: (
      params: GridRenderCellParams<
        components["schemas"]["NetworkCostWorkloadNetworkReport"],
        components["schemas"]["NetworkCostWorkloadNetworkReport"]
      >
    ) =>
      getDataRow({
        value: params.row.intraAZDataTransfer,
      }),
  },
];
