import { components } from "../../../../api/schema";
import { LimitStrategyItems } from "./LimitsStrategy";
import { quantityToScalar } from "../../../../utils/unitUtils";
import { numberFormatter } from "../../../../utils/formatterUtils";

export type OverridePolicy = components["schemas"]["V1alpha1RecommendationSpecOverridePolicy"];
export type PolicyType = components["schemas"]["V1alpha1Policy"];

export const getDisabledByKeepRequestTooltipContent = (type: "CPU" | "memory") => (
  <>
    Disable <b>"Keep original {type} request"</b> to enable this field.
  </>
);

export const getLimitStrategy = (limitConfigs: components["schemas"]["V1alpha1Limits"] | undefined) => {
  if (!limitConfigs) {
    return LimitStrategyItems.NoOverride;
  }
  if (limitConfigs.keepLimit) {
    return LimitStrategyItems.KeepLimit;
  }
  if (limitConfigs.noLimit) {
    return LimitStrategyItems.NoLimit;
  }
  if (limitConfigs.equalsToRequest) {
    return LimitStrategyItems.EqualsToRequest;
  }
  if (limitConfigs.keepLimitRequestRatio) {
    return LimitStrategyItems.KeepLimitRequestRatio;
  }
  if (limitConfigs.setLimit) {
    return LimitStrategyItems.SetLimit;
  }
  if (limitConfigs.setLimitRequestRatio) {
    return LimitStrategyItems.SetLimitRequestRatio;
  }
  return LimitStrategyItems.NoOverride;
};

export const cpuQuantityToScalar = (quantity: string | null | undefined) => {
  return quantity ? quantityToScalar(quantity) : null;
};

export const memoryQuantityToScalar = (quantity: string | null | undefined) => {
  return quantity ? Number(numberFormatter(Math.round(quantityToScalar(quantity)) / Number(1024 * 1024 * 1024))) : null;
};

export const booleanToNumber = (value: boolean | null | undefined): number => {
  const number = Number(value);
  return isNaN(number) ? NO_OVERRIDE_NUMERIC_VALUE : number;
};

export const NO_OVERRIDE_NUMERIC_VALUE = -9999;
export const NO_OVERRIDE_STRING_VALUE = "noOverride";

export type VALUES = {
  cpuHeadroom: number | null | undefined;
  memoryHeadroom: number | null | undefined;
  histogramCPUPercentile: number | null | undefined;
  histogramMemoryPercentile: number | null | undefined;
  cpuMinAllowed: number | null | undefined;
  memoryMinAllowed: number | null | undefined;
  cpuMaxAllowed: number | null | undefined;
  memoryMaxAllowed: number | null | undefined;
  limitStrategyCpu: LimitStrategyItems | undefined;
  cpuLimitsSetLimitValue: number | null | undefined;
  cpuLimitsSetLimitRequestRatioValue: number | null | undefined;
  limitStrategyMemory: LimitStrategyItems | undefined;
  memoryLimitsSetLimitValue: number | null | undefined;
  memoryLimitsSetLimitRequestRatioValue: number | null | undefined;
  deploymentRecommendationType: string | undefined;
  statefulSetRecommendationType: string | undefined;
  daemonSetRecommendationType: string | undefined;
  deploymentConfigRecommendationType: string | undefined;
  argoRolloutRecommendationType: string | undefined;
  familyRecommendationType: string | undefined;
  keepCpuRequest: number | null | undefined;
  keepMemoryRequest: number | null | undefined;
  historyWindowCpu: string | undefined;
  historyWindowMemory: string | undefined;
};

export const getUpdatePolicy = (
  values: VALUES,
  originalOverridePolicy: components["schemas"]["V1alpha1RecommendationSpecOverridePolicy"]
) => {
  let updatePolicy: components["schemas"]["V1alpha1UpdatePolicy"] | undefined = {
    ...originalOverridePolicy?.updatePolicy,
    updateByTypeMode: {
      ...originalOverridePolicy?.updatePolicy?.updateByTypeMode,
      deployment:
        values.deploymentRecommendationType === NO_OVERRIDE_STRING_VALUE
          ? undefined
          : values.deploymentRecommendationType,
      statefulSet:
        values.statefulSetRecommendationType === NO_OVERRIDE_STRING_VALUE
          ? undefined
          : values.statefulSetRecommendationType,
      daemonSet:
        values.daemonSetRecommendationType === NO_OVERRIDE_STRING_VALUE
          ? undefined
          : values.daemonSetRecommendationType,
      deploymentConfig:
        values.deploymentConfigRecommendationType === NO_OVERRIDE_STRING_VALUE
          ? undefined
          : values.deploymentConfigRecommendationType,
      argoRollout:
        values.argoRolloutRecommendationType === NO_OVERRIDE_STRING_VALUE
          ? undefined
          : values.argoRolloutRecommendationType,
      family:
        values.familyRecommendationType === NO_OVERRIDE_STRING_VALUE ? undefined : values.familyRecommendationType,
    },
  };
  updatePolicy = Object.values(updatePolicy?.updateByTypeMode || {}).filter((value) => value).length
    ? updatePolicy
    : undefined;
  return updatePolicy;
};

const getLimitConfigs = (
  values: VALUES,
  originalOverridePolicy: components["schemas"]["V1alpha1RecommendationSpecOverridePolicy"]
) => {
  let limitConfigsCpu: components["schemas"]["V1alpha1Limits"] | undefined = {
    ...originalOverridePolicy?.rightSizePolicy?.limitConfigs?.cpu,
    keepLimit:
      values.limitStrategyCpu === LimitStrategyItems.NoOverride
        ? undefined
        : values.limitStrategyCpu === LimitStrategyItems.KeepLimit,
    noLimit:
      values.limitStrategyCpu === LimitStrategyItems.NoOverride
        ? undefined
        : values.limitStrategyCpu === LimitStrategyItems.NoLimit,
    equalsToRequest:
      values.limitStrategyCpu === LimitStrategyItems.NoOverride
        ? undefined
        : values.limitStrategyCpu === LimitStrategyItems.EqualsToRequest,
    keepLimitRequestRatio:
      values.limitStrategyCpu === LimitStrategyItems.NoOverride
        ? undefined
        : values.limitStrategyCpu === LimitStrategyItems.KeepLimitRequestRatio,
    setLimit:
      values.limitStrategyCpu === LimitStrategyItems.SetLimit &&
      values.cpuLimitsSetLimitValue &&
      values.cpuLimitsSetLimitValue > 0
        ? (values.cpuLimitsSetLimitValue * 1000).toString() + "m"
        : undefined,
    setLimitRequestRatio:
      values.limitStrategyCpu === LimitStrategyItems.SetLimitRequestRatio &&
      values.cpuLimitsSetLimitRequestRatioValue &&
      values.cpuLimitsSetLimitRequestRatioValue > 0
        ? values.cpuLimitsSetLimitRequestRatioValue
        : undefined,
    percentilePercentage: values.limitStrategyCpu === LimitStrategyItems.NoOverride ? undefined : 0,
    headroomPercentage: values.limitStrategyCpu === LimitStrategyItems.NoOverride ? undefined : 0,
  };
  limitConfigsCpu = Object.values(limitConfigsCpu).filter((value) => value).length ? limitConfigsCpu : undefined;

  let limitConfigsMemory: components["schemas"]["V1alpha1Limits"] | undefined = {
    ...originalOverridePolicy?.rightSizePolicy?.limitConfigs?.memory,
    keepLimit:
      values.limitStrategyMemory === LimitStrategyItems.NoOverride
        ? undefined
        : values.limitStrategyMemory === LimitStrategyItems.KeepLimit,
    noLimit:
      values.limitStrategyMemory === LimitStrategyItems.NoOverride
        ? undefined
        : values.limitStrategyMemory === LimitStrategyItems.NoLimit,
    equalsToRequest:
      values.limitStrategyMemory === LimitStrategyItems.NoOverride
        ? undefined
        : values.limitStrategyMemory === LimitStrategyItems.EqualsToRequest,
    keepLimitRequestRatio:
      values.limitStrategyMemory === LimitStrategyItems.NoOverride
        ? undefined
        : values.limitStrategyMemory === LimitStrategyItems.KeepLimitRequestRatio,
    setLimit:
      values.limitStrategyMemory === LimitStrategyItems.SetLimit &&
      values.memoryLimitsSetLimitValue &&
      values.memoryLimitsSetLimitValue > 0
        ? values.memoryLimitsSetLimitValue.toString() + "Gi"
        : undefined,
    setLimitRequestRatio:
      values.limitStrategyMemory === LimitStrategyItems.SetLimitRequestRatio &&
      values.memoryLimitsSetLimitRequestRatioValue &&
      values.memoryLimitsSetLimitRequestRatioValue > 0
        ? values.memoryLimitsSetLimitRequestRatioValue
        : undefined,
    percentilePercentage: values.limitStrategyMemory === LimitStrategyItems.NoOverride ? undefined : 0,
    headroomPercentage: values.limitStrategyMemory === LimitStrategyItems.NoOverride ? undefined : 0,
  };
  limitConfigsMemory = Object.values(limitConfigsMemory).filter((value) => value).length
    ? limitConfigsMemory
    : undefined;

  const limitConfigs:
    | {
        [key: string]: components["schemas"]["V1alpha1Limits"] | undefined;
      }
    | undefined = {
    cpu: limitConfigsCpu,
    memory: limitConfigsMemory,
  };
  return Object.values(limitConfigs).filter((value) => value).length ? limitConfigs : undefined;
};

const getRequestConfigs = (
  values: VALUES,
  originalOverridePolicy: components["schemas"]["V1alpha1RecommendationSpecOverridePolicy"]
) => {
  let requestsConfigsCpu: components["schemas"]["V1alpha1Requests"] | undefined = {
    ...originalOverridePolicy?.rightSizePolicy?.requestsConfigs?.cpu,
    percentilePercentage:
      (values.histogramCPUPercentile ?? NO_OVERRIDE_NUMERIC_VALUE) >= 0 ? values.histogramCPUPercentile : undefined,
    minAllowed:
      values.cpuMinAllowed && values.cpuMinAllowed > 0 ? (values.cpuMinAllowed * 1000).toString() + "m" : undefined,
    maxAllowed:
      values.cpuMaxAllowed && values.cpuMaxAllowed > 0 ? (values.cpuMaxAllowed * 1000).toString() + "m" : undefined,
    headroomPercentage: (values.cpuHeadroom ?? NO_OVERRIDE_NUMERIC_VALUE) >= 0 ? values.cpuHeadroom : undefined,
    keepRequest: values.keepCpuRequest === NO_OVERRIDE_NUMERIC_VALUE ? undefined : Boolean(values.keepCpuRequest),
  };
  requestsConfigsCpu = Object.values(requestsConfigsCpu).filter((value) => value).length
    ? requestsConfigsCpu
    : undefined;

  let requestsConfigsMemory: components["schemas"]["V1alpha1Requests"] | undefined = {
    ...originalOverridePolicy?.rightSizePolicy?.requestsConfigs?.memory,
    percentilePercentage:
      (values.histogramMemoryPercentile ?? NO_OVERRIDE_NUMERIC_VALUE) >= 0
        ? values.histogramMemoryPercentile
        : undefined,
    minAllowed:
      values.memoryMinAllowed && values.memoryMinAllowed > 0 ? values.memoryMinAllowed.toString() + "Gi" : undefined,
    maxAllowed:
      values.memoryMaxAllowed && values.memoryMaxAllowed ? values.memoryMaxAllowed.toString() + "Gi" : undefined,
    headroomPercentage: (values.memoryHeadroom ?? NO_OVERRIDE_NUMERIC_VALUE) >= 0 ? values.memoryHeadroom : undefined,
    keepRequest: values.keepMemoryRequest === NO_OVERRIDE_NUMERIC_VALUE ? undefined : Boolean(values.keepMemoryRequest),
  };
  requestsConfigsMemory = Object.values(requestsConfigsMemory).filter((value) => value).length
    ? requestsConfigsMemory
    : undefined;

  const requestsConfigs:
    | {
        [key: string]: components["schemas"]["V1alpha1Requests"] | undefined;
      }
    | undefined = {
    cpu: requestsConfigsCpu,
    memory: requestsConfigsMemory,
  };
  return Object.values(requestsConfigs).filter((value) => value).length ? requestsConfigs : undefined;
};

const getWindowByResource = (
  values: VALUES,
  originalOverridePolicy: components["schemas"]["V1alpha1RecommendationSpecOverridePolicy"]
) => {
  const windowByResource:
    | {
        [key: string]: string | undefined;
      }
    | undefined = {
    ...originalOverridePolicy?.rightSizePolicy?.windowByResource,
    cpu: values.historyWindowCpu,
    memory: values.historyWindowMemory,
  };
  return Object.values(windowByResource).filter((value) => value).length ? windowByResource : undefined;
};

export const getRightSizePolicy = (
  values: VALUES,
  originalOverridePolicy: components["schemas"]["V1alpha1RecommendationSpecOverridePolicy"]
) => {
  const rightSizePolicy: components["schemas"]["V1alpha1RightSizePolicy"] | undefined = {
    limitConfigs: getLimitConfigs(values, originalOverridePolicy),
    requestsConfigs: getRequestConfigs(values, originalOverridePolicy),
    windowByResource: getWindowByResource(values, originalOverridePolicy),
  };
  return Object.values(rightSizePolicy).filter((value) => value).length ? rightSizePolicy : undefined;
};
