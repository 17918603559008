import {
  getPolicies,
  GetPoliciesResponse,
  getWorkloadRecommendation,
  GetWorkloadRecommendationResponse,
  OverrideRecommendationPolicy,
  OverrideRecommendationPolicyParams,
} from "../../../../api/fetcher";
import { useMutation, useQuery } from "@tanstack/react-query";
import { components } from "../../../../api/schema";
import { Form, Formik } from "formik";
import Button from "../../../../components/Button";
import { Typography } from "@mui/material";
import HistogramRequestPercentileConfiguration from "./HistogramRequestPercentileConfiguration";
import CircularProgress from "@mui/material/CircularProgress";
import { toast } from "react-toastify";
import { ApiError } from "openapi-typescript-fetch";
import MaximumResourceBoundaries from "./MaximumResourceBoundaries";
import LimitsStrategy from "./LimitsStrategy";
import ApplyingRecommendationSettings from "./ApplyingRecommendationSettings";
import SideMenu, { MenuItem } from "../../../../components/SideMenu/SideMenu";
import BasicSettingsIcon from "../../../../Icons/BasicSettingsIcon";
import LimitIcon from "../../../../Icons/LimitIcon";
import AutomationIcon from "../../../../Icons/AutomationIcon";
import { useEffect, useState } from "react";
import MinimumResourceBoundaries from "./MinimumResourceBoundaries";
import KeepRequest from "./KeepRequest";
import RequestsHeadroom from "./RequestsHeadroom";
import {
  booleanToNumber,
  cpuQuantityToScalar,
  getLimitStrategy,
  getRightSizePolicy,
  getUpdatePolicy,
  memoryQuantityToScalar,
  NO_OVERRIDE_NUMERIC_VALUE,
  NO_OVERRIDE_STRING_VALUE,
  OverridePolicy,
  VALUES,
  PolicyType,
} from "./utils";
import CustomizedIcon from "../../../../Icons/CustomizedIcon";
import * as Yup from "yup";
import { nullablePositiveLimitValidation, nullablePositiveNumberValidation } from "../../../../utils/yupUtils";
import SelectPolicy from "../SelectPolicy";
import { Policy } from "../utils";
import { IsSnapshotAdmin } from "../../../../utils/FeaturesHelper";
import Tooltip from "../../../../components/Tooltip";
import LockIcon from "../../../../Icons/LockIcon";

enum MenuItemType {
  REQUEST = "Request",
  LIMIT = "Limit",
  AUTOMATION = "Automation",
}

const menuItems: MenuItem[] = [
  {
    id: MenuItemType.REQUEST,
    title: MenuItemType.REQUEST,
    icon: <BasicSettingsIcon />,
  },
  {
    id: MenuItemType.LIMIT,
    title: MenuItemType.LIMIT,
    icon: <LimitIcon className="mt-[-3px]" />,
  },
  {
    id: MenuItemType.AUTOMATION,
    title: MenuItemType.AUTOMATION,
    icon: (
      <div className="min-w-5 min-h-5">
        <AutomationIcon />
      </div>
    ),
  },
];

interface Props {
  namespace: string;
  name: string;
  kind: string;
  selectedPolicy: Policy | undefined;
  setSelectedPolicy: (policy: Policy | undefined) => void;
  smartPolicyName?: string;
}

const WorkloadOverridePolicy = ({
  namespace,
  name,
  kind,
  selectedPolicy,
  setSelectedPolicy,
  smartPolicyName,
}: Props) => {
  const isSnapshotAdmin = IsSnapshotAdmin();
  const [selectedMenuItem, setSelectedMenuItem] = useState(MenuItemType.REQUEST);
  const [successfulSave, setSuccessfulSave] = useState(false);

  const { queryFn, queryKey } = getWorkloadRecommendation();
  const {
    data,
    isLoading: getCallIsLoading,
    isFetched,
  } = useQuery<GetWorkloadRecommendationResponse, Error>({
    queryKey: [queryKey, namespace, name, kind, successfulSave],
    queryFn: () => queryFn({ namespace, name: `${kind.toLowerCase()}-${name}` }),
  });

  const policies = getPolicies();
  const { data: policiesData } = useQuery<GetPoliciesResponse, Error>({
    queryKey: [policies.queryKey, selectedPolicy?.name],
    queryFn: policies.queryFn,
  });

  const [selectedPolicyData, setSelectedPolicyData] = useState<PolicyType | undefined>(undefined);
  useEffect(() => {
    const policies = policiesData as GetPoliciesResponse;
    const test = policies?.policies as components["schemas"]["V1alpha1Policy"][];
    const policy = test?.filter((policy) => (policy?.metadata?.name || "") === selectedPolicy?.name)[0];
    setSelectedPolicyData(policy);
  }, [policiesData, selectedPolicy]);

  const recommendation = (data as GetWorkloadRecommendationResponse)?.recommendation;

  const { queryFn: overrideQueryFn } = OverrideRecommendationPolicy();
  const mutation = useMutation((params: OverrideRecommendationPolicyParams) => overrideQueryFn(params), {
    onSuccess: (data) => {
      setSuccessfulSave(true);
      const message = "Override successfully saved!";
      toast.success(message, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
      });
      console.log(message, data);
    },
    onError: (error: ApiError) => {
      const message = "Error saving override";
      toast.error(message);
      console.log(message, error.message);
    },
  });

  const handleSubmit = (values: VALUES) => {
    setSuccessfulSave(false);
    const originalOverridePolicy: OverridePolicy = recommendation?.spec?.overridePolicies || {};

    const newOverridePolicy: OverridePolicy = {
      ...originalOverridePolicy,
      rightSizePolicy: getRightSizePolicy(values, originalOverridePolicy),
      updatePolicy: getUpdatePolicy(values, originalOverridePolicy),
    };

    mutation.mutate({ overridePolicy: newOverridePolicy, name: `${kind.toLowerCase()}-${name}`, namespace });
  };

  return (
    <div className={"flex flex-col gap-5"} style={{ minHeight: "inherit" }}>
      <div className="flex border border-border rounded p-4 items-center gap-10">
        <CustomizedIcon width={30} height={30} />
        <div>
          <b>Override workload policy</b>
          <Typography variant="body2">
            <p>Customize this recommendation by overriding policy settings</p>
          </Typography>
        </div>
        <div className="ml-auto border-border border-l pl-10">
          <SelectPolicy
            selectedPolicy={selectedPolicy}
            setSelectedPolicy={setSelectedPolicy}
            smartPolicyName={smartPolicyName}
            isOverridePolicy={false}
            disabled
          />
        </div>
      </div>
      {getCallIsLoading && (
        <div className="flex m-auto items-center justify-center grow h-full w-full">
          <CircularProgress />
        </div>
      )}
      {!getCallIsLoading && isFetched && (
        <Formik
          initialValues={{
            cpuHeadroom:
              recommendation?.spec?.overridePolicies?.rightSizePolicy?.requestsConfigs?.cpu?.headroomPercentage ??
              NO_OVERRIDE_NUMERIC_VALUE,
            memoryHeadroom:
              recommendation?.spec?.overridePolicies?.rightSizePolicy?.requestsConfigs?.memory?.headroomPercentage ??
              NO_OVERRIDE_NUMERIC_VALUE,
            histogramCPUPercentile:
              recommendation?.spec?.overridePolicies?.rightSizePolicy?.requestsConfigs?.cpu?.percentilePercentage ??
              NO_OVERRIDE_NUMERIC_VALUE,
            histogramMemoryPercentile:
              recommendation?.spec?.overridePolicies?.rightSizePolicy?.requestsConfigs?.memory?.percentilePercentage ??
              NO_OVERRIDE_NUMERIC_VALUE,
            cpuMinAllowed: cpuQuantityToScalar(
              recommendation?.spec?.overridePolicies?.rightSizePolicy?.requestsConfigs?.cpu?.minAllowed
            ),
            memoryMinAllowed: memoryQuantityToScalar(
              recommendation?.spec?.overridePolicies?.rightSizePolicy?.requestsConfigs?.memory?.minAllowed
            ),
            cpuMaxAllowed: cpuQuantityToScalar(
              recommendation?.spec?.overridePolicies?.rightSizePolicy?.requestsConfigs?.cpu?.maxAllowed
            ),
            memoryMaxAllowed: memoryQuantityToScalar(
              recommendation?.spec?.overridePolicies?.rightSizePolicy?.requestsConfigs?.memory?.maxAllowed
            ),
            limitStrategyCpu: getLimitStrategy(
              recommendation?.spec?.overridePolicies?.rightSizePolicy?.limitConfigs?.cpu
            ),
            cpuLimitsSetLimitValue: cpuQuantityToScalar(
              recommendation?.spec?.overridePolicies?.rightSizePolicy?.limitConfigs?.cpu?.setLimit
            ),
            cpuLimitsSetLimitRequestRatioValue:
              recommendation?.spec?.overridePolicies?.rightSizePolicy?.limitConfigs?.cpu?.setLimitRequestRatio,
            limitStrategyMemory: getLimitStrategy(
              recommendation?.spec?.overridePolicies?.rightSizePolicy?.limitConfigs?.memory
            ),
            memoryLimitsSetLimitValue: memoryQuantityToScalar(
              recommendation?.spec?.overridePolicies?.rightSizePolicy?.limitConfigs?.memory?.setLimit
            ),
            memoryLimitsSetLimitRequestRatioValue:
              recommendation?.spec?.overridePolicies?.rightSizePolicy?.limitConfigs?.memory?.setLimitRequestRatio,
            deploymentRecommendationType:
              recommendation?.spec?.overridePolicies?.updatePolicy?.updateByTypeMode?.deployment ??
              NO_OVERRIDE_STRING_VALUE,
            statefulSetRecommendationType:
              recommendation?.spec?.overridePolicies?.updatePolicy?.updateByTypeMode?.statefulSet ??
              NO_OVERRIDE_STRING_VALUE,
            daemonSetRecommendationType:
              recommendation?.spec?.overridePolicies?.updatePolicy?.updateByTypeMode?.daemonSet ??
              NO_OVERRIDE_STRING_VALUE,
            deploymentConfigRecommendationType:
              recommendation?.spec?.overridePolicies?.updatePolicy?.updateByTypeMode?.deploymentConfig ??
              NO_OVERRIDE_STRING_VALUE,
            argoRolloutRecommendationType:
              recommendation?.spec?.overridePolicies?.updatePolicy?.updateByTypeMode?.argoRollout ??
              NO_OVERRIDE_STRING_VALUE,
            familyRecommendationType:
              recommendation?.spec?.overridePolicies?.updatePolicy?.updateByTypeMode?.family ??
              NO_OVERRIDE_STRING_VALUE,
            keepCpuRequest: booleanToNumber(
              recommendation?.spec?.overridePolicies?.rightSizePolicy?.requestsConfigs?.cpu?.keepRequest
            ),
            keepMemoryRequest: booleanToNumber(
              recommendation?.spec?.overridePolicies?.rightSizePolicy?.requestsConfigs?.memory?.keepRequest
            ),
            historyWindowCpu: recommendation?.spec?.overridePolicies?.rightSizePolicy?.windowByResource?.cpu,
            historyWindowMemory: recommendation?.spec?.overridePolicies?.rightSizePolicy?.windowByResource?.memory,
            selectedPolicy: selectedPolicyData,
          }}
          onSubmit={handleSubmit}
          validationSchema={Yup.object({
            cpuMinAllowed: nullablePositiveLimitValidation,
            memoryMinAllowed: nullablePositiveLimitValidation,
            cpuMaxAllowed: nullablePositiveLimitValidation,
            memoryMaxAllowed: nullablePositiveLimitValidation,
            cpuLimitsSetLimitValue: nullablePositiveLimitValidation,
            cpuLimitsSetLimitRequestRatioValue: nullablePositiveNumberValidation,
            memoryLimitsSetLimitRequestRatioValue: nullablePositiveNumberValidation,
          })}
          enableReinitialize
        >
          {({ dirty, resetForm }) => (
            <Form className="flex flex-col gap-5 grow">
              <div className="flex w-full grow min-h-[485px]">
                <SideMenu
                  selectedMenuItem={selectedMenuItem}
                  setSelectedMenuItem={(value: string) => setSelectedMenuItem(value as MenuItemType)}
                  menuItems={menuItems}
                  className="shadow-none md:shadow-none border border-r-0 border-border rounded rounded-r-none pr-6"
                  noPaddingForFirstLevel={true}
                />
                <div
                  className={
                    "flex border border-l-0 border-border rounded rounded-l-none py-10 px-16 w-full overflow-y-auto h-[485px]"
                  }
                >
                  {selectedMenuItem === MenuItemType.REQUEST && (
                    <div className="flex flex-col gap-5 h-fit">
                      <RequestsHeadroom selectedPolicy={selectedPolicyData} />
                      <hr className="mt-2" />
                      <HistogramRequestPercentileConfiguration selectedPolicy={selectedPolicyData} />
                      <hr className="mt-2" />
                      <MinimumResourceBoundaries selectedPolicy={selectedPolicyData} />
                      <hr className="mt-2" />
                      <MaximumResourceBoundaries selectedPolicy={selectedPolicyData} />
                      <hr className="mt-2" />
                      <KeepRequest />
                    </div>
                  )}
                  {selectedMenuItem === MenuItemType.LIMIT && <LimitsStrategy selectedPolicy={selectedPolicyData} />}
                  {selectedMenuItem === MenuItemType.AUTOMATION && (
                    <ApplyingRecommendationSettings selectedPolicy={selectedPolicyData} />
                  )}
                </div>
              </div>
              <div className="flex gap-4 mt-auto flex-row-reverse">
                <Button
                  type="submit"
                  label={
                    <Tooltip
                      title={<b>This operation is restricted to admins only</b>}
                      maxWidth={500}
                      disabled={isSnapshotAdmin}
                      className="flex items-center gap-1"
                    >
                      Save
                      {!isSnapshotAdmin && <LockIcon width={18} height={18} />}
                    </Tooltip>
                  }
                  disabled={!isSnapshotAdmin || !dirty}
                />
                <Button label="Cancel" type="button" onClick={() => resetForm()} disabled={!dirty} />
              </div>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};

export default WorkloadOverridePolicy;
