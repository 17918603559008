import { Typography } from "@mui/material";
import clsx from "clsx";

interface Props {
  value?: string | number;
  secondaryValue?: string | number;
  className?: string;
}

const PolicyValue = ({ value, secondaryValue, className }: Props) => {
  return (
    <Typography
      variant="body2"
      className={clsx(className, "flex gap-1 px-[1px] text-text-lightBlack font-xs")}
      style={{ fontSize: "0.75rem" }}
    >
      In policy:
      <div className="flex flex-col font-bold">
        <span>{value}</span>
        <span>{secondaryValue}</span>
      </div>
    </Typography>
  );
};

export default PolicyValue;
