import { DialogContent, Drawer, Typography } from "@mui/material";
import clsx from "clsx";
import { FieldArray, Form, Formik } from "formik";
import * as yaml from "js-yaml";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { components } from "../../../api/schema";
import Button from "../../../components/Button";
import FormTitleAndDescription from "../../../components/formComponents/FormTitleAndDescription";
import SnapshotWrapper from "../../../components/SnapshotWrapper";
import Tab from "../../../components/Tab";
import Tooltip from "../../../components/Tooltip";
import AddIcon from "../../../Icons/AddIcon";
import LockIcon from "../../../Icons/LockIcon";
import { isBuiltInPolicy } from "../../../utils/policyUtils";
import useIsReadyOnlyFrontEnd from "../../../utils/useIsReadyOnlyFrontEnd";
import useSavePolicy from "../mutations/useSavePolicy";
import useUpdatePolicy from "../mutations/useUpdatePolicy";
import PolicyTitle from "../PolicyTitle";
import PolicyYAML from "../PolicyYAML";
import { PolicyTabs } from "../utils";
import DefaultPolicy from "./DefaultPolicy";
import { HistoryCollectionType } from "./HistoryCollection";
import Rule from "./Rule";

const RULES_VALIDATION = Yup.object({
  policyName: Yup.string().required("Required"),
  useRelativeHistoryData: Yup.mixed<HistoryCollectionType>()
    .oneOf(Object.values(HistoryCollectionType), "Invalid rule")
    .required("History collection is required"),
  sleepMode: Yup.boolean(),
  periods: Yup.array()
    .of(
      Yup.object({
        weeklyConfig: Yup.object({
          days: Yup.array()
            .of(Yup.string().min(0, "Invalid day").max(6, "Invalid day"))
            .min(1, "At least one day is required"),
          beginTime: Yup.string().required("Required"),
          endTime: Yup.string().required("Required"),
        }),
      })
    )
    .min(1, "At least one rule is required")
    .required("Required"),
});

interface Props {
  selectedSchedulePolicy: components["schemas"]["V1alpha1Policy"] | undefined;
  setSelectedSchedulePolicy: (policy: components["schemas"]["V1alpha1Policy"] | undefined) => void;
  isNew: boolean;
  setIsNew: React.Dispatch<React.SetStateAction<boolean>>;
}

const SchedulePolicyDrawer = ({ selectedSchedulePolicy, setSelectedSchedulePolicy, isNew, setIsNew }: Props) => {
  const isReadyOnlyFrontEnd = useIsReadyOnlyFrontEnd();
  const updatePolicy = useUpdatePolicy();
  const savePolicy = useSavePolicy();
  const [selectedTab, setSelectedTab] = useState(PolicyTabs.Policy);
  const [yamlString, setYamlString] = useState<string>("");

  useEffect(() => {
    if (selectedSchedulePolicy) {
      setYamlString(yaml.dump(selectedSchedulePolicy));
    }
  }, [selectedSchedulePolicy]);

  const handleClose = () => {
    setSelectedSchedulePolicy(undefined);
    setIsNew(false);
    setSelectedTab(PolicyTabs.Policy);
  };

  const isCustomizedPolicy = !isBuiltInPolicy(selectedSchedulePolicy) && !isReadyOnlyFrontEnd;

  return (
    <Drawer
      onClose={handleClose}
      open={!!selectedSchedulePolicy}
      style={{ zIndex: 1300 }}
      className="overflow-y-auto flex flex-col top-0"
      anchor="right"
    >
      <PolicyTitle policy={selectedSchedulePolicy} />
      <DialogContent
        className="flex"
        sx={{
          padding: 0,
          overflowY: "hidden",
        }}
      >
        <div className="flex flex-col">
          <div className="flex pt-[10px] bg-background-chipHover gap-[3px]">
            {Object.entries(PolicyTabs)
              .filter(([, value]) => !(isNew && value === PolicyTabs.YAML))
              .map(([key, value]) => (
                <Tab
                  key={key}
                  isSelected={selectedTab === value}
                  onClick={() => {
                    setSelectedTab(value);
                  }}
                  name={value}
                  dataTestId={`policy-${key}-tab`}
                  hasSelectedUnderline
                />
              ))}
          </div>
          {selectedTab === PolicyTabs.Policy && (
            <div className="h-full flex flex-col w-[700px] px-8">
              <Formik
                initialValues={{
                  defaultPolicy: selectedSchedulePolicy?.spec?.policySchedule?.schedulePolicyConfig?.defaultPolicy,
                  displayDefaultHistoryCollectionInput: HistoryCollectionType.Always,
                  rules: selectedSchedulePolicy?.spec?.policySchedule?.schedulePolicyConfig?.rules?.map((rule) => ({
                    ...rule,
                    useRelativeHistoryData: rule.useRelativeHistoryData
                      ? HistoryCollectionType.SelectedPeriods
                      : HistoryCollectionType.Always,
                    periods: rule.periods?.map((period) => {
                      return {
                        ...period,
                        weeklyConfig: {
                          ...period.weeklyConfig,
                          days: period.weeklyConfig?.days?.map((day) => day.toString()),
                        },
                      };
                    }),
                  })),
                }}
                validationSchema={Yup.object({
                  defaultPolicy: Yup.string().min(2, "Must be more than 2 characters").required("Required"),
                  rules: Yup.array().of(RULES_VALIDATION).min(1, "At least one rule is required"),
                })}
                onSubmit={(values) => {
                  const newPolicy: components["schemas"]["V1alpha1Policy"] = {
                    ...selectedSchedulePolicy,
                    spec: {
                      ...selectedSchedulePolicy?.spec,
                      type: "Schedule",
                      policySchedule: {
                        ...selectedSchedulePolicy?.spec?.policySchedule,
                        schedulePolicyConfig: {
                          ...selectedSchedulePolicy?.spec?.policySchedule?.schedulePolicyConfig,
                          defaultPolicy: values.defaultPolicy,
                          rules: values.rules?.map((rule) => {
                            return {
                              ...rule,
                              useRelativeHistoryData:
                                rule.useRelativeHistoryData === HistoryCollectionType.SelectedPeriods,
                              periods: rule.periods?.map((period) => {
                                return {
                                  ...period,
                                  weeklyConfig: {
                                    ...period.weeklyConfig,
                                    days: period.weeklyConfig?.days?.map((day) => Number(day)),
                                  },
                                };
                              }),
                            };
                          }),
                        },
                      },
                    },
                  };

                  if (isNew) {
                    savePolicy.mutate({
                      policy: newPolicy,
                    });
                  } else {
                    updatePolicy.mutate({
                      policyName: newPolicy?.metadata?.name ?? "",
                      policy: newPolicy,
                    });
                  }

                  handleClose();
                }}
              >
                {(formik) => {
                  return (
                    <Form className="h-full">
                      <div className="flex flex-col">
                        <div className="px-8 flex-grow flex flex-col py-10 gap-10 overflow-hidden h-[calc(100vh-178px)] overflow-y-auto scrollbar-thin scrollbar-thumb-background-chipActive scrollbar-track-guideline-lightGray scrollbar-thumb-rounded-md scrollbar-track-rounded-md">
                          <DefaultPolicy isCustomizedPolicy={isCustomizedPolicy} />
                          <hr />
                          <div className="flex flex-col gap-3.5">
                            <FormTitleAndDescription
                              title="Override polices"
                              description={
                                <>
                                  Create and define override policies.
                                  <br />
                                  Set your preferred policies schedule by days and time range.
                                </>
                              }
                            />
                            <FieldArray name="rules">
                              {({ push, remove }) => (
                                <div className="flex flex-col gap-3.5">
                                  {formik.values.rules?.map((_, index, allRules) => (
                                    <>
                                      <Rule
                                        index={index}
                                        remove={remove}
                                        numberOfRules={allRules.length}
                                        isCustomizedPolicy={isCustomizedPolicy}
                                      />
                                    </>
                                  ))}
                                  <button
                                    type="button"
                                    onClick={() =>
                                      push({
                                        policyName: "production",
                                        useRelativeHistoryData: HistoryCollectionType.SelectedPeriods,
                                        periods: [
                                          {
                                            weeklyConfig: {
                                              beginTime: "00:00",
                                              endTime: "00:00",
                                              days: ["0", "1", "2", "3", "4", "5", "6"],
                                            },
                                          },
                                        ],
                                      })
                                    }
                                    // className="flex items-center gap-1 w-fit"
                                    className={clsx({
                                      "flex items-center gap-1 w-fit cursor-not-allowed opacity-20":
                                        !isCustomizedPolicy,
                                      "flex items-center gap-1 w-fit": isCustomizedPolicy,
                                    })}
                                    disabled={!isCustomizedPolicy}
                                  >
                                    <AddIcon width={14} height={14} />
                                    <Typography variant="body2">Add new override policy</Typography>
                                  </button>
                                </div>
                              )}
                            </FieldArray>
                          </div>
                        </div>
                        <div className="flex justify-end gap-4 py-5 px-8 drop-shadow-[0_35px_35px_rgba(0,0,0,0.25)]">
                          {!isCustomizedPolicy && (
                            <SnapshotWrapper wrappedType={"button"}>
                              <Button type="submit" label="Override" disabled={!formik.isValid} />
                            </SnapshotWrapper>
                          )}
                          <Button onClick={handleClose} label="Cancel" type="button" />
                          {isCustomizedPolicy && <Button type="submit" label="Save" disabled={!formik.isValid} />}
                          {!isCustomizedPolicy && (
                            <div className="flex items-center">
                              <Tooltip
                                title={
                                  <>
                                    <b>Default policies can't be edited</b>.<br />
                                    You can only edit customized policies
                                  </>
                                }
                              >
                                <LockIcon width={24} height={24} />
                              </Tooltip>
                            </div>
                          )}
                        </div>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          )}
          {!isNew && selectedTab === PolicyTabs.YAML && !!selectedSchedulePolicy && (
            <div className="flex-grow w-[700px]">
              <PolicyYAML yamlString={yamlString} exportFileName={selectedSchedulePolicy?.metadata?.name ?? "policy"} />
            </div>
          )}
        </div>
      </DialogContent>
    </Drawer>
  );
};

export default SchedulePolicyDrawer;
