import { Typography } from "@mui/material";
import clsx from "clsx";
import WasteIcon from "../../../Icons/WasteIcon";
import Tooltip from "../../../components/Tooltip";
import { WastedResourcesTypes } from "../../../components/WorkloadStatusByNamespaceSection/overviewUtils";
import { capitalizeFirstLetter, spacedCamelCase } from "../../../utils/formatterUtils";
import { METRIC_BOX_CLASS_NAME } from "./utils";

export const getWastedResourceDisplayValue = (e: string) => {
  if (Object.keys(WastedResourcesTypes).includes(e)) {
    return <>{WastedResourcesTypes[e as keyof typeof WastedResourcesTypes]}</>;
  }

  return <>{capitalizeFirstLetter(spacedCamelCase(e))}</>;
};
interface Props {
  wastedResourcesReasons: string[] | undefined;
}

const WastedResourcesWarning = ({ wastedResourcesReasons }: Props) => {
  if (!wastedResourcesReasons) return null;

  return (
    <Tooltip
      title={
        <Typography variant="caption">
          <b>Wasted resources</b>
          <br />
          <span>Not running pods waste resources due to:</span>
          <ul className="list-disc pl-4">
            {wastedResourcesReasons?.map((e) => (
              <li key={e}>{getWastedResourceDisplayValue(e)}</li>
            ))}
          </ul>
        </Typography>
      }
      placement="left"
      maxWidth={400}
    >
      <div className={clsx(METRIC_BOX_CLASS_NAME, "mt-2 flex items-center justify-center gap-1")}>
        Wasted resources{" "}
        <div className="w-[12px]">
          <WasteIcon width={14} height={14} />
        </div>
      </div>
    </Tooltip>
  );
};

export default WastedResourcesWarning;
