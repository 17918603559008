import { ListItemText, MenuItem, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import clsx from "clsx";
import { useEffect, useState } from "react";
import DeleteIcon from "../../../Icons/DeleteIcon";
import { components } from "../../../api/schema";
import Button from "../../../components/Button";
import MultiSelect from "../../../components/MultiSelect";
import SingleSelect, { SingleSelectSize } from "../../../components/SingleSelect";
import Tooltip from "../../../components/Tooltip";
import { GetConfig } from "../../../utils/ConfigHelper";
import { capitalizeFirstLetter } from "../../../utils/formatterUtils";
import { getDataGridSx } from "../../../utils/styleUtils";
import AddRole from "../AddRole";
import NamespaceRules, { FilterType } from "../NamespaceRules";
import getNamespacesAndLabels from "../getNamespacesAndLabels";
import useDeleteRole from "../mutations/useDeleteRole";
import { usePatchRole } from "../mutations/usePatchRole";
import { Rule } from "./SSOWithAuthorization";

const HAS_TAGS_FILTER = false;
const Label = ({ label, hasOptionalSpan }: { label: string; hasOptionalSpan?: boolean }) => (
  <Typography variant="caption">
    {label}
    {hasOptionalSpan && (
      <>
        {" "}
        <span className="text-text-disable text-[10px]">(optional)</span>
      </>
    )}
  </Typography>
);

interface Props {
  rows: Rule[];
  clusters: string[];
  tags: string[];
  ssoConf: components["schemas"]["TypesAuthInfo"];
  isLoading?: boolean;
}

const SSOWithAuthUserTable = ({ rows, tags, clusters, ssoConf, isLoading }: Props) => {
  const { namespaces, labelsByKey } = getNamespacesAndLabels();
  const confData = GetConfig();

  const getIsDisabled = (params: GridRenderCellParams<Rule, Rule>, conf: components["schemas"]["TypesAuthInfo"]) =>
    (conf?.suggestedDefaultAdminGroups?.includes(params?.row.groupName || "") && params?.row.role === "Admin") ||
    (conf?.suggestedDefaultOperatorGroups?.includes(params?.row.groupName || "") && params?.row.role === "Operator") ||
    (conf?.suggestedDefaultViewerGroups?.includes(params?.row.groupName || "") && params?.row.role === "Viewer") ||
    (confData.inCloudMode && params?.row.groupName === confData.userName) ||
    conf?.provider == "token";

  const getColumns = (
    clusters: string[],
    tags: string[],
    namespaces: string[] | undefined,
    labelsByKey: Record<string, string[]> | undefined,
    conf: components["schemas"]["TypesAuthInfo"]
  ): GridColDef[] => [
    {
      field: "groupName",
      headerName: confData.inCloudMode ? "Email" : "Group Name",
      flex: 1,
      type: "string",
      align: "left",
      sortable: false,
    },
    {
      field: "memberStatus",
      headerName: confData.inCloudMode ? "Status" : "",
      flex: 1,
      type: "string",
      align: "left",
      sortable: false,
      renderCell: (params: GridRenderCellParams<Rule, Rule>) => {
        return <>{params.row.memberType == "MEMBER_TYPE_MEMBER" ? <>Member</> : <>Invitation Pending</>}</>;
      },
    },
    {
      field: "role",
      headerName: "Role",
      minWidth: 150,
      type: "string",
      align: "center",
      renderCell: (params: GridRenderCellParams<Rule, Rule>) => {
        const update = usePatchRole();
        const [selected, setSelected] = useState<string>(String(params.row.role));
        const isDisabled = getIsDisabled(params, conf);

        useEffect(() => {
          if (params.row.role !== selected) {
            setSelected(String(params.row.role));
          }
        }, [params.row.role]);
        return (
          <div className="w-full h-full flex items-center justify-center">
            <SingleSelect<string>
              // label={<Typography variant="caption">Group role:</Typography>}
              className="h-[34px] w-[110px]"
              selected={selected}
              setSelected={(option) => {
                setSelected(option);
                params.row.id &&
                  update.mutate({
                    id: params.row.id,
                    role: option,
                  });
              }}
              renderOptionsFunc={() =>
                ["Admin", "Operator", "Viewer"].map((option) => {
                  return (
                    <MenuItem value={option} key={option}>
                      <ListItemText
                        primary={capitalizeFirstLetter(option)}
                        sx={{
                          fontSize: "12px",
                        }}
                      />
                    </MenuItem>
                  );
                })
              }
              size={SingleSelectSize.Small}
              disabled={isDisabled}
            />
          </div>
        );
      },
      sortable: false,
    },
    {
      field: "targetClusters",
      headerName: "Allowed Clusters",
      flex: 1,
      type: "string",
      align: "center",
      renderCell: (params: GridRenderCellParams<Rule, Rule>) => {
        const update = usePatchRole();
        const [selectedTags, setSelectedTags] = useState<string[]>(params.row.targetTags ?? []);
        const [selectedClusters, setSelectedClusters] = useState<string[]>(params.row.targetClusters ?? []);
        const isDisabled = getIsDisabled(params, conf);

        useEffect(() => {
          if (JSON.stringify(params.row.targetTags) !== JSON.stringify(selectedTags)) {
            setSelectedTags(params.row.targetTags ?? []);
          }
        }, [params.row.targetTags]);

        useEffect(() => {
          if (JSON.stringify(params.row.targetClusters) !== JSON.stringify(selectedClusters)) {
            setSelectedClusters(params.row.targetClusters ?? []);
          }
        }, [params.row.targetClusters]);

        return (
          <div
            className={clsx("min-w-full h-full flex flex-col gap-2 items-center justify-center relative px-[5%]", {
              "mt-[-20px]": HAS_TAGS_FILTER,
            })}
          >
            <MultiSelect
              label={HAS_TAGS_FILTER ? <Label label="Clusters" hasOptionalSpan /> : undefined}
              wrapperClassName="w-full"
              className="h-[34px] w-full"
              selected={selectedClusters}
              setSelected={(option) => {
                const optionsToSet: string[] = option.filter((o) => o).map((o) => String(o));
                setSelectedClusters(optionsToSet);

                params.row.id &&
                  update.mutate({
                    id: params.row.id,
                    targetClusters: optionsToSet,
                  });
              }}
              options={clusters}
              fontSize="12px"
              renderValue={(selected: (string | undefined)[]) => {
                if (selected.length === 0) {
                  return "All";
                }
                return `${selected?.join(", ")}`;
              }}
              disabled={isDisabled}
            />
            {HAS_TAGS_FILTER && (
              <MultiSelect
                label={<Label label="Tags" hasOptionalSpan />}
                wrapperClassName="w-full"
                className="h-[34px] w-full"
                selected={selectedTags}
                setSelected={(option) => {
                  const optionsToSet: string[] = option.filter((o) => o).map((o) => String(o));
                  setSelectedTags(optionsToSet);

                  params.row.id &&
                    update.mutate({
                      id: params.row.id,
                      targetTags: optionsToSet,
                    });
                }}
                options={tags}
                fontSize="12px"
                disabled={isDisabled}
              />
            )}
          </div>
        );
      },
      sortable: false,
    },
    {
      field: "namespace",
      headerName: "Allowed Namespaces",
      flex: 1,
      type: "string",
      align: "center",
      renderCell: (params: GridRenderCellParams<Rule, Rule>) => {
        const targetNamespaces = params.row.targetNamespaces;
        const isDisabled = getIsDisabled(params, conf);
        let defaultFilterType: FilterType = FilterType.All;

        switch (true) {
          case !!targetNamespaces &&
            targetNamespaces.length > 0 &&
            JSON.stringify(targetNamespaces[0].labelSelector) !== "{}":
            defaultFilterType = FilterType.ByLabel;
            break;
          case !!targetNamespaces && targetNamespaces.length > 0 && !!targetNamespaces[0].namespaceNames:
            defaultFilterType = FilterType.ByNamespace;
            break;
          default:
            defaultFilterType = FilterType.All;
        }

        return (
          <NamespaceRules
            namespaces={namespaces}
            labelsByKey={labelsByKey}
            targetNamespaces={targetNamespaces}
            id={params.row.id}
            defaultSelectedFilterType={defaultFilterType}
            disable={isDisabled}
          />
        );
      },
      sortable: false,
    },
    {
      field: "delete",
      headerName: "Delete",
      type: "string",
      align: "center",
      renderCell: (params: GridRenderCellParams<Rule, Rule>) => {
        const [iconWasClicked, setIconWasClicked] = useState<boolean>(false);
        const deleteFnc = useDeleteRole();

        const isDisabled = getIsDisabled(params, conf);

        const handleDelete = () => {
          params.row.id &&
            deleteFnc.mutate({
              id: params.row.id,
            });
        };
        return (
          <div className="w-full flex items-center justify-center">
            {!iconWasClicked ? (
              <Tooltip title="Delete Role" className="cursor-pointer" disabled={isDisabled}>
                <DeleteIcon
                  onClick={() => {
                    if (isDisabled) return;
                    setIconWasClicked(true);
                  }}
                  className={clsx({
                    "cursor-pointer": !isDisabled,
                    "opacity-50": isDisabled,
                  })}
                />
              </Tooltip>
            ) : (
              <div className="flex flex-col gap-2 justify-center items-center">
                <Typography variant="body2">Are you sure?</Typography>
                <div className="flex items-center justify-center gap-2">
                  <Button
                    variant="extraSmall"
                    label="No"
                    onClick={() => {
                      setIconWasClicked(false);
                    }}
                    className="opacity-50"
                  />
                  <Button
                    variant="extraSmall"
                    label="Yes"
                    onClick={() => {
                      handleDelete();
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        );
      },
      sortable: false,
    },
  ];
  return (
    <div className="flex flex-col gap-4">
      <AddRole tags={tags} clusters={clusters} isLoading={isLoading} />
      <DataGrid
        sx={{
          ...getDataGridSx(),
        }}
        style={{ height: "100%" }}
        hideFooter={true}
        columns={getColumns(clusters, tags, namespaces, labelsByKey, ssoConf)}
        columnVisibilityModel={{
          namespace: !confData.inCloudMode,
          targetClusters: !confData.inCloudMode,
          memberStatus: confData.inCloudMode || false,
        }}
        rows={rows}
        hideFooterPagination={true}
        autoHeight={true}
        disableSelectionOnClick
        disableColumnMenu
        rowHeight={confData.inCloudMode ? 55 : 170}
        loading={isLoading}
      />
    </div>
  );
};

export default SSOWithAuthUserTable;
