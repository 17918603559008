import { useFormikContext } from "formik";
import { components } from "../../../../api/schema";
import FormSingleSelect from "../../../../components/formComponents/FormSingleSelect";
import FormTitleAndDescription from "../../../../components/formComponents/FormTitleAndDescription";
import * as policyUtils from "../../../../utils/policyUtils";
import { HAP_INPUT_CLASS_NAME, HPA_SECTION_CLASS_NAME } from "../utils";
import { IsSnapshotServer } from "../../../../utils/FeaturesHelper";

const windowCoverageOptions: [string, string][] = [
  ["2 days", "48h"],
  ["4 days", "96h"],
  ["7 days", "168h"],
];

interface Props {
  isCustomizedPolicy?: boolean;
  defaultPolicy?: components["schemas"]["V1alpha1Policy"] | undefined;
}

const RequiredWindowPercentage = ({ isCustomizedPolicy, defaultPolicy }: Props) => {
  const { values } = useFormikContext<{
    workloadsToOptimize: string;
    hasHPAenabled: boolean;
  }>();
  const defaultWindowCoverage = isCustomizedPolicy
    ? policyUtils.requiredWindowCoverageDuration(defaultPolicy)
    : undefined;

  return (
    <div className={HPA_SECTION_CLASS_NAME}>
      <FormTitleAndDescription
        title="Required history for optimization"
        description="HPA optimization will be applied after gathering the minimum required historical data."
      />
      <div className={HAP_INPUT_CLASS_NAME}>
        <FormSingleSelect
          name="requiredWindowCoverageDuration"
          options={windowCoverageOptions}
          defaultValue={defaultWindowCoverage}
          areOptionsDisabled={!IsSnapshotServer() && !isCustomizedPolicy}
          disabled={!values.hasHPAenabled}
        />
      </div>
    </div>
  );
};

export default RequiredWindowPercentage;
