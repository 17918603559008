import ScaleopsIcon from "../../../Icons/ScaleopsIcon";

export enum ExploreEntityMessage {
  ExploreWorkload = "Explore workload overview",
  ExploreNode = "Explore node overview",
  ExploreClusterWorkloads = "Explore cluster workloads",
  ExploreClusterSavings = "Explore cluster savings results",
}

interface Props {
  message: ExploreEntityMessage;
}

const ExploreWorkloadsMessage = ({ message }: Props) => {
  return (
    <div className="flex gap-1 text-primary-purpleBlue">
      <ScaleopsIcon width={12} height={12} className="mt-[3px]" /> <b>{message}</b>
    </div>
  );
};

export default ExploreWorkloadsMessage;
