import { Typography } from "@mui/material";
import clsx from "clsx";
import Tooltip from "../../../components/Tooltip";
import { LABEL_BOX_CLASS_WRAPPER, METRIC_BOX_CLASS_NAME } from "./utils";
import InfoIcon from "../../../Icons/InfoIcon";
import { ReactNode } from "react";

interface Props {
  strategyType: string;
  maxSurge?: number | null;
  maxUnavailable?: number | null;
  cpuOverridden?: boolean;
  memoryOverridden?: boolean;
  className?: string;
  children?: React.ReactNode;
  isAuto: boolean | undefined;
  autoIndication: string | undefined;
}

const autoIndicationMessages: Record<string, ReactNode> = {
  "UI-Workload": "This workload is automated by workload action (UI)",
  "UI-Namespace": "This workload is automated by namespace action (UI)",
  "UI-Cluster": "This workload is automated by cluster action (UI)",
  "CM-Cluster": (
    <>
      This workload is automated by <i>scaleops-cluster-automation</i> config map
    </>
  ),
  "Annotation-default-namespace": (
    <>
      This workload is automated by <i>scaleops.sh/default-auto</i> namespace annotation
    </>
  ),
  "Annotation-default-workload": (
    <>
      This workload is automated by <i>scaleops.sh/default-auto</i> workload annotation
    </>
  ),
  "ANS-Namespace": "This workload is automated by AutomatedNamespace CR",
  "Annotation-force-Workload": (
    <>
      This workload is automated by <i>scaleops.sh/force-auto</i> workload annotation
    </>
  ),
  "COG-default-auto": (
    <>
      This workload is automated by CustomOwnerGrouping CR <i>defaultAuto</i> definition
    </>
  ),
  "Annotation-custom-label": "This workload is automated by custom namespace label (defined in the settings page)",
};

const AdditionalInfoBadge = ({
  strategyType,
  maxSurge,
  maxUnavailable,
  cpuOverridden,
  memoryOverridden,
  className,
  children,
  isAuto,
  autoIndication,
}: Props) => {
  const tooltipContent: ReactNode[] = [];

  if (strategyType && (maxSurge || maxUnavailable)) {
    tooltipContent.push(
      <div>
        <b>{strategyType}</b>
        {maxSurge != undefined && <li>{`maxSurge: ${maxSurge}`}</li>}
        {maxUnavailable != undefined && <li>{`maxUnavailable: ${maxUnavailable}`}</li>}
      </div>
    );
  }
  if (cpuOverridden || memoryOverridden) {
    tooltipContent.push(
      <div>
        <b>Recommendation overridden</b>
        {cpuOverridden && <li>{`cpu`}</li>}
        {memoryOverridden && <li>{`memory`}</li>}
      </div>
    );
  }
  if (isAuto && autoIndication) {
    tooltipContent.push(
      <div>
        <b>Auto Indication</b>
        <li>{autoIndicationMessages[autoIndication] ?? ""}</li>
      </div>
    );
  }

  if (!tooltipContent?.length) {
    return null;
  }

  return (
    <Tooltip
      title={
        <Typography variant="caption" className="flex flex-col gap-2">
          {tooltipContent}
        </Typography>
      }
      placement="left"
      maxWidth={500}
    >
      <div className={clsx(METRIC_BOX_CLASS_NAME, LABEL_BOX_CLASS_WRAPPER, className)}>
        Additional info <InfoIcon width={14} height={14} /> {children}
      </div>
    </Tooltip>
  );
};

export default AdditionalInfoBadge;
