import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import {
  DeleteCustomDashboard,
  DeleteCustomDashboardParams,
  GetAllCustomDashboard,
  GetAllCustomDashboardResponse,
} from "../../api/fetcher";

const deleteCustomDashboard = DeleteCustomDashboard();
const getAllCustomDashboard = GetAllCustomDashboard();

const useDeleteCustomDashboard = () => {
  const queryClient = useQueryClient();
  return useMutation((params: DeleteCustomDashboardParams) => deleteCustomDashboard.queryFn(params), {
    onMutate: ({ dashboardId }) => {
      queryClient.cancelQueries([getAllCustomDashboard.queryKey]);

      queryClient.setQueryData([getAllCustomDashboard.queryKey], (old: GetAllCustomDashboardResponse | undefined) => {
        return {
          ...old,
          dashboards: old?.dashboards?.filter((dashboard) => dashboard !== dashboardId),
        };
      });
    },
    onError: () => {
      toast.error("Dashboard was not deleted");
      console.log("Dashboard was not updated");
    },
    onSuccess: () => {
      console.log("Dashboard updated");
    },
    onSettled: () => {
      queryClient.invalidateQueries([getAllCustomDashboard.queryKey]);
    },
  });
};

export default useDeleteCustomDashboard;
