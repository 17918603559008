import ActiveIssuesAlerts from "./ActiveIssuesAlerts";
import AvailableSavingsAlerts from "./AvailableSavingsAlerts";
import { IsSnapshotServer } from "../../utils/FeaturesHelper";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import clsx from "clsx";

const SnapshotAlertBar = () => {
  const [hideAll, setHideAll] = useState<boolean>(false);
  const [activeIssuesAlertsCount, setActiveIssuesAlertsCount] = useState<number>(0);
  const [lowValueAlertsCount, setLowValueAlertsCount] = useState<number>(0);
  const [isLowValueAlertsCollapsed, setIsLowValueAlertsCollapsed] = useState<boolean>(false);
  const [highValueAlertsCount, setHighValueAlertsCount] = useState<number>(0);
  const [totalAlertCount, setTotalAlertCount] = useState<number>(0);

  if (!IsSnapshotServer()) {
    return null;
  }

  useEffect(() => {
    localStorage.getItem("hideSnapshotAlerts") === "true" && setHideAll(true);
  }, []);

  useEffect(() => {
    setTotalAlertCount(activeIssuesAlertsCount + lowValueAlertsCount + highValueAlertsCount);
  }, [activeIssuesAlertsCount, lowValueAlertsCount, highValueAlertsCount]);

  return (
    <div
      className={clsx(
        "fixed top-5 right-5 z-[1500] w-auto h-auto transition-all overflow-hidden duration-[200ms] ease-out shadow-2xl rounded-lg ",
        {
          "max-h-0": hideAll,
          "max-h-[3000px]": !hideAll,
          "pt-2":
            (totalAlertCount < 2 && !lowValueAlertsCount) ||
            (!isLowValueAlertsCollapsed && !highValueAlertsCount && !activeIssuesAlertsCount),
        }
      )}
    >
      <div
        className={clsx("flex flex-row-reverse items-center gap-1.5", {
          "flex-col": activeIssuesAlertsCount || highValueAlertsCount || !isLowValueAlertsCollapsed,
        })}
      >
        {totalAlertCount > 1 && (
          <div className="flex w-full h-6 pr-[5px]">
            <div
              className="flex z-[1] cursor-pointer w-6 ml-auto px-1 items-center rounded-2xl text-sm bg-white transition-width duration-200 ease-out border border-primary-purpleBlue hover:w-[80px] hover:pl-1.5 hover:gap-1"
              onClick={() => setHideAll(true)}
            >
              <div className="whitespace-nowrap overflow-hidden mr-auto">hide all</div>
              <CloseIcon sx={{ fontSize: 14 }} color="action" />
            </div>
          </div>
        )}
        <div className="flex flex-col relative gap-2">
          <div className="absolute inset-0 bg-black z-0 rounded-lg opacity-[.07]"></div>
          <ActiveIssuesAlerts setActiveIssuesAlertsCount={setActiveIssuesAlertsCount} />
          <AvailableSavingsAlerts
            setHighValueAlertsCount={setHighValueAlertsCount}
            setLowValueAlertsCount={setLowValueAlertsCount}
            setIsLowValueAlertsCollapsed={setIsLowValueAlertsCollapsed}
          />
        </div>
      </div>
    </div>
  );
};

export default SnapshotAlertBar;
