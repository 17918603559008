import FormSwitch from "../../../components/formComponents/FormSwitch";
import FormTitleAndDescription from "../../../components/formComponents/FormTitleAndDescription";
import { shouldDisableDefaultPolicyInputs } from "../utils";
import { IsSnapshotServer } from "../../../utils/FeaturesHelper";

interface Props {
  isCustomizedPolicy?: boolean;
}

const SkipRolloutUponAutomation = ({ isCustomizedPolicy }: Props) => {
  return (
    <div className="flex flex-col gap-4">
      <FormTitleAndDescription
        title="Optimization upon automation"
        description="Allow ScaleOps to rollout upon workload automation."
      />
      <FormSwitch
        name="skipRolloutUponAutomation"
        disabled={!isCustomizedPolicy && shouldDisableDefaultPolicyInputs}
        disableChange={!IsSnapshotServer() && !isCustomizedPolicy}
        getCheckedValue={(value) => !value}
        setOnChangeValue={(checked) => !checked}
      />
    </div>
  );
};
export default SkipRolloutUponAutomation;
