import { GetTopKQueryKey } from "../../../api/fetcher";
import { OverviewLinkType } from "../../../utils/graphUtils";
import TopValuesGraph from "../../Analytics/AnalyticsV2/Graphs/TopValuesGraph";
import useGetGraphState from "../useGetGraphsState";
import { CommonGraphProps, GraphDisplayName, NODE_SYNC_ID, PERCENTAGE_Y_AXIS_DOMAIN } from "./utils";

const NodeMemoryAllocationGraph = ({
  isMulticluster,
  tooltipTrigger,
  disabledZoom,
  updateActiveTooltips,
}: CommonGraphProps) => {
  const { date, setDate, numberOfElements, frozenAndClickableTab } = useGetGraphState();

  return (
    <TopValuesGraph
      title={GraphDisplayName.NodeMemoryAllocationGraph}
      queryParams={{
        from: date.range ? undefined : date.from,
        to: date.range ? undefined : date.to,
        range: date.range,
        queryKey: GetTopKQueryKey.NodeMemoryAllocationUsage,
      }}
      setDate={setDate}
      isMulticluster={isMulticluster}
      topK={numberOfElements}
      YAxisDomain={PERCENTAGE_Y_AXIS_DOMAIN}
      YAxisFormatter={(value) => `${value}%`}
      tooltipTrigger={tooltipTrigger}
      disabledZoom={disabledZoom}
      updateActiveTooltips={updateActiveTooltips}
      enableCopyTextOnClick
      targetTooltipLink={OverviewLinkType.Node}
      frozenTooltipType={frozenAndClickableTab}
      syncId={NODE_SYNC_ID}
    />
  );
};

export default NodeMemoryAllocationGraph;
