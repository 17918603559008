import FormSwitch from "../../../components/formComponents/FormSwitch";
import FormTitleAndDescription from "../../../components/formComponents/FormTitleAndDescription";
import { shouldDisableDefaultPolicyInputs } from "../utils";
import { IsSnapshotServer } from "../../../utils/FeaturesHelper";

interface Props {
  isCustomizedPolicy?: boolean;
}

const manageHPA = ({ isCustomizedPolicy }: Props) => {
  return (
    <div className="flex flex-col gap-4">
      <FormTitleAndDescription
        title="Update HPA resource based triggers"
        description="Update HPA CPU and Memory utilization based triggers according to the new container requests size."
        info={
          <>
            ScaleOps will update the resource metrics of type <b>CPU/Memory</b> from Utilization to <b>AverageValue</b>{" "}
            in order to ensure intended threshold
          </>
        }
      />
      <FormSwitch
        name="manageHPA"
        disabled={!isCustomizedPolicy && shouldDisableDefaultPolicyInputs}
        disableChange={!IsSnapshotServer() && !isCustomizedPolicy}
      />
    </div>
  );
};
export default manageHPA;
