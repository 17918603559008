import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import AlertIcon from "../Icons/AlertIcon";
import GitForkIcon from "../Icons/GitForkIcon";
import { useMainContext } from "../MainContext";
import MainMenuItem from "./MainMenuItem";
import MonitoringMenu from "./MonitoringMenu";
import MenuHorizontalRule from "./MenuHorizontalRule";
import ReportsMenu from "./ReportsMenu";
import SavingsIcon from "../Icons/SavingsIcon";

const TO_URL = {
  multicluster: "/multicluster",
  analytics: "/multiCluster/analytics",
  insight: "/multiCluster/insight",
  report: "/multiCluster/reports",
};

const MultiClusterMenu = () => {
  const { currentCluster } = useMainContext();
  const [selectedClusterNamesHistory, setSelectedClusterNamesHistory] = useState<string[]>([]);

  useEffect(() => {
    if (currentCluster && !selectedClusterNamesHistory.includes(currentCluster)) {
      setSelectedClusterNamesHistory([...selectedClusterNamesHistory, currentCluster]);
    }
    if (selectedClusterNamesHistory.length > 0 && currentCluster) {
      toast.success(`Cluster changed successfully to ${currentCluster}`);
    }
  }, [currentCluster]);

  return (
    <div className="flex flex-col gap-3">
      <MenuHorizontalRule title="Multi Cluster" />
      <MainMenuItem icon={<GitForkIcon />} title="Clusters" to={TO_URL.multicluster} />
      <MainMenuItem title="Alerts" icon={<AlertIcon />} to="/multiCluster/alerts" />
      <ReportsMenu />
      <MonitoringMenu isMultiCluster={true} />
      <MainMenuItem title="Savings Result" to="/multiCluster/dashboards/workloads" icon={<SavingsIcon />} />
    </div>
  );
};

export default MultiClusterMenu;
