import { GetTopKQueryKey } from "../../../api/fetcher";
import { getMemoryValue } from "../../../utils/formatterUtils";
import { OverviewLinkType } from "../../../utils/graphUtils";
import TopValuesGraph from "../../Analytics/AnalyticsV2/Graphs/TopValuesGraph";
import useGetGraphState from "../useGetGraphsState";
import { CommonGraphProps, GraphDisplayName, NODE_SYNC_ID, PERCENTAGE_Y_AXIS_DOMAIN } from "./utils";

const NodeMemoryUtilizationGraph = ({
  isMulticluster,
  tooltipTrigger,
  disabledZoom,
  updateActiveTooltips,
}: CommonGraphProps) => {
  const { date, setDate, numberOfElements, frozenAndClickableTab } = useGetGraphState();

  return (
    <TopValuesGraph
      title={GraphDisplayName.NodeMemoryUtilizationGraph}
      queryParams={{
        from: date.range ? undefined : date.from,
        to: date.range ? undefined : date.to,
        range: date.range,
        queryKey: GetTopKQueryKey.NodeMemoryUtilization,
      }}
      setDate={setDate}
      isMulticluster={isMulticluster}
      YAxisDomain={PERCENTAGE_Y_AXIS_DOMAIN}
      YAxisFormatter={(value) => `${value}%`}
      tooltipTrigger={tooltipTrigger}
      disabledZoom={disabledZoom}
      updateActiveTooltips={updateActiveTooltips}
      getSecondaryValue={(label, _, secondaryValues) => {
        const secondaryValue = secondaryValues?.[String(label)];
        if (!secondaryValue) return null;
        return <>| {getMemoryValue(secondaryValue)}</>;
      }}
      enableCopyTextOnClick
      targetTooltipLink={OverviewLinkType.Node}
      tooltipValueTitle={"util | alloc"}
      frozenTooltipType={frozenAndClickableTab}
      syncId={NODE_SYNC_ID}
      topK={numberOfElements}
    />
  );
};

export default NodeMemoryUtilizationGraph;
