import { getDisplayWorkloadName } from "./namesUtils";
import { getWorkloadType, getWorkloadTypeDisplayValue } from "./typesUtils";
import ExploreWorkloadsMessage, {
  ExploreEntityMessage,
} from "../components/autoscalers/NodeOverview/ExploreWorkloadsMessage";

interface Props {
  namespace?: string;
  workloadName?: string;
  type?: string;
  cluster?: string;
  namespaceLabel?: string;
  nameLabel?: string;
  exploreEntityMessage?: ExploreEntityMessage;
}

const keyValRender = (key: string, value?: string) =>
  value && (
    <div>
      <b>{key}:</b> {value}
    </div>
  );

const ExploreEntityTooltip = ({
  namespace,
  workloadName,
  type,
  cluster,
  namespaceLabel = "Namespace",
  nameLabel = "Name",
  exploreEntityMessage,
}: Props) => {
  type = getWorkloadType(workloadName, type);
  workloadName = getDisplayWorkloadName(workloadName ?? "");
  type = getWorkloadType(workloadName, type);
  return (
    <div className={"flex flex-col gap-0.5"}>
      {namespace && keyValRender(namespaceLabel, namespace)}
      {keyValRender(nameLabel, workloadName)}
      {type && keyValRender("Type", getWorkloadTypeDisplayValue(type)?.toLowerCase())}
      {cluster && keyValRender("Cluster", cluster)}
      <ExploreWorkloadsMessage message={exploreEntityMessage ?? ExploreEntityMessage.ExploreWorkload} />
    </div>
  );
};

export default ExploreEntityTooltip;
