import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import {
  GetCostBreakdown,
  GetCostBreakdownAggregations,
  GetCostBreakdownAggregationsResponse,
  GetCostBreakdownResponse,
  GetNetworkCostBreakdown,
  GetNetworkCostBreakdownAggregations,
  GetNetworkCostBreakdownAggregationsResponse,
  GetNetworkCostBreakdownResponse,
} from "../../../api/fetcher";
import GraphCircularLoader from "../../../components/GraphCircularLoader";
import Loading from "../../../components/Loading";
import useHpaOptimizationEnabled from "../../../components/WorkloadStatusByNamespaceSection/useHpaOptimizationEnabled";
import useAggregationFilters from "../Tables/Aggregation/hooks/useAggregationFilters";
import { TableType } from "../Tables/utils";
import useWorkloadsFilters from "../Tables/Workloads/hooks/useWorkloadsFilters";
import { CostReportType } from "../utils";
import EntireWindowChart from "./EntireWindowChart";
import { ChartCostType, EntireWindowData, ToBreakdownCostTypes } from "./utils";

const OTHERS = "Others";
const TOP_K = 9;

const { queryKey, queryFn } = GetCostBreakdown();
const { queryKey: aggregationQueryKey, queryFn: aggregationQueryFn } = GetCostBreakdownAggregations();
const getNetworkCostBreakdown = GetNetworkCostBreakdown();
const getNetworkCostBreakdownAggregations = GetNetworkCostBreakdownAggregations();
interface Props {
  reportType: CostReportType;
  selectedTable: TableType;
  costType: ChartCostType;
}

const EntireWindowChartContainer = ({ selectedTable, reportType, costType }: Props) => {
  const workloadsFilters = useWorkloadsFilters();
  const workloadsFiltersLength = Object.keys(workloadsFilters).length;
  const aggregationFilters = useAggregationFilters();
  const aggregationFiltersLength = Object.keys(aggregationFilters).length;

  const [graphData, setGraphData] = useState<EntireWindowData[]>([]);
  const enableHpaOptimization = useHpaOptimizationEnabled();

  const computeOrGpu = reportType === CostReportType.Compute || reportType === CostReportType.Gpu;

  const getCostBreakdownEnabled = selectedTable === TableType.Workloads && !!workloadsFiltersLength && computeOrGpu;
  const { data, isLoading, error } = useQuery<GetCostBreakdownResponse, Error>({
    queryKey: ["entirewindow", queryKey, workloadsFilters, TOP_K, costType, reportType],
    queryFn: () =>
      queryFn({
        ...workloadsFilters,
        topk: TOP_K,
        multiCluster: true,
        type: ToBreakdownCostTypes(costType, enableHpaOptimization),
        gpuWorkloadsOnly: reportType === CostReportType.Gpu,
      }),
    enabled: getCostBreakdownEnabled,
  });

  const getCostBreakdownAggregationsEnabled =
    selectedTable === TableType.Aggregation && !!aggregationFiltersLength && computeOrGpu;
  const {
    data: aggregationData,
    isLoading: aggregationIsLoading,
    error: aggregationError,
  } = useQuery<GetCostBreakdownAggregationsResponse, Error>({
    queryKey: ["entirewindow", aggregationQueryKey, aggregationFilters, TOP_K, costType, reportType],
    queryFn: () =>
      aggregationQueryFn({
        ...aggregationFilters,
        topk: TOP_K,
        multiCluster: true,
        type: ToBreakdownCostTypes(costType, enableHpaOptimization),
        gpuWorkloadsOnly: reportType === CostReportType.Gpu,
      }),
    enabled: getCostBreakdownAggregationsEnabled,
  });

  const getNetworkCostBreakdownEnabled =
    selectedTable === TableType.Workloads && !!workloadsFiltersLength && reportType === CostReportType.Network;
  const {
    data: networkWorkloadsData,
    isLoading: networkWorkloadsIsLoading,
    error: networkWorkloadsError,
  } = useQuery<GetNetworkCostBreakdownResponse, Error>({
    queryKey: [getNetworkCostBreakdown.queryKey, workloadsFilters, reportType],
    queryFn: () =>
      getNetworkCostBreakdown.queryFn({
        ...workloadsFilters,
        multiCluster: true,
        topk: TOP_K,
      }),
    enabled: getNetworkCostBreakdownEnabled,
  });

  const getNetworkCostBreakdownAggregationsEnabled =
    selectedTable === TableType.Aggregation && !!aggregationFiltersLength && reportType === CostReportType.Network;
  const {
    data: networkAggregationData,
    isLoading: networkAggregationIsLoading,
    error: networkAggregationError,
  } = useQuery<GetNetworkCostBreakdownAggregationsResponse, Error>({
    queryKey: [getNetworkCostBreakdownAggregations.queryKey, aggregationFilters, reportType],
    queryFn: () =>
      getNetworkCostBreakdownAggregations.queryFn({
        ...aggregationFilters,
        multiCluster: true,
        topk: TOP_K,
      }),
    enabled: getNetworkCostBreakdownAggregationsEnabled,
  });

  useEffect(() => {
    let rawData:
      | {
          id?: string;
          value?: number;
          isGpuEnabledWorkload?: boolean;
        }[]
      | undefined = undefined;

    switch (true) {
      case selectedTable === TableType.Workloads && computeOrGpu:
        rawData = data?.costs?.[0]?.costs ?? [];
        break;
      case selectedTable === TableType.Aggregation && computeOrGpu:
        rawData = aggregationData?.costs?.[0]?.costs ?? [];
        break;
      case selectedTable === TableType.Workloads && reportType === CostReportType.Network:
        rawData = networkWorkloadsData?.costs?.[0]?.costs ?? [];
        break;
      case selectedTable === TableType.Aggregation && reportType === CostReportType.Network:
        rawData = networkAggregationData?.costs?.[0]?.costs ?? [];
        break;
      default:
        break;
    }

    if (rawData) {
      let graphData = rawData.map((d) => ({
        name: String(d.id),
        cost: d.value ? Number(d.value) : 0,
        isGpuEnabledWorkload: d.isGpuEnabledWorkload,
      }));

      graphData = graphData.sort((a, b) => {
        if (a.name === "timestamp") {
          return -1;
        } else if (b.name === "timestamp") {
          return 1;
        } else if (a.name === OTHERS) {
          return 1;
        } else if (b.name === OTHERS) {
          return -1;
        } else {
          return b.cost - a.cost;
        }
      });

      setGraphData(graphData);
    }
  }, [data, aggregationData, networkWorkloadsData, networkAggregationData, selectedTable, reportType]);

  if (error || aggregationError || networkWorkloadsError || networkAggregationError) {
    console.log("Error fetching costs", error || aggregationError);
  }

  const loading =
    (isLoading && getCostBreakdownEnabled) ||
    (aggregationIsLoading && getCostBreakdownAggregationsEnabled) ||
    (networkWorkloadsIsLoading && getNetworkCostBreakdownEnabled) ||
    (networkAggregationIsLoading && getNetworkCostBreakdownAggregationsEnabled);

  if (loading) {
    return (
      <Loading loadingText={`Loading ${selectedTable === TableType.Workloads ? "Workloads" : "Aggregations"}...`} />
    );
  }

  if (graphData.length === 0) {
    return null;
  }

  return (
    <div className="relative flex items-center align-center w-full h-full">
      {(isLoading && selectedTable === TableType.Workloads && computeOrGpu) ||
      (aggregationIsLoading && selectedTable === TableType.Aggregation && computeOrGpu) ||
      (networkWorkloadsIsLoading && selectedTable === TableType.Workloads && reportType === CostReportType.Network) ||
      (networkAggregationIsLoading &&
        selectedTable === TableType.Aggregation &&
        reportType === CostReportType.Network) ? (
        <GraphCircularLoader />
      ) : null}
      <EntireWindowChart data={graphData} />
    </div>
  );
};

export default EntireWindowChartContainer;
