import { GetConfig } from "./ConfigHelper";

export function FeatureEnabled(name: string): boolean {
  const config = GetConfig();
  return config.features?.includes(name) || false;
}

export function IsFreeDistribution(): boolean {
  const config = GetConfig();
  if (config.isFreeDist != undefined) {
    return config.isFreeDist;
  }
  return false;
}

export function IsBigCluster(): boolean {
  const config = GetConfig();
  if (config.isBigCluster != undefined) {
    return config.isBigCluster;
  }
  return false;
}

export function IsSnapshotServer(): boolean {
  return !!sessionStorage.getItem("snapshot-server");
}

export function IsSnapshotAdmin(): boolean {
  return !!sessionStorage.getItem("is-snapshot-admin");
}
