import { Typography } from "@mui/material";

const ICON_SIZE = 45;

interface Props {
  stepNumber: number;
  showIcon?: boolean;
  title: React.ReactNode;
  content: React.ReactNode;
}

const InstallStep = ({ stepNumber, title, content, showIcon = true }: Props) => (
  <div className="flex gap-[1.875rem] w-full">
    <Typography
      variant="h5"
      className="rounded-full bg-guideline-darkPurple flex justify-center items-center text-white max-[1298px]:hidden "
      style={{
        width: ICON_SIZE,
        minWidth: ICON_SIZE,
        height: ICON_SIZE,
        visibility: showIcon ? "visible" : "hidden",
      }}
    >
      {stepNumber}
    </Typography>

    <div className="flex flex-col gap-2 max-w-full">
      <Typography variant="h5" className="">
        {title}
      </Typography>
      <div>{content}</div>
    </div>
  </div>
);

export default InstallStep;
