import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import {
  GetAllCustomDashboard,
  GetAllCustomDashboardResponse,
  GetCustomDashboard,
  GetCustomDashboardResponse,
  PostCustomDashboardParams,
  UpdateCustomDashboard,
} from "../../api/fetcher";

const updateCustomDashboard = UpdateCustomDashboard();
const getAllCustomDashboard = GetAllCustomDashboard();
const getCustomDashboard = GetCustomDashboard();

const usePostCustomDashboard = () => {
  const queryClient = useQueryClient();
  return useMutation((params: PostCustomDashboardParams) => updateCustomDashboard.queryFn(params), {
    onMutate: ({ dashboardId, layout }) => {
      queryClient.cancelQueries([getAllCustomDashboard.queryKey]);
      queryClient.cancelQueries([getCustomDashboard.queryKey]);

      queryClient.setQueryData([getAllCustomDashboard.queryKey], (old: GetAllCustomDashboardResponse | undefined) => {
        const doesOldDataIncludeDashboard = dashboardId && old?.dashboards?.includes(dashboardId);
        if (doesOldDataIncludeDashboard) {
          return {
            ...old,
            dashboards: old?.dashboards ? [...old.dashboards, dashboardId] : [dashboardId],
          };
        }
      });

      queryClient.setQueryData(
        [getCustomDashboard.queryKey, dashboardId],
        (old: GetCustomDashboardResponse | undefined) => {
          return {
            ...old,
            layout,
          };
        }
      );
    },
    onError: () => {
      toast.error("Dashboard was not updated");
      console.log("Dashboard was not updated");
    },
    onSuccess: () => {
      console.log("Dashboard updated");
    },
    onSettled: () => {
      queryClient.invalidateQueries([getAllCustomDashboard.queryKey]);
      queryClient.invalidateQueries([getCustomDashboard.queryKey]);
    },
  });
};

export default usePostCustomDashboard;
