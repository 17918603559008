import { useQuery } from "@tanstack/react-query";
import { useCallback, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { BooleanParam, StringParam, useQueryParam, withDefault } from "use-query-params";
import {
  GetAllCustomDashboard,
  GetAllCustomDashboardResponse,
  GetCustomDashboard,
  GetCustomDashboardResponse,
  GRID_LAYOUT_CACHE_KEY_PREFIX,
} from "../../api/fetcher";
import useStateWithLocalStorage from "../../utils/useStateWithLocalStorage";
import useDeleteCustomDashboard from "./useDeleteCustomDashboard";
import usePostCustomDashboard from "./usePostCustomDashboard";
import { LayoutEntry, LayoutStorageType } from "./utils";

const NEW_ITEM_POSITION = -Infinity;
const getCustomDashboard = GetCustomDashboard();
const getAllCustomDashboard = GetAllCustomDashboard();
interface Props {
  defaultDashboard: string;
  layoutStorage: LayoutStorageType;
  dashboardCategory: string;
  readOnlyLayouts?: Record<string, LayoutEntry[]>;
}

const useGetLayout = ({ readOnlyLayouts, layoutStorage, dashboardCategory, defaultDashboard }: Props) => {
  const postCustomDashboard = usePostCustomDashboard();
  const deleteCustomDashboard = useDeleteCustomDashboard();
  const girdLayoutId = `${GRID_LAYOUT_CACHE_KEY_PREFIX}-${dashboardCategory}`;

  const [selectedDashboard, setSelectedDashboard] = useQueryParam(
    "selectedDashboard",
    withDefault(StringParam, defaultDashboard)
  );
  const [isReadOnly, setIsReadOnly] = useState<boolean>(!!readOnlyLayouts && !!readOnlyLayouts[selectedDashboard]);

  const { data } = useQuery<GetCustomDashboardResponse>({
    queryKey: [getCustomDashboard.queryKey, selectedDashboard, girdLayoutId],
    queryFn: () => getCustomDashboard.queryFn({ dashboardId: selectedDashboard }),
    enabled: !isReadOnly && layoutStorage === LayoutStorageType.Server,
  });

  const { data: allDashBoardsData } = useQuery<GetAllCustomDashboardResponse>({
    queryKey: [getAllCustomDashboard.queryKey, girdLayoutId],
    queryFn: () => getAllCustomDashboard.queryFn({ dashboardCategory }),
    enabled:
      layoutStorage === LayoutStorageType.Server || !Object.keys(readOnlyLayouts || {}).includes(selectedDashboard),
  });

  const [isDuplicateDialogueOpen, setIsDuplicateDialogueOpen] = useQueryParam("isDuplicateDialogueOpen", BooleanParam);
  const [isCreateDialogueOpen, setIsCreateDialogueOpen] = useQueryParam("isCreateDialogueOpen", BooleanParam);

  const [cachedLayout, setCachedLayout] = useStateWithLocalStorage<string>({
    localStorageKey: `${girdLayoutId}-${selectedDashboard}`,
    defaultValue: undefined,
  });
  const [existingDashboardNames, setExistingDashboardNames] = useState<string[]>([]);
  const [layout, setLayout] = useState<LayoutEntry[]>([]);
  const [selectedElement, setSelectedElement] = useState<(string | undefined)[]>([]);
  const [wasLayoutChanged, setWasLayoutChanged] = useState<boolean>(false);

  const layoutWasFetchedForTheSelectedDashboard = useRef<boolean>(false);

  useEffect(() => {
    if (layoutStorage === LayoutStorageType.LocalStorage) {
      setExistingDashboardNames(
        Object.keys(localStorage)
          .filter((key) => key.includes(girdLayoutId))
          .map((key) => key.split("-")[3])
      );
    } else {
      setExistingDashboardNames(allDashBoardsData?.dashboards ?? []);
    }
  }, [allDashBoardsData, layoutStorage]);

  useEffect(() => {
    layoutWasFetchedForTheSelectedDashboard.current = false;
    setSelectedElement([]);
    setLayout([]);
  }, [selectedDashboard]);

  useEffect(() => {
    if (isReadOnly) return;

    if ((data || cachedLayout) && layoutWasFetchedForTheSelectedDashboard.current === false) {
      if (layoutStorage === LayoutStorageType.Server && !data) return;
      if (layoutStorage === LayoutStorageType.LocalStorage) {
        setLayout((JSON.parse(cachedLayout) as LayoutEntry[]) || []);
        setSelectedElement((JSON.parse(cachedLayout) as LayoutEntry[]).map((element) => element.i));
      } else {
        setLayout((JSON.parse(String(data?.layout)) as LayoutEntry[]) || []);
        setSelectedElement((JSON.parse(String(data?.layout)) as LayoutEntry[]).map((element) => element.i));
      }
      layoutWasFetchedForTheSelectedDashboard.current = true;
    }
  }, [cachedLayout, layoutWasFetchedForTheSelectedDashboard.current, data, layoutStorage, isReadOnly]);

  useEffect(() => {
    const isReadOnlyValue = !!readOnlyLayouts && !!readOnlyLayouts[selectedDashboard];
    setIsReadOnly(isReadOnlyValue);

    if (isReadOnlyValue) {
      setLayout(readOnlyLayouts?.[selectedDashboard] ?? []);
      setSelectedElement(readOnlyLayouts?.[selectedDashboard]?.map((element) => element.i) ?? []);
    }
  }, [selectedDashboard, readOnlyLayouts]);

  useEffect(() => {
    let updatedLayout = layout.filter((entry) => selectedElement.includes(entry.i));

    selectedElement.forEach((element) => {
      if (!layout.find((entry) => entry.i === element)) {
        updatedLayout = updatedLayout.concat({
          i: element ?? "",
          x: NEW_ITEM_POSITION,
          y: NEW_ITEM_POSITION,
        });
      }
    });
    if (updatedLayout.length !== layout.length) {
      setLayout(updatedLayout);
    }
  }, [selectedElement, layout, isReadOnly]);

  useEffect(() => {
    if (isReadOnly) return;

    let cachedLayoutParsed: LayoutEntry[] = [];

    switch (layoutStorage) {
      case LayoutStorageType.LocalStorage:
        cachedLayoutParsed = cachedLayout ? (JSON.parse(cachedLayout) as LayoutEntry[]) : [];
        break;
      case LayoutStorageType.Server:
        cachedLayoutParsed = data ? (JSON.parse(String(data?.layout)) as LayoutEntry[]) : [];
        break;
      default:
        break;
    }

    if (cachedLayoutParsed) {
      const wereItemsAddedOrMoved = layout.some((element) => {
        const cachedElement = cachedLayoutParsed.find((cachedElement) => cachedElement.i === element.i);
        return !cachedElement || cachedElement.x !== element.x || cachedElement.y !== element.y;
      });

      const wereItemsRemoved = cachedLayoutParsed.some((element) => !layout.find((entry) => entry.i === element.i));

      setWasLayoutChanged(wereItemsAddedOrMoved || wereItemsRemoved);
    }
  }, [layout, cachedLayout, data, layoutStorage, isReadOnly]);

  const saveDashboard = useCallback(() => {
    if (isReadOnly) return;
    if (layoutStorage === LayoutStorageType.LocalStorage) {
      setCachedLayout(JSON.stringify(layout));
    } else {
      postCustomDashboard.mutate({
        dashboardId: selectedDashboard,
        layout: JSON.stringify(layout),
      });
    }
    toast.success("Dashboard saved successfully");
  }, [layout, setCachedLayout, postCustomDashboard, layoutStorage, isReadOnly, selectedDashboard]);

  const createDashboard = useCallback(
    (dashboardName: string, createNew?: boolean) => {
      if (layoutStorage === LayoutStorageType.LocalStorage) {
        localStorage.setItem(`${girdLayoutId}-${dashboardName}`, createNew ? "" : JSON.stringify(layout));
      } else {
        postCustomDashboard.mutate({
          dashboardId: `${girdLayoutId}-${dashboardName}`,
          layout: createNew ? "[]" : JSON.stringify(layout),
        });
      }
      setExistingDashboardNames([...existingDashboardNames, dashboardName]);
      setSelectedDashboard?.(`${girdLayoutId}-${dashboardName}`);
      setIsDuplicateDialogueOpen(false);
      setIsCreateDialogueOpen(false);
      toast.success(`Dashboard ${dashboardName} created successfully`);
    },
    [
      layout,
      existingDashboardNames,
      setSelectedDashboard,
      setIsDuplicateDialogueOpen,
      setIsCreateDialogueOpen,
      layoutStorage,
    ]
  );

  const deleteDashboard = useCallback(
    (dashboardName: string) => {
      if (layoutStorage === LayoutStorageType.LocalStorage) {
        localStorage.removeItem(`${dashboardName}`);
      } else {
        deleteCustomDashboard.mutate({
          dashboardId: `${dashboardName}`,
          layout: "",
        });
      }

      setExistingDashboardNames(existingDashboardNames.filter((name) => name !== dashboardName));
      if (selectedDashboard === dashboardName) {
        setSelectedDashboard?.(defaultDashboard);
      }
      toast.success(`Dashboard ${dashboardName} deleted successfully`);
    },
    [existingDashboardNames, selectedDashboard, setSelectedDashboard, defaultDashboard, layoutStorage]
  );

  return {
    layout,
    setLayout,
    selectedElement,
    setSelectedElement,
    saveDashboard,
    isReadOnly,
    wasLayoutChanged,
    existingDashboardNames,
    isDuplicateDialogueOpen,
    setIsDuplicateDialogueOpen,
    isCreateDialogueOpen,
    setIsCreateDialogueOpen,
    createDashboard,
    deleteDashboard,
    selectedDashboard,
    setSelectedDashboard,
  };
};

export default useGetLayout;
