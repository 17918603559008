import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useMainContext } from "../MainContext";
import { getDashboardByNamespace, GetDashboardByNamespaceResponse } from "../api/fetcher";
import TopOverviewSection from "../pages/Overview/TopOverviewSection/TopOverviewSection";
import { FeatureEnabled } from "../utils/FeaturesHelper";
import useFilterQueryParams, { FilterParamObject } from "./WorkloadStatusByNamespaceSection/useFilterQueryParams";

import { ScaleOpsProduct } from "../utils/typesUtils";

const DEFAULT_FETCH_INTERVAL = 12000;
const DEMO_FETCH_INTERVAL = 5000;

interface Props {
  disableAnimation?: boolean;
  scaleOpsProduct?: ScaleOpsProduct;
}

const TopOverviewContainer = ({ disableAnimation, scaleOpsProduct }: Props) => {
  const [refetchInterval, setRefetchInterval] = useState<number>(DEFAULT_FETCH_INTERVAL);

  const dashboardByNamespace = getDashboardByNamespace();
  const filterQueryParams: FilterParamObject = useFilterQueryParams();
  const { didClusterChanged } = useMainContext();

  const { data, isLoading, error } = useQuery<GetDashboardByNamespaceResponse, Error>({
    queryKey: [dashboardByNamespace.queryKey, filterQueryParams, scaleOpsProduct],
    queryFn: () => dashboardByNamespace.queryFn({ ...filterQueryParams, scaleOpsProduct }),
    refetchInterval,
    enabled: !!Object.keys(filterQueryParams).length,
  });

  useEffect(() => {
    if (FeatureEnabled("DemoVersion")) {
      setRefetchInterval(DEMO_FETCH_INTERVAL);
    }
  }, []);

  if (error && didClusterChanged) {
    toast.error("Error fetching data for this cluster");
    console.log(error);
  }

  return (
    <TopOverviewSection
      isLoading={isLoading}
      disableAnimation={disableAnimation}
      scaleOpsProduct={scaleOpsProduct}
      monthlyForecast={data?.totalNamespaceSummary?.totalNodesCost}
      defaultOptimizedCost={data?.totalNamespaceSummary?.optimized}
      underProvisionedWorkloads={data?.totalNamespaceSummary?.underProvisionedWorkloads}
      overProvisionedWorkloads={data?.totalNamespaceSummary?.overProvisionedWorkloads}
      totalAutomatedWorkloads={data?.totalNamespaceSummary?.automatedWorkloads}
      totalUnAutomatedWorkloads={data?.totalNamespaceSummary?.unautomatedWorkloads}
      monthlySavingsAvailable={data?.totalNamespaceSummary?.monthlySavingsAvailable}
      activeMonthlySavingsAvailable={data?.totalNamespaceSummary?.activeMonthlySavingsAvailable}
      totalUnoptimizedNotEvictableWorkloads={data?.totalNamespaceSummary?.totalUnoptimizedNotEvictableWorkloads}
      predictableWorkloads={data?.totalNamespaceSummary?.predictableWorkloads}
    />
  );
};

export default TopOverviewContainer;
