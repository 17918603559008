import { ListItemText, MenuItem, Typography } from "@mui/material";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import clsx from "clsx";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FixedSizeList as List } from "react-window";
import { StringParam, useQueryParam } from "use-query-params";
import { getClusters, GetClustersResponse } from "../api/fetcher";
import { components } from "../api/schema";
import { SCALEOPS_COLORS } from "../colors";
import Chip, { CHIP_THEME } from "../components/Chip";
import SingleSelect, { SingleSelectSize } from "../components/SingleSelect";
import Tooltip from "../components/Tooltip";
import AddIcon from "../Icons/AddIcon";
import WarningIcon from "../Icons/WarningIcon";
import { useMainContext } from "../MainContext";
import { GetConfig } from "../utils/ConfigHelper";
import { useWorkloadsContext } from "../WorkloadsContext";

const LIST_SIZE = 33;
const MAX_LIST_HEIGHT = 500;

type Cluster = components["schemas"]["MultiClusterInfo"];

const clustersApi = getClusters();

interface RowProps {
  clusters: Cluster[];
  index: number;
  style: React.CSSProperties;
  setSelected: (cluster: string) => void;
  currentCluster: string | undefined;
}

const Row = ({ clusters, index, style, setSelected, currentCluster }: RowProps) => {
  const cluster = clusters[index];
  const clusterName = cluster.name;
  const isParent = cluster.isParent;
  const displayName = cluster.displayName ? cluster.displayName : clusterName;
  const displayValue = isParent ? `${String(displayName ?? "")}` : displayName;

  // Last seen more than ten minutes ago
  const disconnected = cluster.lastPing && new Date(cluster.lastPing).getTime() < Date.now() - 10 * 60 * 1000;

  return (
    <Tooltip title={displayValue} maxWidth={800}>
      <MenuItem
        value={clusterName}
        key={clusterName}
        sx={{
          ...style,
          ...(clusterName === currentCluster && { backgroundColor: SCALEOPS_COLORS.guideline.darkGray }),
          "& .MuiListItemText-primary": {
            fontSize: 14,
          },
        }}
        onClick={() => {
          clusterName && setSelected(clusterName);
        }}
        className={"flex gap-1"}
      >
        <ListItemText className="text-black w-full" primary={<p className="w-full truncate rtl">{displayValue}</p>} />
        {isParent && <Chip label={"main"} theme={CHIP_THEME.DARK} />}
        {!isParent && disconnected && (
          <span style={{ marginRight: "13px", cursor: "default" }}>
            <Tooltip title={`Cluster is down, last seen ${moment(cluster.lastPing).fromNow()}`}>
              <WarningIcon width={16} height={16} />
            </Tooltip>
          </span>
        )}
      </MenuItem>
    </Tooltip>
  );
};
const SelectCluster = () => {
  const [, setCurrentClusterURLParam] = useQueryParam("currentClusterURLParam", StringParam);

  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { resetOverriddenWorkloads } = useWorkloadsContext();
  const { currentCluster, setCurrentCluster, didClusterChanged, setDidClusterChanged } = useMainContext();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const [clusters, setClusters] = useState<components["schemas"]["MultiClusterInfo"][]>([]);

  const { data: clustersData } = useQuery<GetClustersResponse, Error>({
    queryKey: [clustersApi.queryKey],
    queryFn: clustersApi.queryFn,
    refetchInterval: 10 * 1000,
  });

  const configData = GetConfig();

  useEffect(() => {
    setClusters(clustersData?.clusters ?? []);
  }, [clustersData]);

  useEffect(() => {
    if (!currentCluster && configData?.inCloudMode) {
      return;
    }
    sessionStorage.setItem("currentCluster", currentCluster ?? "");
    queryClient.cancelQueries([]);
    queryClient.resetQueries();
  }, [currentCluster]);

  let clustersList = clusters.sort((a, b) => {
    if (a.isParent) {
      return -1;
    }
    if (b.isParent) {
      return 1;
    }
    if (a.name && b.name) {
      return a.name.localeCompare(b.name);
    }
    return 0;
  });
  clustersList = (configData?.inCloudMode ? clustersList : [...clustersList]).sort();

  const setSelected = (cluster: string) => {
    const backdrop = document.querySelector(".MuiBackdrop-root") as HTMLElement;
    backdrop?.click();
    setCurrentClusterURLParam(cluster);
    setCurrentCluster(cluster);
    if (!didClusterChanged) {
      setDidClusterChanged(true);
    }
    resetOverriddenWorkloads();
  };

  return (
    <div className="flex gap-1 items-center w-full relative">
      <SingleSelect<string>
        isSearchable
        prefixDiv={
          <Typography variant="caption" fontWeight={700} className="px-4">
            Select a cluster:
          </Typography>
        }
        selected={currentCluster ?? ""}
        setSelected={setSelected}
        size={SingleSelectSize.Medium}
        className="truncate border border-white rtl w-[10.3125rem]"
        selectedColor="white"
        arrowIconColor="white"
        renderValue={(value) => {
          return (
            <Tooltip title={currentCluster} placement="right" tooltipClassName={clsx({ hidden: isMenuOpen })}>
              <div className="text-white truncate text-[14px] text-left">{value}</div>
            </Tooltip>
          );
        }}
        renderOptionsFunc={(search) => {
          const filteredClusters = clustersList;

          clustersList.filter((entity) => {
            const lastSeenInPrevious24Hours =
              entity.isParent ||
              !entity.lastPing ||
              new Date(entity.lastPing).getTime() > Date.now() - 24 * 60 * 60 * 1000;

            return entity.name?.includes(search ?? "") && lastSeenInPrevious24Hours;
          });

          const LIST_HEIGHT = Math.min(MAX_LIST_HEIGHT, LIST_SIZE * filteredClusters.length);

          return (
            <List
              height={LIST_HEIGHT}
              className={"mt-3"}
              itemCount={filteredClusters?.length ?? 0}
              itemSize={LIST_SIZE}
              width={300}
            >
              {({ index, style }) => (
                <Row
                  clusters={filteredClusters}
                  index={index}
                  style={style}
                  setSelected={setSelected}
                  currentCluster={currentCluster}
                />
              )}
            </List>
          );
        }}
        onOpen={() => setIsMenuOpen(true)}
        onClose={() => setIsMenuOpen(false)}
      />
      <Tooltip title={"Add Cluster"} placement="right" className="cursor-pointer">
        <Typography
          onClick={() => {
            navigate("/multicluster?openDialogueOnLoad=1");
          }}
        >
          <AddIcon width={20} height={20} className="text-white" />
        </Typography>
      </Tooltip>
    </div>
  );
};

export default SelectCluster;
