import FormSingleSelect, { RenderValueType } from "../../../components/formComponents/FormSingleSelect";
import FormTitleAndDescription from "../../../components/formComponents/FormTitleAndDescription";
import { shouldDisableDefaultPolicyInputs } from "../utils";
import { IsSnapshotServer } from "../../../utils/FeaturesHelper";

const DEFAULT_VALUE = 0;

const options: [string, number][] = [
  ["0%", 0],
  ["5%", 5],
  ["7%", 7],
  ["10%", 10],
  ["15%", 15],
  ["20%", 20],
  ["40%", 40],
  ["50%", 50],
  ["60%", 60],
  ["80%", 80],
  ["90%", 90],
  ["100%", 100],
];
interface Props {
  isCustomizedPolicy?: boolean;
}

const RequiredWindowCoverage = ({ isCustomizedPolicy }: Props) => {
  return (
    <div className="flex flex-col gap-4">
      <FormTitleAndDescription
        title="Required Window Coverage"
        description="Define the percentage of data points in history window that is required for applying changes."
      />
      <div className="flex flex-wrap gap-4">
        <FormSingleSelect
          label="Window coverage (%)"
          name="windowCoverage"
          options={options}
          areOptionsDisabled={!IsSnapshotServer() && !isCustomizedPolicy}
          disabled={!isCustomizedPolicy && shouldDisableDefaultPolicyInputs}
          defaultValue={DEFAULT_VALUE}
          renderValueType={RenderValueType.Percentage}
        />
      </div>
    </div>
  );
};

export default RequiredWindowCoverage;
