import FormTitleAndDescription from "../../../../components/formComponents/FormTitleAndDescription";
import ApplyingRecommendationOption, { Options } from "./ApplyingRecommendationOption";
import { PolicyType } from "./utils";
import PolicyValue from "./PolicyValue";

enum WorkloadsTypesNames {
  deploymentRecommendationType = "Deployment",
  statefulSetRecommendationType = "StatefulSet",
  daemonSetRecommendationType = "DaemonSet",
  deploymentConfigRecommendationType = "DeploymentConfig",
  argoRolloutRecommendationType = "Rollout",
  familyRecommendationType = "Custom Workload",
}

enum WorkloadsTypes {
  deploymentRecommendationType = "deployment",
  statefulSetRecommendationType = "statefulSet",
  daemonSetRecommendationType = "daemonSet",
  deploymentConfigRecommendationType = "deploymentConfig",
  argoRolloutRecommendationType = "argoRollout",
  familyRecommendationType = "family",
}

interface Props {
  selectedPolicy?: PolicyType;
}

const ApplyingRecommendationSettings = ({ selectedPolicy }: Props) => {
  return (
    <div className="flex flex-col gap-4">
      <FormTitleAndDescription
        title="Automation optimization strategy"
        description="Define your optimization strategy per workload types."
      />
      <div className="flex flex-col gap-4 mt-1">
        {Object.entries(WorkloadsTypesNames).map(([key, value]) => {
          const workloadTypeValue = selectedPolicy?.spec?.updatePolicy?.updateByTypeMode
            ? selectedPolicy.spec.updatePolicy.updateByTypeMode[WorkloadsTypes[key as keyof typeof WorkloadsTypes]] ??
              ""
            : "";
          const workloadTypeLabel = Options.find((option) => option.value === workloadTypeValue)?.label;
          return (
            <div className="flex items-center gap-2">
              <ApplyingRecommendationOption key={key} label={value} name={key} />
              <PolicyValue key={key} value={workloadTypeLabel} className="pt-2" />
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default ApplyingRecommendationSettings;
