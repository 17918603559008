import { BooleanParam, useQueryParam, withDefault } from "use-query-params";
import CustomFilterChip from "../CustomFilterChip";
import useGetNamespaces from "../WorkloadStatusByNamespaceSection/OverviewHooks/useGetNamespaces";
import { AGGREGATION_NAMESPACES_QUERY_KEY } from "./utils";

const NamespacesFilter = () => {
  const [namespacesParams, setNamespacesParams] = useQueryParam(
    AGGREGATION_NAMESPACES_QUERY_KEY,
    withDefault(BooleanParam, true)
  );
  const namespaces = useGetNamespaces();

  return (
    <CustomFilterChip
      label="namespaces"
      onClick={() => setNamespacesParams(!namespacesParams)}
      selected={!!namespacesParams}
      disabled={namespaces.length === 0}
    />
  );
};

export default NamespacesFilter;
