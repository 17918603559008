import { SCALEOPS_COLORS } from "../../../../../colors";
import ComposeChart from "../../../../../components/ComposeChart/ComposeChart";
import { ChartData, ChartElement, SetDate, YAxisTickFormatterType } from "../../../../../components/ComposeChart/utils";
import { WORKLOAD_EBPF_CHARTS_SYNC_ID, XAxisIntervalBase } from "./utils";

const ELEMENTS: ChartElement[] = [
  // p50: Number(dataPoint.p50),
  // p95: Number(dataPoint.p95),
  // p99: Number(dataPoint.p99),
  {
    key: "p50",
    color: SCALEOPS_COLORS.events.eviction,
    name: "P50",
  },
  {
    key: "p95",
    color: SCALEOPS_COLORS.events.liveness,
    name: "P95",
  },
  {
    key: "p99",
    color: SCALEOPS_COLORS.events.throttling,
    name: "P99",
  },
];

interface Props {
  graphData: ChartData;
  isLoading: boolean;
  setDate: SetDate;
}

const LatencyChart = ({ graphData, isLoading, setDate }: Props) => {
  return (
    <ComposeChart
      data={graphData}
      title="Latency"
      isLoading={isLoading}
      elements={ELEMENTS}
      yAxisTickFormatterType={YAxisTickFormatterType.Duration}
      XAxisIntervalBase={XAxisIntervalBase}
      syncId={WORKLOAD_EBPF_CHARTS_SYNC_ID}
      setDate={setDate}
      yAxisDomain={[0, "auto"]}
    />
  );
};

export default LatencyChart;
