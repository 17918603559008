import { Typography } from "@mui/material";
import { RocketIcon } from "@primer/octicons-react";
import clsx from "clsx";
import { toast } from "react-toastify";
import { StringParam, useQueryParam, withDefault } from "use-query-params";
import Button, { BUTTON_VARIANTS } from "../../components/Button";
import CodeSnippet, { THEME as CodeSnippetTheme } from "../../components/CodeSnippet";
import CustomCheckBox, { THEME } from "../../components/CustomCheckBox";
import Tab, { TABS_CONTAINER_CLASS_NAME } from "../../components/Tab";
import Tooltip from "../../components/Tooltip";
import DocsIcon from "../../Icons/DocsIcon";
import InfoIcon from "../../Icons/InfoIcon";
import InstallStep from "./InstallStep";
import { containerClassName, getCommand } from "./utils";

const INFO_ICON_SIZE = 14;
const HAS_COPY_BUTTON = false;
const codeSnippetClassName = "w-[43rem]:w-full";
const SHOW_INSTALL_OPTIONS_TABS = true;

export enum HostingMethod {
  hostedCloud = "ScaleOps Cloud",
  SelfHostedCluster = "Self Hosted",
}

const HostingMethodDescription = {
  [HostingMethod.hostedCloud]: (
    <>
      ScaleOps hosts your dashboards and provides you with a SaaS like experience{" "}
      <b>without storing or hosting any of your sensitive data</b>
    </>
  ),
  [HostingMethod.SelfHostedCluster]: (
    <>You host your dashboard on your own Cluster while keeping all your sensitive data within your cluster</>
  ),
};

const showSuccessToast = () => {
  toast.success("Command copied to clipboard!", {
    position: "top-center",
  });
};

interface Props {
  token: string;
  customerPrefix: string;
  useScaleOpsRegistry: boolean;
  inCloudUrl?: string;
}

const GuideSection = ({ token, customerPrefix, useScaleOpsRegistry, inCloudUrl }: Props) => {
  const [selectedHostingMethod, setSelectedHostingMethod] = useQueryParam(
    "hostingMethod",
    withDefault(StringParam, HostingMethod.hostedCloud)
  );

  const [hasOpenShift, setHasOpenShift] = useQueryParam("useOpenshift", withDefault(StringParam, "false"));
  const url = inCloudUrl ? inCloudUrl : encodeURI(window.location.protocol + "//" + window.location.host);

  return (
    <>
      <div className="relative">
        {SHOW_INSTALL_OPTIONS_TABS && (
          <div className={TABS_CONTAINER_CLASS_NAME}>
            {Object.entries(HostingMethod).map(([key, value]) => {
              return (
                <Tab
                  key={key}
                  isSelected={selectedHostingMethod === value}
                  onClick={() => {
                    setSelectedHostingMethod(value as HostingMethod);
                  }}
                  name={
                    <Tooltip
                      title={HostingMethodDescription[value]}
                      className="flex justify-center items-center gap-1"
                      maxWidth={500}
                    >
                      <span>{value}</span>
                      <InfoIcon width={INFO_ICON_SIZE} height={INFO_ICON_SIZE} />
                    </Tooltip>
                  }
                  dataTestId={`installation-method-${value}`}
                  className="border border-black border-b-0"
                />
              );
            })}
          </div>
        )}
        <div
          className={clsx(
            { "mt-[35px] rounded-tl-none": SHOW_INSTALL_OPTIONS_TABS },
            containerClassName,
            "bg-white border border-1 border-black flex flex-col gap-5 min-h-[470px]"
          )}
        >
          <InstallStep
            stepNumber={1}
            title={
              <>
                Run the <b>installation command</b>
              </>
            }
            content={
              <div className="flex flex-col gap-2">
                <div className="flex items-center gap-2">
                  <CustomCheckBox
                    value={hasOpenShift === "true"}
                    onClick={(checked: boolean | undefined) => {
                      setHasOpenShift(checked ? "true" : "false");
                    }}
                    theme={THEME.PURPLE}
                  />
                  <Typography variant="body2">OpenShift</Typography>
                </div>
                <CodeSnippet
                  theme={CodeSnippetTheme.light}
                  codeSnippet={getCommand({
                    commandType: "install",
                    customerPrefix,
                    hasOpenShift: hasOpenShift === "true",
                    showToken: false,
                    useScaleOpsRegistry: useScaleOpsRegistry,
                    token,
                    isHostedCloud: selectedHostingMethod === HostingMethod.hostedCloud,
                  })}
                  codeSnippetToCopy={getCommand({
                    commandType: "install",
                    customerPrefix,
                    hasOpenShift: hasOpenShift === "true",
                    showToken: true,
                    useScaleOpsRegistry: useScaleOpsRegistry,
                    token,
                    isHostedCloud: selectedHostingMethod === HostingMethod.hostedCloud,
                  })}
                  className={codeSnippetClassName}
                />
                {HAS_COPY_BUTTON && (
                  <Button
                    label="COPY"
                    variant={BUTTON_VARIANTS.mediumDarkPurple}
                    onClick={() => {
                      let codeToCopy = getCommand({
                        commandType: "install",
                        customerPrefix,
                        hasOpenShift: hasOpenShift === "true",
                        showToken: true,
                        useScaleOpsRegistry: useScaleOpsRegistry,
                        token,
                      });
                      codeToCopy = codeToCopy.trim();
                      navigator.clipboard.writeText(codeToCopy);
                      showSuccessToast();
                    }}
                    fontSize={14}
                  />
                )}
              </div>
            }
          />

          {selectedHostingMethod === HostingMethod.hostedCloud ? (
            <>
              <InstallStep
                stepNumber={2}
                title={
                  <b>
                    Get Started! <span className="text-primary-purpleBlue">Optimize your cluster</span>
                  </b>
                }
                content={
                  <div className="flex flex-col items-start gap-2">
                    <div className="flex items-center gap-2">
                      <RocketIcon />
                      <Typography variant="body1" fontWeight={700}>
                        <span>
                          Browse to{" "}
                          <a
                            className="text-primary-purpleBlue underline hover:text-main-blue"
                            href={url}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {url}
                          </a>
                        </span>
                      </Typography>
                    </div>
                  </div>
                }
              />
            </>
          ) : (
            <>
              <InstallStep
                stepNumber={2}
                title="Enable Port-Forward"
                content={
                  <div className="flex flex-col gap-2">
                    <CodeSnippet
                      theme={CodeSnippetTheme.light}
                      codeSnippet={getCommand({
                        commandType: "portForward",
                        hasOpenShift: hasOpenShift === "true",
                        showToken: false,
                        useScaleOpsRegistry: useScaleOpsRegistry,
                        token,
                        customerPrefix,
                      })}
                      className={codeSnippetClassName}
                    />
                  </div>
                }
              />
              <InstallStep
                stepNumber={3}
                title={
                  <b>
                    Get Started! <span className="text-primary-purpleBlue">Optimize your cluster</span>
                  </b>
                }
                content={
                  <div className="flex flex-col items-start gap-2">
                    <div className="flex items-center gap-2">
                      <RocketIcon />
                      <Typography variant="body1" fontWeight={700}>
                        <span>
                          Browse to{" "}
                          <a
                            className="text-primary-purpleBlue underline hover:text-main-blue"
                            href="http://localhost:8080"
                            target="_blank"
                            rel="noreferrer"
                          >
                            http://localhost:8080
                          </a>
                        </span>
                      </Typography>
                    </div>
                  </div>
                }
              />
            </>
          )}
          <a
            className="absolute max-[1298px]:relative bottom-6 max-[1298px]:bottom-0  right-6 max-[1298px]:right-0  p-2 text-white bg-primary-purpleBlue hover:bg-main-blue rounded-lg scaleopsShadow border border-black"
            href={"https://docs.scaleops.com?token=" + token}
            target="_blank"
            rel="noreferrer"
          >
            <Typography variant="caption" className="flex gap-2 items-center">
              <DocsIcon width={16} height={16} />
              <span>Documentation</span>
            </Typography>
          </a>
        </div>
      </div>
    </>
  );
};

export default GuideSection;
