import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { GetUseHostedCloudQuery, UpdateUseHostedCloud, UpdateUseHostedCloudParams } from "../../api/fetcher";
import { TOAST_SETTINGS } from "../../pages/Ingress/mutations/utils";

const { queryKey } = GetUseHostedCloudQuery();
const { queryFn } = UpdateUseHostedCloud();

const useUpdateHostedCloud = () => {
  const queryClient = useQueryClient();

  return useMutation((params: UpdateUseHostedCloudParams) => queryFn(params), {
    onError: (error: Error) => {
      console.log(`Error: ${error.message}`);
      toast.error(`Error saving hosted cloud settings: ${error.message}`);
    },
    onSuccess: () => {
      toast.success("Hosted cloud settings updated successfully!", TOAST_SETTINGS);
    },
    onSettled: () => {
      queryClient.invalidateQueries([queryKey]).catch((error) => console.log(error));
    },
  });
};

export default useUpdateHostedCloud;
