import { Typography } from "@mui/material";
import clsx from "clsx";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { AxisDomain } from "recharts/types/util/types";
import { ObjectParam, StringParam, useQueryParam } from "use-query-params";
import RecommendationIcon from "../../../../Icons/RecommendationIcon";
import { WorkloadAnalyticsType } from "../../../../api/fetcher";
import { components } from "../../../../api/schema";
import { SCALEOPS_COLORS } from "../../../../colors";
import CustomLegend from "../../../../components/CustomLegend";
import { IsSnapshotServer } from "../../../../utils/FeaturesHelper";
import { getMemoryValue } from "../../../../utils/formatterUtils";
import { memoryDataParser, OverviewLinkType } from "../../../../utils/graphUtils";
import useFreezeTooltip, { TooltipTrigger } from "../../../Analytics/AnalyticsV2/Graphs/hooks/useFreezeTooltip";
import { FrozenTooltipType } from "../../../Analytics/AnalyticsV2/Graphs/hooks/utils";
import ContainerFilter, { SELECTED_CONTAINER_KEY } from "../ContainerFilter";
import NumberOfFilterElements from "../NumberOfFilterElements";
import SelectViewPeriod from "../SelectViewPeriod";
import UsageAndMemoryCharts from "../UsageAndMemoryCharts";
import {
  ChartComponents,
  componentStyle,
  displayNameFormatter,
  POLICY_TUNING_DATES_URL_PARAM,
  useViewPeriodQueryParams,
} from "../utils";
import LineChart from "./LineChart";
import MultipleLinesChart from "./MultipleLinesChart";
import MultipleRegexpLineChart from "./MultipleRegexpLineChart";
import {
  hpaTriggerGetElementsFormat,
  hpaTriggerLegendSortFunction,
  hpaTriggerTooltipSortFnc,
  podTooltipSortFnc,
  recommendedGetElementsFormat,
  recommendedLegendSortFunction,
} from "./utils";

const PERCENTAGE_Y_AXIS_DOMAIN = [0, 100];
const NUMERIC_CPU_Y_AXIS_DOMAIN: AxisDomain = [0, (dataMax: number) => (dataMax < 0 ? 0 : dataMax * 1.2)];
const NUMERIC_MEMORY_Y_AXIS_DOMAIN: AxisDomain = [0, (dataMax: number) => (dataMax < 0 ? 0 : dataMax * 1.2)];
const ROUNDED_TICK_FORMATTER = (value: number) => String(Math.round(value * 100) / 100);

const wrapperClassName = "border rounded-lg border-border";
const halfWrapperClassName = "w-[calc(50%-5px)]";

interface Props {
  selectedWorkload: components["schemas"]["UtilsWorkload"];
  policyName: string | undefined;
  selectedChartComponents: ChartComponents[];
  setSelectedChartComponents: React.Dispatch<React.SetStateAction<ChartComponents[]>>;
  setCpuCappingConfig: (config: components["schemas"]["UtilsPolicyTuningCappingConfig"] | undefined) => void;
  setMemoryCappingConfig: (config: components["schemas"]["UtilsPolicyTuningCappingConfig"] | undefined) => void;
}

const getWorkloadTypeForWlAnalytics = (wl: components["schemas"]["UtilsWorkload"]) => wl?.type;

const WorkloadAnalytics = ({
  selectedWorkload,
  policyName,
  selectedChartComponents,
  setSelectedChartComponents,
  setCpuCappingConfig,
  setMemoryCappingConfig,
}: Props) => {
  const isSnapshotServer = IsSnapshotServer();
  const { tooltipTrigger, disabledZoom, updateActiveTooltips, setTooltipTrigger } = useFreezeTooltip({});

  const [urlDates] = useQueryParam(POLICY_TUNING_DATES_URL_PARAM, ObjectParam);
  const [selectedContainer] = useQueryParam(SELECTED_CONTAINER_KEY, StringParam);
  const [selectedViewPeriod] = useViewPeriodQueryParams();

  const [numberOfElements, setNumberOfElements] = useState<number>(5);
  const [from, setFrom] = useState<undefined | number>(undefined);
  const [to, setTo] = useState<undefined | number>(undefined);

  const [hasHpaCpuData, setHasHpaCpuData] = useState<boolean>(true);
  const [hasHpaMemoryData, setHasHpaMemoryData] = useState<boolean>(true);

  useEffect(() => {
    setSelectedChartComponents([
      ChartComponents.AverageUsage,
      ChartComponents.MaxUsage,
      ChartComponents.CurrentRequest,
      ChartComponents.CurrentLimit,
    ]);
  }, []);

  useEffect(() => {
    const fromValue = urlDates?.from
      ? Number(urlDates?.from)
      : dayjs.utc().subtract(Number(selectedViewPeriod), "hour").unix();
    const toValue = urlDates?.to ? Number(urlDates?.to) : dayjs.utc().unix();

    setFrom(fromValue);
    setTo(toValue);
  }, [urlDates, selectedViewPeriod]);

  useEffect(() => {
    setTooltipTrigger(TooltipTrigger.Hover);
  }, [selectedViewPeriod, selectedContainer, numberOfElements]);

  return (
    <div>
      <div className={clsx(wrapperClassName, "w-full mb-2.5 p-4 flex items-center gap-10")}>
        <div className="w-[30px]">
          <RecommendationIcon width={30} height={30} />
        </div>
        <Typography variant="body2" className="grow">
          <b>Workload Troubleshoot</b>
          <p className="max-w-[100%]">
            Explore the resource usage of your workload including: CPU, memory, evictions, automation, oom events,
            liveness and readiness, CPU and memory throttling and utilization
          </p>
        </Typography>
        <div className="h-16 w-[1px] bg-border" />
        <ContainerFilter
          namespace={selectedWorkload.namespace}
          workloadName={selectedWorkload.workloadName}
          workloadType={selectedWorkload.type}
        />
        <div className="h-16 w-[1px] bg-border" />
        <div className="flex justify-center items-center gap-5">
          <SelectViewPeriod />
          <NumberOfFilterElements numberOfElements={numberOfElements} setNumberOfElements={setNumberOfElements} />
        </div>
      </div>
      <div className={wrapperClassName}>
        <UsageAndMemoryCharts
          selectedWorkload={selectedWorkload}
          policyName={policyName}
          selectedChartComponents={selectedChartComponents}
          setCpuCappingConfig={setCpuCappingConfig}
          setMemoryCappingConfig={setMemoryCappingConfig}
          wrapperHeight={250}
        />
        <CustomLegend<ChartComponents>
          selectedChartComponents={selectedChartComponents}
          setSelectedChartComponents={setSelectedChartComponents}
          componentStyle={componentStyle}
          isDashedFnc={(key: string) => key.includes("Limit")}
          ChartComponents={ChartComponents}
          className="mt-1 pb-3"
          displayNameFormatter={displayNameFormatter}
        />
      </div>
      <div className="flex flex-wrap gap-[10px] my-[10px]">
        <div className={halfWrapperClassName}>
          <LineChart
            title="Automated"
            color={SCALEOPS_COLORS.main.green}
            label="automated"
            queryParams={{
              from: from,
              to: to,
              name: selectedWorkload.workloadName,
              namespace: selectedWorkload.namespace,
              workloadType: getWorkloadTypeForWlAnalytics(selectedWorkload),
              types: ["automated"],
              container: selectedContainer,
            }}
            hasBooleanValue
            booleanOptions={{ true: "ON", false: "OFF" }}
            YAxisDomain={[0, 1]}
            YAxisPadding={{ top: 100 }}
            YAxisAllowDecimals={false}
            tooltipTrigger={tooltipTrigger}
            disabledZoom={disabledZoom}
            frozenTooltipType={FrozenTooltipType.FrozenAndNotClickable}
            updateActiveTooltips={updateActiveTooltips}
          />
        </div>
        <div className={halfWrapperClassName}>
          <MultipleLinesChart
            title="Replicas"
            onLoadSelectedChartComponents={["readyReplicas", "replicas"]}
            YAxisDomain={[0, "dataMax + 1"]}
            YAxisAllowDecimals={false}
            showLegentTooltip
            elements={[
              {
                key: "readyReplicas",
                label: "running",
                color: SCALEOPS_COLORS.workload.readyReplicas,
                fill: SCALEOPS_COLORS.workload.readyReplicas,
                tooltip: "Number of ready replicas",
              },

              {
                key: "replicas",
                label: "current",
                color: SCALEOPS_COLORS.workload.replicas,
                tooltip: "Number of desired replicas by the owning workload",
              },
              ...(selectedWorkload.hasHpa
                ? [
                    {
                      key: "minHpaReplicas",
                      label: "minimum",
                      color: SCALEOPS_COLORS.workload.minReplicas,
                      tooltip: "Minimum replicas, constrained by HPAs",
                    },
                    {
                      key: "maxHpaReplicas",
                      label: "maximum",
                      color: SCALEOPS_COLORS.workload.maxReplicas,
                      tooltip: "Maximum replicas, constrained by HPAs",
                    },
                    {
                      key: "desiredHpaReplicas",
                      label: "hpa desired",
                      color: SCALEOPS_COLORS.workload.replicas,
                      tooltip: "Desired replicas, set by HPAs",
                    },
                  ]
                : []),
            ]}
            queryParams={{
              from: from,
              to: to,
              name: selectedWorkload.workloadName,
              namespace: selectedWorkload.namespace,
              workloadType: getWorkloadTypeForWlAnalytics(selectedWorkload),
              types: [
                "replicas",
                "readyReplicas",
                ...((selectedWorkload.hasHpa
                  ? ["minHpaReplicas", "maxHpaReplicas", "desiredHpaReplicas"]
                  : []) as WorkloadAnalyticsType[]),
              ],
              container: selectedContainer,
            }}
            tooltipTrigger={tooltipTrigger}
            disabledZoom={disabledZoom}
            frozenTooltipType={FrozenTooltipType.FrozenAndNotClickable}
            updateActiveTooltips={updateActiveTooltips}
          />
        </div>
      </div>
      <div className="flex flex-wrap gap-[10px] my-[10px]">
        <div className={halfWrapperClassName}>
          <MultipleRegexpLineChart
            title="Node CPU utilization(%)"
            regexp={"nodeCpuUtilization_"}
            tooltipValueSuffix="%"
            roundValues
            queryParams={{
              from: from,
              to: to,
              name: selectedWorkload.workloadName,
              namespace: selectedWorkload.namespace,
              workloadType: getWorkloadTypeForWlAnalytics(selectedWorkload),
              types: ["nodeCpuUtilization", "nodeCpuAllocatable"],
              container: selectedContainer,
              topk: numberOfElements,
              uniqueQueryKey: String(numberOfElements),
            }}
            YAxisDomain={PERCENTAGE_Y_AXIS_DOMAIN}
            maxValueLimit={100}
            tooltipTrigger={tooltipTrigger}
            disabledZoom={disabledZoom}
            frozenTooltipType={FrozenTooltipType.FrozenAndClickable}
            updateActiveTooltips={updateActiveTooltips}
            targetTooltipLink={OverviewLinkType.Node}
            secondaryValue={(item) => {
              const itemValue = item?.payload["nodeCpuAllocatable_" + (item?.name?.toLowerCase() || "")];
              return itemValue ? " | " + itemValue : "";
            }}
            tooltipValueTitle={"util | alloc"}
          />
        </div>
        <div className={halfWrapperClassName}>
          <MultipleRegexpLineChart
            title="Node memory utilization(%)"
            regexp={"nodeMemoryUtilization_"}
            tooltipValueSuffix="%"
            roundValues
            queryParams={{
              from: from,
              to: to,
              name: selectedWorkload.workloadName,
              namespace: selectedWorkload.namespace,
              workloadType: getWorkloadTypeForWlAnalytics(selectedWorkload),
              types: ["nodeMemoryUtilization", "nodeMemoryAllocatable"],
              container: selectedContainer,
              topk: numberOfElements,
              uniqueQueryKey: String(numberOfElements),
            }}
            YAxisDomain={PERCENTAGE_Y_AXIS_DOMAIN}
            tooltipTrigger={tooltipTrigger}
            disabledZoom={disabledZoom}
            frozenTooltipType={FrozenTooltipType.FrozenAndClickable}
            updateActiveTooltips={updateActiveTooltips}
            targetTooltipLink={OverviewLinkType.Node}
            secondaryValue={(item) => {
              const itemValue = item?.payload["nodeMemoryAllocatable_" + (item?.name?.toLowerCase() || "")];
              return itemValue ? " | " + getMemoryValue(Number(itemValue)) : "";
            }}
            tooltipValueTitle={"util | alloc"}
          />
        </div>
      </div>
      <div className="flex flex-wrap gap-[10px] my-[10px]">
        <div className={halfWrapperClassName}>
          <MultipleRegexpLineChart
            title="CPU throttling"
            infoTooltip={
              <>
                <b>CPU throttling</b> occurs when workload's CPU usage exceeds the configured <b>limit</b>. <br />
                <br />
                Limits can be defined via Workload <b>Configuration</b> or ScaleOps’ <b>policy</b>.
              </>
            }
            regexp={"throttling_"}
            queryParams={{
              from: from,
              to: to,
              name: selectedWorkload.workloadName,
              namespace: selectedWorkload.namespace,
              workloadType: getWorkloadTypeForWlAnalytics(selectedWorkload),
              types: ["throttling"],
              container: selectedContainer,
            }}
            tickFormatter={ROUNDED_TICK_FORMATTER}
            YAxisDomain={[0, "dataMax + 2"]}
            YAxisAllowDecimals={false}
            tooltipTrigger={tooltipTrigger}
            disabledZoom={disabledZoom}
            frozenTooltipType={FrozenTooltipType.FrozenAndNotClickable}
            updateActiveTooltips={updateActiveTooltips}
            dotted={true}
          />
        </div>
        <div className={halfWrapperClassName}>
          <MultipleRegexpLineChart
            title="Out of memory"
            regexp={"oom_"}
            tooltipValueSuffix=""
            roundValues
            queryParams={{
              from: from,
              to: to,
              name: selectedWorkload.workloadName,
              namespace: selectedWorkload.namespace,
              workloadType: getWorkloadTypeForWlAnalytics(selectedWorkload),
              types: ["oom"],
              container: selectedContainer,
            }}
            colors={[
              SCALEOPS_COLORS.events.oomKubelet,
              SCALEOPS_COLORS.events.oomLimit,
              SCALEOPS_COLORS.events.oomNode,
            ]}
            tickFormatter={ROUNDED_TICK_FORMATTER}
            YAxisDomainWithHeadroomAndWholeNumbers
            tooltipTrigger={tooltipTrigger}
            disabledZoom={disabledZoom}
            frozenTooltipType={FrozenTooltipType.FrozenAndNotClickable}
            updateActiveTooltips={updateActiveTooltips}
            dotted={true}
          />
        </div>
        <div className={halfWrapperClassName}>
          <LineChart
            title="Liveness probe failures"
            color={SCALEOPS_COLORS.events.liveness}
            label="liveness"
            queryParams={{
              from: from,
              to: to,
              name: selectedWorkload.workloadName,
              namespace: selectedWorkload.namespace,
              workloadType: getWorkloadTypeForWlAnalytics(selectedWorkload),
              types: ["liveness"],
              container: selectedContainer,
            }}
            tooltipTrigger={tooltipTrigger}
            disabledZoom={disabledZoom}
            frozenTooltipType={FrozenTooltipType.FrozenAndNotClickable}
            updateActiveTooltips={updateActiveTooltips}
            dotted={true}
          />
        </div>
        <div className={halfWrapperClassName}>
          <LineChart
            title="ScaleOps optimization evictions"
            color={SCALEOPS_COLORS.events.eviction}
            label="evictions"
            queryParams={{
              from: from,
              to: to,
              name: selectedWorkload.workloadName,
              namespace: selectedWorkload.namespace,
              workloadType: getWorkloadTypeForWlAnalytics(selectedWorkload),
              types: ["evictions"],
              container: selectedContainer,
            }}
            tooltipTrigger={tooltipTrigger}
            disabledZoom={disabledZoom}
            frozenTooltipType={FrozenTooltipType.FrozenAndNotClickable}
            updateActiveTooltips={updateActiveTooltips}
            dotted={true}
          />
        </div>
        {hasHpaCpuData && (
          <div className={halfWrapperClassName}>
            <MultipleLinesChart
              title="HPA CPU"
              elements={
                isSnapshotServer
                  ? [
                      {
                        key: "hpaCpuUsage",
                        label: "usage (avg)",
                        color: SCALEOPS_COLORS.main.blue,
                        fill: SCALEOPS_COLORS.main.blue,
                      },
                      {
                        key: "hpaCpuThresholdFromUtilization",
                        label: "threshold (by util)",
                        color: SCALEOPS_COLORS.main.orange,
                      },
                      {
                        key: "hpaCpuThreshold",
                        label: "threshold",
                        color: SCALEOPS_COLORS.workload.readyReplicas,
                      },
                      {
                        key: "hpaCpuThresholdFromAverageValue",
                        label: "threshold (by value)",
                        color: SCALEOPS_COLORS.main.burgundy,
                      },
                    ]
                  : [
                      {
                        key: "hpaCpuUsage",
                        label: "usage (avg)",
                        color: SCALEOPS_COLORS.main.blue,
                        fill: SCALEOPS_COLORS.main.blue,
                      },
                      {
                        key: "hpaCpuThreshold",
                        label: "threshold",
                        color: SCALEOPS_COLORS.workload.readyReplicas,
                      },
                    ]
              }
              queryParams={{
                from: from,
                to: to,
                name: selectedWorkload.workloadName,
                namespace: selectedWorkload.namespace,
                workloadType: getWorkloadTypeForWlAnalytics(selectedWorkload),
                types: isSnapshotServer
                  ? [
                      "hpaCpuUsage",
                      "hpaCpuThreshold",
                      "hpaCpuThresholdFromUtilization",
                      "hpaCpuThresholdFromAverageValue",
                    ]
                  : ["hpaCpuUsage", "hpaCpuThreshold"],
                container: selectedContainer,
              }}
              setHasData={setHasHpaCpuData}
              yAxisTickFormatter={ROUNDED_TICK_FORMATTER}
              tooltipTrigger={tooltipTrigger}
              disabledZoom={disabledZoom}
              frozenTooltipType={FrozenTooltipType.FrozenAndNotClickable}
              updateActiveTooltips={updateActiveTooltips}
              isSnapshotElementFnc={
                isSnapshotServer
                  ? (key: string) => ["hpaCpuThresholdFromUtilization", "hpaCpuThresholdFromAverageValue"].includes(key)
                  : undefined
              }
            />
          </div>
        )}
        {hasHpaMemoryData && (
          <div className={halfWrapperClassName}>
            <MultipleLinesChart
              title="HPA Memory"
              yAxisTickFormatter={memoryDataParser}
              elements={
                isSnapshotServer
                  ? [
                      {
                        key: "hpaMemoryUsage",
                        label: "usage (avg)",
                        color: SCALEOPS_COLORS.main.blue,
                        fill: SCALEOPS_COLORS.main.blue,
                        dataParser: memoryDataParser,
                      },
                      {
                        key: "hpaMemoryThresholdFromUtilization",
                        label: "threshold (by util)",
                        color: SCALEOPS_COLORS.main.orange,
                        dataParser: memoryDataParser,
                      },
                      {
                        key: "hpaMemoryThresholdFromAverageValue",
                        label: "threshold (by value)",
                        color: SCALEOPS_COLORS.main.burgundy,
                        dataParser: memoryDataParser,
                      },
                    ]
                  : [
                      {
                        key: "hpaMemoryUsage",
                        label: "usage (avg)",
                        color: SCALEOPS_COLORS.main.blue,
                        fill: SCALEOPS_COLORS.main.blue,
                        dataParser: memoryDataParser,
                      },
                      {
                        key: "hpaMemoryThreshold",
                        label: "threshold",
                        color: SCALEOPS_COLORS.workload.readyReplicas,
                        dataParser: memoryDataParser,
                      },
                    ]
              }
              queryParams={{
                from: from,
                to: to,
                name: selectedWorkload.workloadName,
                namespace: selectedWorkload.namespace,
                workloadType: getWorkloadTypeForWlAnalytics(selectedWorkload),
                types: isSnapshotServer
                  ? ["hpaMemoryUsage", "hpaMemoryThresholdFromUtilization", "hpaMemoryThresholdFromAverageValue"]
                  : ["hpaMemoryUsage", "hpaMemoryThreshold"],
                container: selectedContainer,
              }}
              setHasData={setHasHpaMemoryData}
              tooltipTrigger={tooltipTrigger}
              disabledZoom={disabledZoom}
              frozenTooltipType={FrozenTooltipType.FrozenAndNotClickable}
              updateActiveTooltips={updateActiveTooltips}
              isSnapshotElementFnc={
                isSnapshotServer
                  ? (key: string) =>
                      ["hpaMemoryThresholdFromUtilization", "hpaMemoryThresholdFromAverageValue"].includes(key)
                  : undefined
              }
            />
          </div>
        )}
        <div className={halfWrapperClassName}>
          <MultipleRegexpLineChart
            title="CPU noisy neighbors - under-provisioned workloads"
            infoTooltip={
              <>
                <b>Sum</b> of <b>usage above request</b> for workloads that have been on the same node as this workload,
                at a time when the node was <b>CPU stressed</b>.
              </>
            }
            regexp={"cpu_noisy_neighbors_"}
            roundValues
            queryParams={{
              from: from,
              to: to,
              name: selectedWorkload.workloadName,
              namespace: selectedWorkload.namespace,
              workloadType: getWorkloadTypeForWlAnalytics(selectedWorkload),
              types: ["cpuNoisyNeighbors"],
              container: selectedContainer,
              topk: numberOfElements,
              uniqueQueryKey: String(numberOfElements),
            }}
            tickFormatter={ROUNDED_TICK_FORMATTER}
            YAxisDomain={NUMERIC_CPU_Y_AXIS_DOMAIN}
            YAxisAllowDecimals={false}
            tooltipTrigger={tooltipTrigger}
            disabledZoom={disabledZoom}
            frozenTooltipType={FrozenTooltipType.FrozenAndClickable}
            updateActiveTooltips={updateActiveTooltips}
          />
        </div>
        <div className={halfWrapperClassName}>
          <MultipleRegexpLineChart
            title="Memory noisy neighbors - under-provisioned workloads"
            infoTooltip={
              <>
                <b>Sum</b> of <b>usage above request</b> for workloads that have been on the same node as this workload,
                at a time when the node was <b>Memory stressed</b>.
              </>
            }
            regexp={"memory_noisy_neighbors_"}
            roundValues
            queryParams={{
              from: from,
              to: to,
              name: selectedWorkload.workloadName,
              namespace: selectedWorkload.namespace,
              workloadType: getWorkloadTypeForWlAnalytics(selectedWorkload),
              types: ["memoryNoisyNeighbors"],
              container: selectedContainer,
              topk: numberOfElements,
              uniqueQueryKey: String(numberOfElements),
            }}
            tickFormatter={memoryDataParser}
            YAxisDomain={NUMERIC_MEMORY_Y_AXIS_DOMAIN}
            YAxisAllowDecimals={false}
            tooltipTrigger={tooltipTrigger}
            disabledZoom={disabledZoom}
            frozenTooltipType={FrozenTooltipType.FrozenAndClickable}
            updateActiveTooltips={updateActiveTooltips}
          />
        </div>
        <div className={halfWrapperClassName}>
          <MultipleRegexpLineChart
            title="Pod CPU request"
            regexp={"cpu_request_"}
            roundValues
            queryParams={{
              from: from,
              to: to,
              name: selectedWorkload.workloadName,
              namespace: selectedWorkload.namespace,
              workloadType: getWorkloadTypeForWlAnalytics(selectedWorkload),
              types: ["cpuRequest", "recommendedCpuRequest"],
              container: selectedContainer,
              topk: numberOfElements,
              uniqueQueryKey: String(numberOfElements),
            }}
            tickFormatter={ROUNDED_TICK_FORMATTER}
            getElementsFormat={recommendedGetElementsFormat}
            legendSortFunction={recommendedLegendSortFunction}
            tooltipTrigger={tooltipTrigger}
            disabledZoom={disabledZoom}
            frozenTooltipType={FrozenTooltipType.FrozenAndNotClickable}
            updateActiveTooltips={updateActiveTooltips}
            tooltipSortFnc={podTooltipSortFnc}
          />
        </div>
        <div className={halfWrapperClassName}>
          <MultipleRegexpLineChart
            title="Pod memory request"
            regexp={"memory_request_"}
            roundValues
            queryParams={{
              from: from,
              to: to,
              name: selectedWorkload.workloadName,
              namespace: selectedWorkload.namespace,
              workloadType: getWorkloadTypeForWlAnalytics(selectedWorkload),
              types: ["memoryRequest", "recommendedMemoryRequest"],
              container: selectedContainer,
              topk: numberOfElements,
              uniqueQueryKey: String(numberOfElements),
            }}
            tickFormatter={memoryDataParser}
            YAxisDomain={NUMERIC_MEMORY_Y_AXIS_DOMAIN}
            YAxisAllowDecimals={false}
            getElementsFormat={recommendedGetElementsFormat}
            legendSortFunction={recommendedLegendSortFunction}
            tooltipTrigger={tooltipTrigger}
            disabledZoom={disabledZoom}
            frozenTooltipType={FrozenTooltipType.FrozenAndNotClickable}
            updateActiveTooltips={updateActiveTooltips}
            tooltipSortFnc={podTooltipSortFnc}
          />
        </div>
        <div className={halfWrapperClassName}>
          <MultipleRegexpLineChart
            title="Pod CPU usage"
            regexp={"cpu_usage_"}
            whitelist={["cpu_request_recommended"]}
            roundValues
            queryParams={{
              from: from,
              to: to,
              name: selectedWorkload.workloadName,
              namespace: selectedWorkload.namespace,
              workloadType: getWorkloadTypeForWlAnalytics(selectedWorkload),
              types: ["podCpuUsage", "recommendedCpuRequest"],
              container: selectedContainer,
              topk: numberOfElements,
              uniqueQueryKey: String(numberOfElements),
            }}
            tickFormatter={ROUNDED_TICK_FORMATTER}
            getElementsFormat={recommendedGetElementsFormat}
            legendSortFunction={recommendedLegendSortFunction}
            tooltipTrigger={tooltipTrigger}
            disabledZoom={disabledZoom}
            frozenTooltipType={FrozenTooltipType.FrozenAndNotClickable}
            updateActiveTooltips={updateActiveTooltips}
            tooltipSortFnc={podTooltipSortFnc}
          />
        </div>
        <div className={halfWrapperClassName}>
          <MultipleRegexpLineChart
            title="Pod memory usage"
            regexp={"memory_usage_"}
            whitelist={["memory_request_recommended"]}
            roundValues
            queryParams={{
              from: from,
              to: to,
              name: selectedWorkload.workloadName,
              namespace: selectedWorkload.namespace,
              workloadType: getWorkloadTypeForWlAnalytics(selectedWorkload),
              types: ["podMemoryUsage", "recommendedMemoryRequest"],
              container: selectedContainer,
              topk: numberOfElements,
              uniqueQueryKey: String(numberOfElements),
            }}
            tickFormatter={memoryDataParser}
            YAxisDomain={NUMERIC_MEMORY_Y_AXIS_DOMAIN}
            YAxisAllowDecimals={false}
            getElementsFormat={recommendedGetElementsFormat}
            legendSortFunction={recommendedLegendSortFunction}
            tooltipTrigger={tooltipTrigger}
            disabledZoom={disabledZoom}
            frozenTooltipType={FrozenTooltipType.FrozenAndNotClickable}
            updateActiveTooltips={updateActiveTooltips}
            tooltipSortFnc={podTooltipSortFnc}
          />
        </div>
        <div className={halfWrapperClassName}>
          <MultipleLinesChart
            title="Nodes life cycle"
            elements={[
              {
                key: "spotNodes",
                label: "Spot nodes",
                color: SCALEOPS_COLORS.main.yellow,
              },
              {
                key: "onDemandNodes",
                label: "On demand",
                color: SCALEOPS_COLORS.main.blue,
              },
            ]}
            queryParams={{
              from: from,
              to: to,
              name: selectedWorkload.workloadName,
              namespace: selectedWorkload.namespace,
              workloadType: getWorkloadTypeForWlAnalytics(selectedWorkload),
              types: ["spotNodes", "onDemandNodes"],
              container: selectedContainer,
            }}
            setHasData={setHasHpaMemoryData}
            tooltipTrigger={tooltipTrigger}
            disabledZoom={disabledZoom}
            frozenTooltipType={FrozenTooltipType.FrozenAndNotClickable}
            updateActiveTooltips={updateActiveTooltips}
          />
        </div>
        <div className={halfWrapperClassName}>
          <MultipleRegexpLineChart
            title="Pod disruptions reasons"
            regexp={"scale_down_reason_"}
            whitelist={[]}
            roundValues
            queryParams={{
              from: from,
              to: to,
              name: selectedWorkload.workloadName,
              namespace: selectedWorkload.namespace,
              workloadType: getWorkloadTypeForWlAnalytics(selectedWorkload),
              types: ["scaleDownReasons"],
              container: selectedContainer,
              topk: numberOfElements,
            }}
            tickFormatter={ROUNDED_TICK_FORMATTER}
            getElementsFormat={recommendedGetElementsFormat}
            legendSortFunction={recommendedLegendSortFunction}
            tooltipTrigger={tooltipTrigger}
            disabledZoom={disabledZoom}
            frozenTooltipType={FrozenTooltipType.FrozenAndNotClickable}
            updateActiveTooltips={updateActiveTooltips}
            tooltipSortFnc={podTooltipSortFnc}
            YAxisDomain={[0, 10]}
            dotted={true}
            hasStackedOffset
            displayNameFnc={(key: string | undefined) => {
              let displayName = key;
              switch (true) {
                case String(key).toLowerCase() === "EvctedByScaleops".toLowerCase():
                  displayName = "Evicted by scaleops";
                  break;
                case String(key).toLowerCase() === "ScaledDownHPA".toLowerCase():
                  displayName = "Scaled down by hpa";
                  break;
                case String(key).toLowerCase() === "Preempt".toLowerCase():
                  displayName = "Preempted by kubelet";
                  break;
                case String(key).toLowerCase() === "KubeletEviction".toLowerCase():
                  displayName = "Evicted by kubelet";
                  break;
                case String(key).toLowerCase() === "NodeScaledDown".toLowerCase():
                  displayName = "Node scale down";
                  break;
                case String(key).toLowerCase() === "NodeDown".toLowerCase():
                  displayName = "Node removed";
                  break;
                case String(key).toLowerCase() === "NodeDownSuspected".toLowerCase():
                  displayName = "Node removed suspected";
                  break;
                case String(key).toLowerCase() === "ClusterAutoscaler".toLowerCase():
                  displayName = "Evicted by cluster autoscaler";
                  break;
                case String(key).toLowerCase() === "Karpenter".toLowerCase():
                  displayName = "Evicted by karpenter";
                  break;
                case String(key).toLowerCase() === "Unknown".toLowerCase():
                  displayName = "Pod removed";
                  break;
                default:
                  break;
              }
              return String(displayName);
            }}
          />
        </div>
        <div className={halfWrapperClassName}>
          <MultipleRegexpLineChart
            title={"Attached policy"}
            regexp={"policy_"}
            whitelist={[]}
            roundValues
            queryParams={{
              from: from,
              to: to,
              name: selectedWorkload.workloadName,
              namespace: selectedWorkload.namespace,
              workloadType: getWorkloadTypeForWlAnalytics(selectedWorkload),
              types: ["policyOverTime"],
              container: selectedContainer,
              topk: numberOfElements,
            }}
            tickFormatter={ROUNDED_TICK_FORMATTER}
            getElementsFormat={recommendedGetElementsFormat}
            legendSortFunction={recommendedLegendSortFunction}
            tooltipTrigger={tooltipTrigger}
            disabledZoom={disabledZoom}
            frozenTooltipType={FrozenTooltipType.FrozenAndNotClickable}
            updateActiveTooltips={updateActiveTooltips}
            tooltipSortFnc={podTooltipSortFnc}
            YAxisDomain={[0, "dataMax + 1"]}
            YAxisAllowDecimals={false}
          />
        </div>
        <div className={halfWrapperClassName}>
          <MultipleLinesChart
            title="Unevictable pods"
            elements={[
              {
                key: "binpack",
                label: "optimized unevictable pods",
                color: SCALEOPS_COLORS.main.green,
                fill: SCALEOPS_COLORS.main.lightGreen,
              },
              {
                key: "total",
                label: "unevictable pods",
                color: SCALEOPS_COLORS.main.blue,
              },
            ]}
            queryParams={{
              from: from,
              to: to,
              name: selectedWorkload.workloadName,
              namespace: selectedWorkload.namespace,
              workloadType: getWorkloadTypeForWlAnalytics(selectedWorkload),
              types: ["binPackedPods", "totalPods"],
              container: selectedContainer,
            }}
            setHasData={setHasHpaMemoryData}
            tooltipTrigger={tooltipTrigger}
            disabledZoom={disabledZoom}
            frozenTooltipType={FrozenTooltipType.FrozenAndNotClickable}
            updateActiveTooltips={updateActiveTooltips}
            YAxisDomain={[0, "dataMax + 1"]}
          />
        </div>
        {selectedWorkload.hasHpa && (
          <div className={halfWrapperClassName}>
            <MultipleRegexpLineChart
              title="HPA replicas by triggers"
              regexp={"metric_value_replicas_"}
              whitelist={["replicas", "minHpaReplicas"]}
              roundValues
              queryParams={{
                from: from,
                to: to,
                name: selectedWorkload.workloadName,
                namespace: selectedWorkload.namespace,
                workloadType: getWorkloadTypeForWlAnalytics(selectedWorkload),
                types: ["hpaTriggerValueReplicas", "minHpaReplicas", "replicas"],
                container: selectedContainer,
              }}
              YAxisDomainWithHeadroomAndWholeNumbers
              YAxisAllowDecimals={false}
              tooltipTrigger={tooltipTrigger}
              tickFormatter={ROUNDED_TICK_FORMATTER}
              disabledZoom={disabledZoom}
              frozenTooltipType={FrozenTooltipType.FrozenAndNotClickable}
              updateActiveTooltips={updateActiveTooltips}
              getElementsFormat={hpaTriggerGetElementsFormat}
              legendSortFunction={hpaTriggerLegendSortFunction}
              tooltipSortFnc={hpaTriggerTooltipSortFnc}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default WorkloadAnalytics;
