import { GetTopKQueryKey } from "../../../api/fetcher";
import TopKSummaryLineChart from "../../Analytics/AnalyticsV2/Graphs/TopKSummaryLineChart";
import useGetGraphState from "../useGetGraphsState";
import { GENERAL_SYNC_ID } from "../utils";
import { CommonGraphProps, GraphDisplayName, SCALEOPS_HEALTH_NUMBER_OF_ELEMENTS } from "./utils";

const IssuesGraph = ({ isMulticluster, tooltipTrigger, disabledZoom, updateActiveTooltips }: CommonGraphProps) => {
  const { date, setDate, numberOfElements, frozenAndClickableTab } = useGetGraphState();

  return (
    <TopKSummaryLineChart
      title={GraphDisplayName.IssuesGraph}
      dotted
      queryParams={{
        from: date.range ? undefined : date.from,
        to: date.range ? undefined : date.to,
        range: date.range,
        queryKey: GetTopKQueryKey.IssuesOverTime,
      }}
      setDate={setDate}
      isMulticluster={isMulticluster}
      YAxisDomain={[0, 2]}
      tooltipTrigger={tooltipTrigger}
      disabledZoom={disabledZoom}
      updateActiveTooltips={updateActiveTooltips}
      enableCopyTextOnClick
      getParsedDataFixedValue={1}
      allowDecimals={false}
      renderNameFunction={(name) => {
        if (!name) return "";
        const splitName = name.split("/");
        if (splitName.length < 3) return name;
        const issueType = splitName[3];
        const componentName = splitName[2].replace("scaleops-", "");
        return issueType ? componentName + "/" + issueType : componentName;
      }}
      frozenTooltipType={frozenAndClickableTab}
      syncId={GENERAL_SYNC_ID}
      topK={isMulticluster ? numberOfElements : SCALEOPS_HEALTH_NUMBER_OF_ELEMENTS}
    />
  );
};

export default IssuesGraph;
