import { GetTopKQueryKey } from "../../../api/fetcher";
import { capitalizeFirstLetter } from "../../../utils/formatterUtils";
import TopValuesGraph from "../../Analytics/AnalyticsV2/Graphs/TopValuesGraph";
import { AggregateBy } from "../../Analytics/AnalyticsV2/SelectAggregationView";
import useGetGraphState from "../useGetGraphsState";
import { GENERAL_SYNC_ID } from "../utils";
import { CommonGraphProps, GraphDisplayName } from "./utils";

const WastedCPUGraph = ({ isMulticluster, tooltipTrigger, disabledZoom, updateActiveTooltips }: CommonGraphProps) => {
  const { date, setDate, numberOfElements, aggregationView, frozenAndClickableTab } = useGetGraphState();

  return (
    <TopValuesGraph
      title={GraphDisplayName.WastedCPUGraph + " By " + capitalizeFirstLetter(aggregationView.toLowerCase())}
      queryParams={{
        from: date.range ? undefined : date.from,
        to: date.range ? undefined : date.to,
        range: date.range,
        queryKey:
          aggregationView === AggregateBy.Workloads ? GetTopKQueryKey.WastedCpu : GetTopKQueryKey.WastedCpuByNamespace,
      }}
      setDate={setDate}
      isMulticluster={isMulticluster}
      topK={numberOfElements}
      YAxisFormatter={(value) => `${value}mil`}
      tooltipTrigger={tooltipTrigger}
      disabledZoom={disabledZoom}
      updateActiveTooltips={updateActiveTooltips}
      enableCopyTextOnClick
      frozenTooltipType={frozenAndClickableTab}
      syncId={GENERAL_SYNC_ID}
    />
  );
};

export default WastedCPUGraph;
