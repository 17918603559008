export enum TabType {
  INSTALL = "Install",
  INVITE = "Invite users",
}

export const containerClassName = `p-5 width-full rounded-lg flex items-center gap-[0.9375rem]`;

interface GetCommandProps {
  commandType: "install" | "portForward" | "upgrade";
  token: string;
  customerPrefix: string;
  showToken?: boolean;
  hasOpenShift?: boolean;
  useScaleOpsRegistry?: boolean;
  isHostedCloud?: boolean;
}
export const getCommand = ({
  commandType,
  token,
  customerPrefix,
  hasOpenShift,
  showToken,
  useScaleOpsRegistry,
  isHostedCloud,
}: GetCommandProps) => {
  token = showToken ? token : "*****";

  const installRegular = `
helm install --create-namespace -n scaleops-system \\
  --repo https://raw.githubusercontent.com/scaleops-sh/helm-charts/main/ \\
  --username scaleops --password ${token} \\
  --set scaleopsToken=${token} \\
  --set clusterName=${customerPrefix}-$(kubectl config current-context) \\
  --set scaleopsCloud.enabled=false \\
scaleops scaleops
    `;

  const installFreeTrail = `
helm install --create-namespace -n scaleops-system \\
  --repo https://registry.scaleops.com/charts \\
  --username scaleops --password ${token} \\
  --set scaleopsToken=${token} \\
  --set clusterName=${customerPrefix}-$(kubectl config current-context) \\
  --set global.image.registry=registry.scaleops.com \\
  --set scaleopsCloud.enabled=false \\
scaleops scaleops
    `;

  const installOpenShift = `
helm install --create-namespace -n scaleops-system \\
  --repo https://raw.githubusercontent.com/scaleops-sh/helm-charts/main/ \\
  --username scaleops --password ${token} \\
  --set scaleopsToken=${token} \\
  --set clusterName=${customerPrefix}-$(oc config current-context) \\
  --set global.openShift=true \\
  --set scaleopsCloud.enabled=false \\
scaleops scaleops
    `;

  const installOpenShiftFreeTrial = `
helm install --create-namespace -n scaleops-system \\
  --repo https://registry.scaleops.com/charts \\
  --username scaleops --password ${token} \\
  --set scaleopsToken=${token} \\
  --set clusterName=${customerPrefix}-$(oc config current-context) \\
  --set global.image.registry=registry.scaleops.com \\
  --set global.openShift=true \\
  --set scaleopsCloud.enabled=false \\
scaleops scaleops
    `;

  const installHostedCloud = `
helm install --create-namespace -n scaleops-system \\
  --repo https://raw.githubusercontent.com/scaleops-sh/helm-charts/main/ \\
  --username scaleops --password ${token} \\
  --set scaleopsToken=${token} \\
  --set clusterName=${customerPrefix}-$(kubectl config current-context) \\
scaleops scaleops
    `;

  const installHostedCloudFreeTrial = `
helm install --create-namespace -n scaleops-system \\
  --repo https://registry.scaleops.com/charts \\
  --username scaleops --password ${token} \\
  --set scaleopsToken=${token} \\
  --set clusterName=${customerPrefix}-$(kubectl config current-context) \\
  --set global.image.registry=registry.scaleops.com \\
scaleops scaleops
    `;

  const openShiftHostedCloud = `
helm install --create-namespace -n scaleops-system \\
  --repo https://raw.githubusercontent.com/scaleops-sh/helm-charts/main/ \\
  --username scaleops --password ${token} \\
  --set scaleopsToken=${token} \\
  --set clusterName=${customerPrefix}-$(oc config current-context) \\
  --set global.openShift=true \\
scaleops scaleops
    `;

  const openShiftHostedCloudFreeTrial = `
helm install --create-namespace -n scaleops-system \\
  --repo https://registry.scaleops.com/charts \\
  --username scaleops --password ${token} \\
  --set scaleopsToken=${token} \\
  --set clusterName=${customerPrefix}-$(oc config current-context) \\
  --set global.image.registry=registry.scaleops.com \\
  --set global.openShift=true \\
scaleops scaleops
    `;

  const upgrade = `
helm repo add scaleops --username scaleops \\
  --password ${token} \\
  https://raw.githubusercontent.com/scaleops-sh/helm-charts/main/ \\
  --force-update
helm show crds scaleops/scaleops | kubectl apply --force -f -
helm get values scaleops -n scaleops-system -oyaml | \\
helm upgrade scaleops scaleops/scaleops -n scaleops-system -f -
`;

  const upgradeFreeTrial = `
helm repo add scaleops --username scaleops \\
  --password ${token} \\
  https://registry.scaleops.com/charts \\
  --force-update
helm show crds scaleops/scaleops | kubectl apply --force -f -
helm get values scaleops -n scaleops-system -oyaml | \\
  helm upgrade scaleops scaleops/scaleops -n scaleops-system -f -
`;
  const upgradeOpenShift = `
helm repo add scaleops --username scaleops \\
  --password ${token} \\
  https://raw.githubusercontent.com/scaleops-sh/helm-charts/main/ \\
  --force-update
helm show crds scaleops/scaleops | oc apply --force -f -
helm get values scaleops -n scaleops-system -oyaml | \\
  helm upgrade scaleops scaleops/scaleops -n scaleops-system -f -
`;

  const upgradeOpenShiftFreeTrial = `
helm repo add scaleops --username scaleops \\
  --password ${token} \\
  https://registry.scaleops.com/charts \\
  --force-update
helm show crds scaleops/scaleops | oc apply --force -f -
helm get values scaleops -n scaleops-system -oyaml | \\
  helm upgrade scaleops scaleops/scaleops -n scaleops-system -f -
`;

  const portForward = `kubectl port-forward service/scaleops-dashboards -n scaleops-system 8080`;

  const portForwardOpenShift = `oc port-forward service/scaleops-dashboards -n scaleops-system 8080`;

  let output = "";

  switch (true) {
    case commandType === "install" && isHostedCloud && useScaleOpsRegistry && !hasOpenShift:
      output = installHostedCloudFreeTrial;
      break;
    case commandType === "install" && isHostedCloud && !useScaleOpsRegistry && !hasOpenShift:
      output = installHostedCloud;
      break;
    case commandType === "install" && isHostedCloud && !useScaleOpsRegistry && hasOpenShift:
      output = openShiftHostedCloud;
      break;
    case commandType === "install" && isHostedCloud && useScaleOpsRegistry && hasOpenShift:
      output = openShiftHostedCloudFreeTrial;
      break;
    case commandType === "install" && !hasOpenShift:
      if (useScaleOpsRegistry) {
        output = installFreeTrail;
      } else {
        output = installRegular;
      }
      break;
    case commandType === "install" && hasOpenShift:
      if (useScaleOpsRegistry) {
        output = installOpenShiftFreeTrial;
      } else {
        output = installOpenShift;
      }
      break;
    case commandType === "portForward" && !hasOpenShift:
      output = portForward;
      break;
    case commandType === "portForward" && hasOpenShift:
      output = portForwardOpenShift;
      break;
    case commandType === "upgrade" && !hasOpenShift:
      if (useScaleOpsRegistry) {
        output = upgradeFreeTrial;
      } else {
        output = upgrade;
      }
      break;
    case commandType === "upgrade" && hasOpenShift:
      if (useScaleOpsRegistry) {
        output = upgradeOpenShiftFreeTrial;
      } else {
        output = upgradeOpenShift;
      }
      break;
    default:
      break;
  }

  return output;
};
