import { SCALEOPS_COLORS } from "../../../../../colors";
import ComposeChart from "../../../../../components/ComposeChart/ComposeChart";
import { ChartData, ChartElement, SetDate, YAxisTickFormatterType } from "../../../../../components/ComposeChart/utils";
import { WORKLOAD_EBPF_CHARTS_SYNC_ID, XAxisIntervalBase } from "./utils";

enum ElementsKeys {
  Requests = "requests",
  Errors = "errors",
}

const ELEMENTS: ChartElement[] = [
  {
    key: ElementsKeys.Requests,
    color: SCALEOPS_COLORS.main.blue,
    name: "Requests",
  },
  {
    key: ElementsKeys.Errors,
    color: SCALEOPS_COLORS.main.red,
    name: "Errors",
  },
];

interface Props {
  graphData: ChartData;
  isLoading: boolean;
  setDate: SetDate;
}

const RequestChart = ({ graphData, isLoading, setDate }: Props) => {
  return (
    <ComposeChart
      data={graphData}
      title="Requests"
      isLoading={isLoading}
      elements={ELEMENTS}
      yAxisTickFormatterType={YAxisTickFormatterType.Number}
      XAxisIntervalBase={XAxisIntervalBase}
      syncId={WORKLOAD_EBPF_CHARTS_SYNC_ID}
      setDate={setDate}
      selectedElementsOnInitialRender={[ElementsKeys.Requests]}
    />
  );
};

export default RequestChart;
