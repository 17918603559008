import { useFormikContext } from "formik";
import FormInput from "../../../../components/formComponents/FormInput";
import FormSwitch from "../../../../components/formComponents/FormSwitch";
import FormTitleAndDescription from "../../../../components/formComponents/FormTitleAndDescription";
import useIsReadyOnlyFrontEnd from "../../../../utils/useIsReadyOnlyFrontEnd";
import { HPA_SECTION_CLASS_NAME } from "../../../Policies/EditFormSections/utils";
import { IsSnapshotServer } from "../../../../utils/FeaturesHelper";

interface Props {
  isCustomizedPolicy?: boolean;
}

const GeneralMaxReplicas = ({ isCustomizedPolicy }: Props) => {
  const isReadOnlyFrontEnd = useIsReadyOnlyFrontEnd();
  const { values } = useFormikContext<{
    generalMaxReplicasEnabled: boolean;
  }>();

  return (
    <div className={HPA_SECTION_CLASS_NAME}>
      <FormTitleAndDescription title="Max replicas" description="Set the maximum replicas for this workload." />
      <div className="flex flex-col gap-4 pb-2">
        <FormSwitch
          label="Set max replicas value"
          name="generalMaxReplicasEnabled"
          disableChange={!isCustomizedPolicy || isReadOnlyFrontEnd}
        />
        <FormInput
          label="Max replicas"
          name="generalMaxReplicas"
          type="number"
          disableChange={!IsSnapshotServer() && (!isCustomizedPolicy || isReadOnlyFrontEnd)}
          disabled={!values?.generalMaxReplicasEnabled}
        />
      </div>
    </div>
  );
};

export default GeneralMaxReplicas;
