import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Alert,
  AlertProps,
  CircularProgress,
  DialogContent,
  DialogContentText,
  IconButton,
  InputAdornment,
  List,
  Snackbar,
  Switch,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import SaveButton from "./Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import { DataGrid, GridColDef, GridRenderCellParams, GridRowsProp } from "@mui/x-data-grid";
import clsx from "clsx";
import { ApiResponse } from "openapi-typescript-fetch/dist/cjs/types";
import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import CloseIcon from "../Icons/CloseIcon";
import CustomInfoIcon from "../Icons/InfoIcon";
import { useMainContext } from "../MainContext";
import useGetUserRole from "../MainMenu/useGetUserRole";
import { ScaleOps, ScaleOpsClient } from "../api/api";
import { components } from "../api/schema";
import useGetApi from "../api/useGetApi";
import IngressContainer from "../pages/Ingress/IngressContainer";
import PermissionsContainer from "../pages/Roles/Permission/PermissionsContainer";
import theme from "../theme";
import { FeatureEnabled } from "../utils/FeaturesHelper";
import { getDataGridSx, PAGE_CONTENT_CLASS_NAME } from "../utils/styleUtils";
import useIsReadyOnlyFrontEnd from "../utils/useIsReadyOnlyFrontEnd";
import Tab, { TABS_CONTAINER_CLASS_NAME } from "./Tab";
import Tooltip from "./Tooltip";
import GeneralSettingsTab from "./generalSettings/GeneralSettingsTab";
import CustomCheckBox from "../components/CustomCheckBox";
import { GetConfig } from "../utils/ConfigHelper";

const HAS_INGRESS_TAB = false;
const ADMIN = "Admin";
const CUSTOM_ICON_SIZE = 12;

enum TabOptions {
  COST = "Cost",
  Auth = "Auth",
  METRICS = "Metrics",
  GENERAL = "General",
  INGRESS = "Ingress",
}

function CostTab(props: { handleSave: (success: boolean, message: string) => void; disabled: boolean }) {
  const { handleSave, disabled } = props;
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [cpuManualCost, setCpuManualCost] = React.useState<string | undefined>(undefined);
  const [memoryManualCost, setMemoryManualCost] = React.useState<string | undefined>(undefined);

  const [discount, setDiscount] = React.useState<string | undefined>("0");

  const [enableCustomPricingFlag, setEnableCustomPricingFlag] = useState<boolean>(false);
  const [includeUnallocated, setIncludeUnallocated] = useState<boolean>(false);
  const [applyToAllClusters, setApplyToAllClusters] = useState<boolean>(false);
  const [applyToAllClustersDisabled, setApplyToAllClustersDisabled] = useState<boolean>(false);

  const [cloudProvider, setCloudProvider] = useState<string>("");

  const api = useGetApi();
  const { currentCluster } = useMainContext();

  const [originalCostConfig, setOriginalCostConfig] = useState<components["schemas"]["UtilsCostConfig"]>();

  const handleConfigFetch = (response: ApiResponse<components["schemas"]["UtilsGetConfigResponse"]>) => {
    if (response.ok && response.data.costConfig != undefined) {
      if (response.data.costConfig.discountPercentage != undefined)
        setDiscount(response.data.costConfig.discountPercentage.toString());
      if (response.data.costConfig.enableCustomPricing != undefined)
        setEnableCustomPricingFlag(response.data.costConfig.enableCustomPricing);
      if (response.data.costConfig.includeUnallocatedCost != undefined)
        setIncludeUnallocated(response.data.costConfig.includeUnallocatedCost);
      setApplyToAllClusters(!!response?.data?.costConfig?.applyToAllClusters);
      if (response.data.costConfig.customResourcesPricing != undefined) {
        if (response.data.costConfig.customResourcesPricing["manual"] != undefined) {
          if (response.data.costConfig.customResourcesPricing["manual"]["cpu"] != undefined) {
            setCpuManualCost(response.data.costConfig.customResourcesPricing["manual"]["cpu"].toString());
          }
          if (response.data.costConfig.customResourcesPricing["manual"]["memory"] != undefined) {
            setMemoryManualCost(response.data.costConfig.customResourcesPricing["manual"]["memory"].toString());
          }
        }
      }
    }

    if (response.ok && response.data != undefined && response.data.cloudProvider != undefined) {
      setCloudProvider(response.data.cloudProvider);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    let shouldGetChildConfig = true;
    api
      .getFetcher(true)
      .path("/api/v1/cost/getConfig")
      .method("get")
      .create()({})
      .then((parentResponse) => {
        if (parentResponse.ok && parentResponse?.data?.costConfig != undefined) {
          if (!parentResponse.data.isParent) setApplyToAllClustersDisabled(true);
          else if (parentResponse.data.costConfig.applyToAllClusters) {
            shouldGetChildConfig = false;
            handleConfigFetch(parentResponse);
            setOriginalCostConfig(parentResponse.data.costConfig);
          }
        }
        if (shouldGetChildConfig) {
          api
            .getFetcher()
            .path("/api/v1/cost/getConfig")
            .method("get")
            .create()({})
            .then((response) => {
              handleConfigFetch(response);
              setOriginalCostConfig(response.data.costConfig);
            })
            .catch((error) => console.error(error));
        }
      })
      .catch((error) => console.error(error));
  }, [currentCluster]);

  const handleCostSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      token: data.get("gitToken"),
    });
    const newAccessId = data.get("accessKey");
    const newAccessSecret = data.get("accessSecret");
    const newCustomCpuCost = data.get("cpuManualCost");
    const newCustomMemoryCost = data.get("memoryManualCost");
    const newCustomSpotCpuCost = data.get("cpuSpotManualCost");
    const newCustomSpotMemoryCost = data.get("memorySpotManualCost");
    const newEnableCustomPricing = enableCustomPricingFlag;
    const newIncludeUnallocated = includeUnallocated;
    const newDiscount = data.get("discount");

    const customResourcesPricingMap: { [key: string]: { [key: string]: number } } = {};
    let updateCustomPricing = false;
    if (newCustomCpuCost != null || newCustomMemoryCost != null) {
      updateCustomPricing = true;
      customResourcesPricingMap["manual"] = {};
      if (newCustomCpuCost != null) {
        customResourcesPricingMap["manual"]["cpu"] = +newCustomCpuCost;
      }
      if (newCustomMemoryCost != null) customResourcesPricingMap["manual"]["memory"] = +newCustomMemoryCost;
    }
    if (newCustomSpotCpuCost != null || newCustomSpotMemoryCost != null) {
      updateCustomPricing = true;
      customResourcesPricingMap["manual-spot"] = {};
      if (newCustomSpotCpuCost != null) customResourcesPricingMap["manual-spot"]["cpu"] = +newCustomSpotCpuCost;
      if (newCustomSpotMemoryCost != null)
        customResourcesPricingMap["manual-spot"]["memory"] = +newCustomSpotMemoryCost;
    }

    // eslint-disable-next-line no-debugger
    debugger;

    const doSubmit = () => {
      api
        .getFetcher()
        .path("/api/v1/cost/updateConfig")
        .method("put")
        .create()({
          multiCluster: applyToAllClusters,
          costConfig: {
            accessKeyID: toStringOrUndefined(newAccessId),
            secretAccessKey: toStringOrUndefined(newAccessSecret),
            enableCustomPricing: newEnableCustomPricing,
            includeUnallocatedCost: newIncludeUnallocated,
            discountPercentage: newDiscount == null ? undefined : +newDiscount,
            customResourcesPricing: updateCustomPricing ? customResourcesPricingMap : undefined,
            applyToAllClusters: applyToAllClusters,
          },
        })
        .then(() => {
          handleSave(true, "");
        })
        .catch((reason) => {
          handleSave(false, "Save Failed");
          console.error(reason);
        });
    };
    if (originalCostConfig?.applyToAllClusters && !applyToAllClusters) {
      api
        .getFetcher()
        .path("/api/v1/cost/updateConfig")
        .method("put")
        .create()({
          multiCluster: true,
          costConfig: {
            applyToAllClusters: false,
          },
        })
        .then(() => {
          doSubmit();
        })
        .catch((reason) => {
          handleSave(false, "Save Failed");
          console.error(reason);
        });
    } else doSubmit();
  };

  if (isLoading) {
    return (
      <div className="w-full h-[50vh] flex justify-center items-center">
        <CircularProgress />
      </div>
    );
  }

  return (
    <Box component="form" onSubmit={handleCostSubmit} className="flex flex-col gap-5">
      <div className={"border border-border rounded px-8 py-4"}>
        <div className="mb-5">
          <Typography variant="h6">
            <b>Current cluster</b>
          </Typography>
        </div>
        <Typography variant="body2" fontWeight={600}>
          {cloudProvider && cloudProvider.trim() !== ""
            ? `Auto detected cloud provider: ${cloudProvider}`
            : "Cloud provider wasn’t recognized"}
        </Typography>
        <div className="mt-8 flex flex-col gap-8">
          <div className="flex flex-col gap-3">
            <Typography variant="body2" fontWeight={600} className="flex items-center gap-1">
              Negotiation Discount
            </Typography>
            <TextField
              key={discount}
              label="custom negotiated"
              id="discount"
              name="discount"
              variant="standard"
              type={"number"}
              defaultValue={discount}
              InputProps={{
                startAdornment: <InputAdornment position="start">%</InputAdornment>,
              }}
              sx={{
                width: "150px",
              }}
              disabled={disabled}
            />
          </div>

          <div className="flex flex-col gap-3">
            <div className="flex gap-1 items-center">
              <Switch
                name="enableCustomPricingFlag"
                id="enableCustomPricingFlag"
                checked={!enableCustomPricingFlag}
                key={enableCustomPricingFlag ? "true" : "false"}
                onChange={(_, checked) => {
                  setEnableCustomPricingFlag(!checked);
                }}
                disabled={disabled}
              />
              <Typography variant="body2" className="flex items-center gap-1">
                Cloud based pricing - Automatically discover node pricing using cloud provider list pricing
              </Typography>
            </div>

            {enableCustomPricingFlag && (
              <>
                <Typography
                  variant="body2"
                  fontWeight={600}
                  className={clsx("flex items-center gap-1", {
                    "text-gray-400": !enableCustomPricingFlag,
                  })}
                >
                  On-premise pricing configuration
                </Typography>
                <div className="flex gap-8">
                  <TextField
                    key={cpuManualCost}
                    id="cpuManualCost"
                    name="cpuManualCost"
                    label="vCPU / hour"
                    variant="standard"
                    disabled={!enableCustomPricingFlag || disabled}
                    defaultValue={cpuManualCost}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                    // onBlur={(event) => updateManualCost("cpu", event.target.value)}
                  />
                  <TextField
                    key={memoryManualCost}
                    id="memoryManualCost"
                    name="memoryManualCost"
                    label="GiB / hour"
                    disabled={!enableCustomPricingFlag || disabled}
                    defaultValue={memoryManualCost}
                    variant="standard"
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                    // onBlur={(event) => updateManualCost("memory", event.target.value)}
                  />
                </div>
              </>
            )}
          </div>

          <div className="flex gap-1 items-center">
            <Switch
              name="includeUnallocated"
              id="includeUnallocated"
              checked={includeUnallocated}
              key={includeUnallocated ? "true" : "false"}
              onChange={(_, checked) => {
                setIncludeUnallocated(checked);
              }}
              disabled={disabled}
            />
            <Typography variant="body2" className="flex items-center gap-1">
              Include Unallocated Resources Expenses
              <Tooltip title={"Include nodes unallocated resources cost in overview page"}>
                <CustomInfoIcon width={CUSTOM_ICON_SIZE} height={CUSTOM_ICON_SIZE} />
              </Tooltip>
            </Typography>
          </div>
        </div>
      </div>
      <div className="flex flex-col border border-border rounded px-8 py-4 gap-5 ">
        <Typography variant="h6">
          <b>Multi cluster</b>
        </Typography>
        <div className="flex gap-3">
          <CustomCheckBox
            key={applyToAllClusters ? "true" : "false"}
            value={applyToAllClusters}
            disabled={disabled || applyToAllClustersDisabled}
            onClick={() => setApplyToAllClusters(!applyToAllClusters)}
          />
          <Typography variant="body2">Apply settings to all clusters</Typography>
        </div>
      </div>
      <div className="w-full py-1 flex justify-end">
        <SaveButton type="submit" label="Save" disabled={disabled} />
      </div>
    </Box>
  );
}

function MetricsTab() {
  type metricRow = {
    id: number;
    metricName: string;
    prometheusQuery: string;
    Labels: string[];
    deleteBottunPlaceHolder: boolean;
  };
  const { currentCluster } = useMainContext();
  const api: ScaleOpsClient = ScaleOps();

  const [metricsConfList, setMetricsConfList] = useState<components["schemas"]["V1alpha1MetricConf"][]>([]);
  const [metricRows, setMetricRows] = useState<GridRowsProp<metricRow>>([]);
  const [newMetricsConf, setNewMetricsConf] = useState<components["schemas"]["V1alpha1MetricConf"]>({});
  const [newMetric, setNewMetric] = useState<boolean>(false);
  const [disableNameEdit, setDisableNameEdit] = useState<boolean>(false);

  // const [promEndpoint, setPromEndpoint] = useState<string>("");

  function toMetricRows(metricConfList: components["schemas"]["V1alpha1MetricConf"][]) {
    const metricRowsArr: metricRow[] = [];
    let i = 0;
    for (const metric of metricConfList) {
      metricRowsArr.push({
        id: i,
        metricName: metric?.name || "",
        Labels: metric?.Labels || [],
        prometheusQuery: metric?.promQuery || "",
        deleteBottunPlaceHolder: true, // dummy
      });
      i += 1;
    }
    return metricRowsArr;
  }

  function fetchMetricsConf() {
    api
      .getFetcher()
      .path("/api/v1/metricsConf/")
      .method("get")
      .create()({})
      .then((response) => {
        if (response != undefined) {
          if (response.ok) {
            if (response?.data?.metricsConf != undefined) {
              const res = response?.data?.metricsConf || [];
              setMetricsConfList(res);
              setMetricRows(toMetricRows(res));
            }
          }
        }
      })
      .catch((error) => console.error(error));
  }

  useEffect(() => {
    fetchMetricsConf();
  }, [currentCluster]);

  function updateNewMetric() {
    if (newMetricsConf != undefined) {
      api
        .getFetcher()
        .path("/api/v1/metricsConf/update")
        .method("put")
        .create()({ metricConf: newMetricsConf })
        .then((response) => {
          if (response != undefined) {
            if (response.ok) {
              if (response?.data?.metricsConf != undefined) {
                const res = response?.data?.metricsConf || [];
                setMetricsConfList(res);
                setMetricRows(toMetricRows(res));
              }
            }
          }
        })
        .then(() => setNewMetricsConf({}))
        .catch((error) => console.error(error));
    }
  }

  const columns: GridColDef[] = [
    {
      field: "metricName",
      headerName: "Metric Name",
      flex: 1,
      type: "string",
      align: "left",
      maxWidth: 200,
      minWidth: 80,
    },
    {
      field: "prometheusQuery",
      headerName: "Prometheus Query",
      flex: 1,
      type: "string",
      align: "left",
    },
    {
      field: "Labels",
      headerName: "Selector Keys",
      flex: 1,
      type: "number",
      align: "center",
      maxWidth: 300,
    },
    {
      field: "deleteBottunPlaceHolder",
      headerName: "",
      flex: 0,
      align: "center",
      renderCell: (params: GridRenderCellParams<metricRow, metricRow>) => {
        return (
          <Box>
            <IconButton
              onClick={() => handleEditClick(params?.row.id >= 0 ? params.row.id : -1)}
              color="secondary"
              aria-label="edit"
              size={"medium"}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={() => handleDeleteMetric(params?.row.id >= 0 ? params.row.id : -1)}
              color="secondary"
              aria-label="delete"
              size={"medium"}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  const handleEditClick = (id: number) => {
    if (id != -1) {
      setDisableNameEdit(true);
      setNewMetric(true);
      const copy: components["schemas"]["V1alpha1MetricConf"] = { ...metricsConfList[id] };
      setNewMetricsConf(copy);
    }
  };

  const handleDeleteMetric = (id: number) => {
    if (id != -1) {
      api
        .getFetcher()
        .path("/api/v1/metricsConf/remove")
        .method("put")
        .create()({ metricName: metricsConfList[id].name || "" })
        .then((response) => {
          if (response != undefined) {
            if (response.ok) {
              if (response?.data?.metricsConf != undefined) {
                const res = response?.data?.metricsConf || [];
                setMetricsConfList(res);
                setMetricRows(toMetricRows(res));
              }
            }
          }
        })
        .then(() => setNewMetricsConf({}))
        .catch((error) => console.error(error));
    }
  };

  const labelsInfoMessage =
    "Metrics and Workloads are being adjusted together if they have the same label value for the supplied Selector Keys." +
    "The label value is taken from deployment selector or deployment labels if the first not exists";

  const exampleQuery = "Example: sum(rate(http_requests_total{<<SELECTOR>>}[1m]))\n";

  const newMetricConf = () => {
    const m: components["schemas"]["V1alpha1MetricConf"] = { name: "", promQuery: "", Labels: ["app"] };
    return m;
  };
  const addEmptyMetricLabel = () => {
    if (newMetricsConf.Labels == undefined) newMetricsConf.Labels = [];
    newMetricsConf.Labels.push("");
    setNewMetricsConf({ ...newMetricsConf });
    setNewMetric(newMetric);
  };

  const deleteMetricLabel = (index: number) => {
    if (newMetricsConf.Labels != undefined && newMetricsConf.Labels.length > index)
      newMetricsConf.Labels.splice(index, 1);
    setNewMetricsConf({ ...newMetricsConf });
    setNewMetric(newMetric);
  };

  return (
    <React.Fragment>
      <DialogContentText marginBottom={2} fontSize={17}>
        Prometheus Metrics Configurations
      </DialogContentText>
      <DataGrid
        sx={{
          ...getDataGridSx(),
        }}
        hideFooter={true}
        columns={columns}
        rows={metricRows}
        // onRowClick={handleRowClick}
        // onCellClick={handleCellClick}
        style={{ height: "100%" }}
        hideFooterPagination={true}
        autoHeight={true}
        initialState={{
          sorting: { sortModel: [{ sort: "desc", field: "monthlySavingsAvailable" }] },
        }}
      />
      <Box style={{ float: "right", paddingTop: "5px" }}>
        <Button
          variant="contained"
          onClick={() => {
            setDisableNameEdit(false);
            setNewMetricsConf(newMetricConf());
            setNewMetric(true);
          }}
          style={{ float: "right" }}
        >
          Add Metric
        </Button>
      </Box>
      <Dialog
        maxWidth={"md"}
        open={newMetric}
        onClose={() => {
          setNewMetric(false);
          setNewMetricsConf({});
        }}
        fullWidth={true}
        aria-labelledby="customized-dialog-title"
      >
        <DialogTitle sx={{ backgroundColor: theme.palette.primary.main, color: "white", height: "55px" }}>
          Metric Configuration
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <TextField
              margin="dense"
              label="Metric Name"
              variant="standard"
              disabled={disableNameEdit}
              defaultValue={newMetricsConf?.name || ""}
              onBlur={(event) => {
                if (newMetricsConf) {
                  newMetricsConf.name = event.target.value;
                  setNewMetricsConf(newMetricsConf);
                }
              }}
            />
          </DialogContentText>
          <DialogContentText>
            <TextField
              margin="dense"
              label={"Prometheus Query Template"}
              helperText={exampleQuery}
              variant="standard"
              defaultValue={newMetricsConf?.promQuery || ""}
              onBlur={(event) => {
                if (newMetricsConf) {
                  newMetricsConf.promQuery = event.target.value;
                  setNewMetricsConf(newMetricsConf);
                }
              }}
              style={{ width: "90%" }}
            />
            {labelsInfoMessage != undefined && (
              <Tooltip title={labelsInfoMessage}>
                <InfoOutlinedIcon style={{ lineBreak: "loose", fontSize: "medium", paddingLeft: "2px" }} />
              </Tooltip>
            )}

            <List dense={true}>
              <div style={{ paddingTop: "5px" }}>{"Selector Keys"}</div>
              {newMetricsConf.Labels != undefined &&
                newMetricsConf.Labels.map((labelKey: string, index: number) => (
                  <MenuItem sx={{ padding: "1px", width: "160px" }} key={index.toString() + labelKey}>
                    <TextField
                      margin="dense"
                      // label={<div>{"Selector Keys"}</div>}
                      variant="standard"
                      defaultValue={labelKey || ""}
                      onBlur={(event) => {
                        if (newMetricsConf.Labels == undefined) {
                          newMetricsConf.Labels = [];
                        }
                        newMetricsConf.Labels[index] = event.target.value.replace(" ", "");
                        setNewMetricsConf(newMetricsConf);
                      }}
                    />
                    <IconButton
                      color="primary"
                      aria-label="delete_metric_label"
                      size={"small"}
                      sx={{ margin: "0px", paddingTop: "8px" }}
                      onClick={() => {
                        deleteMetricLabel(index);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </MenuItem>
                ))}
              <IconButton
                color="primary"
                aria-label="add_metric_label"
                size={"small"}
                onClick={() => {
                  addEmptyMetricLabel();
                }}
              >
                <AddIcon />
              </IconButton>
            </List>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setNewMetric(false);
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              updateNewMetric();
              setNewMetric(false);
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

const toStringOrUndefined = (input: FormDataEntryValue | null): string | undefined => {
  if (input == null) {
    return undefined;
  }
  return input.toString();
};

export default function Settings() {
  const isReadyOnlyFrontEnd = useIsReadyOnlyFrontEnd();
  const { userRole, isError } = useGetUserRole();
  const isMetricsSettingsTabEnabled = FeatureEnabled("metricsSettingsTab");
  const [selectedTab, setSelectedTab] = React.useState<TabOptions>(TabOptions.COST);

  const [snackbar, setSnackbar] = useState<Pick<AlertProps, "children" | "severity"> | null>(null);
  const confData = GetConfig();
  const handleCloseSnackbar = () => setSnackbar(null);
  const savedPopupSuccess = useCallback(() => {
    setSnackbar({ children: "Saved", severity: "success" });
  }, []);
  const savedPopupFailed = useCallback((message: string) => {
    setSnackbar({ children: message, severity: "error" });
  }, []);

  const handleSave = (success: boolean, message = "") => {
    if (success) savedPopupSuccess();
    if (!success) savedPopupFailed(message);

    setTimeout(() => {
      handleCloseSnackbar();
    }, 15000);
  };

  if (!userRole)
    return (
      <Paper variant="outlined" className="w-full h-[70vh] bg-white flex justify-center items-center">
        <CircularProgress />
      </Paper>
    );

  if (isError)
    return (
      <Paper variant="outlined" className="w-full h-[70vh] bg-white flex justify-center items-center gap-2">
        <CloseIcon />
        <p>Error fetching permissions</p>
      </Paper>
    );

  return (
    <div className={clsx("relative mt-[36px] rounded-tl-none p-[50px]", PAGE_CONTENT_CLASS_NAME)}>
      <div className={clsx(TABS_CONTAINER_CLASS_NAME, "top-[-36px] left-0")}>
        {Object.entries(TabOptions).map(([key, value]) => {
          if (value === TabOptions.METRICS && !isMetricsSettingsTabEnabled) return null;
          if (value === TabOptions.INGRESS && !HAS_INGRESS_TAB) return null;
          if (value === TabOptions.Auth && confData.inCloudMode) return null;
          const isPermissionsTabDisabled = value === TabOptions.Auth && userRole !== ADMIN;
          const isIngressDisabled = value === TabOptions.INGRESS && userRole !== ADMIN;
          const isTabDisabled = isPermissionsTabDisabled || isIngressDisabled;
          return (
            <Tab
              key={key}
              tooltipContent={
                isTabDisabled ? (
                  <>
                    You <b>don't have permission</b> to access this tab.
                    <br />
                    Only <b>Admins</b> can access this tab.
                  </>
                ) : undefined
              }
              isSelected={selectedTab === value}
              onClick={() => {
                setSelectedTab(value);
              }}
              name={value}
              dataTestId={`settings-tab-${key}-tab`}
              disabled={isTabDisabled}
            />
          );
        })}
      </div>
      {TabOptions.Auth === selectedTab && <PermissionsContainer />}
      {TabOptions.COST === selectedTab && (
        <CostTab handleSave={handleSave} disabled={userRole !== ADMIN || isReadyOnlyFrontEnd} />
      )}
      {TabOptions.METRICS === selectedTab && <MetricsTab />}
      {TabOptions.GENERAL === selectedTab && (
        <GeneralSettingsTab disabled={userRole !== ADMIN || isReadyOnlyFrontEnd} />
      )}
      {TabOptions.INGRESS === selectedTab && <IngressContainer />}
      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          onClose={handleCloseSnackbar}
          autoHideDuration={6000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
    </div>
  );
}
