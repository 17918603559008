import { useEffect, useState } from "react";
import { components } from "../../../api/schema";
import SwitchBox, { THEMES } from "../../../components/SwitchBox";
import Tooltip from "../../../components/Tooltip";
import AutomationButtonDisabledTooltip from "../../../components/WorkloadStatusByNamespaceSection/Columns/AutomationButtonDisabledTooltip";
import useIsAutomationButtonDisabled from "../../../components/WorkloadStatusByNamespaceSection/Columns/useIsAutomationButtonDisabled";
import { ScaleOpsProduct } from "../../../utils/typesUtils";
import useHasMultiProductLayoutWithReactQuery from "../../../utils/useHasMultiProductLayoutWithReactQuery";
import useUpdateAutomation from "./mutation/useUpdateAutomation";

interface Props {
  isAutomate: boolean;
  setIsAutomate: (isAutomate: boolean) => void;
  fetchWorkloads: () => void;
  selectedWorkload: components["schemas"]["UtilsWorkload"];
  scaleOpsProduct?: ScaleOpsProduct;
  autoFieldToUpdate: "auto" | "hpaAuto" | "rightSizeAuto";
}

const AutomationSwitch = ({
  isAutomate,
  setIsAutomate,
  fetchWorkloads,
  selectedWorkload,
  scaleOpsProduct,
  autoFieldToUpdate,
}: Props) => {
  const hasMultiProduct = useHasMultiProductLayoutWithReactQuery();
  const isAutomationButtonDisabled = useIsAutomationButtonDisabled({ params: selectedWorkload });
  const updateAutomation = useUpdateAutomation(fetchWorkloads);

  const [title, setTitle] = useState<string | undefined>("");

  useEffect(() => {
    switch (true) {
      case !hasMultiProduct:
        setTitle("Automate");
        break;
      case scaleOpsProduct === ScaleOpsProduct.HPA && hasMultiProduct:
        setTitle("Automate HPA");
        break;
      case scaleOpsProduct !== ScaleOpsProduct.HPA && hasMultiProduct:
        setTitle("Automate rightsize");
        break;
    }
  }, [hasMultiProduct, scaleOpsProduct]);

  const handleClick = () => {
    updateAutomation.mutate({
      id: selectedWorkload.id,
      namespace: selectedWorkload.namespace,
      workloadName: selectedWorkload.workloadName,
      workloadType: selectedWorkload.type,
      state: !isAutomate,
      scaleOpsProduct,
      autoFieldToUpdate,
    });
    setIsAutomate(!isAutomate);
  };

  const isAutomationExcluded = selectedWorkload.isAutomationExcluded;

  if (!title) return null;

  return (
    <Tooltip
      title={<AutomationButtonDisabledTooltip params={selectedWorkload} />}
      maxWidth={500}
      disabled={!isAutomationButtonDisabled}
    >
      <div className="relative">
        <SwitchBox
          title={title}
          handleClick={handleClick}
          checked={isAutomate}
          disabled={isAutomationButtonDisabled}
          notAllowed={isAutomationExcluded}
          theme={THEMES.Purple}
        />
        {isAutomate && !isAutomationButtonDisabled && (
          <div className="w-2 h-2 mx-1 absolute top-[13px] right-[21px]">
            <div className="animate-ping absolute inline-flex w-4 h-4 rounded-full bg-guideline-darkGreen opacity-50 left-0 top-0" />
            <div className="absolute inline-flex w-4 h-4 rounded-full bg-guideline-darkGreen opacity-75 left-0 top-0" />
          </div>
        )}
      </div>
    </Tooltip>
  );
};

export default AutomationSwitch;
