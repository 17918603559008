import { StringParam, useQueryParam, withDefault } from "use-query-params";
import Users from "../../components/users";
import { GetConfig } from "../../utils/ConfigHelper";
import { FORCE_NO_CLUSTER_MODE } from "../../utils/developmentFeatureFlags";
import DiscoverBanner from "./DiscoverBanner";
import GuideSection from "./GuideSection";
import TabsContainer from "./TabsContainer";
import Terms from "./Terms";
import TopPurpleBanner from "./TopPurpleBanner";
import { TabType } from "./utils";

const HAS_TERMS_SECTION = false;

const DarkBackground = () => <div className="bg-black opacity-80 fixed top-0 right-0 w-[100vw] h-[100vh] z-[1000]" />;

interface Props {
  currentCluster: string | undefined;
  inCloudMode: boolean;
}
const InstallContainer = ({ currentCluster, inCloudMode }: Props) => {
  const [selectedTab, setSelectedTab] = useQueryParam("kind", withDefault(StringParam, TabType.INSTALL)) as [
    TabType,
    (newValue: TabType) => void
  ];

  const confData = GetConfig();
  const isInCloudAdmin = confData.userGroups?.includes("Admin") || confData.userGroups?.includes("ScaleOpsInternal");
  const customerPrefix = confData.customerName
    ? confData.customerName.toLowerCase().replaceAll(" ", "-")
    : "<CUSTOMER_NAME>";

  if (!FORCE_NO_CLUSTER_MODE && (currentCluster || !inCloudMode)) return null;
  return (
    <>
      <DarkBackground />
      <div className="fixed flex justify-center bg-green top-0 right-1/2 transform translate-x-1/2 w-[65rem] px-16 py-2 z-[1000] first-letter:uppercase bg-white overflow-y-auto h-[100vh]">
        <div className="w-[98%] max-w-full flex flex-col gap-[1rem] py-[1.25rem] relative">
          <TabsContainer selectedTab={selectedTab} setSelectedTab={setSelectedTab} isInCloudAdmin={isInCloudAdmin} />
          {selectedTab === TabType.INVITE && <Users />}
          {selectedTab === TabType.INSTALL && (
            <>
              <TopPurpleBanner companyName={confData.customerName} />
              <DiscoverBanner />
              <GuideSection
                token={confData.token || "<SCALEOPS_TOKEN>"}
                customerPrefix={customerPrefix}
                useScaleOpsRegistry={confData.token?.startsWith("soc_") || false}
                inCloudUrl={confData.inCloudUrl}
              />
            </>
          )}
          {HAS_TERMS_SECTION && <Terms />}
        </div>
      </div>
    </>
  );
};

export default InstallContainer;
