import { GetTopKQueryKey } from "../../../api/fetcher";
import TopKMultiLineChart, { QUANTILE, SUM } from "../../Analytics/AnalyticsV2/Graphs/TopKMultiLineChart";
import { NodeStatus, NodeStatusKeys } from "../../Analytics/AnalyticsV2/Graphs/topKUtils";
import useGetGraphState from "../useGetGraphsState";
import { CommonGraphProps, GraphDisplayName, NODE_SYNC_ID } from "./utils";

const NodeNotScalingDownGraph = ({
  isMulticluster,
  tooltipTrigger,
  disabledZoom,
  updateActiveTooltips,
}: CommonGraphProps) => {
  const { date, setDate, numberOfElements, frozenAndClickableTab } = useGetGraphState();

  return (
    <TopKMultiLineChart
      title={GraphDisplayName.NodeNotScalingDownGraph}
      queryParams={{
        from: date.range ? undefined : date.from,
        to: date.range ? undefined : date.to,
        range: date.range,
        queryKey: GetTopKQueryKey.NodeNotScalingDown,
      }}
      setDate={setDate}
      isMulticluster={isMulticluster}
      topK={numberOfElements}
      tooltipTrigger={tooltipTrigger}
      renderNameFunction={(name: string | undefined) => {
        switch (true) {
          case name === SUM:
            return "total";
          case name?.includes(QUANTILE):
            return `${String(name?.replace(QUANTILE, ""))}90th-percentile`;
          case NodeStatusKeys.includes(name as keyof typeof NodeStatus):
            return NodeStatus[name as keyof typeof NodeStatus];
          default:
            return name;
        }
      }}
      updateActiveTooltips={updateActiveTooltips}
      enableCopyTextOnClick
      frozenTooltipType={frozenAndClickableTab}
      syncId={NODE_SYNC_ID}
      disabledZoom={disabledZoom}
      hasLegend
      hasStackedOffset
    />
  );
};

export default NodeNotScalingDownGraph;
