import { shouldDisableDefaultPolicyInputs } from "../utils";
import FormInput from "../../../components/formComponents/FormInput";
import FormTitleAndDescription from "../../../components/formComponents/FormTitleAndDescription";
import { IsSnapshotServer } from "../../../utils/FeaturesHelper";

interface Props {
  isCustomizedPolicy?: boolean;
}

const MinimumResourceBoundaries = ({ isCustomizedPolicy }: Props) => {
  return (
    <div className="flex flex-col gap-4 pb-2">
      <FormTitleAndDescription
        title="Minimum resource requests boundaries"
        description="Determine the minimum CPU and memory requests."
      />
      <div className="flex flex-wrap gap-4">
        <FormInput
          label="Min CPU"
          name="cpuMinAllowed"
          type="number"
          disabled={!isCustomizedPolicy && shouldDisableDefaultPolicyInputs}
          disableChange={!IsSnapshotServer() && !isCustomizedPolicy}
        />
        <FormInput
          label="Min memory (GiB)"
          name="memoryMinAllowed"
          type="number"
          disabled={!isCustomizedPolicy && shouldDisableDefaultPolicyInputs}
          disableChange={!IsSnapshotServer() && !isCustomizedPolicy}
        />
      </div>
    </div>
  );
};

export default MinimumResourceBoundaries;
