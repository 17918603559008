import { MenuItem, Typography } from "@mui/material";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { GRID_LAYOUT_CACHE_KEY_PREFIX } from "../../api/fetcher";
import useGetLayout from "../../components/GridLayout/useGetLayout";
import SingleSelect, { SingleSelectSize } from "../../components/SingleSelect";
import Tooltip from "../../components/Tooltip";
import AddIcon from "../../Icons/AddIcon";
import DeleteIcon from "../../Icons/DeleteIcon";
import DuplicateIcon from "../../Icons/DuplicateIcon";
import { READ_ONLY_LAYOUTS, TROUBLESHOOT_DASHBOARD_CATEGORY } from "../../pages/Troubleshoot/utils";
import BuiltInTag from "../BuiltInTag";
import { LayoutStorageType } from "../GridLayout/utils";

const CustomBuiltInTag = () => (
  <BuiltInTag wrapperClassName="text-white bg-primary-purpleBlue" iconClassName="text-white" />
);

const CREATE_NEW_DASHBOARD_ID = "create-new-dashboard-id";
const DELETE_ICON_SIZE = 16;
const ADD_ICON_SIZE = 12;

interface Props {
  setIsDuplicateDialogueOpen: (isDuplicateDialogueOpen: boolean) => void;
  setIsCreateDialogueOpen: (isDuplicateDialogueOpen: boolean) => void;
  existingDashboardNames: string[];
  deleteDashboard: (dashboardName: string) => void;
  defaultDashboards: string[][];
  defaultDashboard: string;
  dashBoardCategory: string;
}

const SelectDashboard = ({
  setIsDuplicateDialogueOpen,
  setIsCreateDialogueOpen,
  existingDashboardNames,
  deleteDashboard,
  defaultDashboards,
  defaultDashboard,
  dashBoardCategory,
}: Props) => {
  const [dashboards, setDashboards] = useState<string[][]>([]);
  const { selectedDashboard, setSelectedDashboard } = useGetLayout({
    defaultDashboard: defaultDashboard ?? dashboards[0][1],
    layoutStorage: LayoutStorageType.Server,
    dashboardCategory: TROUBLESHOOT_DASHBOARD_CATEGORY,
    readOnlyLayouts: READ_ONLY_LAYOUTS,
  });

  useEffect(() => {
    const dashboardsValue = [
      ...defaultDashboards,
      ...existingDashboardNames.map((dashboard) => [dashboard, dashboard]).sort((a, b) => a[0].localeCompare(b[0])),
    ];

    setDashboards(dashboardsValue);
  }, [defaultDashboards, existingDashboardNames]);

  return (
    <SingleSelect<string>
      label="Dashboards"
      size={SingleSelectSize.Regular}
      className="w-[315px]"
      selected={selectedDashboard}
      setSelected={(option) => {
        setSelectedDashboard(option);
      }}
      renderValue={(dashboard) => {
        const isCustomDashboard = !defaultDashboards.map((e) => e[1]).includes(dashboard);

        return (
          <div className="flex items-center gap-2">
            <span
              className={clsx("truncate", {
                // "text-guideline-lessDarkBlue font-semibold	": !isCustomDashboard,
              })}
            >
              {dashboard.replaceAll(`${GRID_LAYOUT_CACHE_KEY_PREFIX}-${dashBoardCategory}-`, "")}
            </span>
            {!isCustomDashboard && <CustomBuiltInTag />}
          </div>
        );
      }}
      renderOptionsFunc={() =>
        [[CREATE_NEW_DASHBOARD_ID, CREATE_NEW_DASHBOARD_ID], ...dashboards].map((dashboard) => {
          if (dashboard[0] === CREATE_NEW_DASHBOARD_ID) {
            return (
              <div className="mb-[0.8125rem]">
                <Typography
                  variant="caption"
                  className="px-4 flex items-center gap-2 text-main-linkBlue hover:cursor-pointer hover:underline truncate"
                  onClick={() => {
                    setIsCreateDialogueOpen(true);
                  }}
                >
                  Create new dashboard <AddIcon width={ADD_ICON_SIZE} height={ADD_ICON_SIZE} />
                </Typography>
              </div>
            );
          }

          const isCustomDashboard = !defaultDashboards.includes(dashboard);
          const displayValue = isCustomDashboard
            ? dashboard[1].replaceAll(`${GRID_LAYOUT_CACHE_KEY_PREFIX}-${dashBoardCategory}-`, "")
            : dashboard[1];

          return (
            <MenuItem value={dashboard[1]} key={dashboard[1]} className="w-full">
              <div className="min-w-[25rem] flex items-center gap-2 w-full">
                <p className="text-[14px] grow">{displayValue}</p>
                {!isCustomDashboard && <CustomBuiltInTag />}
                {isCustomDashboard && (
                  <Tooltip title="Delete dashboard">
                    <DeleteIcon
                      width={DELETE_ICON_SIZE}
                      height={DELETE_ICON_SIZE}
                      className="cursor-pointer"
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        deleteDashboard(dashboard[1]);
                      }}
                    />
                  </Tooltip>
                )}
                <Tooltip title="Duplicate dashboard">
                  <DuplicateIcon
                    className="cursor-pointer"
                    onClick={(e) => {
                      e.preventDefault();
                      setIsDuplicateDialogueOpen(true);
                    }}
                  />
                </Tooltip>
              </div>
            </MenuItem>
          );
        })
      }
    />
  );
};

export default SelectDashboard;
