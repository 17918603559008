import { Typography } from "@mui/material";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import Button, { BUTTON_VARIANTS } from "./Button";
import FormInput from "./formComponents/FormInput";
import useGetConfLogin from "../MainMenu/useGetConfLogin";
import Loading from "./Loading";
import BackgroundPattern from "../BackgroundPattern";

interface ResponseError {
  [key: string]: string;
}

const fetchCong = () => {
  fetch("/conf").then((res) => {
    if (res.ok) {
      window.location.href = "/";
    }
  });
};

const LoginPage = () => {
  const { loginInfo, versionDataFetchedSuccessfully } = useGetConfLogin();
  const [showLoading, setShowLoading] = useState(false);
  const [showInvalidMessage, setShowInvalidMessage] = useState(false);
  const [showServerResponse, setServerResponse] = useState<string | undefined>(undefined);
  const [showForgetPasswordInstructions, setShowForgetPasswordInstructions] = useState(false);

  useEffect(() => {
    fetchCong();
  }, []);

  useEffect(() => {
    if (
      loginInfo?.name !== "token" &&
      loginInfo?.name !== "simpleAuth" &&
      loginInfo?.name?.toLowerCase() !== "ldap" &&
      versionDataFetchedSuccessfully
    ) {
      // If the user is not using explicitly token or basic auth, redirect to the main page
      window.location.href = "/";
    }
  }, [loginInfo]);

  let passwordText = "Password";
  const isTokenSSO = loginInfo?.name === "token";
  if (isTokenSSO) {
    passwordText = "Token";
  }
  const tokenSSOTooltip = (
    <>
      You can generate token for service account with: <br />
      <code>kubectl -n NAMESPACE create token SERVICE_ACCOUNT</code>
    </>
  );

  return (
    <div className="bg-[#5a588a] w-full h-[100vh] relative flex justify-center items-center">
      <BackgroundPattern />
      <div className="bg-[rgba(255,255,255,0.9)] w-fit h-fit p-16 border-2 rounded-lg border-white mt-[-10%]">
        <Formik
          initialValues={{ userName: "", password: "" }}
          validationSchema={Yup.object({
            userName: isTokenSSO ? Yup.string().optional() : Yup.string().required("Required"),
            password: Yup.string().required("Required"),
          })}
          onSubmit={(values) => {
            setShowInvalidMessage(false);
            setShowLoading(true);
            fetch("/auth/callback", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(values),
            }).then((res) => {
              if (res.status === 200) {
                const url = new URL(res.url);
                const storedToken = url.searchParams.get("token");
                if (storedToken) {
                  localStorage.setItem("__scaleops_token", storedToken);
                  window.location.href = "/";
                }
              } else {
                fetchCong();
                try {
                  res.json().then((data: ResponseError) => {
                    setServerResponse(data["error"]);
                  });
                } catch (e) {
                  setServerResponse("");
                }
                setShowInvalidMessage(true);
                setShowLoading(false);
              }
            });
          }}
        >
          {(formik) => {
            return (
              <Form>
                <div className="flex flex-col gap-8 relative z-9">
                  <div>
                    <Typography
                      variant="h5"
                      fontWeight={600}
                      className="text-center flex justify-center items-center gap-2"
                    >
                      Welcome to ScaleOps
                    </Typography>
                  </div>
                  <div style={{ display: isTokenSSO ? "none" : "block" }}>
                    <FormInput
                      label="Username"
                      name="userName"
                      placeholder="Enter username"
                      type="text"
                      required={!isTokenSSO}
                      inputWidth={300}
                    />
                  </div>
                  <FormInput
                    label={passwordText}
                    name="password"
                    placeholder="Enter password"
                    type="password"
                    required
                    info={isTokenSSO ? tokenSSOTooltip : undefined}
                    inputWidth={isTokenSSO ? 400 : 300}
                    showHidePasswordToggle
                  />
                  <Button
                    type="submit"
                    label="Login"
                    disabled={!(formik.isValid && formik.dirty) || showLoading}
                    variant={BUTTON_VARIANTS.mediumPurple}
                  />
                  {isTokenSSO && (
                    <>
                      <Typography style={{ fontSize: "8pt" }}>
                        To find out more on how to configure and use bearer tokens, <br />
                        please refer to the{" "}
                        <a
                          href={"https://kubernetes.io/docs/reference/access-authn-authz/authentication/"}
                          target="_blank"
                          className="text-main-linkBlue hover:underline"
                          style={{
                            fontWeight: 600,
                          }}
                        >
                          documentation
                        </a>
                        .
                      </Typography>
                    </>
                  )}
                  {showInvalidMessage && (
                    <Typography variant="caption" className="text-red-500 text-center">
                      {isTokenSSO && showServerResponse !== undefined && showServerResponse !== "" && (
                        <>{showServerResponse}</>
                      )}
                      {(!isTokenSSO || showServerResponse === undefined || showServerResponse === "") && (
                        <>Invalid username or password. Please try again.</>
                      )}
                    </Typography>
                  )}
                </div>
              </Form>
            );
          }}
        </Formik>
        {!isTokenSSO && (
          <div>
            <div className="flex flex-col gap-2 max-w-[298px] mt-2">
              <button
                className="text-[12px] text-main-linkBlue hover:text-guideline-darkBlue cursor-pointer"
                onClick={() => setShowForgetPasswordInstructions(!showForgetPasswordInstructions)}
              >
                Forgot your password?
              </button>
              {showForgetPasswordInstructions && (
                <>
                  <Typography variant="caption" className="text-text-lightBlack">
                    Please contact your company's <b>administrator</b> or reach out to <b>ScaleOps support</b> for
                    restoring your password.
                  </Typography>
                </>
              )}
            </div>
          </div>
        )}
        {showLoading && (
          <>
            <br />
            <Loading />
          </>
        )}
      </div>
    </div>
  );
};

export default LoginPage;
