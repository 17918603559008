import { useQuery } from "@tanstack/react-query";
import { NavLink } from "react-router-dom";
import { GetAvailableSavings, GetAvailableSavingsResponse } from "../../api/fetcher";
import BetaIcon from "../../Icons/BetaIcon";
import { getPercentageValue2 } from "../../utils/formatterUtils";
import AlertBar, { AlertBarTheme } from "../AlertBar";
import { Typography } from "@mui/material";
import Button from "../Button";
import React, { useEffect, useState } from "react";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import clsx from "clsx";

const HIGH_SAVING_THRESHOLD = 5;

type AlertData = {
  title?: string;
  name: string;
  amount?: number;
  link?: string;
  rndNeeded?: boolean;
  isBeta?: boolean;
  potentialValueType?: PotentialValueType;
};

enum PotentialValueType {
  CPU = "CPU",
  Cost = "Cost",
}

interface Props {
  setLowValueAlertsCount: (count: number) => void;
  setHighValueAlertsCount: (count: number) => void;
  setIsLowValueAlertsCollapsed?: (isCollapsed: boolean) => void;
}

const AvailableSavingsAlerts = ({
  setLowValueAlertsCount,
  setHighValueAlertsCount,
  setIsLowValueAlertsCollapsed,
}: Props) => {
  const [alerts, setAlerts] = useState<AlertData[]>([]);
  const [visibleAlerts, setVisibleAlerts] = useState<AlertData[]>([]);
  const [hiddenAlerts, setHiddenAlerts] = useState<Set<string>>(new Set());
  const [highValueAlerts, setHighValueAlerts] = useState<AlertData[]>([]);
  const [lowValueAlerts, setLowValueAlerts] = useState<AlertData[]>([]);
  const [lowValueAlertsSum, setLowValueAlertsSum] = useState<number>(0);
  const [showLowValueAlerts, setShowLowValueAlerts] = useState(false);

  const { queryFn, queryKey } = GetAvailableSavings();
  const { data } = useQuery<GetAvailableSavingsResponse, Error>({
    queryKey: [queryKey],
    queryFn,
    refetchInterval: 1000 * 60,
  });

  useEffect(() => {
    setAlerts([
      {
        name: "Complete cluster automation",
        amount: data?.unautomatedWorkloads?.potentialInCpu,
        link: "/?isUnAutomated=1",
      },
      {
        name: "Fix automated unoptimized workloads",
        amount: data?.unoptimizedWorkloads?.potentialInCpu,
        link: "/?isAutomated=1&savingsWithoutMinDiff=1",
      },
      {
        name: "Optimize unevictable workloads",
        amount: data?.unevictableWorkloads?.potentialInCpu,
        link: "/?unevictableTypes=notHealthy&unevictableTypes=unevictable&selectRowsOnInitialLoading=unautomated",
      },
      {
        name: "Use Recommended Policies",
        amount: data?.notUsingSmartPolicy?.potentialInCpu,
        link: "/?notUsingSmartPolicy=1",
      },
      {
        title: "Unrecognized CPU Request",
        name: "Optimize unrecognized workloads",
        amount: data?.unrecognizedWorkloads?.potentialInCpu,
        rndNeeded: true,
      },
      {
        name: "Reduce init containers pain",
        amount: data?.initContainers?.potentialInCpu,
        rndNeeded: true,
      },
      {
        title: "Available savings vs Reliability",
        name: "Decrease HPA CPU factor to 0.4",
        amount: data?.hpaCapping?.potentialInCpu,
        rndNeeded: true,
      },
      {
        name: "Clear wasted resources",
        amount: data?.wastedResources?.potentialInCpu,
        rndNeeded: true,
        link: "/?wastedResources=WorkloadErrorStuckTerminating&wastedResources=WorkloadErrorImagePullBackOff&wastedResources=WorkloadErrorPodInitializing",
      },
      {
        name: "Enable aggressive consolidation",
        amount: data?.consolidation?.potentialInCpu,
        link: "/nodes?isConsolidationDialogueOpen=1",
      },
      {
        name: "Change node groups' min size",
        amount: data?.nodeGroupsMinSize?.potentialInCpu,
        link: "/nodes?scaleDownReasons=NodeGroupMinSizeReached",
      },
      {
        name: "Enable HPA optimization",
        amount: data?.hpaOptimization?.potentialInCpu,
        link: "/?hpaOptimizationType=maxValueMinReplicas&hpaOptimizationType=predictable",
        rndNeeded: true,
        isBeta: true,
      },
      {
        title: "Available savings vs Reliability",
        amount: data?.highReplicaCost?.potentialInCpu,
        name: "Reduce recommendations for workloads with many replicas",
        link: "/?sortField=runningReplicas&sortDirection=desc",
        rndNeeded: true,
        potentialValueType: PotentialValueType.Cost,
      },
    ]);
  }, [data]);

  useEffect(() => {
    setVisibleAlerts(
      alerts
        .filter((alert) => alert.amount && !hiddenAlerts.has(alert.name))
        .sort((a, b) => (b.amount ?? 0) - (a.amount ?? 0))
    );
  }, [alerts, hiddenAlerts]);

  useEffect(() => {
    setHighValueAlerts(visibleAlerts.filter((alert) => alert.amount && alert.amount >= HIGH_SAVING_THRESHOLD));
    setLowValueAlerts(visibleAlerts.filter((alert) => alert.amount && alert.amount < HIGH_SAVING_THRESHOLD));
  }, [visibleAlerts]);

  useEffect(() => {
    setHighValueAlertsCount(highValueAlerts.length);
  }, [highValueAlerts]);

  useEffect(() => {
    setLowValueAlertsCount(lowValueAlerts.length);
    setLowValueAlertsSum(lowValueAlerts.reduce((acc, alert) => acc + (alert.amount ?? 0), 0));
  }, [lowValueAlerts]);

  useEffect(() => {
    setIsLowValueAlertsCollapsed && setIsLowValueAlertsCollapsed(!showLowValueAlerts);
  }, [showLowValueAlerts]);

  return (
    <>
      {highValueAlerts.map((alert) => (
        <CustomAlert
          key={alert.name}
          title={alert.title}
          name={alert.name}
          amount={alert.amount}
          link={alert.link}
          rndNeeded={alert.rndNeeded}
          isBeta={alert.isBeta}
          potentialValueType={alert.potentialValueType}
          handleAlertClose={() => setHiddenAlerts(new Set(hiddenAlerts).add(alert.name))}
        />
      ))}
      {showLowValueAlerts && (
        <div
          className={clsx(
            "flex flex-col gap-2 pt-2 -mt-2 w-full transition-all overflow-hidden duration-[200ms] ease-out z-[1]",
            { "max-h-0": !showLowValueAlerts, "max-h-[3000px]": showLowValueAlerts }
          )}
        >
          {lowValueAlerts.map((alert) => (
            <CustomAlert
              key={alert.name}
              title={alert.title}
              name={alert.name}
              amount={alert.amount}
              link={alert.link}
              rndNeeded={alert.rndNeeded}
              isBeta={alert.isBeta}
              potentialValueType={alert.potentialValueType}
              handleAlertClose={() => setHiddenAlerts(new Set(hiddenAlerts).add(alert.name))}
            />
          ))}
        </div>
      )}
      {!!lowValueAlerts.length && (
        <div
          className="flex w-full cursor-pointer shadow-2xl ml-auto border border-primary-purpleBlue h-7 pl-2 pr-1 items-center rounded-2xl text-sm bg-white z-[1]"
          onClick={() => setShowLowValueAlerts(!showLowValueAlerts)}
        >
          <div className="whitespace-nowrap font-medium text-center grow">
            {" "}
            {showLowValueAlerts ? (
              "Hide alerts under 5% value"
            ) : (
              <>
                Explore {highValueAlerts.length ? "extra" : ""} potential value of{" "}
                <span
                  className="font-medium text-guideline-darkerGreen"
                  style={{ textShadow: "0.2px 0.2px 0.4px black" }}
                >
                  {getPercentageValue2(Number(lowValueAlertsSum))}
                </span>
              </>
            )}
          </div>
          <div className="flex ml-auto mr-1">
            {showLowValueAlerts ? (
              <ExpandLess sx={{ fontSize: 18 }} color="action" />
            ) : (
              <ExpandMore sx={{ fontSize: 18 }} color="action" />
            )}
          </div>
        </div>
      )}
    </>
  );
};

interface CustomAlertProps {
  title?: string;
  name: string;
  amount: number | undefined;
  link?: string;
  rndNeeded?: boolean;
  isBeta?: boolean;
  potentialValueType?: PotentialValueType;
  handleAlertClose: () => void;
}

const CustomAlert = ({
  title = "Available Savings",
  name,
  amount,
  link,
  rndNeeded,
  isBeta,
  potentialValueType,
  handleAlertClose,
}: CustomAlertProps) => {
  return (
    <AlertBar
      title={
        <div className="flex items-center justify-between">
          <div className="font-medium">{title}</div>
          {rndNeeded && <small className="ml-5 font-normal">R&D needed</small>}
        </div>
      }
      customDescription={
        <>
          <div className="flex gap-5 mb-1">
            <Typography
              className="flex gap-1 text-guideline-darkerGreen font-medium text-4"
              style={{ textShadow: "0.2px 0.2px 0.4px black" }}
            >
              {name} {isBeta && <BetaIcon width={18} height={18} />}
            </Typography>
          </div>
          <div className="flex">
            <Typography variant="caption" width={140}>
              {potentialValueType === PotentialValueType.Cost ? "Potential value in cost:" : "Potential value in CPU:"}
            </Typography>
            <Typography variant="caption">
              <b className="mr-5">{getPercentageValue2(Number(amount))}</b>
            </Typography>
            {link && (
              <NavLink to={link} className="ml-auto">
                <Button label="explore" variant={"extraSmall"} />
              </NavLink>
            )}
          </div>
        </>
      }
      theme={AlertBarTheme.White}
      withSnapshotWrapper
      wrapperClassName={"shadow-lg"}
      size={"small"}
      handleAlertClose={handleAlertClose}
    />
  );
};

export default AvailableSavingsAlerts;
