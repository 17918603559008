import { Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import CircleUpArrowIcon from "../Icons/CircleUpArrowIcon";
import { useMainContext } from "../MainContext";
import { GetVersion, GetVersionResponse } from "../api/fetcher";
import CodeSnippet, { THEME } from "../components/CodeSnippet";
import Dialog from "../components/Dialog";
import Tooltip from "../components/Tooltip";
import { GetConfig } from "../utils/ConfigHelper";
import { LINK_CLASS_NAME } from "../utils/styleUtils";
import { documentationUrl } from "../utils/urlUtils";
import clsx from "clsx";

const { queryKey, queryFn } = GetVersion();

const UpgradeVersion = () => {
  const config = GetConfig();
  const { currentCluster } = useMainContext();
  const [isOpen, setIsOpen] = useState(false);
  const [releaseNodeUrl, setReleaseNodeUrl] = useState("");

  const { data, error } = useQuery<GetVersionResponse, Error>({
    queryKey: [queryKey, currentCluster],
    queryFn: queryFn,
  });

  const version = data?.nextVersion || config.version;

  useEffect(() => {
    if (data?.nextVersion) {
      const releaseNodeUrlValue = documentationUrl({
        path: "release-notes",
        subPath: version?.replace(/[^a-zA-Z0-9]/g, ""),
        token: config.token,
      });

      setReleaseNodeUrl(releaseNodeUrlValue);
    }
  }, [data]);

  if (error && !data) {
    return null;
  }

  return (
    <>
      <Dialog
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        title={
          data?.shouldUpdate ? (
            <div className="flex items-end gap-1">
              <span>Upgrade ScaleOps version</span>
              <span className="bg-main-green text-white rounded-full px-2 text-[0.9rem] py-[0.1rem]">
                {data?.nextVersion}
              </span>
            </div>
          ) : (
            <div className="flex items-end gap-1">
              <span>ScaleOps version</span>
              <span className="bg-main-deepBlue text-white rounded-full px-2 text-[0.9rem] py-[0.1rem]">
                {config.version}
              </span>
            </div>
          )
        }
        dialogContentStyle={{
          padding: "0px",
        }}
        fullWidth={false}
      >
        <div className="p-6 flex flex-col gap-2">
          {data?.upgradeCommand ? (
            <CodeSnippet codeSnippet={String(data?.upgradeCommand) ?? ""} theme={THEME.light} />
          ) : null}
          <Typography variant="caption" className="mt-2">
            <div className="mt-2 ml-1">
              For more details please read the{" "}
              <a target="_blank" href={releaseNodeUrl} className={LINK_CLASS_NAME}>
                Release Notes
              </a>
              .
            </div>
          </Typography>
        </div>
      </Dialog>
      <Tooltip
        title={
          data?.shouldUpdate ? (
            <>
              <p>
                New version available <span className="text-guideline-darkGreen">({data?.nextVersion})</span>
              </p>
              <span>
                <b>Click to get more info.</b>
              </span>
            </>
          ) : (
            <>
              <p>
                You are up to date <span className="text-black">({config.version})</span>
              </p>
              <span>
                <b>Click to get more info.</b>
              </span>
            </>
          )
        }
        onClick={() => {
          setIsOpen(true);
        }}
      >
        <CircleUpArrowIcon
          width={20}
          height={20}
          className={clsx("rounded-full cursor-pointer", {
            "text-main-green hover:text-guideline-darkGreen": data?.shouldUpdate,
            "text-white hover:text-white": !data?.shouldUpdate,
          })}
        />
      </Tooltip>
    </>
  );
};

export default UpgradeVersion;
