import ScaleopsIcon from "./Icons/ScaleopsIcon";

const BackgroundPattern = () => (
  <>
    <ScaleopsIcon width={"30vw"} height={"30vw"} className="position fixed top-[-20px] left-0 opacity-25" />
    <ScaleopsIcon width={"50vw"} height={"50vw"} className="position fixed bottom-[-20vw] right-0 opacity-10" />
  </>
);

export default BackgroundPattern;
