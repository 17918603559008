import { useQuery } from "@tanstack/react-query";
import { GetActiveIssues, GetActiveIssuesResponse } from "../../api/fetcher";
import AlertBar, { AlertBarTheme } from "../AlertBar";
import { Typography } from "@mui/material";
import { useEffect, useState } from "react";
import clsx from "clsx";
import InfoIcon from "../../Icons/InfoIcon";
import Tooltip from "../Tooltip";

const titles: { [key: string]: string } = {
  missingNodeGroups: "Missing node group",
  frequentClusterEvictions: "Frequent cluster evictions",
  frequentWorkloadEvictions: "Frequent workload evictions",
  unstableRecommendation: "Unstable recommendation",
  misconfiguredAutomatedHPA: "Misconfigured automated HPA",
  nodeLifecycleTypeUnknown: "Undetectable node lifecycle",
};

const lowPriorityIssues = new Set(["missingNodeGroups", "nodeLifecycleTypeUnknown"]);

interface Props {
  setActiveIssuesAlertsCount: (count: number) => void;
}

const ActiveIssuesAlerts = ({ setActiveIssuesAlertsCount }: Props) => {
  const { queryFn, queryKey } = GetActiveIssues();
  const [visibleActiveIssues, setVisibleActiveIssues] = useState<string[]>([]);
  const [hiddenAlerts, setHiddenAlerts] = useState<Set<string>>(new Set());
  const { data } = useQuery<GetActiveIssuesResponse, Error>({
    queryKey: [queryKey],
    queryFn,
    refetchInterval: 1000 * 60,
  });

  useEffect(() => {
    setVisibleActiveIssues(Object.keys(data || {}).filter((issue) => !hiddenAlerts.has(issue)));
  }, [data, hiddenAlerts]);

  useEffect(() => {
    setActiveIssuesAlertsCount(visibleActiveIssues.length);
  }, [visibleActiveIssues]);

  return (
    (visibleActiveIssues.length > 0 && (
      <>
        {visibleActiveIssues.map((issue) => {
          const isLowPriority = lowPriorityIssues.has(issue);
          return (
            <AlertBar
              key={issue}
              title={
                <div className="flex gap-1 items-center font-medium ">
                  {isLowPriority ? "Minor" : "Active"} Issue
                  {isLowPriority && (
                    <Tooltip title={"Trigger R&D only if necessary"}>
                      <InfoIcon width={10} height={10} />
                    </Tooltip>
                  )}
                </div>
              }
              customDescription={
                <Typography
                  className={clsx("text-guideline-darkRed font-medium", {
                    "text-guideline-yellow text-main-orange": isLowPriority,
                  })}
                  style={{ textShadow: "0.2px 0.2px 0.4px black" }}
                >
                  {titles[issue]}
                </Typography>
              }
              theme={AlertBarTheme.White}
              withSnapshotWrapper
              wrapperClassName={"shadow-lg"}
              size={"small"}
              handleAlertClose={() => {
                setHiddenAlerts(new Set(hiddenAlerts.add(issue)));
              }}
            />
          );
        })}
      </>
    )) ||
    null
  );
};

export default ActiveIssuesAlerts;
