import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import {
  GetCostBreakdown,
  GetCostBreakdownAggregations,
  GetCostBreakdownAggregationsResponse,
  GetCostBreakdownResponse,
  GetNetworkCostBreakdown,
  GetNetworkCostBreakdownAggregations,
  GetNetworkCostBreakdownAggregationsResponse,
  GetNetworkCostBreakdownResponse,
} from "../../../api/fetcher";
import GraphCircularLoader from "../../../components/GraphCircularLoader";
import Loading from "../../../components/Loading";
import useHpaOptimizationEnabled from "../../../components/WorkloadStatusByNamespaceSection/useHpaOptimizationEnabled";
import useAggregationFilters from "../Tables/Aggregation/hooks/useAggregationFilters";
import { TableType } from "../Tables/utils";
import useWorkloadsFilters from "../Tables/Workloads/hooks/useWorkloadsFilters";
import { CostReportType } from "../utils";
import DailyChart from "./DailyChart";
import { ChartCostType, DailyData, ToBreakdownCostTypes } from "./utils";

const TOP_K = 9;
const { queryKey, queryFn } = GetCostBreakdown();
const { queryKey: aggregationQueryKey, queryFn: aggregationQueryFn } = GetCostBreakdownAggregations();
const getNetworkCostBreakdown = GetNetworkCostBreakdown();
const getNetworkCostBreakdownAggregations = GetNetworkCostBreakdownAggregations();

const getUniqueKeys = (data: GetCostBreakdownResponse | undefined) => {
  if (!data || !data.costs) return [];

  const uniqueKeys = [...new Set(data.costs.map((el) => el.costs?.map((item) => item?.id)).flat())]
    .filter((el) => el)
    .map((el) => el as string);
  return uniqueKeys;
};

const getGraphData = (data: GetCostBreakdownResponse) => {
  if (!data || !data.costs) return [];

  const graphData = data.costs.map((el) => {
    const values = el.costs?.reduce((acc, item) => {
      if (item.id && item.value) acc[item.id] = item.value;
      return acc;
    }, {} as Record<string, number>);

    return {
      timestamp: el.timestamp,
      ...values,
    } as DailyData;
  });

  return graphData;
};

const getElementOrder = (data: GetCostBreakdownResponse) => {
  let uniqueKeys = getUniqueKeys(data);
  uniqueKeys = uniqueKeys.filter((key) => key !== "timestamp");

  const sumByUnqiueKey = uniqueKeys.map((key) => {
    const sum = data.costs?.reduce((acc, item) => {
      const value = item.costs?.find((el) => el.id === key)?.value;
      return acc + (value || 0);
    }, 0);
    return { key, sum };
  });

  const elementOrder = sumByUnqiueKey.sort((a, b) => (b.sum ?? 0) - (a.sum ?? 0)).map((el) => el.key);

  return elementOrder;
};

interface Props {
  selectedTable: TableType;
  reportType: CostReportType;
  costType: ChartCostType;
}

const DailyChartContainer = ({ selectedTable, reportType, costType }: Props) => {
  const workloadsFilters = useWorkloadsFilters();
  const workloadsFiltersLength = Object.keys(workloadsFilters).length;
  const aggregationFilters = useAggregationFilters();
  const aggregationFiltersLength = Object.keys(aggregationFilters).length;

  const [uniqueKeys, setUniqueKeys] = useState<string[]>([]);
  const [graphData, setGraphData] = useState<DailyData[]>([]);
  const [elementOrder, setElementOrder] = useState<string[]>([]);
  const enableHpaOptimization = useHpaOptimizationEnabled();

  const computeOrGpu = reportType === CostReportType.Compute || reportType === CostReportType.Gpu;

  const getCostBreakdownEnabled = selectedTable === TableType.Workloads && !!workloadsFiltersLength && computeOrGpu;
  const { data, isLoading, error } = useQuery<GetCostBreakdownResponse, Error>({
    queryKey: [queryKey, workloadsFilters, costType, reportType],
    queryFn: () =>
      queryFn({
        ...workloadsFilters,
        aggregation: 1,
        multiCluster: true,
        topk: TOP_K,
        type: ToBreakdownCostTypes(costType, enableHpaOptimization),
        gpuWorkloadsOnly: reportType === CostReportType.Gpu,
      }),
    enabled: getCostBreakdownEnabled,
  });

  const getCostBreakdownAggregationsEnabled =
    selectedTable === TableType.Aggregation && !!aggregationFiltersLength && computeOrGpu;
  const {
    data: aggregationData,
    isLoading: aggregationIsLoading,
    error: aggregationError,
  } = useQuery<GetCostBreakdownAggregationsResponse, Error>({
    queryKey: [aggregationQueryKey, aggregationFilters, costType, reportType],
    queryFn: () =>
      aggregationQueryFn({
        ...aggregationFilters,
        aggregation: 1,
        multiCluster: true,
        topk: TOP_K,
        type: ToBreakdownCostTypes(costType, enableHpaOptimization),
        gpuWorkloadsOnly: reportType === CostReportType.Gpu,
      }),
    enabled: getCostBreakdownAggregationsEnabled,
  });

  const getNetworkCostBreakdownEnabled =
    selectedTable === TableType.Workloads && !!workloadsFiltersLength && reportType === CostReportType.Network;
  const {
    data: networkWorkloadsData,
    isLoading: networkWorkloadsIsLoading,
    error: networkWorkloadsError,
  } = useQuery<GetNetworkCostBreakdownResponse, Error>({
    queryKey: [getNetworkCostBreakdown.queryKey, workloadsFilters.reportType],
    queryFn: () =>
      getNetworkCostBreakdown.queryFn({
        ...workloadsFilters,
        aggregation: 1,
        multiCluster: true,
        topk: TOP_K,
      }),
    enabled: getNetworkCostBreakdownEnabled,
  });

  const getNetworkCostBreakdownAggregationsEnabled =
    selectedTable === TableType.Aggregation && !!aggregationFiltersLength && reportType === CostReportType.Network;
  const {
    data: networkAggregationData,
    isLoading: networkAggregationIsLoading,
    error: networkAggregationError,
  } = useQuery<GetNetworkCostBreakdownAggregationsResponse, Error>({
    queryKey: [getNetworkCostBreakdownAggregations.queryKey, aggregationFilters, reportType],
    queryFn: () =>
      getNetworkCostBreakdownAggregations.queryFn({
        ...aggregationFilters,
        aggregation: 1,
        multiCluster: true,
        topk: TOP_K,
      }),
    enabled: getNetworkCostBreakdownAggregationsEnabled,
  });

  useEffect(() => {
    switch (true) {
      case selectedTable === TableType.Workloads && computeOrGpu:
        if (data?.costs !== undefined) {
          setUniqueKeys(getUniqueKeys(data ?? []));
          setGraphData(getGraphData(data ?? []));
          setElementOrder(getElementOrder(data ?? []));
        }
        break;
      case selectedTable === TableType.Aggregation && computeOrGpu:
        if (aggregationData?.costs) {
          setUniqueKeys(getUniqueKeys(aggregationData));
          setGraphData(getGraphData(aggregationData));
          setElementOrder(getElementOrder(aggregationData));
        }
        break;
      case selectedTable === TableType.Workloads && reportType === CostReportType.Network:
        if (networkWorkloadsData?.costs) {
          setUniqueKeys(getUniqueKeys(networkWorkloadsData));
          setGraphData(getGraphData(networkWorkloadsData));
          setElementOrder(getElementOrder(networkWorkloadsData));
        }
        break;
      case selectedTable === TableType.Aggregation && reportType === CostReportType.Network:
        if (networkAggregationData?.costs) {
          setUniqueKeys(getUniqueKeys(networkAggregationData));
          setGraphData(getGraphData(networkAggregationData));
          setElementOrder(getElementOrder(networkAggregationData));
        }
        break;
    }
  }, [data, aggregationData, networkWorkloadsData, networkAggregationData, selectedTable, reportType]);

  if (error || aggregationError || networkWorkloadsError || networkAggregationError) {
    console.log("Error fetching cost breakdown", error || aggregationError);
  }

  const loading =
    (isLoading && getCostBreakdownEnabled) ||
    (aggregationIsLoading && getCostBreakdownAggregationsEnabled) ||
    (networkWorkloadsIsLoading && getNetworkCostBreakdownEnabled) ||
    (networkAggregationIsLoading && getNetworkCostBreakdownAggregationsEnabled);

  if (loading) {
    return (
      <Loading
        loadingText={`Loading Daily ${selectedTable === TableType.Workloads ? "Workloads" : "Aggregations"}...`}
      />
    );
  }

  if (graphData.length === 0) {
    return null;
  }

  return (
    <div className="relative flex items-center align-center w-full h-full">
      {(isLoading && selectedTable === TableType.Workloads && computeOrGpu) ||
      (aggregationIsLoading && selectedTable === TableType.Aggregation && computeOrGpu) ||
      (networkWorkloadsIsLoading && selectedTable === TableType.Workloads && reportType === CostReportType.Network) ||
      (networkAggregationIsLoading &&
        selectedTable === TableType.Aggregation &&
        reportType === CostReportType.Network) ? (
        <GraphCircularLoader />
      ) : null}
      <DailyChart data={graphData} uniqueKeys={uniqueKeys} elementOrder={elementOrder} />
    </div>
  );
};

export default DailyChartContainer;
