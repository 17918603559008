import { Form, Formik } from "formik";
import { useEffect } from "react";
import { StringParam, useQueryParam, withDefault } from "use-query-params";
import * as Yup from "yup";
import { HPAPolicy } from "../../../api/fetcher";
import Button from "../../../components/Button";
import RightDrawer from "../../../components/RightDrawer";
import { MenuItem } from "../../../components/SideMenu/SideMenu";
import Tooltip from "../../../components/Tooltip";
import CustomizedIcon from "../../../Icons/CustomizedIcon";
import LockIcon from "../../../Icons/LockIcon";
import StaticIcon from "../../../Icons/StaticIcon";
import UpAndDownCircleIcon from "../../../Icons/UpAndDownCircleIcon";
import { isBuiltInPolicy } from "../../../utils/policyUtils";
import useIsReadyOnlyFrontEnd from "../../../utils/useIsReadyOnlyFrontEnd";
import useCreateHPAPolicy from "../hooks/useCreateHPAPolicy";
import useUpdateHPAPolicy from "../hooks/useUpdateHPAPolicy";
import { HPAMutationType, policyNameValidation } from "../utils";
import GeneralMaxReplicas from "./FormFields/GeneralMaxReplicas";
import GeneralMinimumReplicas from "./FormFields/GeneralMinimumReplicas";
import GeneralRequiredHistoryForOptimization from "./FormFields/GeneralRequiredHistoryForOptimization";
import NewPolicyName from "./FormFields/NewPolicyName";
import PredictableHistoryWindow from "./FormFields/PredictableHistoryWindow";
import PredictableMinReplicas from "./FormFields/PredictableMinReplicas";
import PredictableWorkloadsPrediction from "./FormFields/PredictableWorkloadsPrediction";
import StaticWorkloadsMinReplicas from "./FormFields/StaticWorkloadsMinReplicas";
import { getMinReplicasStrategy, MinReplicasStrategy } from "./FormFields/utils";
import HPAPolicyTitle from "./HPAPolicyTitle";
import SnapshotWrapper from "../../../components/SnapshotWrapper";

enum MenuItemType {
  PredictableWorkloads = "Predictable workloads",
  StaticWorkloads = "Static workloads",
  General = "General",
}

const DEFAULT_SELECTED_MENU_ITEM = MenuItemType.General;
const HPA_POLICY_SELECTED_MENU_ITEM = "HPAPolicySelectedMenuItem";

const menuItems: MenuItem[] = [
  {
    id: MenuItemType.General,
    title: MenuItemType.General,
    icon: <CustomizedIcon />,
  },
  {
    id: MenuItemType.PredictableWorkloads,
    title: MenuItemType.PredictableWorkloads,
    icon: <UpAndDownCircleIcon />,
  },
  {
    id: MenuItemType.StaticWorkloads,
    title: MenuItemType.StaticWorkloads,
    icon: <StaticIcon />,
  },
];

interface Props {
  mutationType: HPAMutationType;
  existingPolicyNames: string[];
  isOpen: boolean;
  setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  selectedPolicyToEdit?: HPAPolicy | undefined;
  setSelectedPolicyToEdit?: React.Dispatch<React.SetStateAction<HPAPolicy | undefined>>;
}

const EditAndCreateHPAPolicy = ({
  mutationType = HPAMutationType.UPDATE,
  existingPolicyNames,
  isOpen,
  setIsOpen,
  selectedPolicyToEdit,
  setSelectedPolicyToEdit,
}: Props) => {
  const updateHPAPolicy = useUpdateHPAPolicy();
  const createHPAPolicy = useCreateHPAPolicy();

  const isReadyOnlyFrontEnd = useIsReadyOnlyFrontEnd();

  const [selectedMenuItem, setSelectedMenuItem] = useQueryParam(
    HPA_POLICY_SELECTED_MENU_ITEM,
    withDefault(StringParam, DEFAULT_SELECTED_MENU_ITEM)
  );

  const isCustomizedPolicy = !isBuiltInPolicy(selectedPolicyToEdit) && !isReadyOnlyFrontEnd;

  const handleClose = () => {
    setIsOpen && setIsOpen(false);
    setSelectedPolicyToEdit && setSelectedPolicyToEdit(undefined);
    setSelectedMenuItem(DEFAULT_SELECTED_MENU_ITEM);
  };

  return (
    <RightDrawer
      title={
        <HPAPolicyTitle
          selectedPolicy={selectedPolicyToEdit}
          createNewPolicyTitle={mutationType === HPAMutationType.CREATE}
        />
      }
      onClose={handleClose}
      isOpen={isOpen}
      menuItems={menuItems}
      defaultSelectedMenuItemId={DEFAULT_SELECTED_MENU_ITEM}
      selectedMenuItemQueryParamsKey={HPA_POLICY_SELECTED_MENU_ITEM}
      selectedPolicy={selectedPolicyToEdit}
      mutationType={mutationType}
    >
      <Formik
        initialValues={{
          newPolicyName: "",
          /**
           * Predictable workloads
           */
          minReplicasStrategy: getMinReplicasStrategy(selectedPolicyToEdit),
          generalMinReplicas: selectedPolicyToEdit?.spec?.policyOptimize?.minReplicas?.setMinReplicas,
          generalMinimumReplicasResourceBoundary:
            selectedPolicyToEdit?.spec?.policyOptimize?.minReplicas?.minAllowed ?? 1,

          predictionHistoryWindow: selectedPolicyToEdit?.spec?.policyOptimize?.replicas?.prediction?.window ?? "336h",
          predictionEnabled: selectedPolicyToEdit?.spec?.policyOptimize?.replicas?.prediction?.enabled ?? true,
          predictionLookAheadDuration:
            selectedPolicyToEdit?.spec?.policyOptimize?.replicas?.prediction?.lookAheadDuration ?? "15m",
          predictionMinReplicasEnabled:
            selectedPolicyToEdit?.spec?.policyOptimize?.minReplicas?.predictableWorkloads?.enabled ?? true,
          // predictionMinReplicasKeepOriginal:
          //   selectedPolicyToEdit?.spec?.policyOptimize?.minReplicas?.predictableWorkloads?.keepMinReplicas ?? false,
          predictionMinReplicasHistoryWindow:
            selectedPolicyToEdit?.spec?.policyOptimize?.minReplicas?.predictableWorkloads?.window ?? "336h",
          predictionMinReplicasPercentile:
            selectedPolicyToEdit?.spec?.policyOptimize?.minReplicas?.predictableWorkloads?.percentilePercentage ?? 80,

          /**
           * Static workloads
           */

          staticWorkloadsEnabled:
            selectedPolicyToEdit?.spec?.policyOptimize?.minReplicas?.generalWorkloads?.enabled ?? true,
          // staticWorkloadsKeepMinReplicas:
          //   selectedPolicyToEdit?.spec?.policyOptimize?.minReplicas?.generalWorkloads?.keepMinReplicas ?? false,
          staticWorkloadsHistoryWindow:
            selectedPolicyToEdit?.spec?.policyOptimize?.minReplicas?.generalWorkloads?.window ?? "336h",
          staticWorkloadsPercentile:
            selectedPolicyToEdit?.spec?.policyOptimize?.minReplicas?.generalWorkloads?.percentilePercentage ?? 100,

          /**
           * General
           */
          generalKeepMinReplicas:
            !!selectedPolicyToEdit?.spec?.policyOptimize?.minReplicas?.predictableWorkloads?.keepMinReplicas &&
            !!selectedPolicyToEdit?.spec?.policyOptimize?.minReplicas?.generalWorkloads?.keepMinReplicas,
          generalMaxReplicas: selectedPolicyToEdit?.spec?.policyOptimize?.maxReplicas?.setMaxReplicas,
          generalMaxReplicasEnabled: !!selectedPolicyToEdit?.spec?.policyOptimize?.maxReplicas?.setMaxReplicas,
          generalRequiredHistoryForOptimization:
            selectedPolicyToEdit?.spec?.updatePolicy?.requiredWindowCoverageDuration ?? "96h",
        }}
        validationSchema={Yup.object({
          newPolicyName:
            mutationType === HPAMutationType.CREATE
              ? policyNameValidation(existingPolicyNames)
              : Yup.mixed().notRequired(),
          /**
           * Predictable workloads
           */
          minReplicasStrategy: Yup.string().required("Required"),
          generalMinReplicas: Yup.number()
            .min(0, "Must be 0 or greater")
            .when("minReplicasStrategy", (minReplicasStrategy, schema) => {
              return String(minReplicasStrategy) === MinReplicasStrategy.SetMinReplicasForAllWorkloads
                ? schema.required("Required")
                : schema;
            }),

          generalMinimumReplicasResourceBoundary: Yup.number()
            .min(0, "Must be 0 or greater")
            .when("minReplicasStrategy", (minReplicasStrategy, schema) => {
              return String(minReplicasStrategy) !== MinReplicasStrategy.SetMinReplicasForAllWorkloads
                ? schema.required("Required")
                : schema;
            }),

          predictionHistoryWindow: Yup.string().required("Required"),
          predictionEnabled: Yup.boolean().required("Required"),
          predictionLookAheadDuration: Yup.string(),
          predictionMinReplicasEnabled: Yup.boolean().required("Required"),
          // predictionMinReplicasKeepOriginal: Yup.boolean().required("Required"),
          predictionMinReplicasHistoryWindow: Yup.string().required("Required"),
          predictionMinReplicasPercentile: Yup.string().required("Required"),

          /**
           * Static workloads
           */
          staticWorkloadsEnabled: Yup.boolean().required("Required"),
          // staticWorkloadsKeepMinReplicas: Yup.boolean().required("Required"),
          staticWorkloadsHistoryWindow: Yup.string().required("Required"),
          staticWorkloadsPercentile: Yup.number().required("Required"),
          /**
           * General
           */
          generalKeepMinReplicas: Yup.boolean().required("Required"),
          generalMaxReplicas: Yup.number()
            .min(1, "Must be 1 or greater")
            .when("generalMaxReplicasEnabled", (generalMaxReplicasEnabled, schema) => {
              // generalMaxReplicasEnabled is array with size 1
              return generalMaxReplicasEnabled && generalMaxReplicasEnabled.length > 0 && generalMaxReplicasEnabled[0]
                ? schema.required("Required")
                : schema;
            }),
          generalRequiredHistoryForOptimization: Yup.string().required("Required"),
        })}
        onSubmit={(values) => {
          const policy = {
            ...selectedPolicyToEdit,
            spec: {
              ...selectedPolicyToEdit?.spec,
              policyOptimize: {
                ...selectedPolicyToEdit?.spec?.policyOptimize,
                replicas: {
                  ...selectedPolicyToEdit?.spec?.policyOptimize?.replicas,
                  prediction: {
                    ...selectedPolicyToEdit?.spec?.policyOptimize?.replicas?.prediction,
                    window: values.predictionHistoryWindow,
                    enabled: values.predictionEnabled,
                    lookAheadDuration: values.predictionLookAheadDuration,
                  },
                },
                minReplicas: {
                  ...selectedPolicyToEdit?.spec?.policyOptimize?.minReplicas,
                  predictableWorkloads: {
                    ...selectedPolicyToEdit?.spec?.policyOptimize?.minReplicas?.predictableWorkloads,
                    enabled: values.predictionMinReplicasEnabled,
                    keepMinReplicas:
                      String(values?.minReplicasStrategy) === MinReplicasStrategy.KeepMinReplicasForAllWorkloads ||
                      String(values?.minReplicasStrategy) === MinReplicasStrategy.SetHistoryBasedForStaticWorkloads
                        ? true
                        : false,
                    window: values.predictionMinReplicasHistoryWindow,
                    percentilePercentage: Number(values.predictionMinReplicasPercentile),
                  },
                  generalWorkloads: {
                    ...selectedPolicyToEdit?.spec?.policyOptimize?.minReplicas?.generalWorkloads,
                    enabled: values.staticWorkloadsEnabled,
                    keepMinReplicas:
                      String(values?.minReplicasStrategy) === MinReplicasStrategy.KeepMinReplicasForAllWorkloads ||
                      String(values?.minReplicasStrategy) === MinReplicasStrategy.SetHistoryBasedForPredictableWorkloads
                        ? true
                        : false,
                    window: values.staticWorkloadsHistoryWindow,
                    percentilePercentage: Number(values.staticWorkloadsPercentile),
                  },
                  minAllowed: values.generalMinimumReplicasResourceBoundary,
                  setMinReplicas:
                    String(values?.minReplicasStrategy) === MinReplicasStrategy.SetMinReplicasForAllWorkloads
                      ? values.generalMinReplicas
                      : undefined,
                },
                maxReplicas: {
                  ...selectedPolicyToEdit?.spec?.policyOptimize?.maxReplicas,
                  setMaxReplicas: values.generalMaxReplicasEnabled ? Number(values.generalMaxReplicas) : undefined,
                },
              },
              updatePolicy: {
                ...selectedPolicyToEdit?.spec?.updatePolicy,
                requiredWindowCoverageDuration: values.generalRequiredHistoryForOptimization,
              },
            },
          };

          if (mutationType === HPAMutationType.UPDATE) {
            updateHPAPolicy.mutate({
              name: String(selectedPolicyToEdit?.metadata?.name),
              policy,
            });
          }

          if (mutationType === HPAMutationType.CREATE) {
            createHPAPolicy.mutate({
              policy: {
                ...policy,
                metadata: {
                  ...selectedPolicyToEdit?.metadata,
                  name: values.newPolicyName,
                  resourceVersion: undefined,
                  creationTimestamp: undefined,
                  uid: undefined,
                  generation: undefined,
                },
              },
            });
          }

          handleClose();
        }}
      >
        {(formik) => {
          useEffect(() => {
            formik.validateForm();
          }, []);

          return (
            <Form className="h-full w-[700px] px-8 py-9 flex flex-col justify-start">
              <div className="px-8 flex-grow flex flex-col overflow-hidden h-[calc(100vh-218px)] overflow-y-auto scrollbar-thin scrollbar-thumb-background-chipActive scrollbar-track-guideline-lightGray scrollbar-thumb-rounded-md scrollbar-track-rounded-md">
                {selectedMenuItem === MenuItemType.General && (
                  <div className="flex flex-col gap-10 pt-1">
                    {mutationType === HPAMutationType.CREATE ? (
                      <>
                        <NewPolicyName />
                        <hr />
                      </>
                    ) : null}
                    <GeneralMinimumReplicas isCustomizedPolicy={isCustomizedPolicy} />
                    <hr />
                    <GeneralMaxReplicas isCustomizedPolicy={isCustomizedPolicy} />
                    <hr />
                    <GeneralRequiredHistoryForOptimization isCustomizedPolicy={isCustomizedPolicy} />
                  </div>
                )}
                {selectedMenuItem === MenuItemType.PredictableWorkloads && (
                  <div className="flex flex-col gap-10 pt-1">
                    <PredictableHistoryWindow isCustomizedPolicy={isCustomizedPolicy} />
                    <hr />
                    <PredictableWorkloadsPrediction isCustomizedPolicy={isCustomizedPolicy} />
                    <hr />
                    <PredictableMinReplicas isCustomizedPolicy={isCustomizedPolicy} />
                  </div>
                )}
                {selectedMenuItem === MenuItemType.StaticWorkloads && (
                  <div className="flex flex-col gap-10 pt-1">
                    <StaticWorkloadsMinReplicas isCustomizedPolicy={isCustomizedPolicy} />
                  </div>
                )}
              </div>
              <div className="flex justify-end gap-4 py-5 px-8 drop-shadow-[0_35px_35px_rgba(0,0,0,0.25)]">
                {!isCustomizedPolicy && (
                  <SnapshotWrapper wrappedType={"button"}>
                    <Button type="submit" label="Override" disabled={!formik.isValid} />
                  </SnapshotWrapper>
                )}
                <Button onClick={handleClose} label="Cancel" type="button" />
                {isCustomizedPolicy && <Button type="submit" label="Save" disabled={!formik.isValid} />}
                {!isCustomizedPolicy && (
                  <div className="flex items-center">
                    <Tooltip
                      title={
                        <>
                          <b>Default policies can't be edited</b>.<br />
                          You can only edit customized policies
                        </>
                      }
                    >
                      <LockIcon width={24} height={24} />
                    </Tooltip>
                  </div>
                )}
              </div>
            </Form>
          );
        }}
      </Formik>
    </RightDrawer>
  );
};

export default EditAndCreateHPAPolicy;
