import { getWorkloadType as getWorkloadTypeView } from "./typesUtils";

export const SCALEOPS_ROLLOUT = "scaleops-rollout";
export const SCALEOPS_CRON_JOB_COG_NAME = "cronjob";
export const SCALEOPS_KEDA_SCALED_JOB_COG_NAME = "kedascaledjob";

export const OPTIMIZED_REQUEST = "Optimized request";
export const OPTIMIZED_LIMIT = "Optimized limit";

export const INCLUDE_SIGN = <span>:</span>;
export const EXCLUDE_SIGN = <b>!:</b>;

/** Use also to build URLs link from charts tooltip **/
export const workloadTypes = {
  deployment: "Deployment",
  daemonset: "DaemonSet",
  statefulset: "StatefulSet",
  job: "Job",
  cronjob: "CronJob",
  kedascaledjob: "KedaScaledJob",
};

export const getWorkloadType = (type: string) => workloadTypes?.[type as keyof typeof workloadTypes] ?? type;

export const getDisplayWorkloadName = (name: string, hideSuffix?: boolean): string => {
  const parts = name.split("/");
  let namespace = "",
    workloadName = "";
  switch (parts.length) {
    case 2:
      [namespace, workloadName] = parts;
      break;
    case 1:
      [workloadName] = parts;
      break;
    default:
      return name;
  }
  const rolloutRegex = new RegExp(`${SCALEOPS_ROLLOUT}-`);
  workloadName = workloadName.replace(rolloutRegex, "");
  let result: string;
  switch (parts.length) {
    case 2:
      result = `${namespace}/${workloadName}`;
      break;
    case 1:
      result = `${workloadName}`;
      break;
    default:
      return "";
  }
  return result.replace(hideSuffix ? /-(?!.*-).*$/ : "", "");
};

export const getDisplayWorkloadKey = (key: string | undefined, namespaceBeforeType = false): string | undefined => {
  if (!key) {
    return key;
  }
  const splitKey = key.split("/");
  if (splitKey.length > 2) {
    const splitLen = splitKey.length;
    let type: string;
    let name = splitKey[splitLen - 1];
    if (namespaceBeforeType) {
      type = splitKey[splitLen - 2];
    } else {
      type = splitKey[splitLen - 3];
    }
    type = getWorkloadTypeView(name, type) ?? type;
    name = getDisplayWorkloadName(name);
    if (namespaceBeforeType) {
      splitKey[splitLen - 2] = type;
    } else {
      splitKey[splitLen - 3] = type;
    }
    splitKey[splitLen - 1] = name;
    key = splitKey.join("/");
  }
  return key;
};

export const DISPLAY_NAME = {
  MemoryBurstReaction: "Memory burst reaction",
  CpuBurstReaction: "CPU burst reaction",
  BurstReaction: "Burst reaction",
  AutoHealingReaction: "Auto healing reaction",
};

export const adjustSearchTerm = (searchTerm: string): string => {
  return searchTerm.split("/").length > 2 ? searchTerm.split("/").slice(-2).join("/") : searchTerm; // removes type from search term
};
