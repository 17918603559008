import React, { useState } from "react";
import { DiagnosticIcons, EventPoint, EventType, ICON_SIZE, ScaleOpsEvents, Y_OFFSET } from "./utils";

const SHOW_LINE = false;
const BASE_X = 10;
const X_GAP = 10;

export const CustomScatterDot: React.FC<{
  cx: number;
  cy: number;
  payload?: EventPoint;
  dotKey: EventType;
  selectedEvents: EventType[];
  setTooltipPayloadContext: React.Dispatch<React.SetStateAction<EventPoint | undefined>>;
}> = ({ cx, cy, payload, selectedEvents, setTooltipPayloadContext }) => {
  const [isHovered, setIsHovered] = useState(false);
  selectedEvents = selectedEvents.includes(EventType.OOM_LIMIT)
    ? [...selectedEvents, EventType.OOM_LIMIT_BY_CONTAINER]
    : selectedEvents;
  selectedEvents = !selectedEvents.includes(EventType.OOM_NODE)
    ? selectedEvents.filter((event) => event !== EventType.OOM_KUBELET)
    : selectedEvents;
  console.log(selectedEvents);
  const eventList = payload
    ? Object.keys(payload)
        .filter((key) => selectedEvents.includes(key as EventType))
        .filter((key) =>
          [
            EventType.AUTO_HEALING,
            EventType.CPU_THROTTLING,
            EventType.EVICTION,
            EventType.HIGH_UTILIZATION_NODES,
            EventType.OOM_EVENT,
            EventType.OOM_KUBELET,
            EventType.OOM_LIMIT,
            EventType.OOM_LIMIT_BY_CONTAINER,
            EventType.OOM_NODE,
            EventType.HIGH_CPU_UTILIZATION,
            EventType.HIGH_MEMORY_UTILIZATION,
            EventType.FAST_REACTION,
            EventType.NODE_MEMORY_PRESSURE_EVENT,
            EventType.IMAGE_CHANGE,
            EventType.POD_DISRUPTION,
            EventType.POD_DISRUPTION_REASON,
          ].includes(key as EventType)
        )
        .filter((key) => {
          return payload[key as keyof typeof payload];
        })
    : 0;

  return (
    <svg
      onMouseEnter={() => {
        setTooltipPayloadContext(payload);
        if (isHovered) return;
        setIsHovered(true);
      }}
      onMouseLeave={() => {
        setTooltipPayloadContext(undefined);
        if (!isHovered) return;
        setIsHovered(false);
      }}
    >
      {eventList &&
        eventList.map((key, index) => {
          const ICON_Y_POSITION = ScaleOpsEvents.includes(key as EventType) ? cy - 23 : cy;
          const BASE = BASE_X - (eventList.length * BASE_X) / 2 + BASE_X / 2;
          if (key === "auto") return null;
          return (
            <svg key={key}>
              <rect x={cx - 10} y={cy - ICON_SIZE - 5} width={20} height={ICON_SIZE + 10} opacity={0.01} fill="white" />
              <svg
                style={{
                  opacity: isHovered ? 1 : 0.7,
                }}
              >
                {DiagnosticIcons[key as keyof typeof DiagnosticIcons](
                  cx - BASE - X_GAP * (eventList.length - index - 1),
                  ICON_Y_POSITION
                )}
              </svg>
              <svg>
                <rect x={cx - 1.5} y={cy - ICON_SIZE - 5} width={3} height={ICON_SIZE + 5} opacity={0} />
                {SHOW_LINE && <line x1={cx} y1={cy - Y_OFFSET + 20} x2={cx} y2={cy - Y_OFFSET + 25} stroke="#666" />}
              </svg>
            </svg>
          );
        })}
    </svg>
  );
};

export default CustomScatterDot;
