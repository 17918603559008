import FormTitleAndDescription from "../../../../components/formComponents/FormTitleAndDescription";
import FormSingleSelect from "../../../../components/formComponents/FormSingleSelect";
import { NO_OVERRIDE_NUMERIC_VALUE, PolicyType } from "./utils";
import PolicyValue from "./PolicyValue";

const options: [string, number][] = [
  ["No override", NO_OVERRIDE_NUMERIC_VALUE],
  ["False", 0],
  ["True", 1],
];

interface Props {
  selectedPolicy?: PolicyType;
}

const KeepRequest = ({ selectedPolicy }: Props) => {
  return (
    <div className="flex flex-col gap-4">
      <FormTitleAndDescription
        title="Keep original requests"
        description="Disable ScaleOps from changing the original resource requests."
      />
      <div className="flex items-center gap-4">
        <div className="flex flex-col gap-1">
          <FormSingleSelect name="keepCpuRequest" label="Keep original CPU request" options={options} />
          <PolicyValue
            value={
              Boolean(
                selectedPolicy?.spec?.policyOptimize?.rightSizePolicy?.requestsConfigs?.cpu?.keepRequest
              )?.toString() ?? ""
            }
          />
        </div>
        <div className="flex flex-col gap-1">
          <FormSingleSelect name="keepMemoryRequest" label="Keep original memory request" options={options} />
          <PolicyValue
            value={
              Boolean(
                selectedPolicy?.spec?.policyOptimize?.rightSizePolicy?.requestsConfigs?.memory?.keepRequest
              ).toString() ?? ""
            }
          />
        </div>
      </div>
    </div>
  );
};
export default KeepRequest;
