import LockedIcon from "../../../../../Icons/LockedIcon";
import UnlockedIcon from "../../../../../Icons/UnlockedIcon";

interface Props {
  isSecure: boolean;
}

const SecureCell = ({ isSecure }: Props) => <>{isSecure ? <LockedIcon /> : <UnlockedIcon />}</>;

export default SecureCell;
