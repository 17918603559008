import { IconProps } from "./utils/utils";

const DragIcon = (props: IconProps) => {
  const { height = 20, width = 20, className } = props;
  return (
    <svg height={height} width={width} className={className} fill="currentColor" viewBox="0 0 20 20" {...props}>
      <path d="M18.5669 10.4419L16.0669 12.9419C15.8225 13.1863 15.4275 13.1863 15.1831 12.9419C14.9388 12.6976 14.9388 12.3026 15.1831 12.0582L16.6163 10.6251H10.625V16.6163L12.0581 15.1832C12.3025 14.9388 12.6975 14.9388 12.9419 15.1832C13.1863 15.4276 13.1863 15.8226 12.9419 16.0669L10.4419 18.5669C10.2056 18.8038 9.80877 18.8182 9.55752 18.5669L7.05752 16.0669C6.81315 15.8226 6.81315 15.4276 7.05752 15.1832C7.3019 14.9388 7.6969 14.9388 7.94127 15.1832L9.37502 16.6163V10.6251H3.38377L4.8169 12.0582C5.06127 12.3026 5.06127 12.6976 4.8169 12.9419C4.57252 13.1863 4.17752 13.1863 3.93315 12.9419L1.43315 10.4419C1.19127 10.2001 1.1894 9.80132 1.43315 9.55757L3.93315 7.05757C4.17752 6.81319 4.57252 6.81319 4.8169 7.05757C5.06127 7.30194 5.06127 7.69694 4.8169 7.94132L3.38377 9.37507H9.37502V3.38382L7.9419 4.81694C7.69752 5.06132 7.30252 5.06132 7.05815 4.81694C6.81377 4.57257 6.81377 4.17757 7.05815 3.93319L9.55815 1.43319C9.80002 1.19132 10.1988 1.18944 10.4425 1.43319L12.9425 3.93319C13.1869 4.17757 13.1869 4.57257 12.9425 4.81694C12.82 4.93882 12.66 5.00007 12.5 5.00007C12.34 5.00007 12.18 4.93882 12.0581 4.81694L10.625 3.38382V9.37507H16.6163L15.1831 7.94194C14.9388 7.69757 14.9388 7.30257 15.1831 7.05819C15.4275 6.81382 15.8225 6.81382 16.0669 7.05819L18.5669 9.55819C18.8106 9.80132 18.8088 10.2007 18.5669 10.4419Z" />
    </svg>
  );
};

export default DragIcon;
