import { GetTopKQueryKey } from "../../../api/fetcher";
import TopValuesGraph from "../../Analytics/AnalyticsV2/Graphs/TopValuesGraph";
import useGetGraphState from "../useGetGraphsState";
import { GENERAL_SYNC_ID } from "../utils";
import { CommonGraphProps, GraphDisplayName, memoryFormatter } from "./utils";

const CustomWorkloadsUnidentifiedGraph = ({
  isMulticluster,
  tooltipTrigger,
  disabledZoom,
  updateActiveTooltips,
}: CommonGraphProps) => {
  const { date, setDate, numberOfElements, frozenAndClickableTab } = useGetGraphState();

  return (
    <TopValuesGraph
      title={GraphDisplayName.SmartPolicyWasteGraph}
      queryParams={{
        from: date.range ? undefined : date.from,
        to: date.range ? undefined : date.to,
        range: date.range,
        queryKey: GetTopKQueryKey.CustomWorkloadUnidentified,
      }}
      setDate={setDate}
      isMulticluster={isMulticluster}
      topK={numberOfElements}
      YAxisFormatter={memoryFormatter}
      tooltipTrigger={tooltipTrigger}
      disabledZoom={disabledZoom}
      updateActiveTooltips={updateActiveTooltips}
      enableCopyTextOnClick
      frozenTooltipType={frozenAndClickableTab}
      syncId={GENERAL_SYNC_ID}
    />
  );
};

export default CustomWorkloadsUnidentifiedGraph;
