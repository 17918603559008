import InstallTab from "./InstallTab";
import { TabType } from "./utils";

interface Props {
  selectedTab: TabType;
  setSelectedTab: (tab: TabType) => void;
  isInCloudAdmin?: boolean;
}

const TabsContainer = ({ selectedTab, setSelectedTab, isInCloudAdmin }: Props) => {
  return (
    <div className="flex items-center gap-2 h-[20px]">
      <InstallTab
        label={TabType.INSTALL}
        isSelected={selectedTab === TabType.INSTALL}
        onClick={() => setSelectedTab(TabType.INSTALL)}
      />
      {isInCloudAdmin && (
        <>
          <div className="h-[80%] w-[1px] bg-text-darkGray" />
          <InstallTab
            label={TabType.INVITE}
            isSelected={selectedTab === TabType.INVITE}
            onClick={() => setSelectedTab(TabType.INVITE)}
          />
        </>
      )}
    </div>
  );
};

export default TabsContainer;
