import { DataGrid, GridColDef, GridRenderCellParams, GridRowParams } from "@mui/x-data-grid";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { components } from "../../../api/schema";
import { useMainContext } from "../../../MainContext";
import { currencyFormatter } from "../../../utils/formatterUtils";
import { getDataGridSx } from "../../../utils/styleUtils";
import { BLOCKED_NODES_CATEGORY_ORDER } from "../../autoscalers/NodeFilterBar";
import { defaultTooltipTitle } from "../../autoscalers/NodesBreakdownTab";
import { getDisplayName } from "../../autoscalers/ScheduleBlockersGraph/utils";
import Tooltip from "../../Tooltip";
import NodeAction from "./NodeAction";
import { BlockedNodeReasons } from "./utils";

type ColParams = GridRenderCellParams<string, components["schemas"]["NodeGroupsBlockedNodeTableEntry"], string>;

interface CellTooltipProps {
  params: ColParams;
  children: React.ReactNode;
}
const CellTooltip = ({ params, children }: CellTooltipProps) => (
  <Tooltip maxWidth={770} title={defaultTooltipTitle(String(params.row.node))} className="max-w-full">
    {children}
  </Tooltip>
);

const getColumns = (): GridColDef[] => [
  {
    field: "node",
    headerName: "Node",
    flex: 2,
    renderCell: (params: ColParams) => {
      return (
        <CellTooltip params={params}>
          <p className="truncate">{params.value}</p>
        </CellTooltip>
      );
    },
  },
  {
    field: "savingsAvailable",
    width: 200,
    headerName: "Savings Available",
    align: "center",
    renderCell: (params: ColParams) => {
      const value = Number(params?.value ?? 0);
      const displayValue = currencyFormatter(value);
      return (
        <CellTooltip params={params}>
          <p className={clsx({ "text-guideline-darkGreen": value > 0 })}>{displayValue}</p>
        </CellTooltip>
      );
    },
  },
  {
    field: "blockedReason",
    flex: 1,
    minWidth: 450,
    headerName: "Blocked Reason",
    align: "center",
    renderCell: (params: ColParams) => {
      const displayValue = getDisplayName(String(params?.value ?? ""));
      return (
        <CellTooltip params={params}>
          <p className="truncate">{displayValue}</p>
        </CellTooltip>
      );
    },
  },
  {
    field: "category",
    flex: 1,
    minWidth: 280,
    headerName: "Action",
    align: "center",
    renderCell: (params: ColParams) => (
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <NodeAction params={params.row} />
      </div>
    ),
  },
];

type Data =
  | (components["schemas"]["NodeGroupsBlockedNodeTableEntry"] & { id: string; action: string | undefined })[]
  | undefined;
interface Props {
  data: Data;
  isLoading: boolean;
  pageSize: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setPageSize: (value: number) => void;
  rowsPerPageOptions: number[];
  rowCount: number;
}

const BlockedNodesTable = ({
  data,
  isLoading,
  pageSize,
  setPage,
  setPageSize,
  rowsPerPageOptions,
  rowCount,
}: Props) => {
  const [initialSortedData, setInitialSortedData] = useState<Data>(undefined);
  const { currentCluster } = useMainContext();
  const navigate = useNavigate();

  useEffect(() => {
    const sortedData = data?.sort((a, b) => {
      const aIndex = BLOCKED_NODES_CATEGORY_ORDER.indexOf(a.category as BlockedNodeReasons);
      const bIndex = BLOCKED_NODES_CATEGORY_ORDER.indexOf(b.category as BlockedNodeReasons);
      return aIndex - bIndex;
    });

    setInitialSortedData(sortedData);
  }, [data]);

  const onRowClick = (
    params: GridRowParams<
      components["schemas"]["NodeGroupsBlockedNodeTableEntry"] & { id: string; action: string | undefined }
    >
  ) => {
    navigate(`/nodes?currentClusterURLParam=${currentCluster ?? ""}&selectedNodeOverviewId=${params.row.node ?? ""}`);
  };

  return (
    <DataGrid
      sx={{
        ...getDataGridSx(),
      }}
      getCellClassName={() => "cursor-pointer"}
      onRowClick={onRowClick}
      rows={initialSortedData || []}
      columns={getColumns()}
      autoHeight={true}
      rowHeight={65}
      loading={isLoading}
      disableSelectionOnClick
      paginationMode="server"
      pageSize={pageSize}
      pagination={true}
      onPageChange={(newPage) => {
        setPage(newPage);
      }}
      onPageSizeChange={(newPageSize) => {
        setPageSize(newPageSize);
      }}
      initialState={{
        pagination: {
          pageSize: pageSize,
        },
      }}
      rowsPerPageOptions={rowsPerPageOptions}
      rowCount={rowCount}
    />
  );
};

export default BlockedNodesTable;
