import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import {
  getConfLogin,
  GetOverrideClusterNameQuery,
  UpdateClusterName,
  UpdateClusterNameParams,
} from "../../api/fetcher";
import { TOAST_SETTINGS } from "../../pages/Ingress/mutations/utils";
import { StringParam, useQueryParam } from "use-query-params";

const { queryKey } = GetOverrideClusterNameQuery();
const { queryFn } = UpdateClusterName();

const useOverrideClusterName = () => {
  const queryClient = useQueryClient();
  const [, setCurrentClusterURLParam] = useQueryParam("currentClusterURLParam", StringParam);
  const RAW_ELEMENT_HTML = `<div id="scaleopsIsBlocked" style="position: fixed; z-index: 999999; top: 0px; left: 0px; width: 100vw; height: 100vh; display: flex; flex-direction: column; align-items: center; justify-content: center; background-color: rgba(0, 0, 0, 0.8);"><div class="bg-white px-16 py-8 text-black flex flex-col justify-end items-center gap-4 rounded-lg"><p class="MuiTypography-root MuiTypography-body1 css-197pl4-MuiTypography-root">Dashboard is restarting...</p></div></div>`;

  return useMutation((params: UpdateClusterNameParams) => queryFn(params), {
    onMutate: async () => {
      await queryClient.cancelQueries([queryKey]);
    },
    onError: (error: Error) => {
      console.log(`Error: ${error.message}`);
      toast.error(`Error overriding cluster name: ${error.message}`);
    },
    onSuccess: (_, variables) => {
      toast.success(`Successfully changed cluster name to ${variables?.clusterName ?? ""}`, TOAST_SETTINGS);
      sessionStorage.removeItem("currentCluster");
      setCurrentClusterURLParam(variables?.clusterName ?? "");
      const newElement = document.createElement("div");
      newElement.innerHTML = RAW_ELEMENT_HTML;
      document.body.appendChild(newElement);
      const intervalId = setInterval(() => {
        getConfLogin()
          .queryFn()
          .then((response) => {
            console.log(response);
            document.body.removeChild(newElement);
            clearInterval(intervalId);
            window.location.reload();
          })
          .catch((error) => {
            console.log(error);
          });
      }, 3000);
    },
    onSettled: () => {
      queryClient.invalidateQueries([queryKey]).catch((error) => console.log(error));
    },
  });
};

export default useOverrideClusterName;
