import { Layout } from "react-grid-layout";

export type GirdLayout = Layout;

export type Elements = Record<string, React.ReactNode>;

export type LayoutEntry = {
  i: string; // elements comp id to be use
  x: number;
  y: number;
  width?: number;
  height?: number;
};

export type Cols = {
  lg: number;
  md: number;
  sm: number;
  xs: number;
  xxs: number;
};

export interface ChildImperativeHandle {
  triggerClick: () => void;
}

export enum LayoutStorageType {
  LocalStorage = "localStorage",
  Server = "server",
}
