import { AlertProps } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { components } from "../../../api/schema";
import ExploreEntityTooltip from "../../../utils/exploreEntityTooltip";
import { ExploreEntityMessage } from "../../autoscalers/NodeOverview/ExploreWorkloadsMessage";

export const isUnallocatedRow = (wlrow: components["schemas"]["UtilsWorkload"]) => {
  return wlrow.workloadName == "__unallocated__";
};

export const WorkloadOverviewTooltipMessage = (
  namespace?: string,
  workloadName?: string,
  type?: string,
  exploreEntityMessage?: ExploreEntityMessage,
  cluster?: string
) => (
  <>
    <div className={"flex flex-col gap-1 w-[300px]"}>
      <ExploreEntityTooltip
        namespace={namespace}
        workloadName={workloadName}
        type={type}
        cluster={cluster}
        exploreEntityMessage={exploreEntityMessage}
      />
    </div>
  </>
);

export const WORKLOAD_OVERVIEW_TOOLTIP_ENTER_DELAY = 1000;

export const getPercentageOfOptimizedReplicas = (
  params: GridRenderCellParams<string, components["schemas"]["UtilsWorkload"], string>
) => (params.row.replicas ? Math.round((params.row?.optimizedReplicas ?? 0 / params.row.replicas) * 100) : 0);

export type SnackbarProps =
  | (Pick<AlertProps, "severity"> & {
      children: { message?: string };
    })
  | null;
