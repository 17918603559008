import { Typography } from "@mui/material";
import clsx from "clsx";
import ScaleOpsSpan from "./ScaleOpsSpan";
import Tooltip from "./Tooltip";

const NewChipFeatureTooltipContent = () => {
  return (
    <p>
      <ScaleOpsSpan className="mt-[4px]" /> new feature.
    </p>
  );
};

interface Props {
  wrapperClassName?: string;
  chipClassName?: string;
  isTooltipDisabled?: boolean;
}

const NewChip = ({ wrapperClassName, chipClassName, isTooltipDisabled }: Props) => {
  return (
    <Tooltip title={<NewChipFeatureTooltipContent />} className={wrapperClassName} disabled={isTooltipDisabled}>
      <Typography
        className={clsx(
          chipClassName,
          "text-main-steelBlue border-main-steelBlue bg-white border-2 px-2 py-1 rounded-lg flex justify-center items-center shadow-sm hover:shadow-none "
        )}
        fontSize={12}
        fontWeight={500}
        style={{
          height: 18,
        }}
      >
        New
      </Typography>
    </Tooltip>
  );
};

export default NewChip;
