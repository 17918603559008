import { Typography } from "@mui/material";
import clsx from "clsx";
import ScaleopsIcon from "../../Icons/ScaleopsIcon";
import { containerClassName } from "./utils";

const ICON_SIZE = "3.625rem";

interface Props {
  companyName?: string;
}

const TopPurpleBanner = ({ companyName }: Props) => {
  return (
    <div className={clsx(containerClassName, "bg-primary-lightPurpleBlue border-[0.1875rem] border-black relative")}>
      <ScaleopsIcon width={ICON_SIZE} height={ICON_SIZE} />
      <div>
        {companyName && (
          <Typography variant="h5" fontWeight={700}>
            Welcome {companyName[0].toUpperCase() + companyName.slice(1)}!
          </Typography>
        )}
        <Typography variant={companyName ? "h6" : "h4"}>
          Install ScaleOps in <b>less than 2 minutes</b>
        </Typography>
      </div>
    </div>
  );
};

export default TopPurpleBanner;
