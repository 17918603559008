import { Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { ObjectParam, useQueryParam } from "use-query-params";
import { GetWorkloadEventsGraph, GetWorkloadEventsGraphResponse } from "../../../../api/fetcher";
import WorkloadEvents from "../../../../components/WorkloadStatusByNamespaceSection/WorkloadEvents";
import EventsIcon from "../../../../Icons/EventsIcon";
import { MOCKED_HISTORY_EVENTS_CHART_DATA } from "../../../../utils/developmentFeatureFlags";
import SelectViewPeriod from "../SelectViewPeriod";
import { POLICY_TUNING_DATES_URL_PARAM, useViewPeriodQueryParams } from "../utils";
import { EventChartPoint, exampleData } from "./utils";
import WorkloadHistoryEventChart from "./WorkloadHistoryEventChart";

const HAS_EVENT_TABLE = false;
const { queryKey, queryFn } = GetWorkloadEventsGraph();

interface Props {
  kind: string;
  name: string;
  namespace: string;
  recommendationName: string;
}

const WorkloadHistoryEventsContainer = ({ kind, name, namespace, recommendationName }: Props) => {
  const [chartData, setChartData] = useState<EventChartPoint[]>([]);
  const [urlDates] = useQueryParam(POLICY_TUNING_DATES_URL_PARAM, ObjectParam);
  const [selectedViewPeriod] = useViewPeriodQueryParams();

  const { data, isLoading } = useQuery<GetWorkloadEventsGraphResponse, Error>({
    queryKey: [queryKey, kind, name, namespace, urlDates?.from, urlDates?.to, selectedViewPeriod],
    queryFn: () =>
      queryFn({
        kind,
        name,
        namespace,
        from: dayjs(
          urlDates?.from ? Number(urlDates.from) * 1000 : dayjs.utc().subtract(Number(selectedViewPeriod ?? 1), "hour")
        ).unix(),
        to: dayjs(urlDates?.to ? Number(urlDates.to) * 1000 : dayjs.utc()).unix(),
      }),
  });

  useEffect(() => {
    if (data) {
      const transformedData: EventChartPoint[] =
        data.eventGraphPoints?.map((point) => {
          return {
            timestamp: Number(point.timestamp),
            podOptimizedCount: point.podOptimizedCount,
            podEvictionCount: point.podEvictionCount,
            optimizationBlockedCount: point.optimizationBlockedCount,
            autoHealingCount: point.autoHealingCount,
            burstReactionCount: point.burstReactionCount,
            recommendationCreationCount: point.recommendationCreationCount,
            isAuto: point.isAuto ? 1 : undefined,
            data: {
              podOptimized: {
                cpuBefore: point.data?.podOptimized?.cpuBefore ?? 0,
                cpuAfter: point.data?.podOptimized?.cpuAfter ?? 0,
                memoryBefore: point.data?.podOptimized?.memoryBefore ?? 0,
                memoryAfter: point.data?.podOptimized?.memoryAfter ?? 0,
              },
              optimizationBlocked: {
                message: point.data?.optimizationBlocked?.message,
              },
              autoHealing: {
                cpuStressNodesCount: point.data?.autoHealing?.cpuStressNodesCount,
                oom: point.data?.autoHealing?.oom,
              },
              burst: {
                cpuCount: point.data?.burst?.cpuCounter,
                memoryCount: point.data?.burst?.memoryCounter,
              },
              recommendationCreationCount: point.recommendationCreationCount,
              podEvictionCount: point.podEvictionCount,
              podOptimizedCount: point.podOptimizedCount,
              burstReactionCount: point.burstReactionCount,
              autoHealingCount: point.autoHealingCount,
            },
          };
        }) ?? [];

      setChartData(transformedData);
    }
  }, [data]);

  return (
    <div className={"flex flex-col gap-5"}>
      <div className="flex border border-border rounded p-4 items-center gap-10">
        <div className="flex gap-10 w-full">
          <EventsIcon width={40} height={40} />
          <Typography variant="body2">
            <b>History Events</b>
            <p>Explore your workload events</p>
          </Typography>
        </div>
        <div className="h-16 w-[1px] bg-border" />
        <SelectViewPeriod />
      </div>
      <WorkloadHistoryEventChart
        data={MOCKED_HISTORY_EVENTS_CHART_DATA ? exampleData : chartData}
        isLoading={isLoading}
      />
      {HAS_EVENT_TABLE && <WorkloadEvents namespace={namespace} name={recommendationName} hasTopSection={false} />}
    </div>
  );
};

export default WorkloadHistoryEventsContainer;
