import { IconProps } from "./utils/utils";

const PodDisruptionIcon = (props: IconProps) => {
  const { height = 20, width = 20, className, fill } = props;
  return (
    <svg height={height} width={width} className={className} fill="currentColor" viewBox="0 0 20 20" {...props}>
      <circle cx="10" cy="10" r="10" fill={fill} fill-opacity="0.35" />
      <g clip-path="url(#clip0_464_11)">
        <path
          d="M16.5805 11.5342L15.2937 10.528C15.31 10.3728 15.324 10.1914 15.324 9.99949C15.324 9.80756 15.3106 9.62616 15.2937 9.47099L16.5817 8.46416C16.8209 8.27516 16.8862 7.93974 16.7339 7.66034L15.3958 5.34506C15.2528 5.08374 14.9396 4.94956 14.6217 5.06681L13.1033 5.67639C12.8122 5.46639 12.5071 5.28906 12.1915 5.14731L11.9605 3.53674C11.9226 3.23106 11.6566 3.00006 11.3422 3.00006H8.65886C8.34443 3.00006 8.07903 3.23106 8.04168 3.53206L7.81011 5.14849C7.50443 5.28616 7.20403 5.46116 6.90011 5.67756L5.37761 5.06624C5.09233 4.95599 4.75108 5.07964 4.60933 5.33981L3.26943 7.65799C3.11136 7.92516 3.17668 8.27224 3.42111 8.46591L4.70793 9.47216C4.68751 9.66874 4.67761 9.83966 4.67761 10.0001C4.67761 10.1605 4.68753 10.3314 4.70793 10.5286L3.41993 11.5354C3.18076 11.725 3.11601 12.0604 3.26826 12.3392L4.60643 14.6545C4.74936 14.9152 5.05968 15.0506 5.38051 14.9327L6.89893 14.3232C7.18943 14.5326 7.49451 14.7099 7.81011 14.8522L8.04111 16.4622C8.07901 16.7691 8.34443 17.0001 8.65943 17.0001H11.3428C11.6572 17.0001 11.9232 16.7691 11.9605 16.4681L12.1921 14.8522C12.4978 14.714 12.7976 14.5396 13.1021 14.3226L14.6246 14.9339C14.6981 14.9625 14.7745 14.9771 14.8533 14.9771C15.0796 14.9771 15.2878 14.8534 15.3928 14.6609L16.7368 12.3334C16.8862 12.0604 16.8208 11.725 16.5805 11.5342ZM10.0005 12.3334C8.71368 12.3334 7.66718 11.2869 7.66718 10.0001C7.66718 8.71324 8.71368 7.66674 10.0005 7.66674C11.2873 7.66674 12.3338 8.71324 12.3338 10.0001C12.3338 11.2869 11.2873 12.3334 10.0005 12.3334Z"
          fill="white"
        />
      </g>
    </svg>
  );
};

export default PodDisruptionIcon;
