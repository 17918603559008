import { Typography } from "@mui/material";
import NewChip from "../../components/NewChip";
import Tooltip from "../../components/Tooltip";
import BatchIcon from "../../Icons/BatchIcon";
import CustomIcon from "../../Icons/CustomIcon";
import CustomizedIcon from "../../Icons/CustomizedIcon";
import DevelopmentIcon from "../../Icons/DevelopmentIcon";
import DynamicIcon from "../../Icons/DynamicIcon";
import HibernateIcon from "../../Icons/HibernateIcon";
import HighAvailabilityIcon from "../../Icons/HighAvailabilityIcon";
import ProductionIcon from "../../Icons/ProductionIcon";
import ScheduleIcon from "../../Icons/ScheduleIcon";
import UpAndDownCircleIcon from "../../Icons/UpAndDownCircleIcon";
import { ScaleOpsProduct } from "../../utils/typesUtils";
import { PolicyDescription, PolicyType } from "./utils";

interface Props {
  policyName: string;
  isBuiltIn?: boolean | undefined;
  isScheduled?: boolean;
  onClick?: () => void;
  scaleOpsProduct?: ScaleOpsProduct;
}

const getPolicyIcon = (policyName: string) => {
  switch (policyName) {
    case PolicyType.Cost:
      return <DevelopmentIcon />;
    case PolicyType.Dynamic:
      return <DynamicIcon />;
    case PolicyType.Hibernate:
      return <HibernateIcon />;
    case PolicyType.HighAvailability:
      return <HighAvailabilityIcon />;
    case PolicyType.Production:
      return <ProductionIcon />;
    case PolicyType.ProductionHpa:
      return <UpAndDownCircleIcon width={19} />;
    case PolicyType.Batch:
      return <BatchIcon />;
    case PolicyType.System:
      return <HighAvailabilityIcon />;
    case PolicyType.WeeklyOptimization:
      return <ScheduleIcon />;
    default:
      return <CustomIcon />;
  }
};

const PolicyName = ({ policyName, isBuiltIn, isScheduled, onClick, scaleOpsProduct }: Props) => {
  let description: React.ReactNode = "";

  switch (true) {
    case isBuiltIn && scaleOpsProduct === ScaleOpsProduct.HPA && policyName === PolicyType.Production:
      description = PolicyDescription[PolicyType.ProductionHpa];
      break;
    case isBuiltIn:
      description = PolicyDescription[policyName as PolicyType];
      break;
    default:
      description = PolicyDescription[PolicyType.CustomPolicy];
      break;
  }

  let icon = <CustomizedIcon />;
  if (isBuiltIn) icon = getPolicyIcon(policyName);

  return (
    <Typography variant="body2" className="w-full cursor-pointer" onClick={onClick}>
      <div className="flex gap-2">
        {isScheduled && !isBuiltIn && <ScheduleIcon />}
        <div>{icon}</div>
        <p className="flex gap-2">{policyName}</p>
        {policyName == PolicyType.WeeklyOptimization && <NewChip />}
      </div>
      <Tooltip
        title={
          <>
            <b>{policyName}:</b> {description}
          </>
        }
      >
        <div className="max-w-full text-ellipsis truncate text-text-darkGray">
          <Typography variant="caption" className="pl-[0.5px]">
            {description}
          </Typography>
        </div>
      </Tooltip>
    </Typography>
  );
};

export default PolicyName;
