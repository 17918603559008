import { GetTopKQueryKey } from "../../../api/fetcher";
import TopKMultiLineChart from "../../Analytics/AnalyticsV2/Graphs/TopKMultiLineChart";
import useGetGraphState from "../useGetGraphsState";
import { GENERAL_SYNC_ID } from "../utils";
import { CommonGraphProps, SCALEOPS_HEALTH_NUMBER_OF_ELEMENTS } from "./utils";

const VersionGraph = ({ isMulticluster, tooltipTrigger, disabledZoom, updateActiveTooltips }: CommonGraphProps) => {
  const { date, setDate, numberOfElements, frozenAndClickableTab } = useGetGraphState();

  return (
    <TopKMultiLineChart
      title={"Version"}
      dotted
      queryParams={{
        from: date.range ? undefined : date.from,
        to: date.range ? undefined : date.to,
        range: date.range,
        queryKey: GetTopKQueryKey.Version,
      }}
      setDate={setDate}
      isMulticluster={isMulticluster}
      YAxisDomain={[0, 2]}
      YAxisType={"category"}
      tooltipTrigger={tooltipTrigger}
      disabledZoom={disabledZoom}
      updateActiveTooltips={updateActiveTooltips}
      enableCopyTextOnClick
      YAxisFormatter={() => ""}
      yTickLine={false}
      frozenTooltipType={frozenAndClickableTab}
      syncId={GENERAL_SYNC_ID}
      topK={isMulticluster ? numberOfElements : SCALEOPS_HEALTH_NUMBER_OF_ELEMENTS}
      hasLegend
    />
  );
};

export default VersionGraph;
