import { useMemo } from "react";
import CreateAndDuplicateNewDashBoard from "../../components/Dashboard/CreateAndDuplicateNewDashBoard";
import GridLayout from "../../components/GridLayout/GridLayout";
import useGetLayout from "../../components/GridLayout/useGetLayout";
import { LayoutStorageType } from "../../components/GridLayout/utils";
import { getGraphElements } from "./Graphs/utils";
import TroubleshootTopSection from "./TroubleshootTopSection";
import useGetGraphState from "./useGetGraphsState";
import { CustomDashboard, READ_ONLY_LAYOUTS, TROUBLESHOOT_DASHBOARD_CATEGORY } from "./utils";

interface Props {
  isMultiCluster?: boolean;
}

const TroubleshootContainer = ({ isMultiCluster }: Props) => {
  const { tooltipTrigger, disabledZoom, updateActiveTooltips, onDragStart, onDragStop } = useGetGraphState();

  const elements = useMemo(
    () =>
      getGraphElements({
        isMulticluster: isMultiCluster,
        tooltipTrigger,
        updateActiveTooltips,
        disabledZoom,
      }),
    [isMultiCluster, tooltipTrigger, updateActiveTooltips, disabledZoom]
  );

  const {
    layout,
    setLayout,
    selectedElement,
    setSelectedElement,
    saveDashboard,
    isReadOnly,
    wasLayoutChanged,
    isDuplicateDialogueOpen,
    setIsDuplicateDialogueOpen,
    isCreateDialogueOpen,
    setIsCreateDialogueOpen,
    existingDashboardNames,
    createDashboard,
    deleteDashboard,
    selectedDashboard,
  } = useGetLayout({
    defaultDashboard: CustomDashboard.Performance,
    layoutStorage: LayoutStorageType.Server,
    dashboardCategory: TROUBLESHOOT_DASHBOARD_CATEGORY,
    readOnlyLayouts: READ_ONLY_LAYOUTS,
  });

  return (
    <>
      <div className="flex flex-col gap-2 min-w-[1280px]">
        <TroubleshootTopSection
          selectedElements={selectedElement}
          setSelectedElements={setSelectedElement}
          saveDashboard={saveDashboard}
          isReadOnly={isReadOnly}
          wasLayoutChanged={wasLayoutChanged}
          setIsDuplicateDialogueOpen={setIsDuplicateDialogueOpen}
          setIsCreateDialogueOpen={setIsCreateDialogueOpen}
          existingDashboardNames={existingDashboardNames}
          isMultiCluster={isMultiCluster}
          deleteDashboard={deleteDashboard}
        />
        <GridLayout
          layoutId={selectedDashboard}
          elements={elements}
          numberOfColumns={2}
          layout={layout}
          setLayout={setLayout}
          wrapperClassName="min-h-[calc(100vh-200px)] relative"
          onDragStart={onDragStart}
          onDragStop={onDragStop}
        />
      </div>
      <CreateAndDuplicateNewDashBoard
        isOpen={!!isDuplicateDialogueOpen || !!isCreateDialogueOpen}
        isCreateDialogueOpen={!!isCreateDialogueOpen}
        setIsOpen={(isOpen) => {
          setIsDuplicateDialogueOpen(isOpen);
          setIsCreateDialogueOpen(isOpen);
        }}
        existingDashboardNames={[...existingDashboardNames, ...Object.values(CustomDashboard)]}
        onSubmit={createDashboard}
        handleClose={() => {
          setIsDuplicateDialogueOpen(false);
          setIsCreateDialogueOpen(false);
        }}
      />
    </>
  );
};

export default TroubleshootContainer;
