import { Typography } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { UnevictableRollout, UnevictableRolloutParams } from "../../api/fetcher";

const unevictableRollout = UnevictableRollout();

const useUnevictableRollout = () => {
  return useMutation((params: UnevictableRolloutParams) => unevictableRollout.queryFn(params), {
    onError: (error: Error) => {
      console.log(`Error: ${error.message}`);
      toast.error("Failed to perform rollout");
    },
    onSuccess: (data) => {
      const skippedWorkloads = data.skippedWorkloads;
      if (skippedWorkloads && !!skippedWorkloads.length) {
        toast.warn(
          <div>
            <Typography variant="body2">Some workloads were skipped during rollout</Typography>
            {skippedWorkloads && (
              <div>
                <Typography variant="caption">The following workloads were skipped:</Typography>
                <ul className="list-disc pl-4 max-h-[126px] overflow-y-auto">
                  {skippedWorkloads.map((workload) => (
                    <li className="text-[0.625rem]" key={workload}>
                      {workload}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>,
          {
            icon: false,
          }
        );
      } else {
        toast.success("Rollout performed successfully");
      }
    },
  });
};

export default useUnevictableRollout;
