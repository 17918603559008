import { Typography } from "@mui/material";
import Tooltip from "../../Tooltip";
import { WORKLOAD_OVERVIEW_TOOLTIP_ENTER_DELAY } from "../../WorkloadStatusByNamespaceSection/Columns/GridColumnsUtils";
import ExploreWorkloadsMessage, { ExploreEntityMessage } from "./ExploreWorkloadsMessage";

interface Props {
  entity: ExploreEntityMessage;
  children?: React.ReactNode;
}

const ExploreWorkloadsTooltipWrapper = ({ entity, children }: Props) => (
  <Typography variant="body2" className="fullCellTooltipContent">
    <Tooltip
      title={<ExploreWorkloadsMessage message={entity} />}
      maxWidth={500}
      className="w-full h-full flex justify-center items-center gap-2"
      enterDelay={WORKLOAD_OVERVIEW_TOOLTIP_ENTER_DELAY}
    >
      {children}
    </Tooltip>
  </Typography>
);

export default ExploreWorkloadsTooltipWrapper;
