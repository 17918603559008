import { Input, SxProps, Typography } from "@mui/material";
import clsx from "clsx";
import { FieldHookConfig, useField } from "formik";
import React, { useState } from "react";
import HideIcon from "../../Icons/HideIcon";
import InfoIcon from "../../Icons/InfoIcon";
import ShowIcon from "../../Icons/ShowIcon";
import Tooltip from "../Tooltip";
import ErrorLabel from "./ErrorLabel";

const ICON_CLASS_NAME = "text-text-darkGray group-hover:text-black cursor-pointer";

type Props = {
  label?: React.ReactNode;
  info?: React.ReactNode;
  className?: string;
  errorLabelClassName?: string;
  inputWidth?: number;
  disableChange?: boolean;
  sx?: SxProps;
  showHidePasswordToggle?: boolean;
} & FieldHookConfig<string | number> &
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

const FormInput = ({
  label,
  info,
  className,
  errorLabelClassName,
  inputWidth,
  disableChange,
  sx,
  showHidePasswordToggle,
  ...props
}: Props) => {
  const [type, setType] = useState<string>(props.type ?? "text");
  const [field, meta] = useField(props);

  return (
    <div
      className={clsx(className, "flex flex-col gap-1 w-fit relative group", {
        "opacity-29": props.disabled,
      })}
    >
      <Typography
        variant="body2"
        className={clsx({
          "text-text-lightBlack flex items-center gap-1": !props.disabled,
          "text-text-disable": props.disabled,
        })}
      >
        {label}
        {info && (
          <Tooltip title={info} maxWidth={500}>
            <InfoIcon width={14} height={14} />
          </Tooltip>
        )}
      </Typography>
      <Input
        {...field}
        onChange={
          disableChange
            ? undefined
            : (e) => {
                field.onChange(e);
              }
        }
        type={type}
        disabled={props.disabled}
        sx={{
          caretColor: disableChange ? "transparent" : undefined,
          height: "34px",
          width: inputWidth,
          border: clsx({
            "1px solid #c4c4c4": !props.disabled,
            "1px solid #E5E7ED": props.disabled,
          }),
          "&:before, &:after": {
            borderBottom: "none",
          },
          "&:hover:not(.Mui-disabled):before": {
            borderBottom: "none",
          },
          padding: "0 10px",
          borderRadius: "4px",
          "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
            "-webkit-appearance": "none",
            margin: 0,
          },
          "&.Mui-disabled:before": {
            borderBottom: "none",
          },
          paddingRight: showHidePasswordToggle ? "34px" : "10px",
          ...sx,
        }}
        placeholder={props.placeholder}
      />
      {meta.touched && meta.error ? (
        <ErrorLabel label={meta.error} className={errorLabelClassName} hasLabel={!!label} />
      ) : null}
      {showHidePasswordToggle && (
        <div
          onClick={() => {
            setType(type === "password" ? "text" : "password");
          }}
          className="absolute right-0 bottom-0 flex items-center gap-1 cursor-pointer p-[7px]"
        >
          {type === "password" ? <HideIcon className={ICON_CLASS_NAME} /> : <ShowIcon className={ICON_CLASS_NAME} />}
        </div>
      )}
      {disableChange && <div className="w-full h-[60px] absolute opacity-0" />}
    </div>
  );
};

export default FormInput;
