import { GetTopKQueryKey } from "../../../api/fetcher";
import TopKMultiLineChart from "../../Analytics/AnalyticsV2/Graphs/TopKMultiLineChart";
import useGetGraphState from "../useGetGraphsState";
import { GENERAL_SYNC_ID } from "../utils";
import {
  CommonGraphProps,
  legendName,
  memoryFormatter,
  multiClusterLegendName,
  SCALEOPS_HEALTH_NUMBER_OF_ELEMENTS,
} from "./utils";

const MemoryRequestsGraph = ({
  isMulticluster,
  tooltipTrigger,
  disabledZoom,
  updateActiveTooltips,
}: CommonGraphProps) => {
  const { date, setDate, numberOfElements, frozenAndClickableTab } = useGetGraphState();

  return (
    <TopKMultiLineChart
      title={"Memory requests"}
      queryParams={{
        from: date.range ? undefined : date.from,
        to: date.range ? undefined : date.to,
        range: date.range,
        queryKey: GetTopKQueryKey.ScaleOpsMemoryRequests,
      }}
      setDate={setDate}
      isMulticluster={isMulticluster}
      YAxisFormatter={memoryFormatter}
      tooltipTrigger={tooltipTrigger}
      disabledZoom={disabledZoom}
      updateActiveTooltips={updateActiveTooltips}
      enableCopyTextOnClick
      hasLegend={true}
      renderNameFunction={isMulticluster ? multiClusterLegendName : legendName}
      frozenTooltipType={frozenAndClickableTab}
      syncId={GENERAL_SYNC_ID}
      topK={isMulticluster ? numberOfElements : SCALEOPS_HEALTH_NUMBER_OF_ELEMENTS}
    />
  );
};

export default MemoryRequestsGraph;
