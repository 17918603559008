import { components } from "../../../api/schema";
import FormSingleSelect, { RenderValueType } from "../../../components/formComponents/FormSingleSelect";
import FormTitleAndDescription from "../../../components/formComponents/FormTitleAndDescription";
import * as policyUtils from "../../../utils/policyUtils";
import { shouldDisableDefaultPolicyInputs } from "../utils";
import { IsSnapshotServer } from "../../../utils/FeaturesHelper";

const options: [string, number][] = [
  ["5%", 5],
  ["10%", 10],
  ["15%", 15],
  ["20%", 20],
  ["25%", 25],
  ["30%", 30],
  ["35%", 35],
  ["40%", 40],
  ["50%", 50],
];

interface Props {
  isCustomizedPolicy?: boolean;
  defaultPolicy?: components["schemas"]["V1alpha1Policy"] | undefined;
}

const ChangeThresholds = ({ isCustomizedPolicy, defaultPolicy }: Props) => {
  const defaultChangeThresholdScaleDown = isCustomizedPolicy
    ? policyUtils.policyMinChangeScaleDownCpu(defaultPolicy) * 100
    : undefined;

  const defaultChangeThresholdScaleUp = isCustomizedPolicy
    ? policyUtils.policyMinChangeScaleUpCpu(defaultPolicy) * 100
    : undefined;

  return (
    <div className="flex flex-col gap-4">
      <FormTitleAndDescription
        title="Actively enforce optimization by thresholds"
        description="Define the thresholds by which ScaleOps will evict pods to update their resources."
      />
      <div className="flex flex-wrap gap-4">
        <FormSingleSelect
          label="Scale down (%)"
          name="changeThresholdScaleDown"
          options={options}
          areOptionsDisabled={!IsSnapshotServer() && !isCustomizedPolicy}
          disabled={!isCustomizedPolicy && shouldDisableDefaultPolicyInputs}
          defaultValue={defaultChangeThresholdScaleDown}
          renderValueType={RenderValueType.Percentage}
        />
        <FormSingleSelect
          label="Scale up (%)"
          name="changeThresholdScaleUp"
          options={options}
          areOptionsDisabled={!IsSnapshotServer() && !isCustomizedPolicy}
          disabled={!isCustomizedPolicy && shouldDisableDefaultPolicyInputs}
          defaultValue={defaultChangeThresholdScaleUp}
          renderValueType={RenderValueType.Percentage}
        />
      </div>
    </div>
  );
};

export default ChangeThresholds;
