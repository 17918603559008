import { CircularProgress } from "@mui/material";
import prettyBytes from "pretty-bytes";
import { useEffect, useState } from "react";
import { GetNodeGroupsNodesResponse } from "../../../api/fetcher";
import { SCALEOPS_COLORS } from "../../../colors";
import { EventPoint } from "../../../pages/Overview/PolicyTuning/Diagnostics/utils";
import { adjustedDayjs } from "../../../utils/dateAndTimeUtils";
import { RequestAndUsageByTimestampKey } from "../../componentUtils/overviewUtils";
import CustomLegend from "../../CustomLegend";
import { CustomTooltipPayload } from "../../WorkloadStatusByNamespaceSection/ResourceOverTime/CustomTooltip";
import FomoChart from "../../WorkloadStatusByNamespaceSection/ResourceOverTime/FomoChart";
import Styles from "../../WorkloadStatusByNamespaceSection/ResourceOverTime/Styles";
import { ChartComponents } from "../../WorkloadStatusByNamespaceSection/ResourceOverTime/utils";

const FOMO_UNIQUE_ID = "resources-over-time-cpu-node-overview";

const CHART_COMPONENTS = [
  ChartComponents.Usage,
  ChartComponents.Request,
  ChartComponents.Waste,
  ChartComponents.Allocatable,
  ChartComponents.WasteFromAllocatableToRequest,
];

enum TimeFormat {
  Date = "DD/MMM",
  DateAndTime = "DD/MMM HH:mm",
}

const getWasteValue = (payload: CustomTooltipPayload) => payload.allocatable - payload.request;

interface Props {
  from: number;
  to: number;
  setEmptyEventArray: React.Dispatch<React.SetStateAction<EventPoint[] | undefined>>;
  selectedViewPeriod: string;
  data?: GetNodeGroupsNodesResponse;
  isLoading?: boolean;
  error?: Error | null;
  isFetching?: boolean;
}

const ResourcesOverTimeCharts = ({
  from,
  to,
  setEmptyEventArray,
  selectedViewPeriod,
  data,
  isLoading,
  error,
  isFetching,
}: Props) => {
  const [cpuData, setCpuData] = useState<RequestAndUsageByTimestampKey[]>([]);
  const [memoryData, setMemoryData] = useState<RequestAndUsageByTimestampKey[]>([]);
  const [selectedChartComponents, setSelectedChartComponents] = useState<ChartComponents[]>(CHART_COMPONENTS);
  const [timeFormat, setTimeFormat] = useState<TimeFormat>(TimeFormat.DateAndTime);

  useEffect(() => {
    if (from && to) {
      const diffInDays = (to - from) / 60 / 60 / 24;
      if (diffInDays >= 7) {
        setTimeFormat(TimeFormat.Date);
      } else {
        setTimeFormat(TimeFormat.DateAndTime);
      }
    }
  }, [from, to]);

  useEffect(() => {
    if (data) {
      /**
       * Parse the CPU data
       */
      data.cpu &&
        setCpuData(
          data.cpu.map((item) => {
            const timestamps = item.timestamp;
            const usage = Math.round((item.values?.usage || 0) * 100) / 100;
            const allocatable = Math.round((item.values?.allocatable || 0) * 100) / 100;
            const request = Math.round((item.values?.request || 0) * 100) / 100;

            return {
              timestamps,
              usage,
              allocatable,
              request,
            };
          })
        );

      /**
       * Parse the Memory data
       */
      data.memory &&
        setMemoryData(
          data.memory.map((item) => {
            const timestamps = item.timestamp;
            const usage = item.values?.usage || 0;
            const allocatable = item.values?.allocatable || 0;
            const request = item.values?.request || 0;

            return {
              timestamps,
              usage,
              allocatable,
              request,
            };
          })
        );

      setEmptyEventArray &&
        data.cpu &&
        setEmptyEventArray(
          data.cpu.map((dataPoint) => {
            return {
              timestamp: adjustedDayjs(dataPoint.timestamp).unix(),
              autoHealing: 0,
              cpuThrottling: 0,
              eviction: 0,
              highUtilizationNodes: 0,
              oomEvent: 0,
              hasEvents: true,
            };
          })
        );
    }
  }, [data, timeFormat]);

  if (error) {
    console.error("Error while fetching node overview data:", error);
  }

  if (isLoading && !data) {
    return (
      <div className="w-full border border-border rounded-lg pt-2 pb-6 relative flex items-center justify-center h-[350px]">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="w-full border border-border rounded-lg pt-2 pb-6">
      <div className="h-[300px] w-full flex relative">
        {isFetching && (
          <div className="absolute w-full h-full bg-white flex justify-center items-center z-10 opacity-50">
            <CircularProgress />
          </div>
        )}
        <FomoChart
          title="CPU over time"
          uniqueId={FOMO_UNIQUE_ID}
          data={cpuData}
          selectedChartComponents={selectedChartComponents}
          getWasteValue={getWasteValue}
          includedChartComponents={CHART_COMPONENTS}
          timeFormat={timeFormat}
          viewPeriod={selectedViewPeriod}
        />
        <FomoChart
          title="Memory over time"
          uniqueId={FOMO_UNIQUE_ID}
          data={memoryData}
          selectedChartComponents={selectedChartComponents}
          getWasteValue={getWasteValue}
          includedChartComponents={CHART_COMPONENTS}
          valueFormatter={(value) =>
            prettyBytes(Number(value) || 0.0, {
              bits: false,
              binary: true,
            })
          }
          timeFormat={timeFormat}
          viewPeriod={selectedViewPeriod}
        />
      </div>
      <CustomLegend<ChartComponents>
        selectedChartComponents={selectedChartComponents}
        setSelectedChartComponents={setSelectedChartComponents}
        componentStyle={{
          [ChartComponents.Usage]: {
            color: Styles.podsAvgUsage.stroke,
          },
          [ChartComponents.Request]: {
            color: "#EAB832",
          },
          [ChartComponents.Waste]: {
            color: "rgb(255 169 164)",
          },
          [ChartComponents.Allocatable]: {
            color: SCALEOPS_COLORS.main.orange,
          },
        }}
        isDashedFnc={(key: string) => key.includes("waste")}
        ChartComponents={{
          [ChartComponents.Usage]: ChartComponents.Usage,
          [ChartComponents.Request]: ChartComponents.Request,
          [ChartComponents.Waste]: ChartComponents.Waste,
          [ChartComponents.Allocatable]: ChartComponents.Allocatable,
        }}
        className="-mt-1"
      />
    </div>
  );
};

export default ResourcesOverTimeCharts;
