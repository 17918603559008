import { Typography } from "@mui/material";
import useGetUserRole from "./useGetUserRole";
import Tooltip from "../components/Tooltip";

type Props = {
  groups?: string[] | undefined;
  name?: string | undefined;
  ssoName?: string;
};

const INFO_TOOLTIP_SHOW_GROUPS = false;

const mapTokenGroups = (groups: string[] | undefined) => {
  const viewers: string[] = [];
  const operators: string[] = [];
  groups?.forEach((group) => {
    if (group.split("-")[0].toLowerCase() === "operators") operators.push(group.split("-").slice(1).join("-"));
    else if (group.split("-")[0].toLowerCase() === "viewers") viewers.push(group.split("-").slice(1).join("-"));
  });
  return (
    <>
      {operators.length > 0 && (
        <>
          <b>Operator namespaces:</b>
          <ul>
            {operators.map((operator) => (
              <li>&nbsp;&nbsp;{operator}</li>
            ))}
          </ul>
        </>
      )}
      <br />
      {viewers.length > 0 && (
        <>
          <b>Viewer namespaces:</b>
          <ul>
            {viewers.map((viewer) => (
              <li>&nbsp;&nbsp;{viewer}</li>
            ))}
          </ul>
        </>
      )}
    </>
  );
};

const UserRole = ({ groups, name, ssoName }: Props) => {
  const { userRole } = useGetUserRole();

  if (!userRole) return null;

  return (
    <Typography variant="caption" className="opacity-50 flex flex-col">
      Role: {userRole}
      {name && ssoName != "simpleAuth" && (
        <Tooltip
          className={"truncate"}
          placement={"right"}
          maxWidth={500}
          title={
            <div className="flex gap-1">
              <b>User:</b>
              {name}
              {INFO_TOOLTIP_SHOW_GROUPS && groups && mapTokenGroups(groups)}
            </div>
          }
        >
          User: {name}
        </Tooltip>
      )}
    </Typography>
  );
};

export default UserRole;
