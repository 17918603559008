import { Typography } from "@mui/material";
import FormSwitch from "../../../components/formComponents/FormSwitch";
import FormTitleAndDescription from "../../../components/formComponents/FormTitleAndDescription";
import { shouldDisableDefaultPolicyInputs } from "../utils";
import { IsSnapshotServer } from "../../../utils/FeaturesHelper";

interface Props {
  isCustomizedPolicy?: boolean;
}

const BinPackUnEvictable = ({ isCustomizedPolicy }: Props) => {
  return (
    <div className="flex flex-col gap-4">
      <FormTitleAndDescription
        title="Bin-pack unevictable pods"
        description="Automatically bin-pack unevictable pods to allow nodes to be more consolidated, further reducing costs."
      />
      <div className="flex items-center">
        <FormSwitch
          name="binPackUnEvictablePods"
          disabled={!isCustomizedPolicy && shouldDisableDefaultPolicyInputs}
          disableChange={!IsSnapshotServer() && !isCustomizedPolicy}
        />
        <Typography variant="body2"></Typography>
      </div>
    </div>
  );
};
export default BinPackUnEvictable;
