import { IconProps } from "./utils/utils";

const MonitorIcon = ({ width = 20, height = 20, className }: IconProps) => {
  return (
    <svg viewBox="0 0 24 24" height={height} width={width} className={className} fill="currentColor">
      <g>
        <g>
          <path d="m22.5 20h-21c-.827 0-1.5-.673-1.5-1.5v-13c0-.827.673-1.5 1.5-1.5h21c.827 0 1.5.673 1.5 1.5v13c0 .827-.673 1.5-1.5 1.5zm-21-15c-.276 0-.5.224-.5.5v13c0 .276.224.5.5.5h21c.276 0 .5-.224.5-.5v-13c0-.276-.224-.5-.5-.5z"></path>
        </g>
        <g>
          <path d="m16.5 24h-9c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h9c.276 0 .5.224.5.5s-.224.5-.5.5z"></path>
        </g>
        <g>
          <path d="m10.5 24c-.276 0-.5-.224-.5-.5v-4c0-.276.224-.5.5-.5s.5.224.5.5v4c0 .276-.224.5-.5.5z"></path>
        </g>
        <g>
          <path d="m13.5 24c-.276 0-.5-.224-.5-.5v-4c0-.276.224-.5.5-.5s.5.224.5.5v4c0 .276-.224.5-.5.5z"></path>
        </g>
      </g>
      <g>
        <path d="m10 16c-.189 0-.362-.106-.447-.276l-1.862-3.724h-2.191c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h2.5c.189 0 .362.107.447.276l1.518 3.036 2.575-6.009c.077-.179.25-.297.445-.303.195.002.375.102.462.276l2.862 5.724h2.191c.276 0 .5.224.5.5s-.224.5-.5.5h-2.5c-.189 0-.362-.107-.447-.276l-2.518-5.036-2.575 6.009c-.077.179-.25.297-.445.303-.005 0-.01 0-.015 0z"></path>
      </g>
    </svg>
  );
};

export default MonitorIcon;
