import { Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { SCALEOPS_COLORS } from "../../../../../colors";

interface Props {
  method: string;
}

enum Method {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
  PATCH = "PATCH",
}

const DEFAULT_COLOR = SCALEOPS_COLORS.guideline.darkGray;

const METHOD_COLOR = {
  [Method.GET]: SCALEOPS_COLORS.guideline.darkGreen,
  [Method.POST]: SCALEOPS_COLORS.guideline.darkBlue,
  [Method.PUT]: SCALEOPS_COLORS.main.orange,
  [Method.DELETE]: SCALEOPS_COLORS.guideline.darkRed,
  [Method.PATCH]: SCALEOPS_COLORS.guideline.darkPurple,
};

const MethodCell = ({ method }: Props) => {
  const [bgColor, setBgColor] = useState(DEFAULT_COLOR);

  useEffect(() => {
    setBgColor(METHOD_COLOR[method as Method] ?? DEFAULT_COLOR);
  }, [method]);

  return (
    <Typography
      variant="caption"
      fontWeight={700}
      className="px-2 rounded-md text-white"
      style={{ backgroundColor: bgColor }}
    >
      {method}
    </Typography>
  );
};

export default MethodCell;
