import { useCallback, useEffect, useRef, useState } from "react";
import { FREEZE_KEY } from "./utils";

export enum TooltipTrigger {
  Hover = "hover",
  Click = "click",
}

interface Props {
  disable?: boolean;
}

export type UpdateActiveTooltips = (tooltipId: string, addToAction: boolean) => void;

const useFreezeTooltip = ({ disable }: Props) => {
  const [tooltipTrigger, setTooltipTrigger] = useState<TooltipTrigger>(TooltipTrigger.Hover);
  const [activeTooltips, setActiveTooltips] = useState<string[]>([]);
  const isFreezeKeyDownRef = useRef(false);

  const onDragStart = () => {
    setTooltipTrigger(TooltipTrigger.Click);
    setActiveTooltips([]);
  };

  const onDragStop = () => {
    setTooltipTrigger(TooltipTrigger.Hover);
  };

  const updateActiveTooltips: UpdateActiveTooltips = useCallback(
    (tooltipId: string, addToAction: boolean) => {
      if (!!addToAction && !activeTooltips.includes(tooltipId)) {
        setActiveTooltips([...activeTooltips, tooltipId]);
      }
      if (addToAction === false && activeTooltips.includes(tooltipId)) {
        setActiveTooltips(activeTooltips.filter((id) => id !== tooltipId));
      }
    },
    [activeTooltips]
  );

  const disabledZoom = tooltipTrigger === TooltipTrigger.Click;

  useEffect(() => {
    if (!disable) {
      const handleKeyDown = (e: KeyboardEvent) => {
        const key = e.key.toLowerCase();
        if (key === FREEZE_KEY && !isFreezeKeyDownRef.current) {
          isFreezeKeyDownRef.current = true;

          if (activeTooltips.length > 0 && tooltipTrigger === TooltipTrigger.Hover) {
            setTooltipTrigger(TooltipTrigger.Click);
          } else if (tooltipTrigger === TooltipTrigger.Click) {
            setTooltipTrigger(TooltipTrigger.Hover);
          }
        } else if (key === "escape" && tooltipTrigger === TooltipTrigger.Click) {
          setTooltipTrigger(TooltipTrigger.Hover);
        }
      };

      const handleKeyUp = (e: KeyboardEvent) => {
        const key = e.key.toLowerCase();
        if (key === FREEZE_KEY) {
          isFreezeKeyDownRef.current = false;
        }
      };

      const onBlur = () => {
        setTooltipTrigger(TooltipTrigger.Hover);
        isFreezeKeyDownRef.current = false; // Reset the flag when window loses focus
      };

      window.addEventListener("keydown", handleKeyDown);
      window.addEventListener("keyup", handleKeyUp);
      window.addEventListener("blur", onBlur);

      return () => {
        window.removeEventListener("keydown", handleKeyDown);
        window.removeEventListener("keyup", handleKeyUp);
        window.removeEventListener("blur", onBlur);
      };
    }
  }, [tooltipTrigger, disable, activeTooltips]);

  useEffect(() => {
    if (!disable) {
      setTooltipTrigger(TooltipTrigger.Hover);
    }
  }, [disable]);

  if (disable) {
    return { tooltipTrigger: undefined, disabledZoom: undefined, setTooltipTrigger, onDragStart, onDragStop };
  }

  return { tooltipTrigger, disabledZoom, updateActiveTooltips, setTooltipTrigger, onDragStart, onDragStop };
};

export default useFreezeTooltip;
