import { ListItemText, MenuItem, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import clsx from "clsx";
import prettyBytes from "pretty-bytes";
import { useEffect, useState } from "react";
import { ArrayParam, useQueryParam } from "use-query-params";
import {
  GetNodeGroupInfoInput,
  getReplicasOverTime,
  getResourceOverTimeNodes,
  getResourcesOverTime,
  ReplicasOverTimeResponse,
  ResourcesOverTimeResponse,
} from "../../../api/fetcher";
import { IsBigCluster } from "../../../utils/FeaturesHelper";
import { DEFAULT_DATE_TIME_FORMAT } from "../../../utils/formatterUtils";
import { OPTIMIZED_REQUEST } from "../../../utils/namesUtils";
import useGetTimeoutSeconds, { MIN_TIMEOUT_SECOND } from "../../../utils/useGetTimeoutSeconds";
import CustomLegend from "../../CustomLegend";
import SingleSelect, { SingleSelectSize } from "../../SingleSelect";
import Tab, { TABS_CONTAINER_CLASS_NAME } from "../../Tab";
import StackedOverviewChart from "../../componentUtils/StackedOverviewChart";
import { ReplicasByTimestampKey, RequestAndUsageByTimestampKey, TabOptions } from "../../componentUtils/overviewUtils";
import useFilterQueryParams, { FilterParamObject, useNodeFilterQueryParams } from "../useFilterQueryParams";
import { CustomTooltipPayload } from "./CustomTooltip";
import FomoChart from "./FomoChart";
import Styles, { ALLOCATABLE_COLOR } from "./Styles";
import { ChartComponents, getDeducedData } from "./utils";
import { ScaleOpsProduct } from "../../../utils/typesUtils";
import Loading from "../../Loading";
import { HpaChartComponent } from "../../../pages/Overview/PolicyTuning/UsageChart/UsageHpaChart";
import ReplicasFomoChart from "../ReplicasOverTime/ReplicasFomoChart";
import { CustomTooltipPayload as ReplicasCustomTooltipPayload } from "../ReplicasOverTime/ReplicasCustomTooltip";

const HAS_DEDUCED_DATA = true;
const HAS_BAR_CHART = false;
const X_AXIS_FORMAT = "DD/MMM";

enum GraphWindow {
  Day = "24h",
  Week = "168h",
  Month = "720h",
}

const STACKED_CHART_WRAP_CLASS_NAME = "grow flex gap-4 flex-col justify-center items-center py-6 px-8 max-w-[50%]";

enum GraphAggregator {
  Hour = "1h",
  Day = "24h",
  TwoDay = "48h",
  Week = "168h",
}

export enum ChartType {
  Line = "Line chart",
  StackedBar = "Bar chart",
}

interface Props {
  includedChartComponents: ChartComponents[];
  defaultSelectedChartComponents: ChartComponents[];
  includedHpaChartComponents?: HpaChartComponent[];
  defaultSelectedHpaChartComponents?: HpaChartComponent[];
  scaleOpsProduct?: ScaleOpsProduct | undefined;
  showRecommended?: boolean;
  showAllocatable?: boolean;
  className?: string;
  getWasteValue?: (payload: CustomTooltipPayload) => number;
  getReplicasWastedValue?: (payload: ReplicasCustomTooltipPayload) => number;
  requestHasLightFill?: boolean;
  isMultiCluster?: boolean;
  disableAnimation?: boolean;
  includeIgnoredNamespaces?: boolean;
  includeAllWorkloads?: boolean;
  nodeView?: boolean;
}

const ResourceOverTimeContainer = ({
  includedChartComponents,
  defaultSelectedChartComponents,
  includedHpaChartComponents,
  defaultSelectedHpaChartComponents,
  scaleOpsProduct,
  showRecommended,
  showAllocatable,
  className,
  getWasteValue,
  getReplicasWastedValue,
  requestHasLightFill,
  isMultiCluster,
  disableAnimation,
  includeIgnoredNamespaces,
  includeAllWorkloads = false,
  nodeView = false,
}: Props) => {
  const [tags] = useQueryParam("tags", ArrayParam);
  const [clusters] = useQueryParam("clusters", ArrayParam);
  const [isQueryEnabled, setIsQueryEnabled] = useState(true);
  const [timeoutSeconds, setTimeoutSeconds] = useState<number | undefined>(MIN_TIMEOUT_SECOND);

  const resourcesOverTime = getResourcesOverTime();
  const resourceOverTimeNodes = getResourceOverTimeNodes();
  const replicasOverTime = getReplicasOverTime();

  const shouldRefetchOnFiltering = !IsBigCluster();

  const [selectedChartComponents, setSelectedChartComponents] =
    useState<ChartComponents[]>(defaultSelectedChartComponents);

  const [selectedHpaChartComponents, setSelectedHpaChartComponents] = useState<HpaChartComponent[]>(
    defaultSelectedHpaChartComponents || []
  );
  // const [hpaChartComponents, setHpaChartComponents] = useState<Record<string, HpaChartComponent>>(
  //   {} as Record<string, HpaChartComponent>
  // );
  useEffect(() => {
    if (scaleOpsProduct === ScaleOpsProduct.HPA) {
      setSelectedTab(TabOptions.Day);
    } else {
      setSelectedTab(TabOptions.Week);
    }
  }, [scaleOpsProduct]);

  const [selectedTab, setSelectedTab] = useState(
    scaleOpsProduct === ScaleOpsProduct.HPA ? TabOptions.Day : TabOptions.Week
  );
  const [memoryData, setMemoryData] = useState<RequestAndUsageByTimestampKey[]>([]);
  const [cpuData, setCpuData] = useState<RequestAndUsageByTimestampKey[]>([]);
  const [replicasData, setReplicasData] = useState<ReplicasByTimestampKey[]>([]);
  const [selectedChartType, setSelectedChartType] = useState<ChartType>(ChartType.Line);
  const [graphWindow, setGraphWindow] = useState(
    scaleOpsProduct === ScaleOpsProduct.HPA ? GraphWindow.Day : GraphWindow.Week
  );
  const [graphAggregator, setGraphAggregator] = useState(GraphAggregator.Hour);

  const filterQueryParams: FilterParamObject | GetNodeGroupInfoInput = nodeView
    ? useNodeFilterQueryParams()
    : useFilterQueryParams();
  const { data, isError, isLoading } = useQuery<ResourcesOverTimeResponse, Error>({
    queryKey: [
      resourcesOverTime.queryKey,
      scaleOpsProduct,
      graphWindow,
      graphAggregator,
      shouldRefetchOnFiltering ? filterQueryParams : undefined,
      isMultiCluster ? "multi-cluster" : undefined,
      tags,
      clusters,
      nodeView,
    ],
    queryFn: () => {
      if (nodeView) {
        return resourceOverTimeNodes.queryFn({
          window: graphWindow,
          aggregation: "1h",
          graphAggregation: graphAggregator,
          multiCluster: isMultiCluster,
          tags: tags?.map((tag) => String(tag)),
          clusters: clusters?.map((cluster) => String(cluster)),
          includeIgnoredNamespaces: includeIgnoredNamespaces,
          timeoutSeconds: timeoutSeconds,
          withNoCluster: isMultiCluster,
          nodeFilters: filterQueryParams,
        });
      } else {
        return resourcesOverTime.queryFn({
          scaleOpsProduct,
          window: graphWindow,
          aggregation: "1h",
          graphAggregation: graphAggregator,
          multiCluster: isMultiCluster,
          tags: tags?.map((tag) => String(tag)),
          clusters: clusters?.map((cluster) => String(cluster)),
          includeIgnoredNamespaces: includeIgnoredNamespaces,
          timeoutSeconds: timeoutSeconds,
          withNoCluster: isMultiCluster,
          ...filterQueryParams,
        });
      }
    },

    enabled: isQueryEnabled,
    refetchInterval: timeoutSeconds ? timeoutSeconds * 1000 : 60 * 5 * 1000,
  });

  const {
    data: replicasFetchedData,
    isError: replicasIsError,
    isLoading: replicasIsLoading,
  } = useQuery<ReplicasOverTimeResponse, Error>({
    queryKey: [
      replicasOverTime.queryKey,
      scaleOpsProduct,
      graphWindow,
      graphAggregator,
      shouldRefetchOnFiltering ? filterQueryParams : undefined,
      isMultiCluster ? "multi-cluster" : undefined,
      tags,
      clusters,
      nodeView,
    ],
    queryFn: () => {
      return replicasOverTime.queryFn({
        scaleOpsProduct,
        window: graphWindow,
        aggregation: "1h",
        graphAggregation: graphAggregator,
        multiCluster: isMultiCluster,
        tags: tags?.map((tag) => String(tag)),
        clusters: clusters?.map((cluster) => String(cluster)),
        includeIgnoredNamespaces: includeIgnoredNamespaces,
        timeoutSeconds: timeoutSeconds,
        withNoCluster: isMultiCluster,
        ...filterQueryParams,
      });
    },

    enabled: isQueryEnabled && scaleOpsProduct === ScaleOpsProduct.HPA,
    refetchInterval: timeoutSeconds ? timeoutSeconds * 1000 : 60 * 5 * 1000,
  });

  const timeoutSecondsValue = useGetTimeoutSeconds({ data, isError, isDisabled: !isMultiCluster });

  useEffect(() => {
    setTimeoutSeconds(timeoutSecondsValue);
  }, [timeoutSecondsValue]);

  useEffect(() => {
    setIsQueryEnabled(true);
  }, [graphWindow, graphAggregator, shouldRefetchOnFiltering ? filterQueryParams : undefined, tags, clusters]);

  useEffect(() => {
    switch (selectedTab) {
      case TabOptions.Day:
        setGraphWindow(GraphWindow.Day);
        setGraphAggregator(GraphAggregator.Hour);
        break;
      case TabOptions.Week:
        setGraphWindow(GraphWindow.Week);
        setGraphAggregator(GraphAggregator.Day);
        break;
      case TabOptions.Month:
        setGraphWindow(GraphWindow.Month);
        setGraphAggregator(GraphAggregator.Day);
        break;
      default:
        break;
    }
  }, [selectedTab]);
  useEffect(() => {
    if (data) {
      if (data.resources) {
        const parsedData = data.resources.map((item) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          const requestBase =
            includeAllWorkloads && (item.cpu?.maxRequestsAny || 0) > 0 ? item.cpu?.maxRequestsAny : item.cpu?.request;
          const request = requestBase ? Math.round((requestBase / 1000) * 10) / 10 : NaN;
          const recommended = item.cpu?.recommended ? Math.round((item.cpu?.recommended / 1000) * 10) / 10 : NaN;
          const allocatable = item.cpu?.allocatable ? Math.round((item.cpu?.allocatable / 1000) * 10) / 10 : NaN;
          const recommendedAllocatable = item.cpu?.recommendedAllocatable
            ? Math.round((item.cpu?.recommendedAllocatable / 1000) * 10) / 10
            : NaN;
          const usage = item.cpu?.usage ? Math.round((item.cpu?.usage / 1000) * 10) / 10 : NaN;

          return {
            request,
            recommended,
            allocatable,
            recommendedAllocatable,
            usage,
            timestampAggregators: item.timestampAggregators,
            timestamps: item.timestamps,
          };
        });

        const deducedData = getDeducedData(parsedData);
        setCpuData(HAS_DEDUCED_DATA ? deducedData : parsedData);
      }

      if (data.resources) {
        const parsedData = data.resources.map((item) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          const request =
            ((includeAllWorkloads && item.memory?.maxRequestsAny) || 0 > 0
              ? item.memory?.maxRequestsAny
              : item.memory?.request) || NaN;
          const recommended = item.memory?.recommended || NaN;
          const allocatable = item.memory?.allocatable || NaN;
          const recommendedAllocatable = item.memory?.recommendedAllocatable || NaN;
          const usage = item.memory?.usage || NaN;

          return {
            request,
            recommended,
            allocatable,
            recommendedAllocatable,
            usage,
            timestampAggregators: item.timestampAggregators,
            timestamps: item.timestamps,
          };
        });

        const deducedData = HAS_DEDUCED_DATA ? getDeducedData(parsedData) : parsedData;

        setMemoryData(deducedData);
      } else {
        setMemoryData([]);
        setCpuData([]);
      }
    }
  }, [data]);

  useEffect(() => {
    if (replicasFetchedData) {
      if (replicasFetchedData.replicas) {
        const parsedData = replicasFetchedData.replicas.map((item) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          const currentReplicas = item.currentReplicas || NaN;
          const recommendedReplicas = item.recommendedReplicas || NaN;
          const replicasByTrigger = item.replicasByTrigger || NaN;

          return {
            currentReplicas,
            recommendedReplicas,
            replicasByTrigger,
            // timestampAggregators: item.timestampAggregators,
            timestamps: item.timestamps,
          };
        });

        const deducedData = /*HAS_DEDUCED_DATA ? getDeducedData(parsedData) :*/ parsedData;

        setReplicasData(deducedData);
      } else {
        setReplicasData([]);
      }
    }
  }, [data]);

  if (isError || (replicasIsError && scaleOpsProduct === ScaleOpsProduct.HPA)) {
    console.log("Failed to fetch querykey:", resourcesOverTime.queryKey);
    return null;
  }

  if (isLoading || (replicasIsLoading && scaleOpsProduct === ScaleOpsProduct.HPA)) {
    return <Loading />;
  }

  return (
    <div
      className={clsx(
        className,
        "items-center mt-[36px] relative h-[280px] bg-white rounded-lg rounded-tl-none shadow-sm"
      )}
    >
      <div className={clsx(TABS_CONTAINER_CLASS_NAME, "top-[-36px]")}>
        {Object.entries(TabOptions).map(([key, value]) => {
          if (value !== TabOptions.Day || scaleOpsProduct === ScaleOpsProduct.HPA) {
            return (
              <Tab
                key={key}
                isSelected={selectedTab === value}
                onClick={() => {
                  setSelectedTab(value);
                }}
                name={value}
                dataTestId={`cpu-and-memory-tab-${key}-tab`}
              />
            );
          }
        })}
      </div>
      {HAS_BAR_CHART && selectedTab !== TabOptions.Day && (
        <div className="absolute right-[10px] top-[10px]">
          <SingleSelect<ChartType>
            selected={selectedChartType}
            setSelected={setSelectedChartType}
            renderOptionsFunc={() =>
              Object.entries(ChartType).map((option) => (
                <MenuItem value={option[1]} key={option[1]}>
                  <ListItemText primary={option[1]} />
                </MenuItem>
              ))
            }
            className="w-[125px]"
            size={SingleSelectSize.Small}
          />
        </div>
      )}
      {selectedChartType === ChartType.StackedBar && (
        <div className="h-full flex pr-8">
          <div className={STACKED_CHART_WRAP_CLASS_NAME}>
            <Typography variant="body1">CPU over time</Typography>
            <StackedOverviewChart
              data={cpuData}
              selectedTab={selectedTab}
              showWaste
              showRecommended={showRecommended}
              showAllocatable={showAllocatable}
            />
          </div>
          <div className={STACKED_CHART_WRAP_CLASS_NAME}>
            <Typography variant="body1">Memory over time</Typography>
            <StackedOverviewChart
              data={memoryData}
              selectedTab={selectedTab}
              showWaste
              showRecommended={showRecommended}
              showAllocatable={showAllocatable}
            />
          </div>
        </div>
      )}
      {selectedChartType === ChartType.Line && !isLoading && (
        <div className="flex h-full w-full items-center">
          {scaleOpsProduct === ScaleOpsProduct.HPA && (
            <>
              <div className="h-full flex flex-col w-[50%]">
                <div className="h-[90%] flex">
                  <ReplicasFomoChart
                    title="Replicas over time"
                    data={replicasData}
                    selectedChartComponents={selectedHpaChartComponents}
                    includedChartComponents={includedHpaChartComponents ?? []}
                    getWasteValue={getReplicasWastedValue}
                    requestHasLightFill={requestHasLightFill}
                    timeFormat={X_AXIS_FORMAT}
                    tooltipTimeFormat={DEFAULT_DATE_TIME_FORMAT}
                    disableAnimation={disableAnimation}
                    viewPeriod={selectedTab === TabOptions.Day ? "24" : selectedTab === TabOptions.Week ? "168" : "720"}
                  />
                </div>
                <CustomLegend<HpaChartComponent>
                  selectedChartComponents={selectedHpaChartComponents}
                  setSelectedChartComponents={setSelectedHpaChartComponents}
                  componentStyle={{
                    ["replicasByTrigger"]: {
                      color: Styles.podsAvgUsage.stroke,
                    },
                    ["currentReplicas"]: {
                      color: "#EAB832",
                    },
                    ["waste"]: {
                      color: "rgb(255 169 164)",
                    },
                    ["recommendedReplicas"]: {
                      color: Styles.recommendedRequest.stroke,
                    },
                  }}
                  // waste capital insensitive
                  isDashedFnc={(key: string) => key.includes("waste")}
                  ChartComponents={{
                    ["replicasByTrigger"]: "replicasByTrigger",
                    ["currentReplicas"]: "currentReplicas",
                    ["waste"]: "waste",
                    ["recommendedReplicas"]: "recommendedReplicas",
                  }}
                  className="-mt-1"
                />
              </div>
              <div className="h-[70%] w-[1px] bg-strongBorder min-w-[1px]"></div>
            </>
          )}
          <div
            className={clsx("flex flex-col  h-full w-[100%]", { "w-[50%]": scaleOpsProduct === ScaleOpsProduct.HPA })}
          >
            <div className="h-[90%] flex">
              <FomoChart
                title="CPU over time"
                data={cpuData}
                selectedChartComponents={selectedChartComponents}
                getWasteValue={getWasteValue}
                includedChartComponents={includedChartComponents}
                requestHasLightFill={requestHasLightFill}
                timeFormat={X_AXIS_FORMAT}
                tooltipTimeFormat={DEFAULT_DATE_TIME_FORMAT}
                disableAnimation={disableAnimation}
                viewPeriod={selectedTab === TabOptions.Day ? "24" : selectedTab === TabOptions.Week ? "168" : "720"}
                intervalX={
                  scaleOpsProduct === ScaleOpsProduct.HPA ? Math.floor(cpuData && cpuData.length / 4) : undefined
                }
              />
              <FomoChart
                title="Memory over time"
                data={memoryData}
                selectedChartComponents={selectedChartComponents}
                getWasteValue={getWasteValue}
                includedChartComponents={includedChartComponents}
                requestHasLightFill={requestHasLightFill}
                valueFormatter={(value) => {
                  if (value === Infinity || value === -Infinity || isNaN(value)) return "0";
                  return prettyBytes(Number(value) || 0.0, {
                    bits: false,
                    binary: true,
                  });
                }}
                timeFormat={X_AXIS_FORMAT}
                tooltipTimeFormat={DEFAULT_DATE_TIME_FORMAT}
                disableAnimation={disableAnimation}
                viewPeriod={selectedTab === TabOptions.Day ? "24" : selectedTab === TabOptions.Week ? "168" : "720"}
                intervalX={
                  scaleOpsProduct === ScaleOpsProduct.HPA ? Math.floor(memoryData && memoryData.length / 4) : undefined
                }
              />
            </div>
            <CustomLegend<ChartComponents>
              selectedChartComponents={selectedChartComponents}
              setSelectedChartComponents={setSelectedChartComponents}
              componentStyle={{
                [ChartComponents.Usage]: {
                  color: Styles.podsAvgUsage.stroke,
                },
                [ChartComponents.Request]: {
                  color: "#EAB832",
                },
                [ChartComponents.Waste]: {
                  color: "rgb(255 169 164)",
                },
                [ChartComponents.Allocatable]: {
                  color: ALLOCATABLE_COLOR,
                },
                [ChartComponents.Recommended]: {
                  color: Styles.recommendedRequest.stroke,
                },
                [ChartComponents.RecommendedAllocatable]: {
                  color: Styles.recommendedAllocatable.stroke,
                },
              }}
              // waste capital insensitive
              isDashedFnc={(key: string) => key.includes("waste")}
              ChartComponents={{
                ...(includedChartComponents.includes(ChartComponents.Usage)
                  ? { [ChartComponents.Usage]: ChartComponents.Usage }
                  : undefined),
                [ChartComponents.Request]: ChartComponents.Request,
                [ChartComponents.Waste]: ChartComponents.Waste,
                ...(showRecommended ? { [ChartComponents.Recommended]: ChartComponents.Recommended } : undefined),
                ...(showAllocatable ? { [ChartComponents.Allocatable]: ChartComponents.Allocatable } : undefined),
                ...(includedChartComponents.includes(ChartComponents.RecommendedAllocatable)
                  ? { [ChartComponents.RecommendedAllocatable]: ChartComponents.RecommendedAllocatable }
                  : undefined),
              }}
              className="-mt-1"
              displayNameStringTransformer={(key: string) => {
                if (key.toLocaleLowerCase() === ChartComponents.Recommended) {
                  return OPTIMIZED_REQUEST;
                }
                return key;
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ResourceOverTimeContainer;
