import { Graph } from "./Graphs/utils";

export const categoryOptions = [
  {
    categoryName: "Optimization",
    options: [Graph.AutomationEventsGraph, Graph.ScaleOpsOptimizationEvictionsGraph],
  },
  {
    categoryName: "Performance",
    options: [
      Graph.CPUUnderProvisionedOnStressedNodesGraph,
      Graph.MemoryUnderProvisionedOnStressedNodesGraph,
      Graph.CPUUnderProvisionedGraph,
      Graph.MemoryUnderProvisionedGraph,
      Graph.WorkloadDisruptionsGraph,
      Graph.DowntimeEventsGraph,
      Graph.OutOfMemoryEventsGraph,
      Graph.CPUThrottlingEventsGraph,
      Graph.LivenessProbeFailuresGraph,
    ],
  },
  {
    categoryName: "Cost",
    options: [
      Graph.WastefulGraph,
      Graph.ExpensiveGraph,
      Graph.SmartPolicyWasteGraph,
      Graph.CustomWorkloadsUnidentifiedGraph,
      Graph.WastedCPUGraph,
      Graph.WastedMemoryGraph,
    ],
  },
  {
    categoryName: "Nodes",
    options: [
      Graph.NodeCpuUtilizationGraph,
      Graph.NodeMemoryUtilizationGraph,
      Graph.NodeCPUAllocationGraph,
      Graph.NodeMemoryAllocationGraph,
      Graph.NodeNotScalingDownGraph,
      Graph.NodeLifeCycleGraph,
      Graph.NodeInstanceTypeGraph,
    ],
  },
  {
    categoryName: "ScaleOps workloads",
    options: [
      Graph.VersionGraph,
      Graph.ScaleOpsCPUUsage,
      Graph.ScaleOpsMemoryUsage,
      Graph.ScaleOpsCPURequests,
      Graph.ScaleOpsMemoryRequests,
      Graph.IssuesGraph,
    ],
  },
  {
    categoryName: "Resource quotas",
    options: [
      Graph.NamespaceLimitationByCPUGraph,
      Graph.NamespaceLimitationByMemoryGraph,
      Graph.NamespaceLimitationByPodsGraph,
      Graph.NamespaceLimitationByReplicaSetsGraph,
    ],
  },
];
