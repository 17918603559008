import { components } from "../api/schema";
import { getCustomerPrefix, getToken } from "../utils/configUtils";
import useGetVersion from "../pages/Cost/hooks/useGetVersion";
import { helmSetToString } from "../pages/Roles/Permission/commandsUtils";

interface HelmSetupProps {
  conf: components["schemas"]["ConfGetConfResponse"];
  showToken: boolean;
  inCloudMode: boolean;
}

export const helmSetup = ({ conf, showToken, inCloudMode }: HelmSetupProps) => {
  const displayScaleOpsToken = showToken ? getToken(conf) : getToken(conf).replace(/./g, "*");
  const { data } = useGetVersion();
  const repoToDisplay = data?.helmCommandConfig?.repositoryURL ?? "<SCALEOPS_REPOSITORY>";
  const extraHelmSets = helmSetToString(data?.helmCommandConfig?.extraHelmSets);
  const namespace = data?.namespace || "<SCALEOPS_NAMEPSACE>";

  const topCommand = `
  helm install --create-namespace -n ${namespace} \\
    --repo ${repoToDisplay} \\
    --username scaleops --password ${displayScaleOpsToken} \\
  `;
  let bottomCommand = ` --set scaleopsToken=${displayScaleOpsToken} \\
    --set clusterName=${getCustomerPrefix(conf)}-$(kubectl config current-context) \\`;
  if (!inCloudMode) {
    bottomCommand += `
    --set parentURL=${encodeURI(window.location.protocol + "//" + window.location.host)} \\`;
  }
  bottomCommand += `
  scaleops scaleops`;
  return extraHelmSets
    ? `${topCommand}  ${extraHelmSets} \\
   ${bottomCommand}`
    : `${topCommand} ${bottomCommand}`;
};
