import FormSwitch from "../../../components/formComponents/FormSwitch";
import FormTitleAndDescription from "../../../components/formComponents/FormTitleAndDescription";
import { shouldDisableDefaultPolicyInputs } from "../utils";
import { IsSnapshotServer } from "../../../utils/FeaturesHelper";

interface Props {
  isCustomizedPolicy?: boolean;
}

const KeepRequest = ({ isCustomizedPolicy }: Props) => {
  return (
    <div className="flex flex-col gap-4">
      <FormTitleAndDescription
        title="Keep original requests"
        description="Disable ScaleOps from changing the original resource requests."
      />
      <div className="flex items-center gap-4">
        <FormSwitch
          name="keepCpuRequest"
          disabled={!isCustomizedPolicy && shouldDisableDefaultPolicyInputs}
          disableChange={!IsSnapshotServer() && !isCustomizedPolicy}
          label="Keep original CPU request"
        />
        <FormSwitch
          name="keepMemoryRequest"
          disabled={!isCustomizedPolicy && shouldDisableDefaultPolicyInputs}
          disableChange={!IsSnapshotServer() && !isCustomizedPolicy}
          label="Keep original memory request"
        />
      </div>
    </div>
  );
};
export default KeepRequest;
