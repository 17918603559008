import { CircularProgress, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridRenderCellParams, GridRowParams } from "@mui/x-data-grid";
import { Ace } from "ace-builds";
import "ace-builds/src-noconflict/mode-yaml";
import "ace-builds/src-noconflict/theme-nord_dark";
import * as React from "react";
import { ReactNode, useState } from "react";
import AceEditor from "react-ace";
import { useNavigate } from "react-router-dom";
import { StringParam, useQueryParam } from "use-query-params";
import { GetPodsResponse } from "../../../api/fetcher";
import { MAIN_YELLOW } from "../../../colors";
import CircleCheckIcon from "../../../Icons/CircleCheckIcon";
import CloseIcon from "../../../Icons/CloseIcon";
import InfoIcon from "../../../Icons/InfoIcon";
import WarningIcon from "../../../Icons/WarningIcon";
import { useMainContext } from "../../../MainContext";
import { CpuFormatter, MemoryFormatter } from "../../../utils/formatterUtils";
import { workloadTypes } from "../../../utils/namesUtils";
import { getDataGridSx } from "../../../utils/styleUtils";
import { getWorkloadType } from "../../../utils/typesUtils";
import useStateWithLocalStorage from "../../../utils/useStateWithLocalStorage";
import ExploreEntityTooltip from "../../../utils/exploreEntityTooltip";
import CustomColumnsFilterButton from "../../CustomColumnsFilterButton";
import ExportCSV, { HAS_EXPORT_TABLE_AS_CSV } from "../../exportCSV/ExportCSV";
import MultiSelect from "../../MultiSelect";
import { default as CustomTooltip } from "../../Tooltip";
import NotScalingDownTooltipContent from "../NotScalingDownTooltipContent";
import { INFO, WARN } from "../useGetNodeIconAndTitle";
import ExploreWorkloadsTooltipWrapper from "./ExploreWorkloadsTooltipWrapper";
import { ExploreEntityMessage } from "./ExploreWorkloadsMessage";

const DEFAULT_PAGE_SIZE = 5;
const ICON_SIZE = 16;
const WARN_ICON_SIZE = 16;
const ROWS_PER_PAGE_OPTIONS = Array.from({ length: 4 }, (_, i) => DEFAULT_PAGE_SIZE * (i + 1));
const NODE_DIAGNOSTICS_PAGE_SIZE_LOCAL_STORAGE_KEY = "nodeDiagnosticsPageSize";
const memoryFormatter = MemoryFormatter();
const cpuFormatter = CpuFormatter();

interface Props {
  rows?: GetPodsResponse[];
  isLoading: boolean;
}

type CSVExportType = GetPodsResponse;

export type PodsRowEntry = {
  auto: boolean;
  binPacked: boolean;
  blockingMessage: string;
  blockingReason: string;
  cpuLimit: number;
  cpuRequest: number;
  memoryLimit: number;
  memoryRequest: number;
  cpuUsage: number;
  memoryUsage: number;
  name: string;
  namespace: string;
  ownerName: string;
  ownerType: string;
  phase: string;
  unevictable: boolean;
  blockScaleDown: boolean;
  tolerations: string;
  requiredAntiAffinity: string;
  nodeSelector: string;
};

enum Columns {
  Pod = "Pod",
  WorkloadType = "Workload Type",
  CpuRequest = "CPU Request",
  MemoryRequest = "Memory Request",
  CpuLimit = "CPU Limit",
  MemoryLimit = "Memory Limit",
  CpuUsage = "CPU Usage",
  MemoryUsage = "Memory Usage",
  Unevictable = "Unevictable",
  PodAffinity = "Pod Affinity",
  PodAntiAffinity = "Pod Anti-Affinity",
  NodeAffinity = "Node Affinity",
  NodeSelector = "Node Selector",
  Tolerations = "Tolerations",
}

const COLUMNS_MENU_OPTIONS = [
  Columns.Pod,
  Columns.WorkloadType,
  Columns.CpuRequest,
  Columns.MemoryRequest,
  Columns.CpuLimit,
  Columns.MemoryLimit,
  Columns.CpuUsage,
  Columns.MemoryUsage,
  Columns.Unevictable,
  Columns.PodAffinity,
  Columns.PodAntiAffinity,
  Columns.NodeAffinity,
  Columns.NodeSelector,
  Columns.Tolerations,
];

const renderNameCell = (
  value: string,
  tooltipContent: ReactNode,
  params: GridRenderCellParams<string, GetPodsResponse, string>,
  icon: React.ReactNode,
  title: string | undefined,
  tooltipFooter?: ReactNode
) => {
  return (
    <div className="w-full flex gap-2">
      <CustomTooltip
        title={
          <NotScalingDownTooltipContent
            nodeGroup=""
            limitScaleDownMessage={params.row.blockingMessage ?? ""}
            limitScaleDownReason={params.row.blockingReason}
            limitScaleDownAction=""
            blockingOwner="Pod"
            blockingName={params.row.name}
            blockingMessage={params.row.blockingMessage}
            nodeReasonDetails={params.row.blockingAffinity ? params.row.blockingAffinity : {}}
            nodeGroups={[]}
            title={title}
            icon={icon}
            displayNodeOverview={false}
          />
        }
        maxWidth={500}
      >
        {icon}
      </CustomTooltip>
      <CustomTooltip
        maxWidth={770}
        title={
          <div className="flex flex-col gap-5">
            {tooltipContent}
            {tooltipFooter}
          </div>
        }
        className="w-full"
      >
        <Typography variant="body2" noWrap={true} className="max-w-full truncate">
          {value}
        </Typography>
      </CustomTooltip>
    </div>
  );
};

const renderMemoryCell = (value: string, showZero = true) => {
  if (!value) {
    return showZero ? 0 : "";
  }
  const memoryValue = memoryFormatter.format(Number(value));
  return (
    <ExploreWorkloadsTooltipWrapper entity={ExploreEntityMessage.ExploreWorkload}>
      {memoryValue}
    </ExploreWorkloadsTooltipWrapper>
  );
};

const renderCpuCell = (value: string, showZero = true) => {
  if (!value) {
    return showZero ? 0 : "";
  }
  const cpuValue = cpuFormatter.format(Number(value) / 1000);
  return (
    <ExploreWorkloadsTooltipWrapper entity={ExploreEntityMessage.ExploreWorkload}>
      {cpuValue}
    </ExploreWorkloadsTooltipWrapper>
  );
};

const getIconAndTitle = (severity: string | undefined) => {
  let icon: React.ReactNode;
  let title: string | undefined;
  switch (severity) {
    case WARN:
      icon = <WarningIcon width={WARN_ICON_SIZE} height={WARN_ICON_SIZE} fill={MAIN_YELLOW} className="mt-[2px]" />;
      break;
    case INFO:
      icon = <InfoIcon width={WARN_ICON_SIZE} height={WARN_ICON_SIZE} className="mt-[2px]" />;
      title = "Scale down constraints";
      break;
  }
  return { icon, title };
};

const renderYAMLCell = (title: string, value: string) => {
  const handleEditorLoad = (editor: Ace.Editor) => {
    const session = editor.getSession();
    const contentWidth = session.getScreenWidth() * editor.renderer.characterWidth;
    editor.container.style.width = `${contentWidth + 16}px`;
  };

  const tooltipContent = (
    <AceEditor
      mode={"yaml"}
      theme={"nord_dark"}
      fontSize={12}
      showGutter={false}
      highlightActiveLine={false}
      wrapEnabled={false}
      readOnly={true}
      className="rounded border border-gray-300"
      onLoad={handleEditorLoad}
      setOptions={{
        autoScrollEditorIntoView: true,
        useWorker: true,
        showLineNumbers: false,
        tabSize: 2,
        maxLines: 30,
        behavioursEnabled: false,
        indentedSoftWrap: true,
      }}
      value={value ? `\n${value}` : value}
    />
  );

  if (!value) return <ExploreWorkloadsTooltipWrapper entity={ExploreEntityMessage.ExploreWorkload} />;
  return (
    <CustomTooltip title={tooltipContent} disabled={!value} maxWidth={900}>
      {<InfoIcon width={ICON_SIZE} height={ICON_SIZE} />}
    </CustomTooltip>
  );
};

const DEFAULT_COL_PROPS: Partial<GridColDef> = {
  flex: 1,
  minWidth: 30,
  type: "string",
  align: "center",
  headerAlign: "center",
  disableColumnMenu: true,
  sortable: true,
};

const getColumns = (selectedColumns: Columns[]): GridColDef[] => {
  return [
    {
      field: "name",
      headerName: Columns.Pod,
      headerAlign: "center",
      hide: !selectedColumns.includes(Columns.Pod),
      flex: 1.5,
      minWidth: 250,
      disableColumnMenu: true,
      sortable: true,
      renderCell: (params: GridRenderCellParams<string, GetPodsResponse, string>) => {
        const namespace = params.row.namespace ?? "";
        const podName = params.value ?? "";
        const { icon, title } = getIconAndTitle(params.row.blockingSeverity);
        return renderNameCell(
          `${namespace}/${podName}`,
          <ExploreEntityTooltip namespace={namespace} workloadName={podName} nameLabel="Pod" />,
          params,
          icon,
          title
        );
      },
    },
    {
      field: "ownerType",
      headerName: Columns.WorkloadType,
      hide: !selectedColumns.includes(Columns.WorkloadType),
      ...DEFAULT_COL_PROPS,
      renderCell: (params) => {
        return (
          <ExploreWorkloadsTooltipWrapper entity={ExploreEntityMessage.ExploreWorkload}>
            {getWorkloadType((params?.row as GetPodsResponse)?.ownerName, (params?.row as GetPodsResponse)?.ownerType)}
          </ExploreWorkloadsTooltipWrapper>
        );
      },
    },
    {
      field: "cpuRequest",
      headerName: Columns.CpuRequest,
      hide: !selectedColumns.includes(Columns.CpuRequest),
      ...DEFAULT_COL_PROPS,
      renderCell: (params) => renderCpuCell(params.value as string),
    },
    {
      field: "cpuUsage",
      headerName: Columns.CpuUsage,
      hide: !selectedColumns.includes(Columns.CpuUsage),
      ...DEFAULT_COL_PROPS,
      renderCell: (params) => renderCpuCell(params.value as string),
    },
    {
      field: "cpuLimit",
      headerName: Columns.CpuLimit,
      hide: !selectedColumns.includes(Columns.CpuLimit),
      ...DEFAULT_COL_PROPS,
      renderCell: (params) => renderCpuCell(params.value as string, false),
    },
    {
      field: "memoryRequest",
      headerName: Columns.MemoryRequest,
      hide: !selectedColumns.includes(Columns.MemoryRequest),
      ...DEFAULT_COL_PROPS,
      renderCell: (params) => renderMemoryCell(params.value as string),
    },
    {
      field: "memoryUsage",
      headerName: Columns.MemoryUsage,
      hide: !selectedColumns.includes(Columns.MemoryUsage),
      ...DEFAULT_COL_PROPS,
      renderCell: (params) => renderMemoryCell(params.value as string),
    },
    {
      field: "memoryLimit",
      headerName: Columns.MemoryLimit,
      hide: !selectedColumns.includes(Columns.MemoryLimit),
      ...DEFAULT_COL_PROPS,
      renderCell: (params) => renderMemoryCell(params.value as string, false),
    },
    {
      field: "unevictable",
      headerName: Columns.Unevictable,
      hide: !selectedColumns.includes(Columns.Unevictable),
      ...DEFAULT_COL_PROPS,
      renderCell: (params) => (
        <ExploreWorkloadsTooltipWrapper entity={ExploreEntityMessage.ExploreWorkload}>
          {!params.value ? (
            <CloseIcon width={ICON_SIZE} height={ICON_SIZE} />
          ) : (
            <CircleCheckIcon width={ICON_SIZE} height={ICON_SIZE} className="text-main-green" />
          )}
        </ExploreWorkloadsTooltipWrapper>
      ),
    },
    {
      field: "requiredAffinity",
      headerName: Columns.PodAffinity,
      hide: !selectedColumns.includes(Columns.PodAffinity),
      ...DEFAULT_COL_PROPS,
      renderCell: (params) => renderYAMLCell(Columns.PodAffinity, params.value as string),
    },
    {
      field: "requiredAntiAffinity",
      headerName: Columns.PodAntiAffinity,
      hide: !selectedColumns.includes(Columns.PodAntiAffinity),
      ...DEFAULT_COL_PROPS,
      renderCell: (params) => renderYAMLCell(Columns.PodAntiAffinity, params.value as string),
    },
    {
      field: "requiredNodeAffinity",
      headerName: Columns.NodeAffinity,
      hide: !selectedColumns.includes(Columns.NodeAffinity),
      ...DEFAULT_COL_PROPS,
      renderCell: (params) => renderYAMLCell(Columns.NodeAffinity, params.value as string),
    },
    {
      field: "nodeSelector",
      headerName: Columns.NodeSelector,
      hide: !selectedColumns.includes(Columns.NodeSelector),
      ...DEFAULT_COL_PROPS,
      renderCell: (params) => renderYAMLCell(Columns.NodeSelector, params.value as string),
    },
    {
      field: "tolerations",
      headerName: Columns.Tolerations,
      hide: !selectedColumns.includes(Columns.Tolerations),
      ...DEFAULT_COL_PROPS,
      renderCell: (params) => renderYAMLCell(Columns.Tolerations, params.value as string),
    },
  ];
};

const PodsTable = ({ rows, isLoading }: Props) => {
  const { currentCluster } = useMainContext();
  const [search, setSearch] = useQueryParam("nodeOverviewSearch", StringParam);

  const navigate = useNavigate();

  const [selectedColumns, setSelectedColumns] = useState<Columns[]>([
    Columns.Pod,
    Columns.CpuRequest,
    Columns.MemoryRequest,
    Columns.CpuUsage,
    Columns.MemoryUsage,
  ]);
  const [pageSize, setPageSize] = useStateWithLocalStorage<number>({
    localStorageKey: NODE_DIAGNOSTICS_PAGE_SIZE_LOCAL_STORAGE_KEY,
    defaultValue: DEFAULT_PAGE_SIZE,
    valueFormatter: (value) => parseInt(value),
  });

  React.useEffect(() => {
    () => setSearch("");
  }, []);

  if (isLoading && !rows) {
    return (
      <div className="w-full border pt-2 pb-6 relative flex items-center justify-center h-[350px]">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-2">
      <div className="flex items-start w-full">
        <div className="flex items-center w-full">
          <input
            placeholder="search..."
            value={search || ""}
            onChange={(event) => {
              setSearch(event.target.value);
            }}
            className="border border-border rounded-md px-2 py-1 focus:outline-none w-[250px]"
          />
        </div>
        <div>
          <MultiSelect
            selected={selectedColumns}
            setSelected={setSelectedColumns as React.Dispatch<React.SetStateAction<(string | undefined)[]>>}
            options={COLUMNS_MENU_OPTIONS}
            className="mb-[6px] w-[85px]"
            customIcon={<CustomColumnsFilterButton isFiltered={selectedColumns.length > 0} />}
          />
        </div>
      </div>
      <DataGrid
        sx={{
          ...getDataGridSx(),
          cursor: "pointer",
        }}
        rows={
          rows?.filter((entity) => {
            if (search === "" || !search) return true;
            if (entity.name.includes(search.trim())) return true;
            return false;
          }) ?? []
        }
        columns={getColumns(selectedColumns)}
        autoHeight={true}
        rowHeight={65}
        getRowId={(row: GetPodsResponse) => row.name}
        loading={isLoading}
        onRowClick={(params: GridRowParams<GetPodsResponse>) => {
          if (!params.row.ownerName || !params.row.ownerType) return;
          const namespace = params.row.namespace;
          const workloadName = params.row.ownerName;
          const searchTerms = `${namespace}/${workloadName}`;
          const currentClusterURLParam = currentCluster ?? "";
          const type = workloadTypes?.[params.row.ownerType as keyof typeof workloadTypes] ?? params.row.ownerType;

          const link = `/?currentClusterURLParam=${currentClusterURLParam}&searchTerms=${searchTerms}&types=${type}&openFirstRowWorkloadOverview=1`;

          navigate(link);
        }}
        pagination
        rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => {
          setPageSize(newPageSize);
          localStorage.setItem(NODE_DIAGNOSTICS_PAGE_SIZE_LOCAL_STORAGE_KEY, newPageSize.toString());
        }}
      />
      {HAS_EXPORT_TABLE_AS_CSV && rows && (
        <div className="mt-[-35px] ml-[10px] z-50 relative w-fit">
          <ExportCSV<CSVExportType>
            filename="node_pods.csv"
            columns={[
              "name",
              "cpuRequest",
              "cpuUsage",
              "cpuLimit",
              "memoryRequest",
              "memoryUsage",
              "memoryLimit",
              "unevictable",
              "requiredAntiAffinity",
              "tolerations",
              "nodeSelector",
            ]}
            columnsToRound={["cpuRequest"]}
            data={
              rows?.map((row) => ({
                name: row.name,
                cpuRequest: row.cpuRequest,
                cpuUsage: row.cpuUsage,
                cpuLimit: row.cpuLimit,
                memoryRequest: row.memoryRequest,
                memoryUsage: row.memoryUsage,
                memoryLimit: row.memoryLimit,
                unevictable: row.unevictable,
                requiredAntiAffinity: row.requiredAntiAffinity,
                tolerations: row.tolerations,
                nodeSelector: row.nodeSelector,
              })) as CSVExportType[]
            }
            columnsToSum={["cpuRequest", "memoryRequest", "cpuLimit", "memoryLimit"]}
            customColumnNames={{
              name: Columns.Pod,
              cpuRequest: Columns.CpuRequest,
              cpuUsage: Columns.CpuUsage,
              cpuLimit: Columns.CpuLimit,
              memoryRequest: Columns.MemoryRequest,
              memoryUsage: Columns.MemoryUsage,
              memoryLimit: Columns.MemoryLimit,
              unevictable: Columns.Unevictable,
              requiredAffinity: Columns.PodAffinity,
              requiredAntiAffinity: Columns.PodAntiAffinity,
              nodeSelector: Columns.NodeSelector,
              tolerations: Columns.Tolerations,
            }}
          />
        </div>
      )}
    </div>
  );
};

export default PodsTable;
