import SSOWithAuthorization from "./SSOWithAuthorization";
import { AuthMethod } from "./utils";

const PermissionsContainer = () => {
  return (
    <div className="flex flex-col gap-10">
      <SSOWithAuthorization currentAuthMethod={AuthMethod.SSO_WITH_AUTHORIZATION} />
    </div>
  );
};

export default PermissionsContainer;
