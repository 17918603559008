import { IconProps } from "./utils/utils";

const User = (props: IconProps) => {
  const { height = 20, width = 20, className } = props;
  return (
    <svg height={height} width={width} className={className} fill="currentColor" viewBox="0 0 20 20" {...props}>
      <g>
        <path d="M17.0711 2.92899C15.1823 1.04025 12.6711 6.10352e-05 10 6.10352e-05C7.32895 6.10352e-05 4.81766 1.04025 2.92892 2.92899C1.04019 4.81772 0 7.32901 0 10.0001C0 12.6711 1.04019 15.1824 2.92892 17.0711C4.81766 18.9599 7.32895 20.0001 10 20.0001C12.6711 20.0001 15.1823 18.9599 17.0711 17.0711C18.9598 15.1824 20 12.6711 20 10.0001C20 7.32901 18.9598 4.81772 17.0711 2.92899ZM4.34006 16.7695C4.67026 13.9283 7.11304 11.7347 10 11.7347C11.5219 11.7347 12.953 12.3276 14.0295 13.404C14.939 14.3136 15.5125 15.5012 15.6601 16.7693C14.1266 18.0536 12.1521 18.8282 10 18.8282C7.8479 18.8282 5.87357 18.0538 4.34006 16.7695ZM10 10.5277C8.32535 10.5277 6.96274 9.1651 6.96274 7.49045C6.96274 5.81564 8.32535 4.45319 10 4.45319C11.6747 4.45319 13.0373 5.81564 13.0373 7.49045C13.0373 9.1651 11.6747 10.5277 10 10.5277ZM16.6687 15.7787C16.3701 14.5748 15.7474 13.4647 14.8581 12.5754C14.1379 11.8552 13.2858 11.3149 12.3566 10.9763C13.4737 10.2187 14.2091 8.93881 14.2091 7.49045C14.2091 5.16959 12.3209 3.28131 10 3.28131C7.67914 3.28131 5.79086 5.16959 5.79086 7.49045C5.79086 8.93958 6.52695 10.2199 7.64496 10.9774C6.79001 11.289 5.99884 11.7707 5.31815 12.4048C4.33243 13.3228 3.64792 14.4933 3.33054 15.7778C1.98639 14.2283 1.17188 12.2076 1.17188 10.0001C1.17188 5.1322 5.13214 1.17194 10 1.17194C14.8679 1.17194 18.8281 5.1322 18.8281 10.0001C18.8281 12.208 18.0133 14.2292 16.6687 15.7787Z" />
      </g>
    </svg>
  );
};

export default User;
