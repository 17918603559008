import { components } from "../../../api/schema";
import FormSingleSelect, { RenderValueType } from "../../../components/formComponents/FormSingleSelect";
import FormTitleAndDescription from "../../../components/formComponents/FormTitleAndDescription";
import * as policyUtils from "../../../utils/policyUtils";
import { shouldDisableDefaultPolicyInputs } from "../utils";
import { getDisabledByKeepRequestTooltipContent } from "./utils";
import { IsSnapshotServer } from "../../../utils/FeaturesHelper";

const options: [string, number][] = [
  ["0%", 0],
  ["5%", 5],
  ["10%", 10],
  ["15%", 15],
  ["20%", 20],
  ["50%", 50],
  ["100%", 100],
  ["200%", 200],
];
interface Props {
  isCustomizedPolicy?: boolean;
  defaultPolicy?: components["schemas"]["V1alpha1Policy"] | undefined;
  disableCPU?: boolean;
  disableMemory?: boolean;
}

const RequestsHeadroom = ({ isCustomizedPolicy, defaultPolicy, disableCPU, disableMemory }: Props) => {
  const defaultCpuHeadroom = isCustomizedPolicy ? policyUtils.policyCpuHeadroom(defaultPolicy) : undefined;

  const defaultMemoryHeadroom = isCustomizedPolicy ? policyUtils.policyMemoryHeadroom(defaultPolicy) : undefined;

  return (
    <div className="flex flex-col gap-4 pb-2">
      <FormTitleAndDescription
        title="Request headroom"
        description="Define requests headroom for container resources."
        info={
          <>
            This value calculated by the <b>resource request percentiles</b> and can be editing on advanced tab.
          </>
        }
      />
      <div className="flex flex-wrap gap-4">
        <FormSingleSelect
          label="CPU %"
          name="cpuHeadroom"
          options={options}
          areOptionsDisabled={!IsSnapshotServer() && !isCustomizedPolicy}
          defaultValue={defaultCpuHeadroom}
          renderValueType={RenderValueType.Percentage}
          disabled={(!isCustomizedPolicy && shouldDisableDefaultPolicyInputs) || disableCPU}
          tooltipContent={disableCPU ? getDisabledByKeepRequestTooltipContent("CPU") : undefined}
        />
        <FormSingleSelect
          label="Memory (%)"
          name="memoryHeadroom"
          options={options}
          areOptionsDisabled={!IsSnapshotServer() && !isCustomizedPolicy}
          defaultValue={defaultMemoryHeadroom}
          renderValueType={RenderValueType.Percentage}
          disabled={(!isCustomizedPolicy && shouldDisableDefaultPolicyInputs) || disableMemory}
          tooltipContent={disableMemory ? getDisabledByKeepRequestTooltipContent("memory") : undefined}
        />
      </div>
    </div>
  );
};

export default RequestsHeadroom;
