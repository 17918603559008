import FormSwitch from "../../../components/formComponents/FormSwitch";
import FormTitleAndDescription from "../../../components/formComponents/FormTitleAndDescription";
import { DISPLAY_NAME } from "../../../utils/namesUtils";
import { shouldDisableDefaultPolicyInputs } from "../utils";
import { IsSnapshotServer } from "../../../utils/FeaturesHelper";

interface Props {
  isCustomizedPolicy?: boolean;
}

const FastReaction = ({ isCustomizedPolicy }: Props) => {
  return (
    <div className="flex flex-col gap-4">
      <FormTitleAndDescription
        title={DISPLAY_NAME.BurstReaction}
        description="ScaleOps optimization analyzes historical data and acts immediately upon rapid bursts that require an update in resources when needed."
      />
      <FormSwitch
        name="fastReaction"
        disabled={!isCustomizedPolicy && shouldDisableDefaultPolicyInputs}
        disableChange={!IsSnapshotServer() && !isCustomizedPolicy}
      />
    </div>
  );
};
export default FastReaction;
