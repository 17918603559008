import { GetTopKQueryKey } from "../../../api/fetcher";
import { capitalizeFirstLetter } from "../../../utils/formatterUtils";
import { getDisplayWorkloadKey } from "../../../utils/namesUtils";
import TopValuesGraph from "../../Analytics/AnalyticsV2/Graphs/TopValuesGraph";
import { AggregateBy } from "../../Analytics/AnalyticsV2/SelectAggregationView";
import useGetGraphState from "../useGetGraphsState";
import { GENERAL_SYNC_ID } from "../utils";
import { CommonGraphProps } from "./utils";

const CPUUnderProvisionedOnStressedNodesGraph = ({
  isMulticluster,
  tooltipTrigger,
  disabledZoom,
  updateActiveTooltips,
}: CommonGraphProps) => {
  const { date, setDate, numberOfElements, aggregationView, frozenAndClickableTab } = useGetGraphState();

  return (
    <TopValuesGraph
      title={"CPU Under Provisioned " + capitalizeFirstLetter(aggregationView.toLowerCase()) + " On Stressed Nodes"}
      infoTooltip={
        <>
          <b>Sum</b> of <b>usage above request</b> for workloads running on <b>CPU stressed</b> nodes.
          <br />
          These workloads can lead to stressed nodes and cause performance issues to their neighbors.
        </>
      }
      queryParams={{
        from: date.range ? undefined : date.from,
        to: date.range ? undefined : date.to,
        range: date.range,
        queryKey:
          aggregationView === AggregateBy.Workloads
            ? GetTopKQueryKey.CpuUnderProvisionedWorkloadsStressedNode
            : GetTopKQueryKey.CpuUnderProvisionedNamespacesStressedNode,
      }}
      setDate={setDate}
      isMulticluster={isMulticluster}
      topK={numberOfElements}
      tooltipTrigger={tooltipTrigger}
      disabledZoom={disabledZoom}
      updateActiveTooltips={updateActiveTooltips}
      enableCopyTextOnClick
      frozenTooltipType={frozenAndClickableTab}
      syncId={GENERAL_SYNC_ID}
      renderNameFunction={aggregationView === AggregateBy.Workloads ? getDisplayWorkloadKey : undefined}
      keyParser={aggregationView === AggregateBy.Workloads ? getDisplayWorkloadKey : undefined}
    />
  );
};

export default CPUUnderProvisionedOnStressedNodesGraph;
