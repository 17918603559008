import { IconProps } from "./utils/utils";

const PlainRolloutIcon = (props: IconProps) => {
  const { height = 20, width = 20, className } = props;
  return (
    <svg height={height} width={width} className={className} fill="currentColor" viewBox="0 0 20 20" {...props}>
      <path d="M9.01196 5.18506C8.84796 5.18506 8.68396 5.12206 8.55896 4.99706C8.30896 4.74706 8.30896 4.34206 8.55896 4.09206L10.546 2.10706C10.796 1.85706 11.201 1.85706 11.451 2.10706C11.701 2.35706 11.701 2.76206 11.451 3.01206L9.46396 4.99806C9.33996 5.12306 9.17596 5.18506 9.01196 5.18506Z" />
      <path d="M10.997 7.17305C10.833 7.17305 10.669 7.11005 10.544 6.98505L8.55896 4.99805C8.30896 4.74805 8.30896 4.34305 8.55896 4.09305C8.80896 3.84305 9.21396 3.84305 9.46396 4.09305L11.45 6.08005C11.7 6.33005 11.7 6.73505 11.45 6.98505C11.325 7.11005 11.161 7.17305 10.997 7.17305Z" />
      <path d="M9.99995 18.0801C9.03895 18.0801 8.10595 17.8921 7.22695 17.5201C6.37895 17.1611 5.61695 16.6481 4.96295 15.9941C4.30895 15.3401 3.79495 14.5781 3.43695 13.7301C3.06495 12.8511 2.87695 11.9181 2.87695 10.9571C2.87695 9.93505 3.08895 8.94805 3.50795 8.02305C3.91095 7.13005 4.48495 6.34205 5.21395 5.68005C5.47595 5.44205 5.87995 5.46205 6.11795 5.72405C6.35595 5.98605 6.33595 6.39005 6.07395 6.62805C4.85495 7.73405 4.15695 9.31205 4.15695 10.9561C4.15695 14.1781 6.77795 16.8001 10.001 16.8001C13.223 16.8001 15.845 14.1791 15.845 10.9561C15.845 7.73305 13.222 5.11305 9.99995 5.11305C9.70295 5.11305 9.40495 5.13505 9.11495 5.18005C8.76595 5.23305 8.43895 4.99305 8.38595 4.64305C8.33295 4.29405 8.57295 3.96705 8.92295 3.91405C9.27695 3.86005 9.63995 3.83305 10.001 3.83305C10.962 3.83305 11.895 4.02105 12.774 4.39305C13.622 4.75205 14.384 5.26505 15.038 5.91905C15.692 6.57305 16.206 7.33505 16.564 8.18305C16.936 9.06205 17.124 9.99505 17.124 10.9561C17.124 11.9171 16.936 12.8501 16.564 13.7291C16.205 14.5771 15.692 15.3391 15.038 15.9931C14.384 16.6471 13.622 17.1611 12.774 17.5191C11.894 17.8921 10.961 18.0801 9.99995 18.0801Z" />
    </svg>
  );
};

export default PlainRolloutIcon;
