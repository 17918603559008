import { SCALEOPS_COLORS } from "../../../../colors";
import DisableIcon from "../../../../Icons/DisableIcon";
import OnGoingOptimizationIcon from "../../../../Icons/OnGoingOptimizationIcon";
import PositiveIcon from "../../../../Icons/PositiveIcon";
import FastReactionEventIcon from "../../../../Icons/FastReactionEventIcon";
import HealingEventIcon from "../../../../Icons/HealingEventIcon";

export enum Event {
  isAuto = "isAuto",
  podOptimizedCount = "podOptimizedCount",
  podEvictionCount = "podEvictionCount",
  recommendationCreationCount = "recommendationCreationCount",
  optimizationBlockedCount = "optimizationBlockedCount",
  autoHealingCount = "autoHealingCount",
  burstReactionCount = "burstReactionCount",
}

export const EventsDisplayName: Record<Event, string> = {
  [Event.podOptimizedCount]: "Pod optimized",
  [Event.podEvictionCount]: "Optimization evictions",
  [Event.optimizationBlockedCount]: "Delayed optimization eviction",
  [Event.recommendationCreationCount]: "Recommendation created",
  [Event.isAuto]: "Automated",
  [Event.autoHealingCount]: "Auto healing reaction",
  [Event.burstReactionCount]: "Burst reaction",
};

export const GraphElements: Record<
  Event,
  { color: string; icon: JSX.Element; iconClassName?: string; isBlockedLineStyle?: boolean }
> = {
  [Event.podOptimizedCount]: {
    color: SCALEOPS_COLORS.guideline.darkGreen,
    icon: <PositiveIcon />,
  },
  [Event.podEvictionCount]: {
    color: SCALEOPS_COLORS.primary.purpleBlue,
    icon: <OnGoingOptimizationIcon />,
  },
  [Event.optimizationBlockedCount]: {
    color: SCALEOPS_COLORS.strongBorder,
    icon: <DisableIcon />,
    isBlockedLineStyle: true,
  },
  [Event.isAuto]: {
    color: SCALEOPS_COLORS.main.green,
    icon: <></>,
  },
  [Event.recommendationCreationCount]: {
    color: SCALEOPS_COLORS.gpu.smActive,
    icon: <PositiveIcon />,
  },
  [Event.autoHealingCount]: {
    color: "rgba(45,174,16,0.5)",
    icon: <HealingEventIcon />,
  },
  [Event.burstReactionCount]: {
    color: SCALEOPS_COLORS.events.fastReaction,
    icon: <FastReactionEventIcon />,
  },
};

export type EventChartPoint = {
  timestamp: number;
  podOptimizedCount?: number;
  podEvictionCount?: number;
  optimizationBlockedCount?: number;
  recommendationCreationCount?: number;
  isAuto?: number;
  autoHealingCount?: number;
  burstReactionCount?: number;
  data?: {
    podOptimized?: {
      cpuBefore?: number;
      cpuAfter?: number;
      memoryBefore?: number;
      memoryAfter?: number;
    };
    optimizationBlocked?: {
      message?: string;
    };
    autoHealing?: {
      cpuStressNodesCount?: number;
      oom?: number;
    };
    burst?: {
      cpuCount?: number;
      memoryCount?: number;
    };

    autoHealingCount?: number;
    podEvictionCount?: number;
    podOptimizedCount?: number;
    recommendationCreationCount?: number;
    burstReactionCount?: number;
  };
};

export const exampleData: EventChartPoint[] = [
  {
    timestamp: 1733344113060,
  },
  {
    timestamp: 1733344245960,
    optimizationBlockedCount: 250,
    podEvictionCount: 3,
    podOptimizedCount: 123,
    autoHealingCount: 1,
    burstReactionCount: 1,
    recommendationCreationCount: 1,
    data: {
      podOptimized: {
        cpuBefore: 20,
        cpuAfter: 2,
        memoryBefore: 10000000000000,
        memoryAfter: 10000000,
      },
      optimizationBlocked: {
        message: "example of blocked optimization message",
      },
    },
  },
  {
    timestamp: 1733344378860,
    optimizationBlockedCount: 11250,
    podEvictionCount: 1,
    podOptimizedCount: 123,
    autoHealingCount: 1,
    burstReactionCount: 1,
    recommendationCreationCount: 1,
    data: {
      podOptimized: {
        cpuBefore: 20,
        cpuAfter: 2,
        memoryBefore: 10000000000000,
        memoryAfter: 10000000,
      },
      optimizationBlocked: {
        message: "example of blocked optimization message",
      },
    },
  },
  {
    timestamp: 1733344511760,
    optimizationBlockedCount: 250,
    podOptimizedCount: 12,
    autoHealingCount: 1,
    recommendationCreationCount: 1,
    data: {
      podOptimized: {
        cpuBefore: 20,
        cpuAfter: 2,
        memoryBefore: 10000000000000,
        memoryAfter: 10000000,
      },
      optimizationBlocked: {
        message: "example of blocked optimization message",
      },
    },
  },
  {
    timestamp: 1733344644660,
  },
  {
    timestamp: 1733344777560,
    optimizationBlockedCount: 250,
    podEvictionCount: 750,
    autoHealingCount: 1,
    burstReactionCount: 1,
    recommendationCreationCount: 1,
    data: {
      podOptimized: {
        cpuBefore: 20,
        cpuAfter: 2,
        memoryBefore: 10000000000000,
        memoryAfter: 10000000,
      },
      optimizationBlocked: {
        message: "example of blocked optimization message",
      },
    },
  },
  {
    timestamp: 1733344910460,
    optimizationBlockedCount: 250,
    podEvictionCount: 11,
    podOptimizedCount: 123,
    autoHealingCount: 1,
    burstReactionCount: 1,
    recommendationCreationCount: 1,
    data: {
      podOptimized: {
        cpuBefore: 20,
        cpuAfter: 2,
        memoryBefore: 10000000000000,
        memoryAfter: 10000000,
      },
      optimizationBlocked: {
        message: "example of blocked optimization message",
      },
    },
  },
  {
    timestamp: 1733345043360,
  },
  {
    timestamp: 1733345176260,
  },
];
