import { Typography } from "@mui/material";
import { DataGrid, GridSortDirection } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { StringParam, useQueryParam, withDefault } from "use-query-params";
import { components } from "../../../../api/schema";
import { DESC } from "../../../../components/WorkloadStatusByNamespaceSection/utils";
import { useMainContext } from "../../../../MainContext";
import { getDataGridSx, HEADER_HEIGHT, ROW_HEIGHT } from "../../../../utils/styleUtils";
import useStateWithLocalStorage from "../../../../utils/useStateWithLocalStorage";
import { getColumns, INITIAL_SORT_MODEL, ROW } from "./utils";

const WORKLOAD_NETWORK_PAGE_SIZE_LOCAL_STORAGE_KEY = "workloadNetworkPageSize";

interface Props {
  data: components["schemas"]["WorkloadsDestinationWorkload"][];
  isLoading: boolean;
  setSelectedWorkload: (workload: components["schemas"]["UtilsWorkload"] | undefined) => void;
}

const Table = ({ data, isLoading, setSelectedWorkload }: Props) => {
  const { currentCluster } = useMainContext();

  const [rows, setRows] = useState<ROW[]>([]);
  const [page, setPage] = useState<number>(0);
  const [rowCount, setRowCount] = useState<number>(0);
  const [sortField, setSortField] = useQueryParam(
    "sortWorkloadNetworkTableField",
    withDefault(StringParam, String("totalCost"))
  );
  const [sortDirection, setSortDirection] = useQueryParam(
    "sortWorkloadNetworkTableDirection",
    withDefault(StringParam, DESC)
  );
  const [isInitialSortModel, setIsInitialSortModel] = useState<boolean>(true);
  const [pageSize, setPageSize] = useStateWithLocalStorage<number>({
    localStorageKey: WORKLOAD_NETWORK_PAGE_SIZE_LOCAL_STORAGE_KEY,
    defaultValue: 25,
    valueFormatter: (value) => parseInt(value),
  });

  useEffect(() => {
    let sortedData = data.map((entity, index) => ({
      id: String(index),
      destination: `${String(entity.Namespace)}/${String(entity.Name)}`,
      ...entity,
    })) as ROW[];

    sortedData = sortedData?.slice(page * pageSize, (page + 1) * pageSize) ?? [];

    if (sortField && sortDirection) {
      if (sortField === "WorkloadType") {
        // sort by destination
        sortedData = sortedData.sort((a, b) => {
          if (a.destination > b.destination) {
            return sortDirection === DESC ? -1 : 1;
          }
          if (a.destination < b.destination) {
            return sortDirection === DESC ? 1 : -1;
          }
          return 0;
        });
      }

      // sort by totalCost
      if (sortField === "totalCost") {
        sortedData = sortedData.sort((a, b) => {
          if (Number(a.totalCost) > Number(b.totalCost)) {
            return sortDirection === DESC ? -1 : 1;
          }
          if (Number(a.totalCost) < Number(b.totalCost)) {
            return sortDirection === DESC ? 1 : -1;
          }
          return 0;
        });
      }

      // sort by intraAZCost
      if (sortField === "intraAZCost") {
        sortedData = sortedData.sort((a, b) => {
          if (Number(a.intraAZCost) > Number(b.intraAZCost)) {
            return sortDirection === DESC ? -1 : 1;
          }
          if (Number(a.intraAZCost) < Number(b.intraAZCost)) {
            return sortDirection === DESC ? 1 : -1;
          }
          return 0;
        });
      }

      // sort by crossAZCost
      if (sortField === "crossAZCost") {
        sortedData = sortedData.sort((a, b) => {
          if (Number(a.crossAZCost) > Number(b.crossAZCost)) {
            return sortDirection === DESC ? -1 : 1;
          }
          if (Number(a.crossAZCost) < Number(b.crossAZCost)) {
            return sortDirection === DESC ? 1 : -1;
          }
          return 0;
        });
      }

      // sort by replicas
      if (sortField === "replicas") {
        sortedData = sortedData.sort((a, b) => {
          if (Number(a.replicas) > Number(b.replicas)) {
            return sortDirection === DESC ? -1 : 1;
          }
          if (Number(a.replicas) < Number(b.replicas)) {
            return sortDirection === DESC ? 1 : -1;
          }
          return 0;
        });
      }

      // sort by totalTraffic
      if (sortField === "totalTraffic") {
        sortedData = sortedData.sort((a, b) => {
          if (Number(a.totalTraffic) > Number(b.totalTraffic)) {
            return sortDirection === DESC ? -1 : 1;
          }
          if (Number(a.totalTraffic) < Number(b.totalTraffic)) {
            return sortDirection === DESC ? 1 : -1;
          }
          return 0;
        });
      }

      // sort by crossAZTraffic
      if (sortField === "crossAZTraffic") {
        sortedData = sortedData.sort((a, b) => {
          if (Number(a.crossAZTraffic) > Number(b.crossAZTraffic)) {
            return sortDirection === DESC ? -1 : 1;
          }
          if (Number(a.crossAZTraffic) < Number(b.crossAZTraffic)) {
            return sortDirection === DESC ? 1 : -1;
          }
          return 0;
        });
      }
    }

    sortedData = sortedData?.slice(page * pageSize, (page + 1) * pageSize) ?? [];

    setRows(sortedData);
    setRowCount(sortedData.length);
  }, [data, page, pageSize, sortField, sortDirection]);

  return (
    <div className="flex flex-col gap-2">
      <Typography variant="body2" fontWeight="bold">
        Explore Connected Workloads
      </Typography>
      <DataGrid
        pagination={true}
        headerHeight={HEADER_HEIGHT}
        autoHeight
        rowHeight={ROW_HEIGHT}
        sx={{ ...getDataGridSx(false, false) }}
        columns={getColumns(setSelectedWorkload, currentCluster ?? "")}
        rows={rows}
        initialState={{
          pagination: {
            pageSize: pageSize,
          },
        }}
        pageSize={pageSize}
        onPageChange={(newPage) => {
          setPage(newPage);
        }}
        onPageSizeChange={(newPageSize) => {
          setPageSize(newPageSize);
        }}
        loading={isLoading}
        experimentalFeatures={{ newEditingApi: true }}
        paginationMode="server"
        sortingMode="server"
        onSortModelChange={(model) => {
          if (isInitialSortModel) setIsInitialSortModel(false);
          setSortField(model[0]?.field);
          setSortDirection(model[0]?.sort);
        }}
        sortModel={
          isInitialSortModel && !sortField && !sortDirection
            ? INITIAL_SORT_MODEL
            : [
                {
                  field: String(sortField),
                  sort: String(sortDirection) as GridSortDirection,
                },
              ]
        }
        rowCount={rowCount}
        disableSelectionOnClick
      />
    </div>
  );
};

export default Table;
