import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { GetUnevictableData, GetUnevictableDataResponse } from "../../api/fetcher";
import AdvancedSettingsIcon from "../../Icons/AdvancedSettingsIcon";
import BinPackingIcon from "../../Icons/BinPackingIcon";
import DisableIcon from "../../Icons/DisableIcon";
import MultipleCubesIcon from "../../Icons/MultipleCubesIcon";
import { useMainContext } from "../../MainContext";
import { get24HoursAgoEpoch } from "../../utils/dateAndTimeUtils";
import { HAS_NEW_BLOCKED_NODES_TABLE, HAS_SCHEDULE_BLOCKERS_GRAPH } from "../../utils/developmentFeatureFlags";
import { TabSelectedTime } from "../../utils/tabUtils";
import ScheduleBlockersGraphContainer from "../autoscalers/ScheduleBlockersGraph/ScheduleBlockersGraphContainer";
import UnEvictableContainer from "../autoscalers/UnEvictableV/UnEvictableContainer";
import BlockedNodes from "./BlockedNodes/BlockedNodes";
import BlockedNodesTableContainer from "./BlockedNodesTable/BlockedNodesTableContainer";
import TopSection from "./TopSection";
import UnevictableTable from "./UnevictableTable";
import useUnevictableRollout from "./useUnevictableRollout";
import { UevictableTableRowType } from "./Utils";

const { queryFn, queryKey } = GetUnevictableData();
let foundClusterAutoscalerShowKubeSystem = false;
const ICON_SIZE = 29;
const ICON_CLASS_NAME = "text-white fill-current m-2";
const ICON_PROPS = { width: ICON_SIZE, height: ICON_SIZE, className: ICON_CLASS_NAME };

{
  /* REMINDER: DO NOT MESS WITH ISRAEL! 😍  */
}
const actions = {
  unevictableWorkloads: {
    title: "Unevictable workloads",
    description: "Bin pack pods with PDB or annotation that prevent node scale down.",
    icon: <MultipleCubesIcon {...ICON_PROPS} />,
    exploreWorkloadsLink: "/?unevictableTypes=unevictable",
  },
  ownerlessWorkloads: {
    title: "Pods without owner",
    description: "Bin pack pods without owner that prevent node scale down.",
    icon: <BinPackingIcon {...ICON_PROPS} />,
  },
  unhealthyWorkloads: {
    title: "Un-ready workloads",
    description: "Bin pack pods of un-ready workloads with availability issues that prevent node scale down.",
    icon: <DisableIcon {...ICON_PROPS} />,
    exploreWorkloadsLink: "/?unevictableTypes=notHealthy",
  },
  kubeSystemWorkloads: {
    title: "Kube-system workloads",
    description: "Bin pack kube-system pods that prevent node scale down.",
    icon: <AdvancedSettingsIcon {...ICON_PROPS} />,
  },
};

export default function Unevictable() {
  const unevictableRollout = useUnevictableRollout();

  const { currentCluster } = useMainContext();
  const [rows, setRows] = useState<UevictableTableRowType[]>([]);
  const [startTime, setStartTime] = useState<number | undefined>(get24HoursAgoEpoch(Date.now()));
  const [selectedTime, setSelectedTime] = useState<TabSelectedTime>(TabSelectedTime.LAST_1_DAY);

  const { data, isLoading, isError, error } = useQuery<GetUnevictableDataResponse, Error>({
    queryKey: [queryKey, startTime, currentCluster],
    queryFn: () =>
      queryFn({
        from: startTime,
        to: selectedTime === TabSelectedTime.LIVE ? undefined : Date.now(),
      }),
  });

  useEffect(() => {
    if (data) {
      const unevictableWorkloads = {
        ...data.unevictableWorkloads,
        id: "binPackUnevictablePods",
        action: actions["unevictableWorkloads"],
        rolloutAction: () => unevictableRollout.mutate({ binPackUnevictablePods: true }),
      };
      const ownerlessWorkloads = {
        id: "binPackOwnerlessPods",
        action: actions["ownerlessWorkloads"],
        ...data.ownerlessWorkloads,
      };
      const unhealthyWorkloads = {
        ...data.unhealthyWorkloads,
        id: "binPackUnHealthyPods",
        action: actions["unhealthyWorkloads"],
      };
      const kubeSystemWorkloads = {
        ...data.kubeSystemWorkloads,
        id: "binPackKubeSystemPods",
        action: actions["kubeSystemWorkloads"],
        rolloutAction: () => unevictableRollout.mutate({ binPackKubeSystemPods: true }),
      };

      foundClusterAutoscalerShowKubeSystem = data?.foundClusterAutoscalerShowKubeSystem ?? false;
      if (foundClusterAutoscalerShowKubeSystem) {
        setRows([unevictableWorkloads, ownerlessWorkloads, unhealthyWorkloads, kubeSystemWorkloads]);
      } else {
        setRows([unevictableWorkloads, ownerlessWorkloads, unhealthyWorkloads]);
      }
    }
  }, [data]);

  if (isError) {
    console.log(error);
    return null;
  }

  let totalOptimizedPods = data?.optimizedPods ?? 0;
  let totalUnOptimizedPods = Number(data?.totalPods ?? 0) - totalOptimizedPods;
  if (!foundClusterAutoscalerShowKubeSystem) {
    totalOptimizedPods = totalOptimizedPods - (data?.kubeSystemWorkloads?.optimizedPods ?? 0);
    totalUnOptimizedPods =
      Number(data?.unevictableWorkloads?.totalPods ?? 0) +
      Number(data?.ownerlessWorkloads?.totalPods ?? 0) +
      Number(data?.unhealthyWorkloads?.totalPods ?? 0) -
      totalOptimizedPods;
  }

  return (
    <div className="flex flex-col gap-5">
      <TopSection
        monthlyCost={data?.cost ?? 0}
        totalOptimizedPods={totalOptimizedPods}
        totalUnOptimizedPods={totalUnOptimizedPods}
        wastedSpend={data?.wastedSpend ?? 0}
        blockedNodes={data?.blockedNodes ?? 0}
        unevictableBlockedNodes={data?.unevictableBlockedNodes ?? 0}
        selectedTime={selectedTime}
        setSelectedTime={setSelectedTime}
        setStartTime={setStartTime}
        isLoading={isLoading}
      />
      {HAS_SCHEDULE_BLOCKERS_GRAPH && <ScheduleBlockersGraphContainer />}
      <UnevictableTable rows={rows} isLoading={isLoading} />
      {HAS_NEW_BLOCKED_NODES_TABLE && <BlockedNodesTableContainer />}
      {!HAS_NEW_BLOCKED_NODES_TABLE && (
        <BlockedNodes foundClusterAutoscalerShowKubeSystem={foundClusterAutoscalerShowKubeSystem} />
      )}
      <UnEvictableContainer
        foundClusterAutoscalerShowKubeSystem={foundClusterAutoscalerShowKubeSystem}
        topSection={false}
        showBlockedCharts={false}
      />
    </div>
  );
}
