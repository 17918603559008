import { CircularProgress } from "@mui/material";
import clsx from "clsx";
import { cloneElement, useEffect, useState } from "react";
import {
  CartesianGrid,
  ComposedChart,
  Line,
  ReferenceArea,
  ResponsiveContainer,
  Scatter,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { ObjectParam, useQueryParam } from "use-query-params";
import { SCALEOPS_COLORS } from "../../../../colors";
import CustomLegend from "../../../../components/CustomLegend";
import DisableIcon from "../../../../Icons/DisableIcon";
import { adjustedDayjs } from "../../../../utils/dateAndTimeUtils";
import { getTimeFormatFromEpochAndPeriodInHours } from "../../../../utils/formatterUtils";
import { POLICY_TUNING_DATES_URL_PARAM, useViewPeriodQueryParams } from "../utils";
import CustomTooltip from "./CustomTooltip";
import { Event, EventChartPoint, EventsDisplayName, GraphElements } from "./utils";

const PERSISTENT_EVENT_SIZE = 20;
const Y_AXIS_LOWEST_POSITION = 250;
const CHART_HEIGHT = 450;
const MIN_OPACITY = 25;
const NUMBER_OF_UNIQUE_ELEMENTS = Object.keys(Event).filter((key) => key.includes("Count"))?.length + 1 || 0;

interface Props {
  data: EventChartPoint[];
  isLoading: boolean;
}

const WorkloadHistoryEventChart = ({ data, isLoading }: Props) => {
  const [tooltipPayloadContext, setTooltipPayloadContext] = useState<EventChartPoint | undefined>(undefined);
  const [selectedViewPeriod, setSelectedViewPeriod] = useViewPeriodQueryParams();

  const [, setDates] = useQueryParam(POLICY_TUNING_DATES_URL_PARAM, ObjectParam);

  const [selectPosition, setSelectPosition] = useState<
    { from?: number; to?: number; fromX?: string; toX?: string } | undefined
  >(undefined);

  const [selectedChartComponents, setSelectedChartComponents] = useState<Event[]>(Object.values(Event));
  const [maxValue, setMaxValue] = useState<number>(0);

  const [displayData, setDisplayData] = useState<EventChartPoint[]>([]);
  const [yAxisMaxDomain, setYAxisMaxDomain] = useState<number>(1000);

  useEffect(() => {
    if (data) {
      const maxValue = Math.max(
        ...data.map((d) => {
          return Object.values(Event).reduce((acc, eventKey) => {
            if (eventKey === Event.optimizationBlockedCount) {
              return acc;
            }
            return d?.[eventKey] ? acc + 1 : acc;
          }, 0);
        })
      );
      setMaxValue(maxValue);

      const newDisplayData = data.map((d) => ({
        ...d,
        ...Object.values(Event)
          .filter((eventKey) => eventKey !== Event.optimizationBlockedCount)
          .reduce((acc, eventKey, index) => {
            acc[eventKey] = d?.[eventKey] ? Y_AXIS_LOWEST_POSITION * (index + 2) : undefined;
            return acc;
          }, {} as Record<Event, number | undefined>),
        [Event.optimizationBlockedCount]: d?.[Event.optimizationBlockedCount] ? Y_AXIS_LOWEST_POSITION : undefined,
        [Event.isAuto]: d?.[Event.isAuto] ? 0 : undefined,
      }));
      setDisplayData(newDisplayData);

      const yAxisMaxDomainValue = Y_AXIS_LOWEST_POSITION * (NUMBER_OF_UNIQUE_ELEMENTS + 1);
      if (yAxisMaxDomainValue) setYAxisMaxDomain(yAxisMaxDomainValue);
    }
  }, [data]);

  const noData = !data || !data.length;

  const firstXPointString = String(displayData[0]?.timestamp);
  const firstXPointEpoch = adjustedDayjs(firstXPointString).unix();
  const lastXPointString = String(displayData[displayData.length - 1]?.timestamp);
  const lastXPointEpoch = adjustedDayjs(lastXPointString).unix();

  const setDateRange = () => {
    if (selectPosition?.from && selectPosition?.to) {
      const from = Math.min(selectPosition?.from || 0, selectPosition?.to || firstXPointEpoch || 0) * 1000;
      const to = Math.max(selectPosition?.from || 0, selectPosition?.to || lastXPointEpoch || 0) * 1000;
      setDates({ from: String(from), to: String(to) });
      if (from && to && setSelectedViewPeriod) {
        setSelectedViewPeriod(String(Math.round((to - from) / 60 / 60)));
      }
    }
    setSelectPosition(undefined);
  };

  useEffect(() => {
    window.addEventListener("mouseup", setDateRange);
    return () => {
      window.removeEventListener("mouseup", setDateRange);
    };
  }, [selectPosition, setDateRange]);

  if (isLoading) {
    return (
      <div
        className="border border-border rounded-lg flex justify-center items-center"
        style={{
          height: CHART_HEIGHT + 80,
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="border border-border rounded-lg py-8">
      <div
        className="w-full"
        style={{
          height: CHART_HEIGHT,
        }}
      >
        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart
            data={displayData}
            margin={{
              top: 20,
              right: 80,
              bottom: 20,
              left: 20,
            }}
            onMouseDown={(e) => {
              e.activeLabel &&
                setSelectPosition({
                  ...selectPosition,
                  from: adjustedDayjs(e.activeLabel).unix(),
                  fromX: e.activeLabel,
                });
            }}
            onMouseMove={(e) => {
              selectPosition?.from &&
                setSelectPosition({
                  ...selectPosition,
                  to: adjustedDayjs(e.activeLabel).unix(),
                  toX: e.activeLabel,
                });
            }}
            onMouseLeave={() => {
              if (selectPosition?.from && selectPosition?.to) {
                if (selectPosition?.from < selectPosition?.to) {
                  setSelectPosition({
                    ...selectPosition,
                    to: lastXPointEpoch,
                    toX: lastXPointString,
                  });
                } else {
                  setSelectPosition({
                    to: selectPosition.from,
                    toX: selectPosition.fromX,
                    from: firstXPointEpoch,
                    fromX: firstXPointString,
                  });
                }
              }
            }}
          >
            <CartesianGrid opacity={0.2} />
            <Tooltip
              content={
                <CustomTooltip
                  data={data}
                  selectedChartComponents={selectedChartComponents}
                  tooltipPayloadContext={tooltipPayloadContext}
                  setTooltipPayloadContext={setTooltipPayloadContext}
                />
              }
              wrapperStyle={{
                outline: "none",
                pointerEvents: "auto",
              }}
            />
            {selectedChartComponents.includes(Event.optimizationBlockedCount) && (
              <Line
                type="monotone"
                dataKey={Event.optimizationBlockedCount}
                stroke={SCALEOPS_COLORS.strongBorder}
                strokeWidth={PERSISTENT_EVENT_SIZE * 0.9}
                strokeOpacity={0.5}
                strokeLinecap="round"
                dot={({ cx, cy, index }: { cx: number; cy: number; index: number }) => {
                  const currData = data[index];
                  const prevData = data[index - 1];
                  const nextData = data[index + 1];

                  const radius = PERSISTENT_EVENT_SIZE / 2;
                  const isSingleEvent =
                    !prevData?.[Event.optimizationBlockedCount] && !nextData?.[Event.optimizationBlockedCount];
                  const foreignObjectX = cx - (!isSingleEvent ? radius : radius * 1.08);
                  const foreignObjectY = cy - (!isSingleEvent ? radius : radius);

                  if (!prevData?.[Event.optimizationBlockedCount] && currData?.[Event.optimizationBlockedCount]) {
                    return (
                      <foreignObject
                        x={foreignObjectX}
                        y={foreignObjectY}
                        width={PERSISTENT_EVENT_SIZE}
                        height={PERSISTENT_EVENT_SIZE}
                      >
                        <DisableIcon
                          width={PERSISTENT_EVENT_SIZE}
                          height={PERSISTENT_EVENT_SIZE}
                          className="text-white rounded-full bg-strongBorder p-[5%]"
                        />
                      </foreignObject>
                    );
                  }
                  return <></>;
                }}
                activeDot={false}
              />
            )}

            {selectedChartComponents.includes(Event.isAuto) && (
              <Line
                type="linear"
                dataKey={Event.isAuto}
                stroke={SCALEOPS_COLORS.main.green}
                strokeWidth={6}
                dot={false}
                isAnimationActive={false}
              />
            )}
            {Object.values(Event).map((eventKey: Event) => {
              if (
                !selectedChartComponents.includes(eventKey) ||
                eventKey === Event.optimizationBlockedCount ||
                eventKey === Event.isAuto
              ) {
                return null;
              }

              const icon = cloneElement(GraphElements[eventKey].icon, {
                width: PERSISTENT_EVENT_SIZE,
                height: PERSISTENT_EVENT_SIZE,
                className: clsx(GraphElements[eventKey].iconClassName, "text-white p-[5%]"),
              });

              return (
                <Scatter
                  name={eventKey}
                  dataKey={eventKey}
                  shape={(entry: { cx: number; cy: number; payload: EventChartPoint }) => {
                    const { cx, cy } = entry;

                    if (!cx || !cy) {
                      return <></>;
                    }

                    const value = entry?.payload?.data?.[eventKey] ?? 0;
                    const valuePercentageOfMax = (value / maxValue) * 100;
                    let opacity = MIN_OPACITY + valuePercentageOfMax;
                    if (opacity > 100) {
                      opacity = 100;
                    }

                    return (
                      <foreignObject
                        x={cx - 10}
                        y={cy - 10}
                        width={PERSISTENT_EVENT_SIZE}
                        height={PERSISTENT_EVENT_SIZE}
                        style={{
                          backgroundColor: GraphElements[eventKey].color,
                          borderRadius: "100%",
                          opacity: `${opacity}%`,
                        }}
                        onMouseEnter={() => {
                          setTooltipPayloadContext(entry.payload);
                        }}
                        onMouseLeave={() => {
                          setTooltipPayloadContext(undefined);
                        }}
                      >
                        {icon}
                      </foreignObject>
                    );
                  }}
                />
              );
            })}
            {selectPosition?.fromX && selectPosition?.toX ? (
              <ReferenceArea
                x1={selectPosition?.fromX}
                x2={selectPosition?.toX}
                stroke="#3B8BFF"
                fill="#3B8BFF"
                fillOpacity={0.3}
                strokeOpacity={0.3}
              />
            ) : null}
            <XAxis
              dataKey="timestamp"
              tickLine={false}
              strokeWidth={2}
              style={{ fontSize: "x-small" }}
              tickFormatter={(value) => {
                if (noData) return "";
                return getTimeFormatFromEpochAndPeriodInHours(Number(value), selectedViewPeriod);
              }}
              interval={Math.floor(displayData.length / 5)}
            />
            <YAxis
              domain={[0, () => yAxisMaxDomain]}
              style={{ fontSize: "x-small" }}
              tickLine={false}
              strokeWidth={2}
              tickFormatter={() => ""}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
      <div className="w-full max-h-[80px] overflow-y-auto scrollbar-thin scrollbar-thumb-background-chip scrollbar-track-guideline-lightGray scrollbar-thumb-rounded-md scrollbar-track-rounded-md">
        <CustomLegend<Event>
          selectedChartComponents={selectedChartComponents}
          setSelectedChartComponents={setSelectedChartComponents}
          componentStyle={Object.values(Event).reduce((acc, eventKey) => {
            acc[eventKey] = {
              color: GraphElements[eventKey].color,
            };
            return acc;
          }, {} as Record<Event, { color: string }>)}
          ChartComponents={Object.values(Event).reduce((acc, eventKey) => {
            acc[eventKey] = eventKey;
            return acc;
          }, {} as Record<string, Event>)}
          renderNameFunction={(key) => {
            const element = Object.values(Event).find((eventKey) => eventKey === key);
            return EventsDisplayName[element ?? Event.podOptimizedCount];
          }}
          className="-mt-1"
          fontWeight={400}
          fontSpanClassName="text-[10px]"
          hasTooltip
        />
      </div>
    </div>
  );
};

export default WorkloadHistoryEventChart;
