import dayjs, { Dayjs } from "dayjs";
import { adjustedDayjs } from "../utils/dateAndTimeUtils";
import { DEFAULT_DATE_TIME_FORMAT } from "../utils/formatterUtils";

interface Props {
  timestamp: string | number | Dayjs;
  timeFormat?: string;
  fontWeight?: number;
  fontSize?: string;
}
const ChartTooltipTime = ({
  timestamp,
  timeFormat = DEFAULT_DATE_TIME_FORMAT,
  fontWeight = 600,
  fontSize = "10px",
}: Props) => {
  let dayjsValue = adjustedDayjs(timestamp).format(timeFormat);
  if (dayjsValue === "Invalid Date") {
    dayjsValue = dayjs(timestamp, "YYYY-MM-DD HH:mm:ss Z").format(timeFormat);
  }
  return <p style={{ fontWeight, fontSize }}>{dayjsValue}</p>;
};

export default ChartTooltipTime;
