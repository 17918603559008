import { useFormikContext } from "formik";
import FormSingleSelect, { RenderValueType } from "../../../../components/formComponents/FormSingleSelect";
import FormSwitch from "../../../../components/formComponents/FormSwitch";
import FormTitleAndDescription from "../../../../components/formComponents/FormTitleAndDescription";
import useIsReadyOnlyFrontEnd from "../../../../utils/useIsReadyOnlyFrontEnd";
import { HPA_SECTION_CLASS_NAME } from "../../../Policies/EditFormSections/utils";
import { minReplicaPercentileOptions, MinReplicasStrategy, minReplicaWindowOptions } from "./utils";
import { IsSnapshotServer } from "../../../../utils/FeaturesHelper";

interface Props {
  isCustomizedPolicy?: boolean;
}

const StaticWorkloadsMinReplicas = ({ isCustomizedPolicy }: Props) => {
  const isReadOnlyFrontEnd = useIsReadyOnlyFrontEnd();

  const { values } = useFormikContext<{
    minReplicasStrategy: MinReplicasStrategy;
  }>();

  const isEnabled =
    values.minReplicasStrategy === MinReplicasStrategy.SetHistoryBasedForAllWorkloads ||
    values.minReplicasStrategy === MinReplicasStrategy.SetHistoryBasedForStaticWorkloads;

  return (
    <div className={HPA_SECTION_CLASS_NAME}>
      <FormTitleAndDescription
        title="Min replicas"
        description="Define the history window and percentile for the suggested min replicas optimization."
      />
      <div className="flex flex-col gap-4">
        <FormSwitch
          label="Enable min replicas optimization"
          name="staticWorkloadsEnabled"
          disableChange={!IsSnapshotServer() && (!isCustomizedPolicy || isReadOnlyFrontEnd)}
          disabled={!isEnabled}
        />
        <div className="flex gap-8">
          <FormSingleSelect
            label="History window"
            name="staticWorkloadsHistoryWindow"
            options={minReplicaWindowOptions}
            areOptionsDisabled={!IsSnapshotServer() && !isCustomizedPolicy}
            disabled={!isEnabled}
            defaultValue="336h"
          />
          <FormSingleSelect
            label="Percentile"
            name="staticWorkloadsPercentile"
            options={minReplicaPercentileOptions}
            areOptionsDisabled={!IsSnapshotServer() && !isCustomizedPolicy}
            renderValueType={RenderValueType.Percentage}
            disabled={!isEnabled}
            defaultValue="100"
          />
        </div>
      </div>
    </div>
  );
};

export default StaticWorkloadsMinReplicas;
